import React from 'react';
import ImageThumbnail from './ImageThumbnail';
import FileThumbnail from './FileThumbnail';

const ThumbnailWrapper = ({ file, onRemove }) => {
  // Función para determinar si es una imagen
  const isImage = (file) => {
    const imageTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/webp',
      'image/svg+xml'
    ];
    return imageTypes.includes(file.type);
  };

  // Función para determinar si es un archivo de documento
  const isDocument = (file) => {
    const documentTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain',
      'text/csv'
    ];
    return documentTypes.includes(file.type);
  };

  // Si es una imagen, usar ImageThumbnail
  if (isImage(file)) {
    return <ImageThumbnail file={file} onRemove={onRemove} />;
  }

  // Para todos los demás tipos de archivo (documentos, PDFs, etc)
  // usar FileThumbnail
  if (isDocument(file)) {
    return <FileThumbnail file={file} onRemove={onRemove} />;
  }

  // Para cualquier otro tipo de archivo que no reconozcamos
  // también usamos FileThumbnail como fallback
  return <FileThumbnail file={file} onRemove={onRemove} />;
};

export default ThumbnailWrapper;