import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Chip, 
  TextField, 
  Avatar, 
  Rating,
  useMediaQuery,
  Tabs,
  Tab
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  Search,
  Filter,
  MessageSquare,
  Star,
  Globe,
  Truck,
  DollarSign,
  Shield,
  Clock,
  BadgeCheck,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const SupplierCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  textAlign: 'center',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(40, 40, 42, 0.94)'
    : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(118, 118, 128, 0.24)'
    : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow: theme.palette.mode === 'dark'
    ? 'none'
    : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)'
    : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  }
}));

const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  }
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background: theme.palette.mode === 'dark' 
      ? '#1C1C1E'
      : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden',
  }
}));

const AGTSupplierFinder = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(0);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeNegotiations] = useState([
    {
      id: 1,
      name: 'Tech Components Ltd',
      status: 'In Progress',
      lastMessage: 'Discussing volume discounts',
      progress: 65,
    },
    {
      id: 2,
      name: 'Global Materials Inc',
      status: 'Pending',
      lastMessage: 'Awaiting price quote',
      progress: 30,
    },
  ]);

  const navigationTabs = [
    { id: 0, label: 'Search', disabled: false },
    { id: 1, label: 'Active', disabled: false },
    { id: 2, label: 'Stats', disabled: false },
    { id: 3, label: 'Recent', disabled: false }
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView !== isMobile) {
        setVisibleRange({ start: 0, end: 3 });
      }
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleRange({ start: 0, end: navigationTabs.length });
      return;
    }

    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return;

    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
    } else if (activeIdx >= navigationTabs.length - 3) {
      setVisibleRange({ 
        start: navigationTabs.length - 3, 
        end: navigationTabs.length 
      });
    } else {
      setVisibleRange({
        start: activeIdx - 1,
        end: activeIdx + 2
      });
    }
  }, [activeTab, navigationTabs.length, isMobile]);

  const getIndicatorStyle = useCallback(() => {
    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return {};
    
    const visibleIdx = activeIdx - visibleRange.start;
    const offset = visibleIdx * (74 + 4);
    return {
      transform: `translateX(${offset}px)`,
    };
  }, [activeTab, visibleRange.start, navigationTabs]);

  const handleTabChange = useCallback((event, newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      const tab = navigationTabs.find(tab => tab.id === newValue);
      
      if (tab?.disabled) {
        return;
      }

      lastNavigationTime.current = now;
      setActiveTab(newValue);
    }
  }, [navigationTabs]);

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case 0:
        return (
          <Box className="space-y-6">
            <StyledPaper>
              <SearchBar>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search suppliers by name, location, or product..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: <Search size={20} className="mr-2 text-gray-400" />,
                  }}
                />
                <ActionButton>
                  <Filter size={16} />
                  Filters
                </ActionButton>
              </SearchBar>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <StatBox>
                    <Globe size={24} className="mx-auto mb-2 text-blue-500" />
                    <Typography variant="h4">150+</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Countries
                    </Typography>
                  </StatBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatBox>
                    <Truck size={24} className="mx-auto mb-2 text-green-500" />
                    <Typography variant="h4">10k+</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Suppliers
                    </Typography>
                  </StatBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatBox>
                    <DollarSign size={24} className="mx-auto mb-2 text-purple-500" />
                    <Typography variant="h4">95%</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Price Match
                    </Typography>
                  </StatBox>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <StatBox>
                    <Shield size={24} className="mx-auto mb-2 text-red-500" />
                    <Typography variant="h4">100%</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Verified
                    </Typography>
                  </StatBox>
                </Grid>
              </Grid>
            </StyledPaper>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>Recommended Suppliers</Typography>
                  
                  <SupplierCard>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={8}>
                        <Box className="flex items-center gap-3 mb-3">
                          <Avatar sx={{ width: 50, height: 50 }}>TC</Avatar>
                          <Box>
                            <Box className="flex items-center gap-2">
                              <Typography variant="h6">Tech Components Ltd</Typography>
                              <BadgeCheck size={16} className="text-blue-500" />
                            </Box>
                            <Rating value={4.5} readOnly size="small" />
                          </Box>
                        </Box>
                        <Box className="space-y-2">
                          <Box className="flex gap-2">
                            <Chip icon={<Globe size={14} />} label="United States" size="small" />
                            <Chip icon={<Clock size={14} />} label="Fast Response" size="small" color="success" />
                            <Chip icon={<Star size={14} />} label="Top Rated" size="small" color="primary" />
                          </Box>
                          <Typography variant="body2" color="text.secondary">
                            Specialized in electronic components and manufacturing supplies. 
                            ISO 9001 certified with 10+ years of experience.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box className="space-y-2">
                          <ActionButton className="w-full">
                            <MessageSquare size={16} />
                            Contact Supplier
                          </ActionButton>
                          <ActionButton className="w-full">
                            View Details
                          </ActionButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </SupplierCard>

                  <SupplierCard>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={8}>
                        <Box className="flex items-center gap-3 mb-3">
                          <Avatar sx={{ width: 50, height: 50 }}>GM</Avatar>
                          <Box>
                            <Box className="flex items-center gap-2">
                              <Typography variant="h6">Global Materials Inc</Typography>
                              <BadgeCheck size={16} className="text-blue-500" />
                            </Box>
                            <Rating value={4.8} readOnly size="small" />
                          </Box>
                        </Box>
                        <Box className="space-y-2">
                          <Box className="flex gap-2">
                            <Chip icon={<Globe size={14} />} label="Germany" size="small" />
                            <Chip icon={<Truck size={14} />} label="Free Shipping" size="small" color="success" />
                            <Chip icon={<Shield size={14} />} label="Verified" size="small" color="primary" />
                          </Box>
                          <Typography variant="body2" color="text.secondary">
                            Leading supplier of industrial materials and custom solutions.
                            Known for quality and reliable delivery times.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box className="space-y-2">
                          <ActionButton className="w-full">
                            <MessageSquare size={16} />
                            Contact Supplier
                          </ActionButton>
                          <ActionButton className="w-full">
                            View Details
                          </ActionButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </SupplierCard>
                </StyledPaper>
              </Grid>
            </Grid>
          </Box>
        );

      case 1:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Active Negotiations</Typography>
            {activeNegotiations.map((negotiation) => (
              <Box 
                key={negotiation.id}
                className="p-4 bg-gray-50 rounded-lg mb-3"
              >
                <Box className="flex justify-between items-start mb-2">
                  <Typography variant="subtitle1">{negotiation.name}</Typography>
                  <Chip 
                    label={negotiation.status} 
                    size="small"
                    color={negotiation.status === 'In Progress' ? 'success' : 'warning'}
                  />
                </Box>
                <Box className="flex items-center gap-2 mb-2">
                  <MessageSquare size={14} className="text-gray-400" />
                  <Typography variant="body2" color="text.secondary">
                    {negotiation.lastMessage}
                  </Typography>
                </Box>
                <Box>
                  <Box className="flex justify-between mb-1">
                    <Typography variant="caption" color="text.secondary">
                      Negotiation Progress
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {negotiation.progress}%
                    </Typography>
                  </Box>
                  <Box className="w-full bg-gray-200 rounded-full h-2">
                    <Box
                      className="bg-blue-600 h-2 rounded-full"
                      style={{ width: `${negotiation.progress}%` }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
            
            <ActionButton className="w-full mt-4">
              View All Negotiations
            </ActionButton>
          </StyledPaper>
        );

      case 2:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Quick Stats</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box className="p-3 bg-blue-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Average Response Time
                  </Typography>
                  <Typography variant="h6">2.5 hrs</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-green-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Success Rate
                  </Typography>
                  <Typography variant="h6">85%</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-purple-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Active Quotes
                  </Typography>
                  <Typography variant="h6">12</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-yellow-50 rounded-lg">
                  <Typography variant="caption" color="text.secondary">
                    Pending Reviews
                  </Typography>
                  <Typography variant="h6">3</Typography>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        );

      case 3:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Recent Activity</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box className="p-4 border rounded-lg">
                  <Box className="flex items-center gap-2 mb-2">
                    <MessageSquare size={16} className="text-blue-500" />
                    <Typography variant="subtitle2">New Message</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Tech Components Ltd sent you a new price quote
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    2 hours ago
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="p-4 border rounded-lg">
                  <Box className="flex items-center gap-2 mb-2">
                    <Star size={16} className="text-yellow-500" />
                    <Typography variant="subtitle2">New Review</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    You received a 5-star rating from Global Materials Inc
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Yesterday
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="p-4 border rounded-lg">
                  <Box className="flex items-center gap-2 mb-2">
                    <Truck size={16} className="text-green-500" />
                    <Typography variant="subtitle2">Order Update</Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    Order #12345 has been shipped and is on its way
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    2 days ago
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        );

      default:
        return null;
    }
  }, [activeTab, searchQuery, activeNegotiations]);

  return (
    <Box className="space-y-4">
      <NavigationContainer>
        <Box>
          {isMobile && activeTab !== 0 && (
            <NavigationButton
              onClick={() => handleTabChange(null, 0)}
              aria-label="Go back"
            >
              <ChevronLeft size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}

          <StyledTabs
            ref={tabsRef}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="supplier finder navigation tabs"
          >
            <TabIndicator style={getIndicatorStyle()} />
            {navigationTabs
              .slice(visibleRange.start, visibleRange.end)
              .map((tab) => (
                <StyledTab 
                  key={tab.id}
                  label={tab.label}
                  disabled={tab.disabled}
                  disableRipple
                  value={tab.id}
                />
              ))}
          </StyledTabs>

          {isMobile && visibleRange.end < navigationTabs.length && (
            <NavigationButton
              onClick={() => {
                const nextTab = navigationTabs[visibleRange.end];
                handleTabChange(null, nextTab.id);
              }}
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}
        </Box>
      </NavigationContainer>

      {renderContent()}
    </Box>
  );
};

export default AGTSupplierFinder;