import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Eye } from 'lucide-react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

const shimmerAnimation = keyframes`
  0% {
    transform: translateX(-100%) skewX(-15deg);
  }
  100% {
    transform: translateX(200%) skewX(-15deg);
  }
`;

const VisionContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: '4px',
  marginTop: '24px',
  minHeight: '120px',
  transition: 'all 0.3s ease'
}));

const VisionBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -12,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#4A5568', // Color gris en lugar de celeste
  color: '#fff',
  padding: '8px 16px',
  borderRadius: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '0.875rem',
  fontWeight: 500,
  zIndex: 2,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  whiteSpace: 'nowrap',
  minWidth: 'fit-content',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.1), transparent)',
    animation: `${shimmerAnimation} 5s infinite`,
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  padding: '24px',
  borderRadius: '16px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(74, 85, 104, 0.1)' 
    : 'rgba(74, 85, 104, 0.05)',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark'
    ? 'rgba(74, 85, 104, 0.2)'
    : 'rgba(74, 85, 104, 0.15)',
  transition: 'all 0.3s ease',
  minHeight: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
}));

const VisionMessage = ({ content }) => {
  const theme = useTheme();
  
  const parseContent = (content) => {
    try {
      if (typeof content === 'string') {
        const parsed = JSON.parse(content);
        return parsed.text || content;
      }
      if (content?.text) {
        const parsed = JSON.parse(content.text);
        return parsed.text;
      }
      return '';
    } catch (e) {
      return content || '';
    }
  };

  const messageText = parseContent(content);

  return (
    <VisionContainer>
      <VisionBadge>
        <Eye size={16} />
        <span>AGT Vision</span>
      </VisionBadge>
      
      <ContentWrapper>
        <Typography 
          sx={{ 
            fontSize: '17px', // Tamaño de fuente ajustado a 17px
            lineHeight: 1.6,
            color: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.9)' 
              : 'rgba(0, 0, 0, 0.87)',
            textAlign: 'center',
            fontStyle: 'italic',
            width: '100%',
            padding: '0 16px'
          }}
        >
          "{messageText}"
        </Typography>
      </ContentWrapper>
    </VisionContainer>
  );
};

export default VisionMessage;