import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Slider,
  TextField,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Button,
  useTheme
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  DollarSign,
  TrendingUp,
  TrendingDown,
  Calculator,
  LineChart,
  BarChart2,
  Download,
  AlertCircle,
  Check,
  Target,
  Activity,
  Users,
  ShoppingCart,
  PieChart as PieChartIcon,
  Lock,
  Zap,
  Package,
  RefreshCw,
  Settings
} from 'lucide-react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  Line,
  Legend,
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart as RechartsPieChart
} from 'recharts';

import PricingStrategyService from '../../services/AGTUps-product-services/pricingStrategyService';
import ProductSelector from './ProductSelector';

/**
 * Sistema de colores estilo Apple (modo claro / oscuro).
 */
const appleColors = {
  light: {
    label: {
      primary: '#000000'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      green: '#31C859', // Éxito
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: {
      primaryElevated: '#FFFFFF'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#007BFE',  // Azul iOS
      base: '#F2F2F7'
    }
  },
  dark: {
    label: {
      primary: '#FFFFFF'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      green: '#2DD257', // Éxito en modo oscuro
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: {
      primaryElevated: '#000000'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#0385FF',
      base: '#272729'
    }
  }
};

/**
 * Función para extraer la paleta de colores según el modo (light/dark).
 */
const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const c = mode === 'light' ? appleColors.light : appleColors.dark;
  return {
    background: c.bg.primaryElevated,
    textDefault: c.label.primary,
    textSecondary: c.system.grey,
    cardBackground: c.ui.base,
    borderColor: c.system.grey3,
    accent: c.ui.accent,
    success: c.system.green,
    error: '#FF3B30', // típico rojo iOS
    warning: '#FF9500' // típico naranja/amarillo iOS
  };
};

/**
 * Paper con estilo Apple (bordes suaves, transparencia, blur).
 */
const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    backdropFilter: 'blur(20px)',
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    border: `1px solid ${navColors.borderColor}`,
    transition: 'all 0.3s ease',
    boxShadow: 'none',
    '&:hover': {
      borderColor: navColors.accent
    }
  };
});

/**
 * Card para métricas con estilo Apple (ligeramente translúcido).
 */
const MetricCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2),
    borderRadius: '12px',
    border: `1px solid ${navColors.borderColor}`,
    backdropFilter: 'blur(10px)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      borderColor: navColors.accent,
      transform: 'translateY(-2px)'
    }
  };
});

/**
 * Overlay de carga (cuando se refrescan datos).
 */
const LoadingOverlay = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 0.5)'
        : 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    borderRadius: '16px'
  };
});

/**
 * Slider con estilo Apple.
 */
const PriceSlider = styled(Slider)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    '& .MuiSlider-track': {
      backgroundColor: navColors.accent
    },
    '& .MuiSlider-thumb': {
      backgroundColor: navColors.accent,
      border: `2px solid ${navColors.cardBackground}`
    },
    '& .MuiSlider-rail': {
      opacity: 0.3
    },
    '& .MuiSlider-markLabel': {
      fontSize: '0.75rem',
      color: navColors.textSecondary
    },
    '& .MuiSlider-valueLabel': {
      background: alpha(navColors.accent, 0.8)
    }
  };
});

const PricingStrategy = ({ products }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);

  // Estados locales
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [pricingModel, setPricingModel] = useState('fixed');
  const [marginTarget, setMarginTarget] = useState(40);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [priceHistory, setPriceHistory] = useState(null);
  const [optimalPrice, setOptimalPrice] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (selectedProduct) {
      loadPricingData();
    }
  }, [selectedProduct]);

  const loadPricingData = async () => {
    try {
      setLoading(true);
      setError(null);

      const [strategyResponse, historyResponse] = await Promise.all([
        PricingStrategyService.getPricingStrategiesByProduct(selectedProduct.id),
        PricingStrategyService.analyzePriceHistory(selectedProduct.id)
      ]);

      setStrategyData(strategyResponse.data);
      setPriceHistory(historyResponse.data);

      if (strategyResponse.data) {
        setCurrentPrice(strategyResponse.data.currentPrice);
        setPricingModel(strategyResponse.data.pricingModel);
        setMarginTarget(strategyResponse.data.marginTarget);
      } else {
        setCurrentPrice(selectedProduct.price);
      }

      setLastUpdated(new Date());
    } catch (err) {
      setError(`Error loading pricing data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = async (event, newValue) => {
    setCurrentPrice(newValue);
    calculateOptimalPrice(newValue);
  };

  const calculateOptimalPrice = async (price = currentPrice) => {
    try {
      if (!selectedProduct || !strategyData) return;

      const marketData = {
        competitorPrices: priceHistory?.competitorPrices || [],
        costData: {
          productionCost: strategyData?.costs?.production || 0,
          operationalCost: strategyData?.costs?.operational || 0
        },
        targetMargin: marginTarget
      };

      const response = await PricingStrategyService.calculateOptimalPrice(
        selectedProduct.id,
        marketData
      );

      setOptimalPrice(response.data);
    } catch (err) {
      setError(`Error calculating optimal price: ${err.message}`);
    }
  };

  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      await loadPricingData();
      await calculateOptimalPrice();
    } finally {
      setUpdating(false);
    }
  };

  const handleSaveStrategy = async () => {
    try {
      if (!selectedProduct) return;
      setLoading(true);
      setError(null);

      const strategyUpdate = {
        productId: selectedProduct.id,
        currentPrice,
        pricingModel,
        marginTarget,
        basePrice: selectedProduct.price,
        status: 'active'
      };

      if (strategyData?.strategyId) {
        // Actualizar
        await PricingStrategyService.updatePricingStrategy(
          strategyData.strategyId,
          selectedProduct.id,
          strategyUpdate
        );
      } else {
        // Crear
        await PricingStrategyService.createPricingStrategy(strategyUpdate);
      }

      await loadPricingData();
    } catch (err) {
      setError(`Error saving pricing strategy: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleExportData = async () => {
    try {
      if (!selectedProduct) return;

      const data = {
        strategy: strategyData,
        priceHistory,
        optimalPrice,
        product: selectedProduct,
        exportDate: new Date().toISOString()
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `pricing-strategy-${selectedProduct.id}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error exporting data: ${err.message}`);
    }
  };

  const formattedPriceHistory = useMemo(() => {
    if (!priceHistory) return null;
    return PricingStrategyService.formatChartData(priceHistory);
  }, [priceHistory]);

  return (
    <Box
      sx={{
        color: navColors.textDefault,
        backgroundColor: navColors.background,
        minHeight: '100vh',
        '& > *:not(:last-child)': {
          mb: 4
        }
      }}
    >
      {/* Selector de productos */}
      <ProductSelector
        products={products}
        selectedProduct={selectedProduct}
        onProductSelect={setSelectedProduct}
        compact
      />

      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{
            borderRadius: '12px',
            mt: 2,
            backdropFilter: 'blur(10px)',
            backgroundColor: alpha(navColors.error, 0.1),
            border: `1px solid ${alpha(navColors.error, 0.2)}`,
            color: navColors.error
          }}
        >
          {error}
        </Alert>
      )}

      {/* Si no hay producto seleccionado */}
      {!selectedProduct ? (
        <StyledPaper sx={{ textAlign: 'center', py: 8 }}>
          <Package
            size={48}
            style={{
              marginBottom: '16px',
              color: alpha(navColors.textSecondary, 0.6)
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            Select a Product
          </Typography>
          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
            Choose a product from above to view pricing strategy
          </Typography>
        </StyledPaper>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <StyledPaper sx={{ position: 'relative' }}>
              {updating && (
                <LoadingOverlay>
                  <CircularProgress />
                </LoadingOverlay>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, letterSpacing: '-0.02em', mb: 0.5 }}
                  >
                    {selectedProduct.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                    Price Optimization
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip
                    title={
                      lastUpdated
                        ? `Last updated: ${lastUpdated.toLocaleString()}`
                        : 'Never updated'
                    }
                  >
                    <IconButton onClick={handleRefreshData} disabled={updating || loading}>
                      <RefreshCw
                        size={20}
                        style={{
                          color: navColors.accent,
                          animation: updating ? 'spin 1s linear infinite' : 'none'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleExportData} disabled={loading}>
                    <Download size={20} style={{ color: navColors.accent }} />
                  </IconButton>
                  <IconButton disabled={loading}>
                    <Settings size={20} style={{ color: navColors.accent }} />
                  </IconButton>
                </Box>
              </Box>

              {/* Slider de precio */}
              <Box sx={{ mb: 4 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}
                >
                  <Typography variant="subtitle2">Current Price Point</Typography>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 600, color: navColors.accent }}
                  >
                    ${currentPrice.toFixed(2)}
                  </Typography>
                </Box>
                <PriceSlider
                  value={currentPrice}
                  onChange={handlePriceChange}
                  min={strategyData?.minimumPrice || selectedProduct.price * 0.5}
                  max={strategyData?.maximumPrice || selectedProduct.price * 1.5}
                  step={0.01}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `$${value}`}
                  marks={[
                    {
                      value:
                        strategyData?.minimumPrice || selectedProduct.price * 0.5,
                      label: 'Min'
                    },
                    {
                      value: optimalPrice?.suggested || selectedProduct.price,
                      label: 'Optimal'
                    },
                    {
                      value:
                        strategyData?.maximumPrice || selectedProduct.price * 1.5,
                      label: 'Max'
                    }
                  ]}
                  disabled={loading}
                  sx={{ mt: 2 }}
                />
              </Box>

              {optimalPrice && (
                <Alert
                  severity={
                    currentPrice === optimalPrice.suggested ? 'success' : 'info'
                  }
                  icon={
                    currentPrice === optimalPrice.suggested ? (
                      <Check />
                    ) : (
                      <AlertCircle />
                    )
                  }
                  sx={{
                    mb: 4,
                    borderRadius: '12px',
                    backdropFilter: 'blur(10px)'
                  }}
                >
                  {currentPrice === optimalPrice.suggested
                    ? "You're at the optimal price point!"
                    : `AI suggests optimal price point: $${optimalPrice.suggested}`}
                </Alert>
              )}

              {/* Gráfica de precio histórico */}
              <Box sx={{ height: 260 }}>
                {priceHistory ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={formattedPriceHistory?.priceHistory?.data}>
                      <CartesianGrid strokeDasharray="3 3" stroke={alpha('#000', 0.1)} />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: navColors.textDefault, fontSize: 12 }}
                      />
                      <YAxis tick={{ fill: navColors.textDefault, fontSize: 12 }} />
                      <RechartsTooltip
                        contentStyle={{
                          backgroundColor: alpha(navColors.cardBackground, 0.8),
                          borderRadius: '8px',
                          color: navColors.textDefault,
                          border: `1px solid ${navColors.borderColor}`
                        }}
                      />
                      <Area
                        type="monotone"
                        dataKey="price"
                        stroke={navColors.accent}
                        fill={alpha(navColors.accent, 0.3)}
                        name="Price History"
                      />
                      <Area
                        type="monotone"
                        dataKey="optimal"
                        stroke={navColors.success}
                        strokeDasharray="5 5"
                        fill="none"
                        name="Optimal Price"
                      />
                      <Legend wrapperStyle={{ color: navColors.textDefault }} />
                    </AreaChart>
                  </ResponsiveContainer>
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: navColors.textSecondary
                    }}
                  >
                    <Typography>No price history available</Typography>
                  </Box>
                )}
              </Box>
            </StyledPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={4}>
              {/* Métricas proyectadas */}
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography
                    variant="h6"
                    sx={{ mb: 2, fontWeight: 600, letterSpacing: '-0.02em' }}
                  >
                    Projected Metrics
                  </Typography>
                  {optimalPrice ? (
                    <Box sx={{ display: 'grid', rowGap: 2 }}>
                      {/* Sales Volume */}
                      <MetricCard>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                          <ShoppingCart size={20} style={{ color: navColors.accent }} />
                          <Typography variant="body2">Sales Volume</Typography>
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {optimalPrice.metrics.estimatedVolume} units
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                          {optimalPrice.metrics.volumeChange >= 0 ? (
                            <TrendingUp size={14} style={{ color: navColors.success }} />
                          ) : (
                            <TrendingDown size={14} style={{ color: navColors.error }} />
                          )}
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                optimalPrice.metrics.volumeChange >= 0
                                  ? navColors.success
                                  : navColors.error
                            }}
                          >
                            {Math.abs(optimalPrice.metrics.volumeChange)}% vs current
                          </Typography>
                        </Box>
                      </MetricCard>

                      {/* Revenue */}
                      <MetricCard>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                          <DollarSign size={20} style={{ color: navColors.success }} />
                          <Typography variant="body2">Revenue</Typography>
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          $
                          {optimalPrice.metrics.potentialRevenue.toLocaleString()}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                          {optimalPrice.metrics.revenueChange >= 0 ? (
                            <TrendingUp size={14} style={{ color: navColors.success }} />
                          ) : (
                            <TrendingDown size={14} style={{ color: navColors.error }} />
                          )}
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                optimalPrice.metrics.revenueChange >= 0
                                  ? navColors.success
                                  : navColors.error
                            }}
                          >
                            {Math.abs(optimalPrice.metrics.revenueChange)}% vs current
                          </Typography>
                        </Box>
                      </MetricCard>

                      {/* Profit Margin */}
                      <MetricCard>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                          <Target size={20} style={{ color: '#AF52DE' }} />
                          <Typography variant="body2">Profit Margin</Typography>
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {optimalPrice.metrics.expectedMargin}%
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                          {optimalPrice.metrics.marginChange >= 0 ? (
                            <TrendingUp size={14} style={{ color: navColors.success }} />
                          ) : (
                            <TrendingDown size={14} style={{ color: navColors.error }} />
                          )}
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                optimalPrice.metrics.marginChange >= 0
                                  ? navColors.success
                                  : navColors.error
                            }}
                          >
                            {Math.abs(optimalPrice.metrics.marginChange)}% vs current
                          </Typography>
                        </Box>
                      </MetricCard>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', py: 2 }}>
                      <CircularProgress />
                    </Box>
                  )}
                </StyledPaper>
              </Grid>

              {/* Modelo de pricing */}
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography
                    variant="h6"
                    sx={{ mb: 2, fontWeight: 600, letterSpacing: '-0.02em' }}
                  >
                    Pricing Model
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel
                      sx={{
                        color: navColors.textSecondary,
                        '&.Mui-focused': { color: navColors.accent }
                      }}
                    >
                      Pricing Strategy
                    </InputLabel>
                    <Select
                      value={pricingModel}
                      label="Pricing Strategy"
                      onChange={(e) => setPricingModel(e.target.value)}
                      disabled={loading}
                      sx={{
                        backgroundColor: alpha(navColors.cardBackground, 0.5),
                        borderRadius: '12px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: navColors.borderColor
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: navColors.accent
                        }
                      }}
                    >
                      <MenuItem value="fixed">Fixed Pricing</MenuItem>
                      <MenuItem value="dynamic">Dynamic Pricing</MenuItem>
                      <MenuItem value="tiered">Tiered Pricing</MenuItem>
                      <MenuItem value="subscription">Subscription Based</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Target Profit Margin (%)"
                    type="number"
                    value={marginTarget}
                    onChange={(e) => setMarginTarget(e.target.value)}
                    disabled={loading}
                    sx={{
                      mb: 2,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: alpha(navColors.cardBackground, 0.5),
                        borderRadius: '12px',
                        '& fieldset': {
                          borderColor: navColors.borderColor
                        },
                        '&:hover fieldset': {
                          borderColor: navColors.accent
                        }
                      }
                    }}
                    InputProps={{
                      endAdornment: '%'
                    }}
                  />

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSaveStrategy}
                    disabled={loading}
                    sx={{
                      borderRadius: '12px',
                      backgroundColor: navColors.accent,
                      color: '#fff',
                      textTransform: 'none',
                      fontWeight: 600,
                      '&:hover': {
                        backgroundColor: alpha(navColors.accent, 0.9)
                      }
                    }}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Save Strategy'}
                  </Button>
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>

          {/* Competitor Price Analysis */}
          <Grid item xs={12}>
            <StyledPaper>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, letterSpacing: '-0.02em' }}
                >
                  Competitor Price Analysis
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title="Download Report">
                    <IconButton onClick={handleExportData} disabled={loading}>
                      <Download size={20} style={{ color: navColors.accent }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Premium Features">
                    <IconButton disabled={loading}>
                      <Lock size={20} style={{ color: navColors.accent }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ height: 320 }}>
                    {priceHistory?.competitorPrices ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <RechartsBarChart data={priceHistory.competitorPrices}>
                          <CartesianGrid
                            strokeDasharray="3 3"
                            stroke={alpha(navColors.textDefault, 0.1)}
                          />
                          <XAxis
                            dataKey="name"
                            tick={{ fill: navColors.textDefault, fontSize: 12 }}
                          />
                          <YAxis
                            tick={{ fill: navColors.textDefault, fontSize: 12 }}
                          />
                          <RechartsTooltip
                            formatter={(value) => [`$${value}`, 'Price']}
                            contentStyle={{
                              backgroundColor: alpha(navColors.cardBackground, 0.8),
                              borderRadius: '8px',
                              color: navColors.textDefault,
                              border: `1px solid ${navColors.borderColor}`
                            }}
                          />
                          <Bar dataKey="price" fill={navColors.accent}>
                            {priceHistory.competitorPrices.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry.name === selectedProduct.name
                                    ? navColors.accent
                                    : alpha(navColors.accent, 0.6)
                                }
                              />
                            ))}
                          </Bar>
                          <Legend wrapperStyle={{ color: navColors.textDefault }} />
                        </RechartsBarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: navColors.textSecondary
                        }}
                      >
                        <Typography>No competitor data available</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'grid', rowGap: 2 }}>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '12px',
                        backgroundColor: alpha(navColors.accent, 0.1)
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          mb: 1
                        }}
                      >
                        <Activity size={20} style={{ color: navColors.accent }} />
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          Market Position
                        </Typography>
                      </Box>
                      <Typography variant="body2">
                        {optimalPrice
                          ? `Your price point is ${optimalPrice.marketAnalysis.positionDescription}. ${optimalPrice.marketAnalysis.recommendation}`
                          : 'Market position analysis not available'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '12px',
                        backgroundColor: alpha(navColors.success, 0.1)
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          mb: 1
                        }}
                      >
                        <Zap size={20} style={{ color: navColors.success }} />
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                          Opportunity
                        </Typography>
                      </Box>
                      <Typography variant="body2">
                        {optimalPrice?.marketAnalysis.opportunity ||
                          'Opportunity analysis not available'}
                      </Typography>
                    </Box>
                    {optimalPrice?.marketAnalysis.alert && (
                      <Alert
                        severity="warning"
                        icon={<AlertCircle size={20} />}
                        sx={{
                          borderRadius: '12px',
                          backdropFilter: 'blur(10px)',
                          backgroundColor: alpha(navColors.warning, 0.1),
                          border: `1px solid ${alpha(navColors.warning, 0.2)}`,
                          color: navColors.textDefault
                        }}
                      >
                        {optimalPrice.marketAnalysis.alert}
                      </Alert>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          {/* Price History Analysis */}
          <Grid item xs={12}>
            <StyledPaper>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, letterSpacing: '-0.02em' }}
                >
                  Price History Analysis
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    icon={<TrendingUp size={16} />}
                    label={formattedPriceHistory?.trend || 'No trend'}
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: alpha(navColors.success, 0.1),
                      color: navColors.success
                    }}
                  />
                  <Chip
                    icon={<Activity size={16} />}
                    label={`Volatility: ${formattedPriceHistory?.volatility || 'N/A'}`}
                    sx={{
                      borderRadius: '8px',
                      border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                      color: navColors.textDefault
                    }}
                  />
                </Box>
              </Box>

              <Grid container spacing={4}>
                <Grid item xs={12} md={7}>
                  <Box sx={{ height: 260 }}>
                    {formattedPriceHistory ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <RechartsLineChart
                          data={formattedPriceHistory.priceHistory.data}
                        >
                          <CartesianGrid
                            strokeDasharray="3 3"
                            stroke={alpha(navColors.textDefault, 0.1)}
                          />
                          <XAxis
                            dataKey="date"
                            tick={{ fill: navColors.textDefault, fontSize: 12 }}
                          />
                          <YAxis
                            tick={{ fill: navColors.textDefault, fontSize: 12 }}
                          />
                          <RechartsTooltip
                            contentStyle={{
                              backgroundColor: alpha(navColors.cardBackground, 0.8),
                              borderRadius: '8px',
                              color: navColors.textDefault,
                              border: `1px solid ${navColors.borderColor}`
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey="price"
                            stroke={navColors.accent}
                            strokeWidth={2}
                            dot={{ r: 4 }}
                            name="Price"
                          />
                          <Line
                            type="monotone"
                            dataKey="margin"
                            stroke={navColors.success}
                            strokeWidth={2}
                            dot={{ r: 4 }}
                            name="Margin %"
                          />
                          <Legend wrapperStyle={{ color: navColors.textDefault }} />
                        </RechartsLineChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: navColors.textSecondary
                        }}
                      >
                        <Typography>No price history available</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Box sx={{ display: 'grid', rowGap: 2 }}>
                    <MetricCard>
                      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                        Price Range
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{ color: navColors.textSecondary }}
                          >
                            Lowest
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formattedPriceHistory?.summary.lowestPrice || 'N/A'}
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography
                            variant="caption"
                            sx={{ color: navColors.textSecondary }}
                          >
                            Highest
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formattedPriceHistory?.summary.highestPrice || 'N/A'}
                          </Typography>
                        </Box>
                      </Box>
                    </MetricCard>

                    <MetricCard>
                      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                        Performance
                      </Typography>
                      <Box sx={{ display: 'grid', rowGap: 1 }}>
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{ color: navColors.textSecondary }}
                          >
                            Average Margin
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formattedPriceHistory?.summary.averageMargin || 'N/A'}%
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{ color: navColors.textSecondary }}
                          >
                            Price Stability
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formattedPriceHistory?.summary.stability || 'N/A'}%
                          </Typography>
                        </Box>
                      </Box>
                    </MetricCard>
                  </Box>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PricingStrategy;
