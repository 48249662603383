// D:\directiveai-react\frontend\src\utils\nameUtils.js

/**
 * Extrae el primer nombre de un nombre completo
 * @param {string} fullName - Nombre completo del usuario
 * @returns {string} - Primer nombre o string vacío si no hay nombre
 */
export const getFirstName = (fullName) => {
    if (!fullName) return '';
    return fullName.trim().split(' ')[0];
  };