// ProductsManager.jsx

import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Alert,
  CircularProgress,
  Snackbar,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Package,
  MoreVertical,
  Edit,
  Trash2,
  Plus,
  DollarSign,
  ShoppingBag,
  Briefcase,
  Smartphone,
  Coffee,
  AlertCircle,
  Search,
  RefreshCw,
  AlertTriangle,
  Sparkles
} from 'lucide-react';

import { v4 as uuidv4 } from 'uuid';
import ProductService from '../../services/AGTUps-product-services/productService';

/* ---------------------------------------------------------------------------
   Función para unificar ciertos estilos (tema claro/oscuro)
   --------------------------------------------------------------------------- */
const navigationColors = (theme) => ({
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(40, 40, 42, 0.94)'
      : 'rgba(242, 242, 247, 1)',
  textDefault: theme.palette.mode === 'dark' ? '#FFFFFF' : '#666666',
  textPlaceholder:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#666666',
  iconColor: '#007BFE'
});

/* ---------------------------------------------------------------------------
   Botón personalizado para IA
   --------------------------------------------------------------------------- */
const AGTEnhanceButton = ({
  onEnhance,
  isLoading,
  disabled = false,
  label = 'Enhance with AGT',
  tooltip = 'Automatically enhance this field using AI',
  icon = <Sparkles size={20} />,
  sx = {}
}) => {
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <span>
        <Button
          variant="outlined"
          startIcon={isLoading ? <CircularProgress size={20} /> : icon}
          onClick={onEnhance}
          disabled={disabled || isLoading}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderRadius: '28px',
            color: '#0385FF',
            borderColor: '#0385FF',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(3,133,255,0.08)',
              borderColor: '#0266cc'
            },
            ...sx
          }}
        >
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

/* -----------------------------------------------
   Estilos para el contenedor principal
   ----------------------------------------------- */
const StyledContainer = styled(Box)(() => ({
  backgroundColor: 'transparent',
  minHeight: '100vh',
  padding: '24px'
}));

/* -----------------------------------------------
   SearchBar con estilos unificados
   ----------------------------------------------- */
const StyledSearchBar = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: navigationColors(theme).background,
    '& fieldset': { border: 'none' },
    '& input': {
      color: navigationColors(theme).textDefault,
      '&::placeholder': {
        color: navigationColors(theme).textPlaceholder,
        opacity: 1
      }
    }
  }
}));

/* -----------------------------------------------
   Select con estilos unificados (filtro principal)
   ----------------------------------------------- */
const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: navigationColors(theme).background,
  color: navigationColors(theme).textDefault,

  '& .MuiOutlinedInput-root': {
    borderRadius: '12px'
  },
  '& .MuiSelect-select': {
    borderRadius: '12px',
    padding: '8px 16px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '12px'
  },
  '& .MuiMenu-paper': {
    borderRadius: '12px',
    marginTop: '8px'
  },
  '& .MuiMenu-list': {
    borderRadius: '12px'
  }
}));

/* -----------------------------------------------
   MenuItem con estilos unificados
   ----------------------------------------------- */
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: navigationColors(theme).textDefault,
  padding: '8px 16px',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.04)'
  },
  '&:first-of-type': {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px'
  }
}));

/* -----------------------------------------------
   Botón de acciones (Add, Refresh, etc.)
   ----------------------------------------------- */
const StyledActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: navigationColors(theme).iconColor,
  borderRadius: '30px',
  color: '#FFFFFF',
  textTransform: 'none',
  padding: '6px 16px',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: '#005FDB'
  }
}));

/* -----------------------------------------------
   Tarjeta para cada producto
   ----------------------------------------------- */
const ProductCard = styled(Box)(({ bgcolor }) => ({
  position: 'relative',
  borderRadius: '16px',
  aspectRatio: '1',
  overflow: 'hidden',
  background: `linear-gradient(45deg, ${bgcolor}, ${bgcolor}88)`,
  padding: '16px',
  transition: 'transform 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:hover': {
    transform: 'scale(1.02)'
  }
}));

/* -----------------------------------------------
   Estilos para los diálogos
   ----------------------------------------------- */
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: navigationColors(theme).background,
    color: navigationColors(theme).textDefault,
    borderRadius: '16px',
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 2px 10px rgba(0, 0, 0, 0.9)'
        : '0 2px 10px rgba(0, 0, 0, 0.2)'
  }
}));

/* -----------------------------------------------
   TextField en el diálogo (SIN borde)
   ----------------------------------------------- */
const StyledDialogTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.08)'
        : 'rgba(0, 0, 0, 0.04)',
    color: navigationColors(theme).textDefault,

    '& fieldset': {
      border: 'none'
    },
    '&:hover fieldset': {
      border: 'none'
    },
    '&.Mui-focused fieldset': {
      border: 'none'
    }
  },
  '& .MuiInputLabel-root': {
    color: navigationColors(theme).textPlaceholder
  }
}));

/* -----------------------------------------------
   Select en el diálogo (SIN borde)
   ----------------------------------------------- */
const StyledDialogSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.08)'
        : 'rgba(0, 0, 0, 0.04)',
    color: navigationColors(theme).textDefault,

    '& fieldset': {
      border: 'none'
    },
    '&:hover fieldset': {
      border: 'none'
    },
    '&.Mui-focused fieldset': {
      border: 'none'
    }
  },
  '& .MuiInputLabel-root': {
    color: navigationColors(theme).textPlaceholder
  }
}));

/* -----------------------------------------------
   Categorías por defecto
   ----------------------------------------------- */
const defaultCategories = [
  { id: 'physical', name: 'Physical Products', icon: Package, color: '#4f46e5' },
  { id: 'digital', name: 'Digital Products', icon: Smartphone, color: '#0891b2' },
  { id: 'services', name: 'Services', icon: Briefcase, color: '#059669' },
  { id: 'subscription', name: 'Subscriptions', icon: ShoppingBag, color: '#7c3aed' },
  { id: 'food', name: 'Food & Beverage', icon: Coffee, color: '#db2777' }
];

/* -----------------------------------------------
   Componente principal (CRUD)
   ----------------------------------------------- */
const ProductsManager = ({
  products: initialProducts,
  onProductCreate,
  onProductUpdate,
  onProductDelete
}) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);

  const [products, setProducts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories] = useState(defaultCategories);
  const [loadingStates, setLoadingStates] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const mounted = useRef(true);

  // Nuevo estado para el producto que se va a crear/editar
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    customFields: {}
  });

  // Estados para IA
  const [enhanceLoading, setEnhanceLoading] = useState({
    description: false,
    category: false,
    price: false
  });
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);

  // Para marketing tips
  const [marketingTips, setMarketingTips] = useState(null);
  const [showMarketingDialog, setShowMarketingDialog] = useState(false);

  /* -----------------------------
     Montaje y desmontaje
  ----------------------------- */
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      setProducts(initialProducts || []);
    }
  }, [initialProducts]);

  useEffect(() => {
    if (mounted.current) {
      loadProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  /* -----------------------------
     Notificaciones
  ----------------------------- */
  const showNotification = useCallback((message, severity = 'success') => {
    if (mounted.current) {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setShowSnackbar(true);
    }
  }, []);

  const handleError = useCallback(
    (error) => {
      console.error(error);
      if (mounted.current) {
        if (error.name === 'CooldownError') {
          setError({
            name: 'CooldownError',
            message: error.message
          });
        } else {
          setError({
            name: 'Error',
            message: error.message || 'An unexpected error occurred'
          });
          showNotification(
            error.message || 'An unexpected error occurred',
            'error'
          );
        }
      }
    },
    [showNotification]
  );

  /* -----------------------------
     Cargar productos
  ----------------------------- */
  const loadProducts = useCallback(async () => {
    if (!mounted.current) return;
    try {
      setLoading(true);
      setError(null);

      const params = new URLSearchParams();
      if (categoryFilter) params.append('category', categoryFilter);

      const response = await ProductService.getAllProducts(params);
      if (!response?.data?.products) {
        throw new Error('Invalid product data received from server');
      }

      if (mounted.current) {
        setProducts(response.data.products);
      }
    } catch (error) {
      handleError(error);
      if (mounted.current) {
        setProducts([]);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [categoryFilter, handleError]);

  /* -----------------------------
     Cargar detalles de 1 producto
     (solo para editar)
  ----------------------------- */
  const loadProductDetails = useCallback(
    async (productId) => {
      if (!mounted.current || !productId) return null;
      try {
        setLoading(true);
        setError(null);

        const response = await ProductService.getProductById(productId);
        if (!response?.data) {
          throw new Error('Product data not found');
        }
        return response.data;
      } catch (error) {
        handleError(error);
        return null;
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    [handleError]
  );

  /* -----------------------------
     Menu contextual (abrir/cerrar)
  ----------------------------- */
  const handleOpenMenu = useCallback((event, product) => {
    if (!mounted.current) return;
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setProductToDelete(product);
  }, []);

  const handleCloseMenu = useCallback(() => {
    if (mounted.current) {
      setAnchorEl(null);
    }
  }, []);

  /* -----------------------------
     Confirmar borrado
  ----------------------------- */
  const handleDeleteClick = useCallback(
    (product) => {
      if (!mounted.current) return;
      if (!product?.id) {
        showNotification('Invalid product selected for deletion', 'error');
        return;
      }
      const productExists = products.some((p) => p.id === product.id);
      if (!productExists) {
        showNotification('Product no longer exists', 'info');
        handleCloseMenu();
        return;
      }
      setProductToDelete(product);
      setDeleteConfirmOpen(true);
      handleCloseMenu();
    },
    [handleCloseMenu, showNotification, products]
  );

  const handleCloseDeleteDialog = useCallback(() => {
    if (mounted.current) {
      setDeleteConfirmOpen(false);
      setProductToDelete(null);
      handleCloseMenu();
    }
  }, [handleCloseMenu]);

  const handleDeleteProduct = useCallback(async () => {
    if (!mounted.current || !productToDelete?.id) return;
    try {
      const deletingProductId = productToDelete.id;
      const deletingProductName = productToDelete.name;

      setLoadingStates((prev) => ({ ...prev, [deletingProductId]: true }));

      const response = await ProductService.deleteProduct(deletingProductId);
      if (response.success && mounted.current) {
        // Quitar de la lista local
        setProducts((prevProducts) =>
          prevProducts.filter((p) => p.id !== deletingProductId)
        );
        showNotification(
          `Product "${deletingProductName}" has been deleted successfully`,
          'success'
        );

        if (typeof onProductDelete === 'function') {
          onProductDelete(deletingProductId);
        }

        setDeleteConfirmOpen(false);
        setProductToDelete(null);
        handleCloseMenu();
        setError(null);

        // Forzar refresh
        handleRefresh();
      }
    } catch (error) {
      handleError(error);
    } finally {
      if (mounted.current) {
        if (productToDelete?.id) {
          setLoadingStates((prev) => ({
            ...prev,
            [productToDelete.id]: false
          }));
        }
        setDeleteConfirmOpen(false);
        setProductToDelete(null);
        handleCloseMenu();
      }
    }
  }, [
    productToDelete,
    onProductDelete,
    handleCloseMenu,
    showNotification,
    handleError
  ]);

  /* -----------------------------
     Refresh
  ----------------------------- */
  const handleRefresh = useCallback(() => {
    if (mounted.current) {
      setRefreshKey((prev) => prev + 1);
    }
  }, []);

  /* -----------------------------
     Search
  ----------------------------- */
  const handleSearch = useCallback(async () => {
    if (!mounted.current) return;
    try {
      setLoading(true);
      setError(null);

      if (!searchQuery.trim()) {
        await loadProducts();
        return;
      }

      const response = await ProductService.searchProducts({
        query: searchQuery.trim()
      });

      if (!response?.data?.products && mounted.current) {
        throw new Error('Invalid search response from server');
      }
      if (mounted.current) {
        setProducts(response.data.products);
      }
    } catch (error) {
      handleError(error);
      if (mounted.current) {
        setProducts([]);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [searchQuery, loadProducts, handleError]);

  /* -----------------------------
     Filtrar por categoría
  ----------------------------- */
  const handleCategoryFilter = useCallback(
    async (category) => {
      if (!mounted.current) return;
      try {
        setLoading(true);
        setError(null);
        setCategoryFilter(category);

        if (!category) {
          await loadProducts();
          return;
        }

        const response = await ProductService.getProductsByCategory(category);
        if (!response?.data?.products && mounted.current) {
          throw new Error('Invalid category filter response');
        }
        if (mounted.current) {
          setProducts(response.data.products);
        }
      } catch (error) {
        handleError(error);
        if (mounted.current) {
          setProducts([]);
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    [loadProducts, handleError]
  );

  /* -----------------------------
     Abrir diálogo (crear/editar)
  ----------------------------- */
  const handleOpenDialog = useCallback(
    async (product = null) => {
      if (!mounted.current) return;
      try {
        setError(null);

        // Reset IA states
        setEnhanceLoading({ description: false, category: false, price: false });
        setIsTyping(false);

        if (product && product.id) {
          // Modo Editar: cargar el producto
          const productDetails = await loadProductDetails(product.id);
          if (productDetails && mounted.current) {
            setNewProduct(productDetails);
          }
        } else {
          // Modo Crear
          setNewProduct({
            name: '',
            description: '',
            category: '',
            price: '',
            customFields: {}
          });
        }

        if (mounted.current) {
          setOpenDialog(true);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [loadProductDetails, handleError]
  );

  /* -----------------------------
     Cerrar diálogo
  ----------------------------- */
  const handleCloseDialog = useCallback(() => {
    if (mounted.current) {
      setOpenDialog(false);
      setNewProduct({
        name: '',
        description: '',
        category: '',
        price: '',
        customFields: {}
      });
      setError(null);

      setEnhanceLoading({ description: false, category: false, price: false });
      setIsTyping(false);
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    }
  }, []);

  /* -----------------------------
     Crear/editar producto
  ----------------------------- */
  const handleAddProduct = useCallback(async () => {
    if (!mounted.current) return;
    try {
      setLoading(true);
      setError(null);

      const formattedProduct = ProductService.formatProductData(newProduct);

      let response;
      if (newProduct.id) {
        // Actualizar
        response = await ProductService.updateProduct(newProduct.id, formattedProduct);
      } else {
        // Crear
        response = await ProductService.createProduct(formattedProduct);
      }

      if (!response?.data) {
        throw new Error('Invalid server response');
      }

      handleCloseDialog();
      handleRefresh();

      // Notificar
      if (newProduct.id) {
        if (typeof onProductUpdate === 'function') {
          onProductUpdate(response.data);
        }
        showNotification('Product updated successfully');
      } else {
        if (typeof onProductCreate === 'function') {
          onProductCreate(response.data);
        }
        showNotification('Product created successfully');
      }
    } catch (error) {
      handleError(error);
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [
    newProduct,
    onProductUpdate,
    onProductCreate,
    handleCloseDialog,
    handleRefresh,
    showNotification,
    handleError
  ]);

  /* -----------------------------
     Manejo del texto de descripción
  ----------------------------- */
  const handleDescriptionChange = useCallback((e) => {
    setNewProduct((prev) => ({
      ...prev,
      description: e.target.value
    }));
    setIsTyping(true);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 500);
  }, []);

  /* -----------------------------
     Función canUseAI
  ----------------------------- */
  const canUseAI = (field) => {
    try {
      const canEnhance = ProductService.canEnhanceProduct(newProduct, field);
      if (!canEnhance.canEnhance) {
        showNotification(canEnhance.reason, 'warning');
        return false;
      }
      return true;
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      }
      return false;
    }
  };

  /* -----------------------------
     Asegurar que el producto exista (borrador)
  ----------------------------- */
  const ensureProductInDB = useCallback(async () => {
    // Si ya existe un id, no hacemos nada
    if (newProduct.id) {
      return newProduct;
    }

    // Generar un ID
    const generatedId = `prod_${uuidv4()}`;

    // Crear un borrador con status='draft'
    const draftData = {
      ...newProduct,
      id: generatedId,
      status: 'draft' // Para evitar validaciones de categoría o precio
    };

    try {
      const response = await ProductService.createProduct(draftData);
      if (response?.success && response?.data) {
        setNewProduct(response.data);
        showNotification(`Draft created with ID: ${response.data.id}`);
        return response.data;
      } else {
        showNotification('Failed to create draft product', 'error');
        return null;
      }
    } catch (error) {
      handleError(error);
      return null;
    }
  }, [newProduct, showNotification, handleError]);

  /* -----------------------------
     Enhance Description
  ----------------------------- */
  const handleEnhanceDescription = async () => {
    if (isTyping) return;
    if (!canUseAI('description')) return;

    try {
      const product = await ensureProductInDB();
      if (!product?.id) return;

      setEnhanceLoading((prev) => ({ ...prev, description: true }));

      const response = await ProductService.enhanceProduct(product.id, 'description');
      if (response.success && response.data.field === 'description') {
        setNewProduct((prev) => ({
          ...prev,
          description: response.data.value
        }));
        showNotification('Description has been enhanced by AGT');
      }
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      } else {
        showNotification(error.message || 'Failed to enhance description', 'error');
      }
    } finally {
      setEnhanceLoading((prev) => ({ ...prev, description: false }));
    }
  };

  /* -----------------------------
     Enhance Category
  ----------------------------- */
  const handleEnhanceCategory = async () => {
    if (!canUseAI('category')) return;
    try {
      const product = await ensureProductInDB();
      if (!product?.id) return;

      setEnhanceLoading((prev) => ({ ...prev, category: true }));

      const response = await ProductService.enhanceProduct(product.id, 'category');
      if (response.success && response.data.field === 'category') {
        const suggestedCategory = response.data.value;
        const validCategory = categories.find(
          (cat) => cat.id === suggestedCategory
        );
        if (!validCategory) {
          throw new Error('Invalid category suggestion');
        }
        setNewProduct((prev) => ({
          ...prev,
          category: suggestedCategory
        }));
        showNotification(`Category set to: ${validCategory.name}`);
      }
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      } else {
        showNotification(error.message || 'Failed to suggest category', 'error');
      }
    } finally {
      setEnhanceLoading((prev) => ({ ...prev, category: false }));
    }
  };

  /* -----------------------------
     Enhance Price
  ----------------------------- */
  const handleEnhancePrice = async () => {
    if (!canUseAI('price')) return;
    try {
      const product = await ensureProductInDB();
      if (!product?.id) return;

      setEnhanceLoading((prev) => ({ ...prev, price: true }));

      const response = await ProductService.enhanceProduct(product.id, 'price');
      if (response.success && response.data.field === 'price') {
        const suggestedPrice = parseFloat(response.data.value);
        if (isNaN(suggestedPrice) || suggestedPrice < 0) {
          throw new Error('Invalid price suggestion');
        }
        setNewProduct((prev) => ({
          ...prev,
          price: suggestedPrice
        }));
        showNotification(`Price set to: $${suggestedPrice.toFixed(2)}`);
      }
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      } else {
        showNotification(error.message || 'Failed to suggest price', 'error');
      }
    } finally {
      setEnhanceLoading((prev) => ({ ...prev, price: false }));
    }
  };

  /* -----------------------------
     Obtener marketing tips
  ----------------------------- */
  const handleGetMarketingTips = async () => {
    const product = await ensureProductInDB();
    if (!product?.id) {
      showNotification('Save the product first to get marketing tips', 'info');
      return;
    }
    try {
      setLoading(true);
      const response = await ProductService.enhanceProduct(product.id, 'marketing');
      if (response.success && response.data.field === 'marketingTips') {
        setMarketingTips(response.data.value);
        setShowMarketingDialog(true);
      }
    } catch (error) {
      showNotification(error.message || 'Failed to get marketing tips', 'error');
    } finally {
      setLoading(false);
    }
  };

  if (!mounted.current) {
    return null;
  }

  return (
    <StyledContainer>
      {/* Notificaciones de error global */}
      <Box sx={{ marginBottom: 4 }}>
        {error && (
          <Alert
            severity={error.name === 'CooldownError' ? 'warning' : 'error'}
            onClose={() => setError(null)}
            icon={
              error.name === 'CooldownError' ? (
                <AlertCircle size={20} color="#FFFFFF" />
              ) : undefined
            }
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setError(null)}
                sx={{ color: '#FFFFFF' }}
              >
                {error.name === 'CooldownError' ? 'DISMISS' : 'CLOSE'}
              </Button>
            }
            sx={{
              marginBottom: 2,
              backgroundColor:
                error.name === 'CooldownError'
                  ? 'transparent'
                  : 'rgba(255,0,0,0.1)',
              color: '#FFFFFF',
              border: 'none',
              '& .MuiAlert-icon': {
                color: '#FFFFFF'
              },
              '& .MuiAlert-message': {
                color: '#FFFFFF'
              }
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* Search, Filtros, Botones */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <StyledSearchBar
              fullWidth
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              InputProps={{
                startAdornment: (
                  <Search
                    size={20}
                    style={{
                      color: navColors.textPlaceholder,
                      marginRight: 8,
                      borderRadius: '28px'
                    }}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <FormControl sx={{ minWidth: 120, width: '100%' }}>
              <StyledSelect
                variant="outlined"
                value={categoryFilter}
                onChange={(e) => handleCategoryFilter(e.target.value)}
                displayEmpty
                fullWidth
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: '12px'
                    }
                  }
                }}
              >
                <StyledMenuItem value="">All Categories</StyledMenuItem>
                {categories.map((category) => (
                  <StyledMenuItem key={category.id} value={category.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {React.createElement(category.icon, {
                        size: 16,
                        style: { color: category.color }
                      })}
                      {category.name}
                    </Box>
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm="auto">
            <StyledActionButton
              onClick={() => handleOpenDialog(null)}
              startIcon={<Plus size={20} />}
              disabled={loading}
            >
              Add Product
            </StyledActionButton>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Tooltip title="Refresh">
              <IconButton
                onClick={handleRefresh}
                disabled={loading}
                sx={{ color: navColors.iconColor }}
              >
                <RefreshCw
                  size={20}
                  className={loading ? 'animate-spin' : ''}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      {/* Grid de productos */}
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 4,
                color: '#FFFFFF'
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          </Grid>
        ) : products.length === 0 ? (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 4,
                textAlign: 'center',
                backgroundColor: 'rgba(255,255,255,0.1)',
                borderRadius: '16px',
                color: '#FFFFFF'
              }}
            >
              <AlertCircle size={48} style={{ marginBottom: '16px', opacity: 0.7 }} />
              <Typography variant="h6" gutterBottom>
                No products found
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>
                {searchQuery
                  ? 'No products match your search'
                  : 'Get started by adding your first product'}
              </Typography>
            </Box>
          </Grid>
        ) : (
          products.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
              <ProductCard
                bgcolor={
                  categories.find((c) => c.id === product.category)?.color || '#666'
                }
              >
                <Box>
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '12px'
                    }}
                  >
                    {React.createElement(
                      categories.find((c) => c.id === product.category)?.icon ||
                        Package,
                      { size: 24, color: '#FFFFFF' }
                    )}
                  </Box>
                </Box>

                <Box sx={{ position: 'relative' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}
                  >
                    <Typography
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: 500,
                        fontSize: '1.25rem',
                        marginBottom: '4px',
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {product.name}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#FFFFFF', flexShrink: 0 }}>
                      $
                      {typeof product.price === 'number'
                        ? product.price.toFixed(2)
                        : '0.00'}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '0.875rem'
                    }}
                  >
                    {product.description
                      ? product.description.length > 50
                        ? `${product.description.substring(0, 50)}...`
                        : product.description
                      : 'No description available'}
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -30,
                      right: 0,
                      display: 'flex',
                      gap: 1
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => handleOpenMenu(e, product)}
                      disabled={loadingStates[product.id]}
                      sx={{ color: '#FFFFFF' }}
                    >
                      {loadingStates[product.id] ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <MoreVertical size={20} />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </ProductCard>
            </Grid>
          ))
        )}
      </Grid>

      {/* Menú contextual (Editar/Borrar) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            backgroundColor: navigationColors(theme).background,
            color: navigationColors(theme).textDefault,
            borderRadius: '12px'
          }
        }}
      >
        <StyledMenuItem
          onClick={() => {
            handleOpenDialog(productToDelete);
            handleCloseMenu();
          }}
        >
          <Edit size={16} style={{ marginRight: 8 }} />
          Edit Product
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            if (productToDelete) {
              handleDeleteClick(productToDelete);
            }
          }}
          sx={{ color: '#ff4444' }}
        >
          <Trash2 size={16} style={{ marginRight: 8 }} />
          Delete Product
        </StyledMenuItem>
      </Menu>

      {/* Diálogo confirmación de borrado */}
      <StyledDialog
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ color: navColors.textDefault }}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <AlertTriangle size={24} color="#ff4444" />
            <Typography sx={{ color: navColors.textDefault }}>
              {productToDelete
                ? `Are you sure you want to delete "${productToDelete.name}"? This action cannot be undone.`
                : 'No product selected for deletion.'}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <StyledActionButton onClick={handleCloseDeleteDialog}>
            Cancel
          </StyledActionButton>
          <StyledActionButton
            onClick={handleDeleteProduct}
            disabled={!productToDelete || loadingStates[productToDelete?.id]}
            sx={{
              backgroundColor: '#ff4444',
              '&:hover': {
                backgroundColor: '#ff6666'
              }
            }}
          >
            {loadingStates[productToDelete?.id] ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Delete'
            )}
          </StyledActionButton>
        </DialogActions>
      </StyledDialog>

      {/* Diálogo crear/editar producto */}
      <StyledDialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ color: navColors.textDefault }}>
          {newProduct.id ? 'Edit Product' : 'Add New Product'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 1 }}>
            {/* Nombre */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Product Name
              </Typography>
              <StyledDialogTextField
                fullWidth
                placeholder="Enter product name"
                value={newProduct.name || ''}
                onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                error={Boolean(error && error.message?.includes('name'))}
                required
              />
            </Box>

            {/* Descripción + Botón IA */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Description
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <StyledDialogTextField
                    fullWidth
                    multiline
                    minRows={3}
                    placeholder="Add a detailed description..."
                    value={newProduct.description || ''}
                    onChange={handleDescriptionChange}
                  />
                </Grid>
                <Grid item>
                  <AGTEnhanceButton
                    onEnhance={handleEnhanceDescription}
                    isLoading={enhanceLoading.description}
                    disabled={enhanceLoading.description || isTyping}
                    label="Enhance with AGT"
                    tooltip="Automatically enhance the product description using AI"
                    sx={{ minWidth: '180px' }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Categoría + Botón IA */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Category
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <FormControl
                    fullWidth
                    required
                    error={Boolean(error && error.message?.includes('category'))}
                  >
                    <InputLabel sx={{ color: navColors.textPlaceholder }}>
                      Category
                    </InputLabel>
                    <StyledDialogSelect
                      value={newProduct.category || ''}
                      onChange={(e) =>
                        setNewProduct({ ...newProduct, category: e.target.value })
                      }
                      label="Category"
                    >
                      {categories.map((cat) => (
                        <StyledMenuItem key={cat.id} value={cat.id}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {React.createElement(cat.icon, {
                              size: 16,
                              style: { color: cat.color }
                            })}
                            {cat.name}
                          </Box>
                        </StyledMenuItem>
                      ))}
                    </StyledDialogSelect>
                  </FormControl>
                </Grid>
                <Grid item>
                  <AGTEnhanceButton
                    onEnhance={handleEnhanceCategory}
                    isLoading={enhanceLoading.category}
                    disabled={enhanceLoading.category || isTyping}
                    label="Let AGT Decide"
                    tooltip="Have AI suggest the best category"
                    sx={{ minWidth: '180px' }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Precio + Botón IA */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Price
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <StyledDialogTextField
                    fullWidth
                    placeholder="0.00"
                    type="number"
                    value={newProduct.price || ''}
                    onChange={(e) =>
                      setNewProduct({
                        ...newProduct,
                        price: e.target.value ? parseFloat(e.target.value) : ''
                      })
                    }
                    error={Boolean(error && error.message?.includes('price'))}
                    required
                    InputProps={{
                      startAdornment: (
                        <DollarSign
                          size={16}
                          style={{
                            marginRight: 8,
                            color: navColors.textPlaceholder
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
                <Grid item>
                  <AGTEnhanceButton
                    onEnhance={handleEnhancePrice}
                    isLoading={enhanceLoading.price}
                    disabled={enhanceLoading.price || isTyping}
                    label="Let AGT Decide"
                    tooltip="Have AI suggest a suitable price"
                    sx={{ minWidth: '180px' }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Botón para marketing tips */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Marketing
              </Typography>
              <Tooltip title="Generate marketing tips for this product" placement="top">
                <span>
                  <AGTEnhanceButton
                    onEnhance={handleGetMarketingTips}
                    isLoading={loading}
                    disabled={loading}
                    label="Get Marketing Tips"
                    tooltip="Generate marketing tips for this product"
                    sx={{ minWidth: '180px' }}
                  />
                </span>
              </Tooltip>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <StyledActionButton onClick={handleCloseDialog}>
            Cancel
          </StyledActionButton>
          <StyledActionButton
            onClick={handleAddProduct}
            disabled={loading || !newProduct.name || !newProduct.category}
            sx={{
              backgroundColor: '#1DB954',
              '&:hover': {
                backgroundColor: '#1ed760'
              }
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : newProduct.id ? (
              'Save Changes'
            ) : (
              'Add Product'
            )}
          </StyledActionButton>
        </DialogActions>
      </StyledDialog>

      {/* Diálogo para marketingTips */}
      <StyledDialog
        open={showMarketingDialog}
        onClose={() => setShowMarketingDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ color: navColors.textDefault }}>
          Marketing Tips
        </DialogTitle>
        <DialogContent>
          {marketingTips ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {/* Target Audience */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navColors.textDefault, mb: 1, fontWeight: 600 }}
                >
                  Target Audience
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {marketingTips.targetAudience.map((audience, index) => (
                    <Chip
                      key={index}
                      label={audience}
                      sx={{
                        backgroundColor: 'rgba(3, 133, 255, 0.1)',
                        color: '#0385FF',
                        borderRadius: '16px'
                      }}
                    />
                  ))}
                </Box>
              </Box>

              {/* Selling Points */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navColors.textDefault, mb: 1, fontWeight: 600 }}
                >
                  Key Selling Points
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {marketingTips.sellingPoints.map((point, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 2
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '24px',
                          height: '24px',
                          borderRadius: '12px',
                          backgroundColor: 'rgba(3, 133, 255, 0.1)',
                          color: '#0385FF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 600,
                          fontSize: '14px'
                        }}
                      >
                        {index + 1}
                      </Box>
                      <Typography sx={{ color: navColors.textDefault }}>
                        {point}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Marketing Channels */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navColors.textDefault, mb: 1, fontWeight: 600 }}
                >
                  Recommended Channels
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {marketingTips.channels.map((channel, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: navColors.textDefault,
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        padding: '8px 16px',
                        borderRadius: '8px'
                      }}
                    >
                      {channel}
                    </Typography>
                  ))}
                </Box>
              </Box>

              {/* Keywords */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navColors.textDefault, mb: 1, fontWeight: 600 }}
                >
                  Suggested Keywords
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {marketingTips.keywords.map((keyword, index) => (
                    <Chip
                      key={index}
                      label={keyword}
                      sx={{
                        backgroundColor: 'rgba(29, 185, 84, 0.1)',
                        color: '#1DB954',
                        borderRadius: '16px'
                      }}
                    />
                  ))}
                </Box>
              </Box>

              {/* Promotional Ideas */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navColors.textDefault, mb: 1, fontWeight: 600 }}
                >
                  Promotional Ideas
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {marketingTips.promotionalIdeas.map((idea, index) => (
                    <Box
                      key={index}
                      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    >
                      <Sparkles size={16} color="#1DB954" />
                      <Typography sx={{ color: navColors.textDefault }}>
                        {idea}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography sx={{ color: navColors.textDefault }}>
              No tips available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <StyledActionButton onClick={() => setShowMarketingDialog(false)}>
            Close
          </StyledActionButton>
        </DialogActions>
      </StyledDialog>

      {/* Snackbar de notificaciones */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default ProductsManager;
