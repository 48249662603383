import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import DirectiveLogo from '../../common/DirectiveLogo';
import { getThemeStyles } from '../../themes/themeConfig';

/**
 * Animaciones para el "shimmer" y los efectos de onda y pupila
 */
const shimmerAnimation = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const waveAnimation = keyframes`
  0% { transform: scaleY(0.3); opacity: 0.5; }
  25% { transform: scaleY(0.7); opacity: 0.8; }
  50% { transform: scaleY(1); opacity: 1; }
  75% { transform: scaleY(0.7); opacity: 0.8; }
  100% { transform: scaleY(0.3); opacity: 0.5; }
`;

const movePupilAnimation = keyframes`
  0%, 100% { transform: translate(-3px, 0); }
  25% { transform: translate(0, -1px); }
  50% { transform: translate(3px, 0); }
  75% { transform: translate(0, 1px); }
`;

/**
 * Styled Components
 */
const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  paddingLeft: '16px',
  paddingRight: '0',
  marginBottom: '8px',
  paddingBottom: '8px',
  opacity: 1,
  '&:last-child': {
    marginBottom: '12px',
  },
  '@media (max-width: 1200px)': {
    paddingLeft: '0',
    paddingRight: '16px',
  },
  '@media (max-width: 600px)': {
    paddingLeft: '0',
    paddingRight: '8px',
  },
  ...getThemeStyles('MessageContainer', theme.palette.mode),
}));

const MessageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  marginLeft: '0',
  paddingRight: '0',
  paddingTop: '8px',
});

const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  marginLeft: '0',
  marginTop: '10px',
  opacity: 1,
  '@media (max-width: 1200px)': {
    marginRight: '4px',
  },
  '@media (max-width: 600px)': {
    marginRight: '0px',
  },
  backgroundColor:
    theme.palette.mode === 'dark' ? '#000000 !important' : '#fff !important',
  border:
    theme.palette.mode === 'dark'
      ? '1px solid rgba(255, 255, 255, 0.1)'
      : '1px solid rgba(0, 0, 0, 0.1)',
  ...getThemeStyles('AssistantIconContainer', theme.palette.mode),
}));

const MessageContent = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  width: 'calc(100% - 44px)',
  padding: '12px 16px',
  borderRadius: '30px',
  position: 'relative',
  marginLeft: '0',
  marginRight: '0',
  lineHeight: '1.75',
  overflow: 'hidden',
  '@media (max-width: 1200px)': {
    width: 'calc(100% - 44px)',
  },
  '@media (max-width: 600px)': {
    width: 'calc(100% - 34px)',
  },
  ...getThemeStyles('MessageContent', theme.palette.mode),
}));

const TextContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const StyledText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(
    90deg,
    ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 0%,
    ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.5)'
        : 'rgba(0,0,0,0.5)'
    } 50%,
    ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 100%
  )`,
  backgroundSize: '200% auto',
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  animation: `${shimmerAnimation} 2s linear infinite`,
  fontWeight: 400,
}));

const WaveContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  height: '15px',
  marginLeft: '4px',
});

const WaveBar = styled(Box)(({ delay }) => ({
  width: '2px',
  height: '100%',
  background: '#666',
  animation: `${waveAnimation} 0.8s ease-in-out infinite`,
  animationDelay: `${delay}ms`,
  transformOrigin: 'center',
}));

const EyeContainer = styled(Box)({
  position: 'relative',
  marginLeft: '4px',
  width: '18px',
  height: '18px',
});

const StyledEye = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  '& svg': {
    strokeWidth: '3.5px',
    fill: 'transparent',
  },
  opacity: 0.85,
  '& path': {
    background: `linear-gradient(
      90deg,
      ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 0%,
      ${
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,0.5)'
          : 'rgba(0,0,0,0.5)'
      } 50%,
      ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 100%
    )`,
    backgroundSize: '200% auto',
    strokeWidth: '3px',
    animation: `${shimmerAnimation} 2s linear infinite`,
  },
}));

const Pupil = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '35%',
  width: '6px',
  height: '6px',
  marginTop: '-3px',
  borderRadius: '50%',
  border: `2.5px solid ${
    theme.palette.mode === 'dark' ? '#fff' : '#000000de'
  }`,
  background: 'transparent',
  backgroundSize: '200% auto',
  animation: `
    ${movePupilAnimation} 3s ease-in-out infinite,
    ${shimmerAnimation} 3s linear infinite
  `,
  opacity: theme.palette.mode === 'dark' ? 1 : 0.9,
}));

/**
 * Indicador de ondas para "audio" (Listening)
 */
const AudioWaveIndicator = () => {
  return (
    <WaveContainer>
      {[0, 1, 2].map((index) => (
        <WaveBar key={index} delay={index * 100} />
      ))}
    </WaveContainer>
  );
};

/**
 * Ojo animado para "observación" (Imagen)
 */
const AnimatedEye = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <EyeContainer>
      <StyledEye>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          height={18}
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
            stroke={isDarkMode ? '#ffffff' : '#000000de'}
          />
        </svg>
      </StyledEye>
      <Pupil />
    </EyeContainer>
  );
};

/**
 * Retorna un array de arrays con frases personalizadas según el tipo de mensaje.
 */
const getCustomPhrases = (info) => {
  const {
    isAudio,
    isImage,
    isPdf,
    isExcel,
    isProjectChat,
    modelType,
    userIsPro,
  } = info || {};

  // 1) Audio
  if (isAudio) {
    return [
      ['Listening to audio', 'Transcribing audio...'],
      ['Converting speech to text...'],
      ['Refining audio input...'],
    ];
  }

  // 2) Imagen
  if (isImage) {
    return [
      ['Observing image data', 'Enhancing visual elements'],
      ['Extracting image features'],
      ['Combining text & image context'],
    ];
  }

  // 3) PDF / Excel / combinación
  if (isPdf && isExcel) {
    return [
      ['Reading PDF & Excel', 'Combining PDF + XLS insights', 'Processing multiple documents'],
      ['Cross-referencing documents...', 'Merging document data...', 'Analyzing both files...'],
      ['Extracting combined insights', 'Synthesizing document information', 'Integrating file contents'],
      ['Compiling comprehensive report', 'Building unified analysis', 'Creating document summary']
    ];
  }
  if (isPdf) {
    return [
      ['Reading PDF', 'Extracting PDF data', 'Processing document contents'],
      ['Summarizing PDF content', 'Analyzing document structure', 'Reading through pages...'],
      ['Extracting key information', 'Processing document metadata', 'Compiling document insights'],
      ['Building document overview', 'Organizing PDF contents', 'Preparing document summary']
    ];
  }
  if (isExcel) {
    return [
      ['Analyzing Excel sheet', 'Compiling data from XLS', 'Processing spreadsheet'],
      ['Building data tables', 'Reading spreadsheet contents', 'Extracting cell data...'],
      ['Processing numerical data', 'Analyzing spreadsheet structure', 'Compiling worksheet info'],
      ['Organizing tabular data', 'Processing formulas and values', 'Building data summary']
    ];
  }

  // 4) Chat de proyecto
  if (isProjectChat) {
    return [
      ['Processing project-specific data', 'Analyzing project information', 'Loading project context'],
      ['Analyzing tasks & context of project', 'Reviewing project objectives', 'Mapping project structure'],
      ['Integrating project files', 'Consolidating project data', 'Organizing project resources'],
      ['Building project insights', 'Processing milestone information', 'Analyzing project timeline'],
      ['Evaluating project dependencies', 'Reviewing project hierarchy', 'Analyzing team structure']
    ];
  }

  // 5) Modelo Ultra vs Pro
  if (modelType?.includes('sonnet')) {
    return [
      ['Ultra: In-depth analysis', 'Deep scanning your request', 'Performing comprehensive review'],
      ['Refining advanced details...', 'Gathering ultra-level insights', 'Processing complex patterns'],
      ['Extracting nuanced information', 'Analyzing deeper correlations', 'Building detailed insights'],
      ['Performing advanced reasoning', 'Synthesizing complex data', 'Evaluating subtle patterns']
    ];
  } else if (modelType?.includes('haiku')) {
    return [
      ['Pro: Processing your request', 'Gathering relevant parameters...', 'Analyzing key elements'],
      ['Integrating additional context', 'Processing essential data', 'Building core insights'],
      ['Optimizing response efficiency', 'Streamlining data analysis', 'Focusing on key patterns'],
      ['Reviewing primary factors', 'Processing main variables', 'Analyzing core components']
    ];
  }

  // 6) Usuario Pro (sin un modelo detectado)
  if (userIsPro) {
    return [
      ['Pro user: Enhanced thinking', 'Deploying advanced features', 'Activating premium analysis'],
      ['Combining knowledge with pro-level context', 'Leveraging premium capabilities', 'Enabling advanced processing'],
      ['Optimizing pro-level insights', 'Processing with enhanced depth', 'Utilizing premium features'],
      ['Applying advanced algorithms', 'Enhanced pattern recognition', 'Deploying premium analysis tools']
    ];
  }

  // 7) Por defecto
  return [
    ['Analyzing input data', 'Processing information', 'Gathering relevant parameters', 'Evaluating request context'],
    ['Identifying key variables', 'Establishing causal relationships', 'Mapping data correlations', 'Processing core elements'],
    ['Formulating hypotheses', 'Predicting outcomes', 'Building analytical framework', 'Developing response strategy'],
    ['Checking logical consistency', 'Evaluating results', 'Validating conclusions', 'Verifying analysis accuracy'],
    ['Integrating new information', 'Preparing response', 'Synthesizing insights', 'Finalizing analysis'],
    ['Processing contextual data', 'Analyzing semantic patterns', 'Building comprehensive view', 'Evaluating key factors']
  ];
};

/**
 * Componente principal "ThinkingIcon"
 * Muestra frases animadas, con posibilidad de pausar aleatoriamente para que alguna frase dure más tiempo.
 */
const ThinkingIcon = ({
  startTime,
  messageTypeInfo = {},
}) => {
  const theme = useTheme();
  const phrases = getCustomPhrases(messageTypeInfo);

  // Si se está en isAudio o isImage, podemos forzar una sola frase
  // (pero conserva tu lógica si quieres rotar también en audio/imágenes).
  const isAudioOrImage = messageTypeInfo.isAudio || messageTypeInfo.isImage;

  // --- Estados para manejar la rotación de frases "manual" ---
  const [phaseIndex, setPhaseIndex] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState(() => {
    return phrases[0][0];
  });

  // Duración de la “pausa” (en ticks, cada tick es 1 segundo en este ejemplo)
  const [pauseTimer, setPauseTimer] = useState(0);

  useEffect(() => {
    // Si es audio o imagen, fijamos una frase única y no rotamos
    if (isAudioOrImage) {
      if (messageTypeInfo.isAudio) {
        setCurrentPhrase('Listening');
      } else if (messageTypeInfo.isImage) {
        setCurrentPhrase('Observing');
      }
      return;
    }

    // De lo contrario, iniciamos la lógica de rotación + pausa
    const interval = setInterval(() => {
      setPhaseIndex((prevIndex) => {
        // Si todavía estamos en una pausa, decrementamos y no avanzamos la fase
        if (pauseTimer > 0) {
          setPauseTimer((p) => p - 1);
          return prevIndex;
        }

        // Si no hay pausa, pasamos a la siguiente fase
        const nextIndex = Math.min(prevIndex + 1, phrases.length - 1);

        // Aquí puedes aplicar una probabilidad de pausar de nuevo
        // Ejemplo: 30% de que se active la pausa
        const shouldPause = Math.random() < 0.30;

        if (shouldPause && nextIndex < phrases.length - 1) {
          // Pausa entre 1 y 3 segundos por ejemplo
          const randomPause = Math.floor(Math.random() * 3) + 1;
          setPauseTimer(randomPause);
        }

        return nextIndex;
      });
    }, 500); // cada 1 segundo checamos si avanzamos o pausamos

    return () => clearInterval(interval);
  }, [phrases, pauseTimer, messageTypeInfo, isAudioOrImage]);

  // Cada vez que cambie la fase, elegimos una frase aleatoria del bloque actual
  useEffect(() => {
    if (isAudioOrImage) return;
    const currentBlock = phrases[phaseIndex];
    const randomIndex = Math.floor(Math.random() * currentBlock.length);
    setCurrentPhrase(currentBlock[randomIndex]);
  }, [phaseIndex, phrases, isAudioOrImage]);

  // Render principal
  return (
    <MessageContainer>
      <MessageWrapper>
        <AssistantIconContainer>
          <DirectiveLogo
            size={18}
            className="text-current"
            style={{
              color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
            }}
          />
        </AssistantIconContainer>
        <MessageContent>
          <TextContainer>
            <StyledText variant="body1">{currentPhrase}</StyledText>

            {/* Mostrar indicadores especiales si la metadata lo indica */}
            {messageTypeInfo.isAudio && <AudioWaveIndicator />}
            {messageTypeInfo.isImage && <AnimatedEye />}
          </TextContainer>
        </MessageContent>
      </MessageWrapper>
    </MessageContainer>
  );
};

export default ThinkingIcon;
