import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  useTheme,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Plus,
  FolderOpen,
  FilePlus2,
  ScrollText,
  Wrench
} from 'lucide-react';

import ChatViewNest from '../core/ChatViewNest';
import {
  createNewChat,
  updateProjectContext,
  updateProjectInstructions
} from '../../services/AGTService';
import FilesView from './views/FilesView';
import ChatsView from './views/ChatsView';
import ContextView from './views/ContextView';
import InstructionsView from './views/InstructionsView';
import { useProjectContext, ProjectContextProvider } from './ProjectContextProvider';

const ProjectHeader = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '54px',
  zIndex: 1100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#FFFFFF',
}));

const CategoryChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '20px',
  padding: '0 8px',
  borderRadius: '10px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(3, 133, 255, 0.1)'
      : 'rgba(3, 133, 255, 0.08)',
  color: '#0385FF',
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  marginLeft: theme.spacing(1),
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: '#0385FF',
  color: '#FFFFFF',
  borderRadius: '20px',
  textTransform: 'none',
  padding: '6px 16px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#0371db',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: 6,
  },
}));

const ProjectChatViewContent = ({
  project,
  userId,
  selectedModel,
  selectedPersonality,
  isWaitingForResponse,
  inputRef,
  rateLimitInfo,
  onOpenAGTPromo,
  onModelChange,
  onPersonalityChange,
  isPro,
  error,
  onErrorClose,
  lastAssistantMessage,
  handleSendMessage,
  toggleSidebar,
  onProjectChatSelect,
}) => {
  const theme = useTheme();
  const [currentView, setCurrentView] = useState('main');
  const [isLoading, setIsLoading] = useState(false);
  const { addProjectChat } = useProjectContext();

  // Estados del snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Estados para los modales
  const [openFilesModal, setOpenFilesModal] = useState(false);
  const [openContextModal, setOpenContextModal] = useState(false);
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false);

  // Campos de context
  const [contextTitle, setContextTitle] = useState('');
  const [contextContent, setContextContent] = useState('');

  // Campos de instructions
  const [instructionsTitle, setInstructionsTitle] = useState('');
  const [instructionsContent, setInstructionsContent] = useState('');

  // Crear un nuevo chat asociado al proyecto
  const handleCreateNewChatCard = useCallback(async () => {
    if (!project || !userId) {
      return;
    }

    setIsLoading(true);

    try {
      const newChat = await createNewChat(
        userId,
        project.category,
        project.id
      );

      if (!newChat || !newChat.id) {
        throw new Error('Invalid chat data received');
      }

      if (onProjectChatSelect) {
        await onProjectChatSelect(newChat.id, true, 'chat');
        setCurrentView('chat');
      }

    } catch (error) {
      const errorMessage = error?.response?.data?.message ||
                         error?.message ||
                         'Error creating chat';

      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  }, [project, userId, onProjectChatSelect]);

  // Guardar el contexto del proyecto
  const handleSaveContext = async () => {
    if (!project || !project.id) {
      console.error('[ProjectChatView] No project info. Cannot update context.');
      return;
    }

    try {
      await updateProjectContext({
        userId,
        projectId: project.id,
        contextTitle,
        contextContent
      });

      setSnackbarMessage('Context saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error saving context:', err);
      setSnackbarMessage('Error saving context');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    setOpenContextModal(false);
    setContextTitle('');
    setContextContent('');
  };

  // Guardar las instrucciones del proyecto
  const handleSaveInstructions = async () => {
    if (!project || !project.id) {
      console.error('[ProjectChatView] No project info. Cannot update instructions.');
      return;
    }

    try {
      await updateProjectInstructions({
        userId,
        projectId: project.id,
        instructionsTitle,
        instructionsContent
      });

      setSnackbarMessage('Instructions saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error saving instructions:', err);
      setSnackbarMessage('Error saving instructions');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    setOpenInstructionsModal(false);
    setInstructionsTitle('');
    setInstructionsContent('');
  };

  // Header: se oculta si currentView === 'chat'
  const renderHeader = () => {
    if (currentView === 'chat') {
      return null;
    }

    return (
      <ProjectHeader>
        <IconButton onClick={toggleSidebar} sx={{ color: '#0385FF' }}>
          <MenuIcon size={20} />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: '21.25px',
              letterSpacing: '-0.3px',
              textAlign: 'center',
            }}
          >
            {project?.name || 'Project Chat'}
          </Typography>
          {project?.category && (
            <CategoryChip>{project.category} Project</CategoryChip>
          )}
        </Box>

        <IconButton disabled sx={{ visibility: 'hidden', color: '#0385FF' }}>
          <MenuIcon size={20} />
        </IconButton>
      </ProjectHeader>
    );
  };

  // Determina cuál vista mostrar
  const renderContent = () => {
    switch (currentView) {
      case 'files':
        return (
          <FilesView
            project={project}
            onBack={() => setCurrentView('main')}
            user={{ uid: userId }}
          />
        );

      case 'chats':
        return (
          <ChatsView
            project={project}
            onBack={() => setCurrentView('main')}
            user={{ uid: userId }}
            onChatSelect={(chat) => {
              if (onProjectChatSelect) {
                onProjectChatSelect(chat.id);
              }
            }}
          />
        );

      case 'context':
        return (
          <ContextView
            project={project}
            onBack={() => setCurrentView('main')}
            user={{ uid: userId }}
          />
        );

      case 'instructions':
        return (
          <InstructionsView
            project={project}
            onBack={() => setCurrentView('main')}
            user={{ uid: userId }}
          />
        );

      case 'chat':
        return null;

      default:
        // Pantalla principal con 4 tiles + "Create New Chat"
        return (
          <>
            <Box
              sx={{
                width: '100%',
                maxWidth: 'sm',
                mx: 'auto',
                mt: 2,
                mb: 3,
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    onClick={() => setOpenFilesModal(true)}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      border: (theme) => `2px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      transition: 'all 0.2s',
                      '&:hover': {
                        borderColor: '#0385FF',
                        '& .icon': {
                          color: '#0385FF',
                        },
                      },
                    }}
                  >
                    <FilePlus2
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Add Files
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    onClick={() => setOpenContextModal(true)}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      border: (theme) => `2px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      transition: 'all 0.2s',
                      '&:hover': {
                        borderColor: '#0385FF',
                        '& .icon': {
                          color: '#0385FF',
                        },
                      },
                    }}
                  >
                    <ScrollText
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Add Context
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    onClick={() => setOpenInstructionsModal(true)}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      border: (theme) => `2px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      transition: 'all 0.2s',
                      '&:hover': {
                        borderColor: '#0385FF',
                        '& .icon': {
                          color: '#0385FF',
                        },
                      },
                    }}
                  >
                    <Wrench
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Add Instructions
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper
                    elevation={0}
                    onClick={handleCreateNewChatCard}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      border: (theme) => `2px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      transition: 'all 0.2s',
                      '&:hover': {
                        borderColor: '#0385FF',
                        '& .icon': {
                          color: '#0385FF',
                        },
                      },
                    }}
                  >
                    <Plus
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Create New Chat
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '100%', maxWidth: 'sm', mx: 'auto' }}>
              <Divider sx={{ my: 2 }} />
            </Box>

            <Box
              sx={{
                width: '100%',
                maxWidth: 'sm',
                mx: 'auto',
                mt: 2,
                mb: 3,
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    onClick={() => setCurrentView('files')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      '&:hover .icon': {
                        color: '#0385FF',
                      },
                    }}
                  >
                    <FolderOpen
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Files
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    onClick={() => setCurrentView('chats')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      '&:hover .icon': {
                        color: '#0385FF',
                      },
                    }}
                  >
                    <FolderOpen
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Chats
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    onClick={() => setCurrentView('context')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      '&:hover .icon': {
                        color: '#0385FF',
                      },
                    }}
                  >
                    <FolderOpen
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Context
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box
                    onClick={() => setCurrentView('instructions')}
                    sx={{
                      p: 3,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      '&:hover .icon': {
                        color: '#0385FF',
                      },
                    }}
                  >
                    <FolderOpen
                      className="icon"
                      size={32}
                      style={{ marginBottom: 8, color: '#0385FF' }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      Instructions
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        );
    }
  };

  return (
    <>
      {renderHeader()}

      <Box
        sx={{
          marginTop: currentView === 'chat' ? 0 : '56px',
          width: '100%',
        }}
      >
        {renderContent()}
      </Box>

      {/* Modal de Files */}
      <FilesView
        project={project}
        user={{ uid: userId }}
        isModal={true}
        open={openFilesModal}
        onClose={() => setOpenFilesModal(false)}
      />

      {/* Modal de Context */}
      <Dialog
        open={openContextModal}
        onClose={() => setOpenContextModal(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            bgcolor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle
          sx={{
            pt: 3,
            pb: 2,
            px: 3,
            fontSize: '1.1rem',
            fontWeight: 500,
            borderBottom: 1,
            borderColor: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)'
          }}
        >
          New Context
        </DialogTitle>

        <DialogContent sx={{ mt: 2, p: 3 }}>
          <TextField
            autoFocus
            fullWidth
            placeholder="Title (Optional)"
            value={contextTitle}
            onChange={(e) => setContextTitle(e.target.value)}
            variant="outlined"
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
                border: 'none',
                '& fieldset': {
                  border: 'none',
                },
              }
            }}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="e.g. 'Project deadline is next month. Creating a customer feedback dashboard.' or 'Redesigning the mobile app with focus on accessibility.'"
            value={contextContent}
            onChange={(e) => setContextContent(e.target.value)}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
                border: 'none',
                '& fieldset': {
                  border: 'none',
                },
              }
            }}
          />

          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mt: 2,
              color: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.5)'
                : 'rgba(0, 0, 0, 0.5)',
              fontSize: '0.7rem',
              textAlign: 'center'
            }}
          >
            Note: New context will replace any previous context for this project
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3, display: 'flex', gap: 1 }}>
          <Button
            onClick={() => setOpenContextModal(false)}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              px: 3,
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.05)'
              }
            }}
          >
            Cancel
          </Button>
          <StyledButton
            onClick={handleSaveContext}
            disabled={!contextContent.trim()}
          >
            Save
          </StyledButton>
        </DialogActions>
      </Dialog>

      {/* Modal de Instructions */}
      <Dialog
        open={openInstructionsModal}
        onClose={() => setOpenInstructionsModal(false)}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            bgcolor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle
          sx={{
            pt: 3,
            pb: 2,
            px: 3,
            fontSize: '1.1rem',
            fontWeight: 500,
            borderBottom: 1,
            borderColor: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)'
          }}
        >
          New Instructions
        </DialogTitle>

        <DialogContent sx={{ mt: 2, p: 3 }}>
          <TextField
            autoFocus
            fullWidth
            placeholder="Title (Optional)"
            value={instructionsTitle}
            onChange={(e) => setInstructionsTitle(e.target.value)}
            variant="outlined"
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
                border: 'none',
                '& fieldset': {
                  border: 'none',
                },
              }
            }}
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="e.g. 'Write in a professional and technical tone.' or 'Analyze the data and provide insights with visualizations.'"
            value={instructionsContent}
            onChange={(e) => setInstructionsContent(e.target.value)}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
                border: 'none',
                '& fieldset': {
                  border: 'none',
                },
              }
            }}
          />

          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mt: 2,
              color: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.5)'
                : 'rgba(0, 0, 0, 0.5)',
              fontSize: '0.7rem',
              textAlign: 'center'
            }}
          >
            Note: New instructions will replace any previous instructions for this project
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3, display: 'flex', gap: 1 }}>
          <Button
            onClick={() => setOpenInstructionsModal(false)}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              px: 3,
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.05)'
              }
            }}
          >
            Cancel
          </Button>
          <StyledButton
            onClick={handleSaveInstructions}
            disabled={!instructionsContent.trim()}
          >
            Save
          </StyledButton>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

// Envolver el componente con ProjectContextProvider
const ProjectChatView = (props) => (
  <ProjectContextProvider>
    <ProjectChatViewContent {...props} />
  </ProjectContextProvider>
);

export default ProjectChatView;