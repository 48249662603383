// AGTVision.js
import React, { useState, useRef, useEffect, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { flushSync } from 'react-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AGTVoice from './AGTVoice';
import VoiceInterface from './VoiceInterface';
import VoiceIndicator from './VoiceIndicator';
import ThinkingAudioPlayer from './ThinkingAudioPlayer';
import { v4 as uuidv4 } from 'uuid';
import { styled, keyframes } from '@mui/system';
import {
  Eye, 
  Camera, 
  Monitor, 
  StopCircle, 
  Mic, 
  MicOff, 
  RefreshCw,
  Brain,
  Send,
  X,
} from 'lucide-react';
import { voiceColors } from './voiceConfig';
import SourceSelector from './SourceSelector';
import { hasVisualTrigger } from './triggers';

// >>> LOGS <<< Se usará `// >>> LOGS <<<` para detallar la ejecución.

// Animaciones
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const wave = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '0px',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  }
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.background.default,
}));

const CloseButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 20,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.2)'
      : 'rgba(0, 0, 0, 0.2)',
  },
  width: '40px',
  height: '40px',
}));

const VideoSection = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

const LeftContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '16px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.default,
}));

const MiddleContainer = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '28px',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  maxWidth: '90%',
  maxHeight: '80vh',
  margin: '0 auto',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.75rem',
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: '24px',
  color: theme.palette.text.primary,
}));

const ControlButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== '$active',
})(({ $active, theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: $active 
    ? theme.palette.error.main
    : theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: $active 
      ? theme.palette.error.dark
      : theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : 'rgba(0, 0, 0, 0.2)',
  },
  '&:disabled': {
    color: theme.palette.action.disabled,
  },
  width: '48px',
  height: '48px',
}));

const AGTVisionButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  width: '32px',
  height: '32px',
  minWidth: '32px',
  minHeight: '32px',
  backgroundColor: 'transparent',
  color: '#0385FF',
  transition: 'all 0.3s ease',
  margin: '0',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#026fcc',
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    color: theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.26)'
      : 'rgba(255, 255, 255, 0.26)',
  },
}));

const StyledVideo = styled('video', {
  shouldForwardProp: (prop) => prop !== '$isScreenShare',
})(({ $isScreenShare, theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: $isScreenShare ? 'contain' : 'cover',
  transform: 'none',
  borderRadius: '28px',
  maxWidth: '1280px',
  maxHeight: '720px',
  backgroundColor: theme.palette.background.paper,
}));

const VideoControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  padding: '8px 16px',
  borderRadius: '12px',
  backgroundColor: 'transparent',
  zIndex: 10,
  marginTop: '16px',
  justifyContent: 'center',
}));

const VideoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.6)'
    : 'rgba(255, 255, 255, 0.6)',
  color: theme.palette.text.primary,
  zIndex: 5,
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  left: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.6)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.text.primary,
  zIndex: 10,
}));

const StatusIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$active',
})(({ $active, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  opacity: $active ? 1 : 0.5,
  animation: $active ? `${pulse} 2s infinite ease-in-out` : 'none',
  color: theme.palette.text.primary,
}));

const FadeInBox = styled(Box)(({ theme }) => ({
  animation: `${fadeIn} 0.5s ease-in-out`,
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '80px',
  right: '20px',
  width: '200px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.7)'
    : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  padding: '8px',
  color: theme.palette.text.primary,
  zIndex: 10,
}));

const PreviewImage = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
  marginBottom: '8px',
});

const PreviewControls = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
});

// >>> Indicadores
const ProcessingIndicator = ({ 
  isListening = false, 
  isProcessingImage = false, 
  isThinking = false 
}) => {
  return (
    <StatusContainer>
      <StatusIconContainer $active={isListening}>
        <Mic size={16} />
        <Typography variant="caption">
          Listening
          {isListening && (
            <Box sx={{ display: 'inline-flex', gap: '4px', marginLeft: '4px' }}>
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out`,
                }}
              />
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out 0.2s`,
                }}
              />
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out 0.4s`,
                }}
              />
            </Box>
          )}
        </Typography>
      </StatusIconContainer>

      <StatusIconContainer $active={isProcessingImage}>
        <Eye size={16} />
        <Typography variant="caption">
          Looking
          {isProcessingImage && (
            <Box sx={{ display: 'inline-flex', gap: '4px', marginLeft: '4px' }}>
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out`,
                }}
              />
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out 0.2s`,
                }}
              />
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out 0.4s`,
                }}
              />
            </Box>
          )}
        </Typography>
      </StatusIconContainer>

      <StatusIconContainer $active={isThinking}>
        <Brain size={16} />
        <Typography variant="caption">
          Thinking
          {isThinking && (
            <Box sx={{ display: 'inline-flex', gap: '4px', marginLeft: '4px' }}>
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out`,
                }}
              />
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out 0.2s`,
                }}
              />
              <span
                className="dot"
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'currentColor',
                  animation: `${wave} 1s infinite ease-in-out 0.4s`,
                }}
              />
            </Box>
          )}
        </Typography>
      </StatusIconContainer>
    </StatusContainer>
  );
};

// >>> Preview del mensaje (con o sin screenshot)
//     Mantenemos la lógica pero lo vamos a ocultar (display: 'none') en la UI.
const MessagePreview = ({ 
  imageUrl, 
  transcription, 
  onCancel, 
  onSend 
}) => {
  return (
    <FadeInBox>
      <PreviewContainer>
        {/* Si hay imagenUrl, mostramos la imagen */}
        {imageUrl && <PreviewImage src={imageUrl} alt="Preview" />}

        {/* Siempre mostramos el texto transcrito (o "Processing...") */}
        <Typography variant="caption" sx={{ display: 'block', marginBottom: '16px' }}>
          {transcription || 'Processing transcription...'}
        </Typography>

        <PreviewControls>
          <IconButton
            size="small"
            onClick={onCancel}
            sx={{ color: 'white' }}
            title="Cancel"
          >
            <X size={20} />
          </IconButton>
          <IconButton
            size="small"
            onClick={onSend}
            disabled={!transcription}
            sx={{ color: 'white' }}
            title="Send"
          >
            <Send size={20} />
          </IconButton>
        </PreviewControls>
      </PreviewContainer>
    </FadeInBox>
  );
};

// >>> Alert de error
const ErrorAlert = ({ message, onClose }) => (
  <Alert
    severity="error"
    onClose={onClose}
    sx={{
      position: 'absolute',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 20
    }}
  >
    {message}
  </Alert>
);

// >>> Voces
const AGTVISION_VOICES = [
  { id: 'alloy', name: 'Sova', description: 'Versatile, neutral voice' },
  { id: 'echo', name: 'Loren', description: 'Expressive, melodic voice' },
  { id: 'fable', name: 'Willow', description: 'Authoritative, mature voice' },
  { id: 'onyx', name: 'Max', description: 'Distinct, resonant voice' },
  { id: 'nova', name: 'Aurora', description: 'Energetic, friendly voice' },
  { id: 'shimmer', name: 'Charlotte', description: 'Clear, gentle voice' }
];

const AGTVision = forwardRef(({
  isPro = false,
  onSendMessage,
  onClose: onExternalClose,
  isWaitingForResponse = false,
}, ref) => {
  // Flag para reactivar mic luego
  const [autoResumeMic, setAutoResumeMic] = useState(false);

  const [step, setStep] = useState('selectVoice');
  const [selectedVoice, setSelectedVoice] = useState('alloy');
  const [open, setOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [stream, setStream] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [showTranscription, setShowTranscription] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);
  const [lastAssistantMessage, setLastAssistantMessage] = useState(null);

  const [hasMediaAccess, setHasMediaAccess] = useState({
    camera: null,
    microphone: null
  });
  const [voiceError, setVoiceError] = useState(null);
  const [isVoicePlaying, setIsVoicePlaying] = useState(false);

  const videoRef = useRef(null);
  const recognitionRef = useRef(null);
  const errorTimeoutRef = useRef(null);
  const silenceTimerRef = useRef(null);
  const lastProcessedTranscript = useRef('');
  const mountedRef = useRef(true);

  // >>> Nuevo timer para enviar automáticamente
  const pendingMessageTimerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    click: () => {
      handleOpenAGTVision();
    }
  }));

  const MIN_SPEECH_LENGTH = 5;
  const SILENCE_TIMEOUT = 1500;
  const ERROR_DISPLAY_TIME = 5000;

  const showTemporaryError = useCallback((msg) => {
    setError(msg);
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    errorTimeoutRef.current = setTimeout(() => {
      setError(null);
    }, ERROR_DISPLAY_TIME);
  }, []);

  const clearError = useCallback(() => {
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    setError(null);
  }, []);

  const captureScreenshot = useCallback(async () => {
    if (!videoRef.current || !stream) {
      return null;
    }
    try {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      const ctx = canvas.getContext('2d');
      if (selectedSource === 'camera') {
        // Invertir la imagen (espejo)
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);
      }
      ctx.drawImage(videoRef.current, 0, 0);

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (!blob) return resolve(null);
          const file = new File([blob], `screenshot-${Date.now()}.png`, {
            type: 'image/png'
          });
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Data = reader.result.split(',')[1];
            resolve({
              name: file.name,
              type: file.type,
              data: base64Data,
              size: file.size
            });
          };
          reader.onerror = () => {
            resolve(null);
          };
          reader.readAsDataURL(file);
        }, 'image/png', 0.95);
      });
    } catch (err) {
      // console.error('captureScreenshot error =>', err);
      return null;
    }
  }, [selectedSource, stream]);

  // >>> Lógica para procesar transcripciones
  const processAndSendMessage = useCallback(
    async (finalTranscript) => {
      if (isThinking || !finalTranscript.trim()) return;

      // Si ya hay un pendingMessage => lo cancelamos antes de crear otro
      if (pendingMessage) {
        // Cancelamos el anterior
        handleCancelPendingMessage();
      }

      setIsProcessingImage(true);

      try {
        let screenshot = null;

        // >>> Mantener triggers: capturar screenshot si pasa el trigger
        if (hasVisualTrigger(finalTranscript)) {
          screenshot = await captureScreenshot();
        }

        if (screenshot) {
          const previewUrl = `data:image/png;base64,${screenshot.data}`;
          setPreviewImage(previewUrl);

          const userMessage = {
            text: finalTranscript.trim(),
            files: [
              {
                type: 'image/png',
                name: `screenshot-${Date.now()}.png`,
                data: screenshot.data,
                source: 'AGTVision',
                captureType: selectedSource
              }
            ],
            source: 'AGTVision',
            captureType: selectedSource,
            metadata: {
              captureMode: selectedSource,
              deviceInfo: 'web',
              timestamp: new Date().toISOString(),
              transcriptionSource: 'webSpeechAPI',
              source: 'AGTVision'
            },
            voicePreference: selectedVoice,
            isUser: true
          };
          setPendingMessage(userMessage);

        } else {
          // No screenshot => mandar texto (sin imagen)
          const userMessage = {
            text: finalTranscript.trim(),
            source: 'AGTVision',
            captureType: selectedSource,
            metadata: {
              captureMode: selectedSource,
              deviceInfo: 'web',
              timestamp: new Date().toISOString(),
              transcriptionSource: 'webSpeechAPI',
              source: 'AGTVision'
            },
            voicePreference: selectedVoice,
            isUser: true
          };
          setPendingMessage(userMessage);
        }

        // Parar la escucha mientras decidimos (2s) => Evita interrupciones
        if (recognitionRef.current) {
          try {
            recognitionRef.current.stop();
          } catch {}
        }
      } catch (err) {
        // console.error('processAndSendMessage error =>', err);
        showTemporaryError('Error processing message');
      } finally {
        setIsProcessingImage(false);
      }
    },
    [
      isThinking,
      pendingMessage,
      captureScreenshot,
      showTemporaryError,
      selectedSource,
      selectedVoice
    ]
  );

  // >>> handleSpeechResult
  const handleSpeechResult = useCallback(
    (event) => {
      let interimTranscript = '';
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        const txt = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += txt + ' ';
        } else {
          interimTranscript += txt;
        }
      }

      // Cuando sea final
      if (
        finalTranscript.trim().length >= MIN_SPEECH_LENGTH &&
        finalTranscript.trim() !== lastProcessedTranscript.current
      ) {
        lastProcessedTranscript.current = finalTranscript.trim();
        processAndSendMessage(finalTranscript.trim());
      }

      // Interim: vamos mostrando
      if (interimTranscript.trim()) {
        setTranscription(interimTranscript);
        setShowTranscription(true);

        // Eliminamos timeouts anteriores
        if (silenceTimerRef.current) clearTimeout(silenceTimerRef.current);

        // Cuando pasa X tiempo sin recibir más audio => enviamos
        silenceTimerRef.current = setTimeout(() => {
          if (interimTranscript.trim().length >= MIN_SPEECH_LENGTH) {
            processAndSendMessage(interimTranscript.trim());
          }
        }, SILENCE_TIMEOUT);
      }
    },
    [processAndSendMessage]
  );

  // >>> Al crear/actualizar pendingMessage => arrancar timer de 2s para envío auto
  useEffect(() => {
    if (pendingMessage) {
      // Limpiamos cualquier timer anterior
      if (pendingMessageTimerRef.current) {
        clearTimeout(pendingMessageTimerRef.current);
      }
      // Después de 2s => mandar
      pendingMessageTimerRef.current = setTimeout(() => {
        handleSendPendingMessage();
      }, 2000);
    }

    return () => {
      if (pendingMessageTimerRef.current) {
        clearTimeout(pendingMessageTimerRef.current);
      }
    };
  }, [pendingMessage]);

  // >>> Configuración de SpeechRecognition
  const setupSpeechRecognition = useCallback(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      throw new Error('Speech recognition not supported in this browser');
    }
    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
    recognition.lang = 'es-ES';

    recognition.onstart = () => {
      setIsListening(true);
      clearError();
    };
    recognition.onresult = handleSpeechResult;
    recognition.onerror = (e) => {
      if (e.error === 'no-speech') {
        return;
      }
      showTemporaryError(`Transcription error: ${e.error}`);
      setIsListening(false);
    };
    recognitionRef.current = recognition;
    return recognition;
  }, [handleSpeechResult, clearError, showTemporaryError]);

  // Detener stream
  const stopCurrentStream = useCallback(() => {
    if (stream) {
      stream.getTracks().forEach((t) => {
        try {
          t.stop();
        } catch {}
      });
      setStream(null);
    }
  }, [stream]);

  // >>> Al dar Close (botón X)
  const handleClose = useCallback(() => {
    stopCurrentStream();
    if (recognitionRef.current) {
      try {
        recognitionRef.current.stop();
      } catch {}
      recognitionRef.current = null;
    }
    if (silenceTimerRef.current) {
      clearTimeout(silenceTimerRef.current);
    }
    setLastAssistantMessage(null);
    setIsListening(false);
    setOpen(false);
    setSelectedSource(null);
    setTranscription('');
    setShowTranscription(false);
    clearError();
    setVoiceError(null);
    lastProcessedTranscript.current = '';
    setStep('selectVoice');
    setSelectedVoice('alloy');

    onExternalClose?.();
  }, [stopCurrentStream, clearError, onExternalClose]);

  // >>> Al dar Send en el preview o por timeout
  const handleSendPendingMessage = useCallback(async () => {
    if (!pendingMessage) return;

    setIsVoicePlaying(false);
    setIsThinking(true);

    try {
      const messageWithMetadata = {
        ...pendingMessage,
        source: 'AGTVision',
        captureType: selectedSource,
        text: JSON.stringify({
          text: pendingMessage.text,
          source: 'AGTVision',
          captureType: selectedSource,
          timestamp: new Date().toISOString()
        }),
        files: pendingMessage.files?.map((file) => ({
          ...file,
          source: 'AGTVision',
          captureType: selectedSource
        })),
        metadata: {
          captureMode: selectedSource,
          deviceInfo: 'web',
          timestamp: new Date().toISOString(),
          voicePreference: selectedVoice,
          source: 'AGTVision'
        },
        voicePreference: selectedVoice
      };

      const response = await onSendMessage(messageWithMetadata);

      if (response?.reply) {
        const uniqueId = uuidv4();
        const assistantMessage = {
          id: uniqueId,
          text: response.reply,
          audioBlob: response.audioBlob,
          isUser: false,
          source: 'AGTVision',
          captureType: selectedSource,
          metadata: {
            captureMode: selectedSource,
            deviceInfo: 'web',
            timestamp: new Date().toISOString(),
            messageId: uniqueId,
            voicePreference: selectedVoice
          }
        };
        setLastAssistantMessage(null);
        await new Promise((r) => setTimeout(r, 100));
        if (!isThinking && mountedRef.current) {
          setLastAssistantMessage(assistantMessage);
        }
      }
    } catch (err) {
      showTemporaryError('Error receiving assistant response');
    } finally {
      if (mountedRef.current) {
        setPreviewImage(null);
        setPendingMessage(null);
        setTranscription('');
        setShowTranscription(false);
        setIsThinking(false);

        // Reactivamos mic si procedía
        if (isListening) {
          try {
            const rec = recognitionRef.current || setupSpeechRecognition();
            rec.start();
          } catch {}
        }
      }
    }
  }, [
    pendingMessage,
    isListening,
    isThinking,
    onSendMessage,
    selectedSource,
    selectedVoice,
    setupSpeechRecognition,
    showTemporaryError
  ]);

  // >>> Al dar Cancel en el preview
  const handleCancelPendingMessage = useCallback(() => {
    setPreviewImage(null);
    setPendingMessage(null);
    setTranscription('');
    setShowTranscription(false);

    // Volvemos a escuchar
    if (isListening) {
      try {
        const rec = recognitionRef.current || setupSpeechRecognition();
        rec.start();
      } catch {}
    }
  }, [isListening, setupSpeechRecognition]);

  // Toggle mic
  const toggleListening = useCallback(() => {
    if (isListening) {
      setAutoResumeMic(false);
      if (recognitionRef.current) {
        try {
          recognitionRef.current.stop();
        } catch {}
      }
      setIsListening(false);
    } else {
      setAutoResumeMic(true);
      try {
        const recognition = recognitionRef.current || setupSpeechRecognition();
        recognition.start();
        setIsListening(true);
      } catch (err) {
        showTemporaryError('Error starting voice recognition');
      }
    }
  }, [isListening, setupSpeechRecognition, showTemporaryError]);

  // startCapture
  const startCapture = useCallback(
    async (source) => {
      try {
        setIsLoading(true);
        clearError();
        stopCurrentStream();
        let constraints;
        if (source === 'camera') {
          constraints = {
            video: {
              width: { ideal: 1920 },
              height: { ideal: 1080 },
              facingMode: 'enviromnent'
            },
            audio: false
          };
        } else {
          constraints = {
            video: {
              cursor: 'always',
              displaySurface: 'monitor'
            },
            audio: false
          };
        }
        const newStream =
          source === 'camera'
            ? await navigator.mediaDevices.getUserMedia(constraints)
            : await navigator.mediaDevices.getDisplayMedia(constraints);

        setStream(newStream);
        setSelectedSource(source);

        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
          await videoRef.current.play().catch((e) => {
            showTemporaryError('Error playing video stream');
          });
        }

        if (source === 'camera') {
          setHasMediaAccess((prev) => ({ ...prev, camera: true }));
        }
        if (!isListening && recognitionRef.current) {
          toggleListening();
        }
      } catch (err) {
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          if (source === 'camera') {
            setHasMediaAccess((prev) => ({ ...prev, camera: false }));
          }
          showTemporaryError(`Permission denied for ${source} access`);
        } else if (err.name === 'NotFoundError') {
          showTemporaryError(`No ${source} device found`);
        } else if (err.name === 'NotReadableError') {
          showTemporaryError(`${source} is already in use`);
        } else {
          showTemporaryError(`Error accessing ${source}`);
        }
        setSelectedSource(null);
      } finally {
        setIsLoading(false);
      }
    },
    [
      clearError,
      stopCurrentStream,
      isListening,
      toggleListening,
      showTemporaryError
    ]
  );

  const handleStop = useCallback(() => {
    stopCurrentStream();
    setSelectedSource(null);
    if (isListening) {
      toggleListening();
    }
  }, [stopCurrentStream, isListening, toggleListening]);

  const handleRefresh = useCallback(() => {
    if (selectedSource) {
      startCapture(selectedSource);
    }
  }, [selectedSource, startCapture]);

  // Apagar mic cuando thinking
  useEffect(() => {
    if (isThinking && isListening) {
      if (recognitionRef.current) {
        try {
          recognitionRef.current.stop();
        } catch {}
      }
      setIsListening(false);
    }
  }, [isThinking, isListening]);

  // checkMediaPermissions
  useEffect(() => {
    const checkMediaPermissions = async () => {
      try {
        const micPermission = await navigator.permissions.query({ name: 'microphone' });
        setHasMediaAccess((prev) => ({
          ...prev,
          microphone: micPermission.state === 'granted'
        }));
        micPermission.onchange = () => {
          setHasMediaAccess((prev2) => ({
            ...prev2,
            microphone: micPermission.state === 'granted'
          }));
        };
      } catch (e) {
        // console.warn('Cannot query microphone perms =>', e);
      }
    };
    checkMediaPermissions();
  }, []);

  // Cleanup general
  useEffect(() => {
    return () => {
      stopCurrentStream();
      if (recognitionRef.current) {
        try {
          recognitionRef.current.stop();
        } catch {}
      }
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (silenceTimerRef.current) {
        clearTimeout(silenceTimerRef.current);
      }
      setLastAssistantMessage(null);
    };
  }, [stopCurrentStream]);

  // Mantener referencia de montado
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // >>> Asegurarnos de que si open=false, se pare el mic sí o sí
  useEffect(() => {
    if (!open && recognitionRef.current) {
      try {
        recognitionRef.current.stop();
      } catch {}
      recognitionRef.current = null;
      setIsListening(false);
    }
  }, [open]);

  const selectedVoiceObj = useMemo(() => {
    return AGTVISION_VOICES.find((v) => v.id === selectedVoice) || AGTVISION_VOICES[0];
  }, [selectedVoice]);

  const handleSourceSelect = useCallback(
    (source) => {
      setStep('main');
      startCapture(source);
    },
    [startCapture]
  );

  // >>> Función para "desbloquear" audio en iOS y luego abrir el modal
  const handleOpenAGTVision = () => {
    // 1) Intentar reanudar AudioContext o reproducir un sonido corto
    if (window.AudioContext || window.webkitAudioContext) {
      try {
        const ctx = new (window.AudioContext || window.webkitAudioContext)();
        if (ctx.state === 'suspended') {
          ctx.resume();
        }
        // Generar un pequeño beep silencioso (opcional)
        const osc = ctx.createOscillator();
        const gain = ctx.createGain();
        gain.gain.value = 0; // silencio
        osc.connect(gain);
        gain.connect(ctx.destination);
        osc.start();
        osc.stop(ctx.currentTime + 0.05);
      } catch (err) {
      }
    }

    // 2) Abrir el diálogo
    setOpen(true);
    setStep('selectVoice');
    setSelectedVoice('alloy');
  };

  return (
    <>
      <Tooltip title={isPro ? "AGT Vision" : "AGT Vision (Pro only)"}>
        <span>
          <AGTVisionButton
            onClick={handleOpenAGTVision}
            disabled={!isPro || isWaitingForResponse}
          >
            <Eye size={24} />
          </AGTVisionButton>
        </span>
      </Tooltip>

      <StyledDialog open={open} onClose={handleClose} fullScreen maxWidth={false}>
        <CloseButtonContainer>
          <CloseButton onClick={handleClose} aria-label="Close">
            <X size={24} />
          </CloseButton>
        </CloseButtonContainer>

        {step === 'selectVoice' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <VoiceInterface
              selectedVoice={selectedVoice}
              onVoiceChange={setSelectedVoice}
              disabled={false}
              voices={AGTVISION_VOICES}
              onDone={() => {
                setStep('selectSource');
              }}
              onCancel={handleClose}
            />
          </Box>
        ) : (
          <ContentContainer>
            <VideoSection>
              <LeftContainer>
                <Title variant="h5">AGT Vision</Title>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '16px'
                  }}
                >
                  <VoiceIndicator
                    color={voiceColors[selectedVoiceObj.id]}
                    name={selectedVoiceObj.name}
                    onClick={() => {
                      setStep('selectVoice');
                    }}
                    tooltip="Click para cambiar la voz"
                    isSpeaking={isVoicePlaying}
                    isThinking={isThinking}
                  />
                </Box>

                <MiddleContainer>
                  {isLoading && (
                    <VideoOverlay>
                      <CircularProgress color="inherit" />
                    </VideoOverlay>
                  )}

                  {error && <ErrorAlert message={error} onClose={clearError} />}

                  {voiceError && (
                    <Alert
                      severity="error"
                      onClose={() => setVoiceError(null)}
                      sx={{
                        position: 'absolute',
                        top: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 20
                      }}
                    >
                      {voiceError}
                    </Alert>
                  )}

                  {step === 'selectSource' ? (
                    <SourceSelector onSelect={handleSourceSelect} onCancel={handleClose} />
                  ) : (
                    <StyledVideo
                      ref={videoRef}
                      autoPlay
                      playsInline
                      muted
                      $isScreenShare={selectedSource === 'screen'}
                    />
                  )}

                  {step === 'main' && (
                    <ProcessingIndicator
                      isListening={isListening}
                      isProcessingImage={isProcessingImage}
                      isThinking={isThinking}
                    />
                  )}

                  {showTranscription && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: '96px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        p: 3,
                        borderRadius: '16px',
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        maxWidth: '80%',
                        textAlign: 'center',
                        zIndex: 10
                      }}
                    >
                      <Typography variant="body1">{transcription}</Typography>
                    </Box>
                  )}

                  {step === 'main' && (
                    <>
                      <AGTVoice
                        message={lastAssistantMessage}
                        onVoiceEnd={() => {
                          setIsVoicePlaying(false);
                          if (mountedRef.current) {
                            setLastAssistantMessage((prev) => ({
                              ...prev,
                              id: undefined
                            }));
                            if (autoResumeMic && !isListening && !isThinking) {
                              try {
                                const rec = recognitionRef.current || setupSpeechRecognition();
                                rec.start();
                                setIsListening(true);
                              } catch {}
                            }
                          }
                        }}
                        isEnabled={
                          selectedSource !== null &&
                          lastAssistantMessage?.id !== undefined &&
                          step === 'main'
                        }
                        onError={(err) => {
                          if (mountedRef.current) {
                            setVoiceError(err);
                            setIsVoicePlaying(false);
                            setLastAssistantMessage((prev) => ({
                              ...prev,
                              id: undefined
                            }));
                          }
                        }}
                        onPlayStart={(playing) => {
                          if (playing) {
                            if (recognitionRef.current) {
                              try {
                                recognitionRef.current.stop();
                              } catch {}
                            }
                            setIsListening(false);
                          }
                          if (mountedRef.current) {
                            setIsVoicePlaying(playing);
                          }
                        }}
                      />

                      <ThinkingAudioPlayer
                        isThinking={isThinking && step === 'main'}
                        selectedVoice={selectedVoice}
                        onPlayingChange={(p) => {
                          if (!lastAssistantMessage?.id && mountedRef.current) {
                            setIsVoicePlaying(p);
                          }
                        }}
                        onError={(err) => {
                          if (mountedRef.current) {
                            setVoiceError(err);
                          }
                        }}
                      />

                      {/* 
                        Mantenemos el MessagePreview, pero oculto.
                        De esa forma "sigue existiendo" la lógica de pendingMessage,
                        pero el usuario no lo ve.
                      */}
                      <div style={{ display: 'none' }}>
                        {pendingMessage && (
                          <MessagePreview
                            imageUrl={previewImage}
                            transcription={pendingMessage?.text}
                            onCancel={handleCancelPendingMessage}
                            onSend={handleSendPendingMessage}
                          />
                        )}
                      </div>
                    </>
                  )}
                </MiddleContainer>

                {step === 'main' && (
                  <VideoControls>
                    <Tooltip title="Refresh Stream">
                      <ControlButton onClick={handleRefresh} $active={false}>
                        <RefreshCw size={24} />
                      </ControlButton>
                    </Tooltip>

                    <Tooltip title={isListening ? 'Stop Listening' : 'Start Listening'}>
                      <ControlButton onClick={toggleListening} $active={isListening}>
                        {isListening ? <Mic size={24} /> : <MicOff size={24} />}
                      </ControlButton>
                    </Tooltip>

                    <Tooltip title="Stop Capture">
                      <ControlButton onClick={handleStop} $active={false}>
                        <StopCircle size={24} />
                      </ControlButton>
                    </Tooltip>

                    <Tooltip title="Camera">
                      <ControlButton
                        onClick={() => startCapture('camera')}
                        $active={selectedSource === 'camera'}
                      >
                        <Camera size={24} />
                      </ControlButton>
                    </Tooltip>

                    <Tooltip title="Screen">
                      <ControlButton
                        onClick={() => startCapture('screen')}
                        $active={selectedSource === 'screen'}
                      >
                        <Monitor size={24} />
                      </ControlButton>
                    </Tooltip>
                  </VideoControls>
                )}
              </LeftContainer>
            </VideoSection>
          </ContentContainer>
        )}
      </StyledDialog>
    </>
  );
});

export default AGTVision;
