import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const PRIMARY_COLOR = '#0385FF';

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const UserAudioMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 8px', // Reducido el padding
  width: '100%', // Cambiado a 100% para que se ajuste a la burbuja
  minWidth: '160px', // Reducido el minWidth
  maxWidth: '100%', // Asegura que no se desborde
  position: 'relative', // Añadido para contener todo dentro
}));

const WaveformContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '32px',
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '4px',
  minWidth: 0, // Importante para que flex funcione correctamente
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    background: 'linear-gradient(90deg, rgba(255,255,255,0.1) -50%, transparent 50%)',
    opacity: 0,
    transition: 'opacity 0.2s ease',
  },
  '&:hover::before': {
    opacity: 1,
  }
}));

const TimeDisplay = styled(Typography)({
  color: 'rgba(255, 255, 255, 0.7)',
  fontSize: '0.75rem',
  minWidth: '34px', // Reducido para ahorrar espacio
  textAlign: 'right',
  marginLeft: 'auto', // Asegura que se mantenga al final
  whiteSpace: 'nowrap', // Previene que el tiempo se rompa en múltiples líneas
});

const PlayButton = styled(IconButton)(({ theme }) => ({
  padding: '4px', // Reducido el padding
  backgroundColor: '#fff',
  borderRadius: '50%',
  color: PRIMARY_COLOR,
  minWidth: '24px', // Añadido para mantener consistencia
  height: '24px', // Añadido para mantener consistencia
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px', // Reducido el tamaño del icono
  }
}));

const WaveformCanvas = ({ audioUrl, isPlaying, onClickPosition }) => {
  const canvasRef = useRef(null);
  const [waveformData, setWaveformData] = useState(null);
  const [playProgress, setPlayProgress] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [hoverPosition, setHoverPosition] = useState(0);
  const requestRef = useRef();
  const audioContextRef = useRef();

  useEffect(() => {
    const loadAudio = async () => {
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
        const rawData = audioBuffer.getChannelData(0);

        const samples = 20; // Número fijo de barras
        const blockSize = Math.floor(rawData.length / samples);
        const filteredData = [];

        // Calcular el valor máximo para referencia
        let maxAmplitude = 0;
        for (let i = 0; i < rawData.length; i++) {
          const absValue = Math.abs(rawData[i]);
          if (absValue > maxAmplitude) {
            maxAmplitude = absValue;
          }
        }

        for (let i = 0; i < samples; i++) {
          const blockStart = blockSize * i;
          let blockSum = 0;
          for (let j = 0; j < blockSize; j++) {
            blockSum += Math.abs(rawData[blockStart + j]);
          }
          const average = blockSum / blockSize;

          // Convertir a dB
          let dB = 20 * Math.log10(average / maxAmplitude);
          if (isNaN(dB)) dB = -80; // Manejar log10(0)

          // Clampear entre -80 dB y -20 dB
          dB = Math.max(-80, Math.min(-20, dB));

          // Normalizar al rango [0, 1]
          const normalizedValue = (dB + 80) / 60;

          filteredData.push(normalizedValue);
        }

        setWaveformData(filteredData);
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    };

    loadAudio();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [audioUrl]);

  const handleClick = (event) => {
    if (!canvasRef.current || !waveformData) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    
    const totalWidth = rect.width;
    const barWidth = Math.max(2, (totalWidth / 20) * 0.6);
    const barGap = (totalWidth - (barWidth * 20)) / 19;
    const totalBarSpace = barWidth + barGap;
    
    const barIndex = Math.floor(x / totalBarSpace);
    const position = barIndex / waveformData.length;
    
    const normalizedPosition = Math.max(0, Math.min(1, position));
    onClickPosition(normalizedPosition);
  };

  const handleMouseMove = (event) => {
    if (!canvasRef.current || !waveformData) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    
    const totalWidth = rect.width;
    const barWidth = Math.max(2, (totalWidth / 20) * 0.6);
    const barGap = (totalWidth - (barWidth * 20)) / 19;
    const totalBarSpace = barWidth + barGap;
    
    const barIndex = Math.floor(x / totalBarSpace);
    const position = barIndex / waveformData.length;

    setHoverPosition(Math.max(0, Math.min(1, position)));
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !waveformData) return;

    const ctx = canvas.getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();

    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    ctx.scale(dpr, dpr);

    const drawWaveform = () => {
      ctx.clearRect(0, 0, rect.width, rect.height);

      // Dibujamos la línea de hover alineada con las barras
      if (isHovering) {
        const totalWidth = rect.width;
        const barWidth = Math.max(2, (totalWidth / 20) * 0.6);
        const barGap = (totalWidth - (barWidth * 20)) / 19;
        const hoverX = Math.floor(hoverPosition * waveformData.length) * (barWidth + barGap);
        ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
        ctx.fillRect(hoverX, 0, barWidth, rect.height);
      }

      const totalWidth = rect.width;
      const barWidth = Math.max(2, (totalWidth / 20) * 0.6); // 60% del espacio disponible por barra
      const barGap = (totalWidth - (barWidth * 20)) / 19; // Distribuye el espacio restante entre las barras
      const centerY = rect.height / 2;
      const maxHeight = rect.height * 0.8;

      waveformData.forEach((value, index) => {
        const x = index * (barWidth + barGap);
        const height = value * maxHeight;

        const progress = playProgress * waveformData.length;
        const alpha = index < progress ? 1 : 0.5;

        ctx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
        ctx.fillRect(x, centerY - height / 2, barWidth, height);
      });
    };

    drawWaveform();

    if (isPlaying) {
      const animate = () => {
        drawWaveform();
        requestRef.current = requestAnimationFrame(animate);
      };
      requestRef.current = requestAnimationFrame(animate);
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [waveformData, isPlaying, playProgress, isHovering, hoverPosition]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      }}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    />
  );
};

const UserAudioMessage = ({ audioUrl, duration }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playProgress, setPlayProgress] = useState(0);
  const audioRef = useRef(new Audio(audioUrl));

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      if (Number.isFinite(audio.currentTime) && Number.isFinite(audio.duration) && audio.duration > 0) {
        setPlayProgress(audio.currentTime / audio.duration);
      }
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setPlayProgress(0);
    };

    const handleLoadedMetadata = () => {
      if (Number.isFinite(audio.duration)) {
        setPlayProgress(0);
      }
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);

    audio.load();

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
      audio.pause();
    };
  }, []);

  const togglePlay = async () => {
    try {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        await audioRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Error toggling audio playback:', error);
      setIsPlaying(false);
    }
  };

  const handleWaveformClick = (position) => {
    if (!audioRef.current.duration || !Number.isFinite(position)) return;

    const newTime = position * audioRef.current.duration;

    if (Number.isFinite(newTime)) {
      audioRef.current.currentTime = newTime;
      setPlayProgress(position);

      if (!isPlaying) {
        audioRef.current.play()
          .then(() => setIsPlaying(true))
          .catch(error => console.error('Error playing audio:', error));
      }
    }
  };

  return (
    <UserAudioMessageContainer>
      <PlayButton onClick={togglePlay} aria-label={isPlaying ? "Pausar audio" : "Reproducir audio"}>
        {isPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
      </PlayButton>
      <WaveformContainer>
        <WaveformCanvas 
          audioUrl={audioUrl}
          isPlaying={isPlaying}
          onClickPosition={handleWaveformClick}
        />
      </WaveformContainer>
      <TimeDisplay>
        {formatTime(duration)}
      </TimeDisplay>
    </UserAudioMessageContainer>
  );
};

export default UserAudioMessage;
