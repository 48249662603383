import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getThemeStyles } from '../../themes/themeConfig';
import { PencilLine } from 'lucide-react';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 12px',
  margin: '10px 0',
  borderRadius: '20px',
  transition: 'all 0.2s ease',
  '& .lucide': {
    marginLeft: '8px',
  },
  '&:hover': {
    transform: 'translateY(-1px)',
  },
  ...getThemeStyles('StyledButton', theme.palette.mode),
}));

const AGTMakerButton = ({ onOpenAGTMaker, isNew = false }) => {
  const [isLoading, setIsLoading] = useState(isNew);
  
  useEffect(() => {
    if (isNew) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [isNew]);

  return (
    <StyledButton
      onClick={!isLoading ? onOpenAGTMaker : undefined}
      disabled={isLoading}
      startIcon={isLoading ? (
        <CircularProgress size={24} style={{ marginRight: '4px' }} />
      ) : (
        <PencilLine size={24} style={{ marginRight: '4px' }} />
      )}
    >
      {isLoading ? 'Loading AGT Maker...' : 'Open AGT Maker'}
    </StyledButton>
  );
};

export default AGTMakerButton;