import React, { useRef, useState, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IconButton, Box, Menu, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus, ImageIcon, Camera, FolderOpen, Scan } from 'lucide-react';
import ScreenshotPreviewModal from './ScreenshotPreviewModal';

// 4 MB (o el límite que desees)
const MAX_FILE_SIZE_BYTES = 4 * 1024 * 1024;

/** Styled IconButton para el botón principal "+" */
const BlueIconButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  width: '32px',
  height: '32px',
  minWidth: '32px',
  minHeight: '32px',
  backgroundColor: 'transparent',
  color: '#0385FF',
  transition: 'all 0.3s ease',
  margin: '0',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#026fcc',
  },
  '&:disabled': {
    backgroundColor: 'transparent',
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.26)'
        : 'rgba(255, 255, 255, 0.26)',
  },
}));

/** Styled MUI Menu para las opciones de adjuntar */
const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '14px',
    marginBottom: '8px',
    minWidth: '200px',
    padding: 0,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[3],
  },
  '& .MuiMenuItem-root': {
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      flexGrow: 1,
    },
    '& svg': {
      color: theme.palette.text.secondary,
      width: '20px',
      height: '20px',
    },
    '&.Mui-disabled': {
      '& .MuiTypography-root': {
        color: theme.palette.text.disabled,
      },
      '& svg': {
        color: theme.palette.text.disabled,
      },
    },
  },
}));

/** Input oculto para seleccionar archivos */
const HiddenInput = styled('input')({
  display: 'none',
});

// Helper para iOS
const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

/**
 * Convierte un File en un objeto base64 con { name, type, size, data }.
 */
async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result.split(',')[1];
      resolve({
        name: file.name,
        type: file.type,
        size: file.size,
        data: base64Data,
      });
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Crea un canvas desde una URL de imagen (para capturas).
 */
const createCanvasFromImage = async (imageUrl) => {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      resolve(canvas);
    };
    img.src = imageUrl;
  });
};

/**
 * FileAttachment: Componente para subir archivos (imágenes, PDF, DOC, XLS, etc.),
 * convirtiéndolos a base64 y llamando onFileSelect().
 */
const FileAttachment = forwardRef(({ onFileSelect, onFileError, disabled }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [screenshotData, setScreenshotData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageQuality, setImageQuality] = useState(0.92);
  const [currentImage, setCurrentImage] = useState(null);

  // Referencias para las inputs de fotos/archivos
  const photoInputRef = useRef(null);
  const fileInputRef = useRef(null);

  // Detecta si es mobile
  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      setIsMobile(isMobileDevice);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  /** Cierra el menú de adjuntos. */
  const handleClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    click: () => {
      // On iOS, directly open photos/camera
      if (isIOS()) {
        photoInputRef.current?.click();
      } else {
        // Otherwise show the menu with all options
        const button = document.querySelector('[aria-controls="attachment-menu"]');
        if (button) {
          button.click();
        }
      }
    }
  }));

  /** Abre el menú al hacer clic en el botón "+". */
  const handleClick = (event) => {
    if (isIOS()) {
      // iOS: abrimos directamente la cámara/fotos
      photoInputRef.current?.click();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  /**
   * Maneja la selección de archivos en <input type="file">,
   * convirtiéndolos a base64 y saltando los que superen 4 MB.
   */
  const handleFileInput = useCallback(
    async (event) => {
      const files = Array.from(event.target.files);
      handleClose();

      const base64Files = [];
      for (const file of files) {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          console.warn('[FileAttachment] Exceeds limit:', file.name);
          onFileError?.(`File "${file.name}" exceeds the 4 MB limit.`);
          continue;
        }
        const base64Obj = await fileToBase64(file);
        base64Files.push(base64Obj);
      }

      if (base64Files.length > 0) {
        onFileSelect(base64Files);
      }
    },
    [onFileSelect, onFileError]
  );

  /**
   * Toma una captura de pantalla (desktop) usando getDisplayMedia,
   * luego abre la previsualización para recortar/confirmar.
   */
  const handleScreenshot = useCallback(async () => {
    try {
      setIsLoading(true);
      handleClose();

      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: 'browser',
          selfBrowserSurface: 'include',
          surfaceSwitching: 'include',
          systemAudio: 'exclude',
        },
      });

      const video = document.createElement('video');
      video.muted = true;
      video.style.position = 'fixed';
      video.style.top = '-9999px';
      video.style.left = '-9999px';
      video.srcObject = stream;

      document.body.appendChild(video);

      await new Promise((resolve) => {
        video.onloadedmetadata = () => {
          video.play();
          resolve();
        };
      });

      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0);

      // Detener stream
      stream.getTracks().forEach((track) => track.stop());
      document.body.removeChild(video);
      video.srcObject = null;

      setScreenshotData({
        canvas,
        originalDataUrl: canvas.toDataURL('image/png'),
        width: canvas.width,
        height: canvas.height,
      });

      setShowPreview(true);
      setCurrentImage(canvas.toDataURL('image/png'));
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        return;
      }
      console.error('Error taking screenshot:', err);
      alert('Error capturing the screen. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  /** Guarda la captura (la convierte a base64 y llama onFileSelect). */
  const handleSaveScreenshot = useCallback(async () => {
    if (!currentImage) return;
    try {
      setIsLoading(true);

      const canvas = await createCanvasFromImage(currentImage);
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png', imageQuality);
      });

      const file = new File([blob], `screenshot-${new Date().toISOString()}.png`, {
        type: 'image/png',
      });

      const base64Obj = await fileToBase64(file);
      onFileSelect([base64Obj]);

      setShowPreview(false);
      setScreenshotData(null);
      setCurrentImage(null);
    } catch (error) {
      console.error('Error saving screenshot:', error);
      alert('Error saving screenshot. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentImage, imageQuality, onFileSelect]);

  /** Copia la captura al portapapeles como image/png */
  const handleCopyToClipboard = useCallback(async () => {
    if (!currentImage) return;
    try {
      setIsLoading(true);

      const canvas = await createCanvasFromImage(currentImage);
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png', imageQuality);
      });

      await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);

      const successMessage = document.createElement('div');
      successMessage.textContent = 'Copied to clipboard!';
      successMessage.style.cssText = `
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #4CAF50;
        color: white;
        padding: 10px 20px;
        border-radius: 4px;
        z-index: 9999;
      `;
      document.body.appendChild(successMessage);
      setTimeout(() => {
        document.body.removeChild(successMessage);
      }, 2000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      alert('Error copying screenshot. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentImage, imageQuality]);

  /** Descarga la captura localmente. */
  const handleDownload = useCallback(async () => {
    if (!currentImage) return;
    try {
      setIsLoading(true);

      const canvas = await createCanvasFromImage(currentImage);
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png', imageQuality);
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `screenshot-${new Date().toISOString()}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading screenshot:', error);
      alert('Error downloading screenshot. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [currentImage, imageQuality]);

  // Opciones del menú (para no-iOS)
  const menuOptions = !isIOS()
    ? [
        {
          label: 'Attach Photos',
          icon: <ImageIcon size={20} />,
          action: () => photoInputRef.current?.click(),
        },
        isMobile
          ? {
              label: 'Take Photo',
              icon: <Camera size={20} />,
              action: () => photoInputRef.current?.click(),
            }
          : {
              label: 'Take Screenshot',
              icon: <Scan size={20} />,
              action: handleScreenshot,
            },
        {
          label: 'Attach Files',
          icon: <FolderOpen size={20} />,
          action: () => fileInputRef.current?.click(),
          disabled: false,
        },
      ]
    : [];

  return (
    <Box>
      <BlueIconButton
        onClick={handleClick}
        disabled={disabled}
        aria-controls="attachment-menu"
        aria-haspopup="true"
      >
        <Plus size={24} />
      </BlueIconButton>

      {!isIOS() && (
        <StyledMenu
          id="attachment-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {menuOptions.map((option) => (
            <MenuItem
              key={option.label}
              onClick={option.action}
              disabled={option.disabled}
            >
              <Typography>{option.label}</Typography>
              {option.icon}
            </MenuItem>
          ))}
        </StyledMenu>
      )}

      {/* Input para fotos (imágenes) */}
      <HiddenInput
        type="file"
        accept="image/*"
        multiple
        ref={photoInputRef}
        onChange={handleFileInput}
        capture={isIOS() ? undefined : 'environment'}
      />

      {/* Input para PDFs, DOC, XLS, etc. */}
      <HiddenInput
        type="file"
        // Acepta PDF, DOC, DOCX, XLS, XLSX, TXT, CSV, etc.
        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,image/*"
        multiple
        ref={fileInputRef}
        onChange={handleFileInput}
      />

      <ScreenshotPreviewModal
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
          setCurrentImage(null);
        }}
        screenshotData={screenshotData}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
        onCopyToClipboard={handleCopyToClipboard}
        onDownload={handleDownload}
        onSave={handleSaveScreenshot}
        isLoading={isLoading}
      />
    </Box>
  );
});

export default React.memo(FileAttachment);