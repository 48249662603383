import { styled, keyframes } from '@mui/material/styles';
import {
  Box,
  TextField,
  Typography,
  Card as MuiCard,
  Autocomplete,
  IconButton,
  Link,
  CircularProgress
} from '@mui/material';

const fontFamily = '"SF Pro Display", -apple-system, BlinkMacSystemFont, system-ui, sans-serif';

export const glowAnimation = keyframes`
  0% { opacity: 0; filter: blur(10px); }
  50% { opacity: 1; filter: blur(0px); }
  100% { opacity: 0; filter: blur(10px); }
`;

export const CenteredContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100vh - 69px)',  // <- Ajuste aquí
  padding: theme.spacing(4),
  boxSizing: 'border-box',
  fontFamily,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  textRendering: 'optimizeLegibility',
  backgroundColor: 'var(--bg-primary-elevated)',
  color: 'var(--label-primary)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    paddingBottom: 'calc(env(safe-area-inset-bottom) + 16px)',
    paddingTop: 'calc(env(safe-area-inset-top) + 16px)',
    minHeight: 'calc(100vh - 56px)'
  }
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily,
  [theme.breakpoints.down('sm')]: {
    padding: '0 8px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    gap: theme.spacing(2)
  }
}));

export const TypedText = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  lineHeight: 1.08349,
  letterSpacing: '-0.003em',
  fontWeight: 600,
  fontFamily,
  textAlign: 'center',
  maxWidth: '800px',
  margin: '0 auto',
  marginBottom: '28px',
  padding: '0 20px',
  color: 'var(--label-primary)',
  '& span': {
    fontSize: 'inherit !important',
    lineHeight: 'inherit !important',
    letterSpacing: 'inherit !important',
    fontWeight: 'inherit !important',
    fontFamily: 'inherit !important',
  },
  '& .typed-cursor': {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '32px',
    lineHeight: 1.2,
    padding: '0 16px',
    marginBottom: '16px'
  }
}));

export const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  position: 'relative',
  fontFamily,
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px'
  }
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  margin: '10px 0',
  maxWidth: '800px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'var(--ui-base)',
    borderRadius: '30px',
    minHeight: '57px',
    height: 'auto',
    backdropFilter: 'blur(10px)',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid var(--ui-base)',
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    color: 'var(--label-primary)',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: 'var(--ui-base)',
      border: '1px solid var(--ui-base)',
      '[data-theme="light"] &': {
        backgroundColor: '#F2F2F7',
        border: '1px solid #F2F2F7',
      }
    },
    '&.Mui-focused': {
      border: '1px solid var(--ui-accent)',      
      backgroundColor: 'var(--grouped-bg-primary-base)',
      '[data-theme="light"] &': {
        border: '1px solid #0066CC',
        backgroundColor: '#EAEAEB',
      }
    }
  },
  '& .MuiInputBase-input': {
    minHeight: '25px',
    height: 'auto',
    padding: '0 24px 0 32px',                     
    fontFamily,
    fontSize: '17px',
    lineHeight: '25px',
    letterSpacing: '-0.022em',
    paddingRight: '70px !important',
    color: 'var(--label-primary)',
    '[data-theme="light"] &': {
      color: '#1D1D1F',
    },
    '&::placeholder': {
      color: 'var(--system-grey)',
      '[data-theme="light"] &': {
        color: '#86868B',
      }
    },
  },
  [theme.breakpoints.down('sm')]: {
    margin: '8px 0',
    '& .MuiOutlinedInput-root': {
      minHeight: '48px',
      borderRadius: '20px',
    },
    '& .MuiInputBase-input': {
      fontSize: '16px',
      padding: '0 50px 0 16px',
      minHeight: '20px',
    }
  }
}));
  
export const SendButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '14px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
  padding: '8px',
  color: 'var(--ui-accent)',
  '&.Mui-disabled': {
    color: 'var(--system-grey)',
  },
  [theme.breakpoints.down('sm')]: {
    right: '8px',
    padding: '6px'
  }
}));

export const ClearButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '70px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
  padding: '8px',
  color: 'var(--fill-tertiary)',
  [theme.breakpoints.down('sm')]: {
    right: '50px',
    padding: '6px'
  }
}));

export const SectionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(4),
  fontFamily,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: '0 4px'
  }
}));

export const Section = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
  fontFamily,
  [theme.breakpoints.down('sm')]: {
    gap: '12px'
  }
}));

export const CardsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  fontFamily,
  [theme.breakpoints.down('sm')]: {
    gap: '6px'
  }
}));

export const CustomCard = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  borderRadius: '30px',
  transition: 'all 0.2s ease-in-out',
  boxShadow: 'none',
  fontFamily,
  backgroundColor: 'var(--ui-base)',
  border: '1px solid var(--ui-base)',
  '&:hover': {
    backgroundColor: 'var(--grouped-bg-primary-base)',
    borderColor: 'var(--grouped-bg-primary-base)',
    '[data-theme="light"] &': {
      backgroundColor: '#C7C7CC',
      borderColor: '#C7C7CC',
    }
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: '20px',
    border: 'none'
  }
}));

export const CustomCardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  '& .MuiTypography-body2': {
    fontFamily,
    fontSize: '17px',
    lineHeight: 1.47059,
    letterSpacing: '-0.022em',
    color: 'var(--label-primary)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
    gap: '8px',
    '& .MuiTypography-body2': {
      fontSize: '15px',
    }
  }
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  backgroundColor: 'var(--bg-primary-elevated)',
  '[data-theme="light"] &': {
    backgroundColor: '#FFFFFF',
  },
  zIndex: 1000,
  fontFamily,
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    paddingBottom: 'calc(env(safe-area-inset-bottom) + 12px)'
  }
}));

export const LinksContainer = styled(Box)({
  display: 'flex',
  gap: '32px',
  justifyContent: 'center',
  flex: 1,
});

export const FooterLink = styled(Link)({
  color: '#86868B',
  textDecoration: 'none',
  fontSize: '14px',
  '[data-theme="dark"] &': {
    color: '#98989D',
  },
  '&:hover': {
    textDecoration: 'underline',
    color: 'var(--ui-accent)',
    '[data-theme="light"] &': {
      color: '#0066CC',
    }
  }
});

export const HelpButton = styled(IconButton)({
  backgroundColor: 'transparent',
  color: 'var(--ui-accent)',
  '[data-theme="light"] &': {
    color: '#0066CC',
  },
  padding: '8px',
  '&:hover': {
    backgroundColor: 'var(--grouped-bg-primary-base)',
    '[data-theme="light"] &': {
      backgroundColor: '#F2F2F7',
    },
    color: 'var(--ui-accent)',
    '[data-theme="light"] &': {
      color: '#0066CC',
    },
  },
  '& svg': {
    stroke: 'var(--ui-accent)',
    '[data-theme="light"] &': {
      stroke: '#0066CC',
    },
    strokeWidth: 1.5,
  }
});

export const WaitingMessage = styled(Typography)({
  marginTop: '16px',
  fontStyle: 'italic',
  color: 'var(--fill-tertiary)',
  textAlign: 'center',
  width: '100%',
  animation: `${glowAnimation} 3s ease-in-out infinite`,
});

export const LoadingIndicator = styled(CircularProgress)({
  color: 'var(--ui-accent)',
});

export const InputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '& .MuiInputBase-root': {
    '& > textarea': {
      paddingRight: '120px !important',
      boxSizing: 'border-box',
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiInputBase-root': {
      '& > textarea': {
        paddingRight: '80px !important',
      }
    }
  }
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '0 70px 0 32px !important',
  },
  '& .MuiAutocomplete-paper': {
    backgroundColor: 'var(--bg-primary-elevated)',
    border: '1px solid var(--ui-base)',
    '& .MuiAutocomplete-option': {
      color: 'var(--label-primary)',
      '&[aria-selected="true"]': {
        backgroundColor: 'var(--grouped-bg-primary-base)',
      },
      '&:hover': {
        backgroundColor: 'var(--grouped-bg-primary-base)',
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiInputBase-input': {
      padding: '0 50px 0 16px !important',
    },
    '& .MuiAutocomplete-paper': {
      marginTop: '4px',
      borderRadius: '16px'
    }
  }
}));