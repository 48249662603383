import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowLeft, Plus } from 'lucide-react';
import { updateProjectContext } from '../../../services/AGTService';
import { useProjectContext } from '../ProjectContextProvider';

const StyledButton = styled(Button)(() => ({
  backgroundColor: '#0385FF',
  color: '#FFFFFF',
  borderRadius: '20px',
  textTransform: 'none',
  padding: '6px 16px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#0371db',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: 6,
  },
}));

// Definimos los límites de caracteres
const MAX_TITLE_CHARS = 50;
const MAX_CONTEXT_CHARS = 1000;

const ContextView = ({ project, onBack, user }) => {
  const theme = useTheme();
  const { projectContexts, updateProjectContext: updateContextInProvider } = useProjectContext();

  // Obtener el contexto del provider
  const currentContext = projectContexts[project?.id] || {};
  const realContextTitle = currentContext.contextTitle || '';
  const realContextContent = currentContext.contextContent || '';

  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [title, setTitle] = useState('');
  const [context, setContext] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);

  // Cargar contexto inicial
  useEffect(() => {
    if (project?.id && project?.contextData) {
      updateContextInProvider(project.id, {
        contextTitle: project.contextData.contextTitle || '',
        contextContent: project.contextData.contextContent || ''
      });
    }
  }, [project?.id, project?.contextData, updateContextInProvider]);

  const handleAddContext = () => {
    setOpenModal(true);
    setTitle('');
    setContext('');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTitle('');
    setContext('');
  };

  const handleSave = async () => {
    if (!project?.id || !user?.uid) return;

    setIsLoading(true);
    const finalTitle = title.trim() || 'Project Context';

    try {
      await updateProjectContext({
        userId: user.uid,
        projectId: project.id,
        contextTitle: finalTitle,
        contextContent: context
      });

      // Actualizar el contexto en el provider
      updateContextInProvider(project.id, {
        contextTitle: finalTitle,
        contextContent: context
      });

      setSnackbarMessage('Project context saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleCloseModal();

    } catch (error) {
      console.error('Error saving context:', error);
      setSnackbarMessage('Error saving project context');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenViewModal = () => {
    if (realContextTitle || realContextContent) {
      setOpenViewModal(true);
    }
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '800px',
            lg: '900px'
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 2,
            borderBottom: 1,
            borderColor: 'divider',
            mb: 3
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={onBack}
              size="small"
              sx={{
                mr: 1,
                color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700'
              }}
            >
              <ArrowLeft />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.1rem',
                fontWeight: 500
              }}
            >
              Context
            </Typography>
          </Box>

          <StyledButton
            startIcon={<Plus size={18} />}
            onClick={handleAddContext}
            disabled={isLoading}
          >
            New Context
          </StyledButton>
        </Box>

        <List sx={{ px: 0 }}>
          {!realContextTitle && !realContextContent ? (
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2 }}>
              No context found.
            </Typography>
          ) : (
            <ListItem
              sx={{
                mb: 1.5,
                p: 1.5,
                borderRadius: '12px',
                cursor: 'pointer',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.03)'
                    : 'rgba(0, 0, 0, 0.02)',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.08)'
                      : 'rgba(0, 0, 0, 0.05)',
                },
                transition: 'background-color 0.2s ease',
              }}
              onClick={handleOpenViewModal}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '0.95rem', fontWeight: 500, mb: 0.5 }}
                  >
                    {realContextTitle || 'Untitled Context'}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '0.8rem',
                      color:
                        theme.palette.mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.5)'
                          : 'rgba(0, 0, 0, 0.5)'
                    }}
                  >
                    {realContextContent.length > 60
                      ? `${realContextContent.slice(0, 60)}...`
                      : realContextContent}
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </Box>

      {/* Modal para nuevo contexto */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            bgcolor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle
          sx={{
            pt: 3,
            pb: 2,
            px: 3,
            fontSize: '1.1rem',
            fontWeight: 500,
            borderBottom: 1,
            borderColor: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)'
          }}
        >
          New Context
        </DialogTitle>

        <DialogContent sx={{ mt: 2, p: 3 }}>
          {/* Título con límite de 50 caracteres */}
          <TextField
            autoFocus
            fullWidth
            placeholder="Title (Optional)"
            value={title}
            onChange={(e) => {
              const newTitle = e.target.value.slice(0, MAX_TITLE_CHARS);
              setTitle(newTitle);
            }}
            variant="outlined"
            sx={{
              mb: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
                border: 'none',
                '& fieldset': { border: 'none' },
              }
            }}
          />
          {/* Contador para el título */}
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mb: 2,
              textAlign: 'right',
              color: title.length >= MAX_TITLE_CHARS ? 'red' : 'inherit',
            }}
          >
            {MAX_TITLE_CHARS - title.length}
          </Typography>

          {/* Context con límite de 1000 caracteres */}
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="e.g. 'Project deadline is next month. Creating a customer feedback dashboard.' or 'Redesigning the mobile app with focus on accessibility.'"
            value={context}
            onChange={(e) => {
              const newContext = e.target.value.slice(0, MAX_CONTEXT_CHARS);
              setContext(newContext);
            }}
            variant="outlined"
            sx={{
              mb: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
                border: 'none',
                '& fieldset': { border: 'none' },
                '& textarea': {
                  scrollbarWidth: 'thin',
                  '&::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.2)'
                      : 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '3px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.3)'
                      : 'rgba(0, 0, 0, 0.3)',
                  },
                },
              }
            }}
          />
          {/* Contador para el contexto */}
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mb: 2,
              textAlign: 'right',
              color: context.length >= MAX_CONTEXT_CHARS ? 'red' : 'inherit',
            }}
          >
            {MAX_CONTEXT_CHARS - context.length}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mt: 2,
              color: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.5)'
                : 'rgba(0, 0, 0, 0.5)',
              fontSize: '0.7rem',
              textAlign: 'center'
            }}
          >
            Note: New context will replace any previous context for this project
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3, display: 'flex', gap: 1 }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              px: 3,
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.05)'
              }
            }}
          >
            Cancel
          </Button>
          <StyledButton 
            onClick={handleSave}
            disabled={isLoading || !context.trim()}
          >
            Save
          </StyledButton>
        </DialogActions>
      </Dialog>

      {/* Modal para ver contexto */}
      <Dialog
        open={openViewModal}
        onClose={handleCloseViewModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            bgcolor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
            backgroundImage: 'none'
          }
        }}
      >
        <DialogTitle
          sx={{
            pt: 3,
            pb: 2,
            px: 3,
            fontSize: '1.1rem',
            fontWeight: 500,
            borderBottom: 1,
            borderColor: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, 0.1)'
          }}
        >
          {realContextTitle || 'Project Context'}
        </DialogTitle>

        <DialogContent sx={{ mt: 2, p: 3 }}>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {realContextContent}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseViewModal}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              px: 3,
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.05)'
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContextView;