import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Card, 
  Typography, 
  useTheme,
  Grow,
  Paper,
  Tooltip
} from '@mui/material';
import { Monitor, Camera, Search, Mic, Upload } from 'lucide-react';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius * 20,
  marginBottom: theme.spacing(1),
  background: theme.palette.background.paper,
  transition: theme.transitions.create(['transform', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  '&.disabled': {
    opacity: 0.6,
    cursor: 'not-allowed',
    '&:hover': {
      transform: 'none',
    },
  },
}));

const CardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

const IconWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: '50%',
  boxShadow: 'none',
}));

const promptCards = [
  {
    Icon: typeof window !== 'undefined' && window.innerWidth > 768 ? Monitor : Camera,
    text: typeof window !== 'undefined' && window.innerWidth > 768 
      ? "Share Screen" 
      : "Share Camera",
    color: "#0385FF",
    background: `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), #0385FF`,
    action: "vision",
    isProOnly: true,
  },
  {
    Icon: Search,
    text: "Search on Web",
    color: "#FFC107",
    background: `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), #FFC107`,
    action: "search",
    isProOnly: false,
  },
  {
    Icon: Mic,
    text: "Voice Recording",
    color: "#FF4D4D",
    background: `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), #FF4D4D`,
    action: "voice",
    isProOnly: true,
  },
  {
    Icon: Upload,
    text: "Upload Files",
    color: "#4CAF50",
    background: `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), #4CAF50`,
    action: "files",
    isProOnly: false,
  }
];

const PromptCard = React.memo(({ 
  Icon, 
  text, 
  color, 
  background, 
  isProOnly, 
  isPro,
  onClick 
}) => {
  const theme = useTheme();
  const isDisabled = isProOnly && !isPro;

  const handleClick = (e) => {
    if (!isDisabled) {
      onClick(e);
    }
  };

  return (
    <Tooltip
      title={isDisabled ? 'Pro feature only' : ''}
      placement="bottom"
      arrow
    >
      <StyledCard 
        onClick={handleClick} 
        elevation={0}
        className={isDisabled ? 'disabled' : ''}
      >
        <CardContent>
          <IconWrapper sx={{ background }}>
            <Icon size={20} color="white" />
          </IconWrapper>
          <Typography 
            variant="subtitle2"
            fontWeight="500"
            sx={{ py: 0.5, flexGrow: 0, minWidth: 'auto' }}
          >
            {text}
          </Typography>
        </CardContent>
      </StyledCard>
    </Tooltip>
  );
});

const PromptCards = ({ isPro, onSelectPrompt }) => {
  const handleCardClick = (action) => {
    onSelectPrompt(action);
  };

  const cards = useMemo(() => 
    promptCards.map((card, index) => (
      <Grow 
        key={card.text}
        in={true} 
        timeout={(index + 1) * 200}
      >
        <Box>
          <PromptCard
            Icon={card.Icon}
            text={card.text}
            color={card.color}
            background={card.background}
            action={card.action}
            isProOnly={card.isProOnly}
            isPro={isPro}
            onClick={() => handleCardClick(card.action)}
          />
        </Box>
      </Grow>
    )), [isPro, onSelectPrompt]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 1,
      width: '100%',
      maxWidth: '250px',
      mx: 'auto',
      p: 1.5,
    }}>
      {cards}
    </Box>
  );
};

PromptCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  isProOnly: PropTypes.bool.isRequired,
  isPro: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

PromptCards.propTypes = {
  onSelectPrompt: PropTypes.func.isRequired,
  isPro: PropTypes.bool,
};

PromptCards.defaultProps = {
  isPro: false,
};

export default React.memo(PromptCards);