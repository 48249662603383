import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Box,
  IconButton,
  Typography,
  Button,
  styled,
  alpha
} from '@mui/material';
import { Monitor, Sun, Moon, X } from 'lucide-react';

const AppleDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 20,
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.05)'
      : 'rgba(255, 255, 255, 0.98)',
    backdropFilter: 'blur(20px)',
    maxWidth: 600,
    width: '100%',
    margin: 16,
    boxShadow: theme.palette.mode === 'dark'
      ? '0px 1px 3px rgba(0, 0, 0, 0.5)'
      : '0px 8px 32px rgba(0, 0, 0, 0.08)'
  }
}));

const AppleDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderBottom: `1px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.06)'
  }`,
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const BackgroundButton = styled(IconButton)(({ theme, selected }) => ({
  width: 80,
  height: 80,
  borderRadius: 16,
  backgroundColor: selected 
    ? theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.15)'
      : '#0071e3'
    : theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.05)'
      : alpha(theme.palette.action.hover, 0.1),
  color: theme.palette.mode === 'dark'
    ? selected ? '#fff' : 'rgba(255, 255, 255, 0.8)'
    : selected ? '#fff' : theme.palette.text.secondary,
  transition: theme.transitions.create(['transform', 'background-color', 'box-shadow'], {
    duration: 200
  }),
  '&:hover': {
    backgroundColor: selected
      ? theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : '#0077ed'
      : theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : alpha(theme.palette.action.hover, 0.2),
    transform: 'scale(1.05)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0px 1px 3px rgba(0, 0, 0, 0.5)'
      : theme.shadows[4]
  }
}));

const AppleButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  padding: theme.spacing(1, 3),
  textTransform: 'none',
  fontWeight: 500,
  backgroundColor: theme.palette.mode === 'dark' && 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.mode === 'dark' && '#fff',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' && 'rgba(255, 255, 255, 0.15)'
  }
}));

const backgroundGroups = [
  {
    title: 'Typical',
    backgrounds: [
      { name: 'System', type: 'system', icon: Monitor },
      { name: 'Light', type: 'light', icon: Sun },
      { name: 'Dark', type: 'dark', icon: Moon },
    ],
  }
];

const BackgroundSelector = ({ open, onClose, onBackgroundChange, currentBackground }) => {
  const handleBackgroundChange = (bgType) => {
    onBackgroundChange(bgType);
    onClose();
  };

  return (
    <AppleDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <AppleDialogTitle>
        <Typography 
          variant="h5" 
          component="div" 
          fontWeight={500}
        >
          Choose your Background
        </Typography>
        <IconButton 
          onClick={onClose}
          size="large"
          sx={{ 
            color: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.8)' : undefined 
          }}
        >
          <X />
        </IconButton>
      </AppleDialogTitle>

      <DialogContent sx={{ p: 4 }}>
        {backgroundGroups.map((group) => (
          <Box key={group.title} sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 3,
                color: theme => theme.palette.mode === 'dark' ? '#fff' : undefined
              }}
            >
              {group.title}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(3, 1fr)',
                  md: 'repeat(4, 1fr)',
                },
                gap: 3
              }}
            >
              {group.backgrounds.map((bg) => {
                const Icon = bg.icon;
                return (
                  <Box
                    key={bg.type}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <BackgroundButton
                      onClick={() => handleBackgroundChange(bg.type)}
                      selected={currentBackground === bg.type}
                    >
                      <Icon size={32} />
                    </BackgroundButton>
                    <Typography 
                      variant="body2" 
                      sx={{
                        color: theme => 
                          theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.8)' 
                            : undefined
                      }}
                    >
                      {bg.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </DialogContent>

      <DialogActions
        sx={{
          p: 3,
          borderTop: 1,
          borderColor: theme => 
            theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.1)' 
              : 'rgba(0, 0, 0, 0.06)',
          gap: 2
        }}
      >
        <AppleButton 
          onClick={onClose} 
          variant="text"
          sx={{
            color: theme => theme.palette.mode === 'dark' ? '#fff' : '#0071e3'
          }}
        >
          Cancel
        </AppleButton>
        <AppleButton 
          onClick={onClose} 
          variant="contained"
          sx={{
            backgroundColor: theme => 
              theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.15)' 
                : '#0071e3',
            '&:hover': {
              backgroundColor: theme => 
                theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.2)' 
                  : '#0077ed'
            }
          }}
        >
          Done
        </AppleButton>
      </DialogActions>
    </AppleDialog>
  );
};

export default BackgroundSelector;