import React, { useState, useRef, useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  Typography, 
  IconButton, 
  Box, 
  Button,
  CircularProgress,
  styled
} from '@mui/material';
import { Copy, Download, Crop, X, Check } from 'lucide-react';

const APPLE_BLUE = '#0385FF';
const CANCEL_RED = 'rgba(239, 68, 68, 1)';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    margin: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'hidden'
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
  color: theme.palette.text.secondary,
  padding: '8px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '16px 0',
}));

const ToolbarWrapper = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.primary,
  fontWeight: 500,
  padding: '6px 12px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.active': {
    backgroundColor: `${APPLE_BLUE}20`,
    color: APPLE_BLUE,
  }
}));

const ConfirmButton = styled(ActionButton)({
  color: APPLE_BLUE,
  '&:hover': {
    backgroundColor: `${APPLE_BLUE}10`,
  },
});

const CancelCropButton = styled(ActionButton)({
  color: CANCEL_RED,
  '&:hover': {
    backgroundColor: 'rgba(239, 68, 68, 0.1)',
  },
});

const PreviewImage = styled('img')({
  maxWidth: '100%',
  maxHeight: 'calc(90vh - 280px)',
  objectFit: 'contain',
  display: 'block',
  margin: '0 auto',
});

const StyledCancelButton = styled(Button)({
  color: CANCEL_RED,
  textTransform: 'capitalize',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(239, 68, 68, 0.04)',
  },
});

const StyledInsertButton = styled(Button)({
  backgroundColor: APPLE_BLUE,
  color: 'white',
  textTransform: 'capitalize',
  borderRadius: '24px',
  '&:hover': {
    backgroundColor: '#0374e0',
  },
  '&:disabled': {
    color: 'white',
    opacity: 0.7,
  },
});

const CropContainer = styled(Box)({
  position: 'relative',
  userSelect: 'none',
  width: 'fit-content',
  height: 'fit-content',
});

const CropFrame = styled(Box)({
  position: 'absolute',
  border: '2px solid white',
  boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
  cursor: 'move',
  touchAction: 'none',
});

const ResizeHandle = styled(Box)({
  position: 'absolute',
  width: '20px',
  height: '20px',
  backgroundColor: 'white',
  border: `2px solid ${APPLE_BLUE}`,
  borderRadius: '50%',
  zIndex: 2,
  
  '&.top-left': {
    top: '-10px',
    left: '-10px',
    cursor: 'nw-resize',
  },
  '&.top-right': {
    top: '-10px',
    right: '-10px',
    cursor: 'ne-resize',
  },
  '&.bottom-left': {
    bottom: '-10px',
    left: '-10px',
    cursor: 'sw-resize',
  },
  '&.bottom-right': {
    bottom: '-10px',
    right: '-10px',
    cursor: 'se-resize',
  },
});

const CropDimensions = styled(Box)({
  position: 'absolute',
  color: 'white',
  backgroundColor: APPLE_BLUE,
  padding: '4px 8px',
  borderRadius: '4px',
  fontSize: '12px',
  pointerEvents: 'none',
  zIndex: 1,
  top: '-30px',
  left: '50%',
  transform: 'translateX(-50%)',
});

const ScreenshotPreviewModal = ({
  open,
  onClose,
  screenshotData,
  currentImage,
  setCurrentImage,
  onCopyToClipboard,
  onDownload,
  onSave,
  isLoading,
}) => {
  const [isCropping, setIsCropping] = useState(false);
  const [cropFrame, setCropFrame] = useState(null);
  const [dragStart, setDragStart] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const imageRef = useRef(null);
  const frameRef = useRef(null);
  const activeHandle = useRef(null);

  useEffect(() => {
    if (screenshotData?.originalDataUrl) {
      setCurrentImage(screenshotData.originalDataUrl);
      const img = new Image();
      img.src = screenshotData.originalDataUrl;
      img.onload = () => {
        setOriginalImage({
          width: img.width,
          height: img.height,
          url: screenshotData.originalDataUrl
        });
      };
    }
    
    return () => {
      setIsCropping(false);
      setCropFrame(null);
      setCroppedImage(null);
    };
  }, [screenshotData, setCurrentImage]);

  useEffect(() => {
    if (isCropping) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleMouseUp);
      };
    }
  }, [isCropping, dragStart]);

  useEffect(() => {
    if (cropFrame && isCropping) {
      updateCroppedImage(cropFrame);
    }
  }, [cropFrame]);

  const initializeCrop = () => {
    if (!imageRef.current) return;
    
    const rect = imageRef.current.getBoundingClientRect();
    const initialSize = Math.min(rect.width, rect.height) * 0.8;
    
    const newFrame = {
      x: (rect.width - initialSize) / 2,
      y: (rect.height - initialSize) / 2,
      width: initialSize,
      height: initialSize
    };
    
    setCropFrame(newFrame);
    setIsCropping(true);
    setCroppedImage(null);
  };

  const handleCropStart = () => {
    initializeCrop();
  };

  const handleCropCancel = () => {
    setIsCropping(false);
    setCropFrame(null);
    setCroppedImage(null);
    setCurrentImage(originalImage?.url || null);
  };

  const handleCropConfirm = () => {
    if (croppedImage) {
      setCurrentImage(croppedImage);
      const img = new Image();
      img.src = croppedImage;
      img.onload = () => {
        setOriginalImage({
          width: img.width,
          height: img.height,
          url: croppedImage
        });
      };
      setIsCropping(false);
      setCropFrame(null);
    }
  };

  const getEventCoordinates = (e) => {
    if (e.touches) {
      return { clientX: e.touches[0].clientX, clientY: e.touches[0].clientY };
    }
    return { clientX: e.clientX, clientY: e.clientY };
  };

  const handleMouseDown = (e, handle = null) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isCropping) return;

    const coords = getEventCoordinates(e);
    setDragStart({
      x: coords.clientX,
      y: coords.clientY,
      frame: { ...cropFrame }
    });
    activeHandle.current = handle;
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    handleMouseMove(e.touches[0]);
  };

  const handleMouseMove = (e) => {
    if (!dragStart || !isCropping || !frameRef.current) return;

    const coords = getEventCoordinates(e);
    const dx = coords.clientX - dragStart.x;
    const dy = coords.clientY - dragStart.y;
    const imageRect = imageRef.current.getBoundingClientRect();
    const minSize = 100;

    if (activeHandle.current) {
      let newFrame = { ...cropFrame };

      switch (activeHandle.current) {
        case 'top-left':
          newFrame = {
            x: Math.max(0, Math.min(dragStart.frame.x + dx, dragStart.frame.x + dragStart.frame.width - minSize)),
            y: Math.max(0, Math.min(dragStart.frame.y + dy, dragStart.frame.y + dragStart.frame.height - minSize)),
            width: Math.max(minSize, dragStart.frame.width - dx),
            height: Math.max(minSize, dragStart.frame.height - dy)
          };
          break;
        case 'top-right':
          newFrame = {
            x: dragStart.frame.x,
            y: Math.max(0, Math.min(dragStart.frame.y + dy, dragStart.frame.y + dragStart.frame.height - minSize)),
            width: Math.max(minSize, Math.min(imageRect.width - dragStart.frame.x, dragStart.frame.width + dx)),
            height: Math.max(minSize, dragStart.frame.height - dy)
          };
          break;
        case 'bottom-left':
          newFrame = {
            x: Math.max(0, Math.min(dragStart.frame.x + dx, dragStart.frame.x + dragStart.frame.width - minSize)),
            y: dragStart.frame.y,
            width: Math.max(minSize, dragStart.frame.width - dx),
            height: Math.max(minSize, Math.min(imageRect.height - dragStart.frame.y, dragStart.frame.height + dy))
          };
          break;
        case 'bottom-right':
          newFrame = {
            x: dragStart.frame.x,
            y: dragStart.frame.y,
            width: Math.max(minSize, Math.min(imageRect.width - dragStart.frame.x, dragStart.frame.width + dx)),
            height: Math.max(minSize, Math.min(imageRect.height - dragStart.frame.y, dragStart.frame.height + dy))
          };
          break;
      }

      setCropFrame(newFrame);
    } else {
      const newX = Math.max(0, Math.min(imageRect.width - cropFrame.width, dragStart.frame.x + dx));
      const newY = Math.max(0, Math.min(imageRect.height - cropFrame.height, dragStart.frame.y + dy));
      
      const newFrame = {
        ...cropFrame,
        x: newX,
        y: newY
      };
      
      setCropFrame(newFrame);
    }
  };

  const handleMouseUp = () => {
    setDragStart(null);
    activeHandle.current = null;
  };

  const updateCroppedImage = (frame) => {
    if (!originalImage || !imageRef.current) return;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    
    img.onload = () => {
      const displayedWidth = imageRef.current.offsetWidth;
      const displayedHeight = imageRef.current.offsetHeight;
      const scaleX = originalImage.width / displayedWidth;
      const scaleY = originalImage.height / displayedHeight;
      
      const scaledWidth = Math.round(frame.width * scaleX);
      const scaledHeight = Math.round(frame.height * scaleY);
      canvas.width = scaledWidth;
      canvas.height = scaledHeight;
      
      const scaledX = Math.round(frame.x * scaleX);
      const scaledY = Math.round(frame.y * scaleY);
      
      ctx.drawImage(
        img,
        scaledX,
        scaledY,
        scaledWidth,
        scaledHeight,
        0,
        0,
        scaledWidth,
        scaledHeight
      );
      
      const croppedDataUrl = canvas.toDataURL('image/png');
      setCroppedImage(croppedDataUrl);
    };
    
    img.src = originalImage.url;
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xl"
      fullWidth
    >
      <CloseButton onClick={onClose} size="small">
        <X size={20} />
      </CloseButton>

      <Title>
        Screenshot Preview
      </Title>

      <ToolbarWrapper>
        {!isCropping ? (
          <>
            <ActionButton
              startIcon={<Copy size={18} />}
              onClick={onCopyToClipboard}
              disabled={isLoading}
            >
              Copy
            </ActionButton>
            
            <ActionButton
              startIcon={<Download size={18} />}
              onClick={onDownload}
              disabled={isLoading}
            >
              Download
            </ActionButton>

            <ActionButton
              startIcon={<Crop size={18} />}
              onClick={handleCropStart}
              disabled={isLoading}
            >
              Crop
            </ActionButton>
          </>
        ) : (

            <>
            <ConfirmButton
              startIcon={<Check size={18} />}
              onClick={handleCropConfirm}
              disabled={!croppedImage}
            >
              Confirm
            </ConfirmButton>
            
            <CancelCropButton
              startIcon={<X size={18} />}
              onClick={handleCropCancel}
            >
              Cancel
            </CancelCropButton>
          </>
        )}
      </ToolbarWrapper>

      <DialogContent sx={{ 
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3,
        position: 'relative',
      }}>
        <CropContainer>
          <PreviewImage
            ref={imageRef}
            src={currentImage}
            alt="Screenshot preview"
            draggable={false}
          />
          
          {isCropping && cropFrame && (
            <CropFrame
              ref={frameRef}
              onMouseDown={(e) => handleMouseDown(e)}
              onTouchStart={(e) => handleMouseDown(e)}
              style={{
                left: `${cropFrame.x}px`,
                top: `${cropFrame.y}px`,
                width: `${cropFrame.width}px`,
                height: `${cropFrame.height}px`,
              }}
            >
              <ResizeHandle 
                className="top-left"
                onMouseDown={(e) => handleMouseDown(e, 'top-left')}
                onTouchStart={(e) => handleMouseDown(e, 'top-left')}
              />
              <ResizeHandle 
                className="top-right"
                onMouseDown={(e) => handleMouseDown(e, 'top-right')}
                onTouchStart={(e) => handleMouseDown(e, 'top-right')}
              />
              <ResizeHandle 
                className="bottom-left"
                onMouseDown={(e) => handleMouseDown(e, 'bottom-left')}
                onTouchStart={(e) => handleMouseDown(e, 'bottom-left')}
              />
              <ResizeHandle 
                className="bottom-right"
                onMouseDown={(e) => handleMouseDown(e, 'bottom-right')}
                onTouchStart={(e) => handleMouseDown(e, 'bottom-right')}
              />
              <CropDimensions>
                {Math.round(cropFrame.width)} × {Math.round(cropFrame.height)}
              </CropDimensions>
            </CropFrame>
          )}
        </CropContainer>

        {isLoading && (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}>
            <CircularProgress sx={{ color: APPLE_BLUE }} />
          </Box>
        )}
      </DialogContent>

      {!isCropping && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end',
          gap: 1,
          p: 2,
          borderTop: theme => `1px solid ${theme.palette.divider}`,
        }}>
          <StyledCancelButton onClick={onClose}>
            Cancel
          </StyledCancelButton>
          <StyledInsertButton
            onClick={() => onSave(currentImage)}
            variant="contained"
            disabled={isLoading}
          >
            Insert Screenshot
          </StyledInsertButton>
        </Box>
      )}
    </StyledDialog>
  );
};

export default ScreenshotPreviewModal;