import React, { createContext, useState, useContext, useCallback } from 'react';
import { checkProStatus } from '../components/services/AGTService';

const ProContext = createContext();

export const ProProvider = ({ children }) => {
  const [proState, setProState] = useState({
    isPro: false,
    loading: true,
    expirationDate: null,
    error: null
  });

  // En ProContext.js
const checkAndUpdateStatus = useCallback(async (userId) => {
  try {
    setProState(prev => ({ ...prev, loading: true, error: null }));
    const status = await checkProStatus(userId);
    
    setProState({
      isPro: status.isPro,
      loading: false,
      expirationDate: status.proExpirationDate || null,
      error: null
    });
    
    return status.isPro;
  } catch (error) {
    console.error('Error verificando estado PRO:', error);
    setProState(prev => ({
      ...prev,
      loading: false,
      error: 'Failed to verify PRO status'
    }));
    return false;
  }
}, []);

  const clearProState = useCallback(() => {
    setProState({
      isPro: false,
      loading: false,
      expirationDate: null,
      error: null
    });
  }, []);

  const value = {
    ...proState,
    checkStatus: checkAndUpdateStatus,
    clearProState
  };

  return (
    <ProContext.Provider value={value}>
      {children}
    </ProContext.Provider>
  );
};

export const useProStatus = () => {
  const context = useContext(ProContext);
  if (context === undefined) {
    throw new Error('useProStatus must be used within a ProProvider');
  }
  return context;
};

export default ProProvider;