import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/agtups/pricing`;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Pricing Strategy API Error:', error);
    const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
    return Promise.reject(new Error(errorMessage));
  }
);

class PricingStrategyService {
  // Crear nueva estrategia de precios
  static async createPricingStrategy(strategyData) {
    try {
      const validatedData = this.formatStrategyData(strategyData);
      const validation = this.validateStrategyData(validatedData);
      
      if (!validation.isValid) {
        throw new Error(`Validation error: ${validation.errors.join(', ')}`);
      }

      const response = await api.post('/', validatedData);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating pricing strategy: ${error.message}`);
    }
  }

  // Obtener estrategia por ID
  static async getPricingStrategyById(strategyId, productId) {
    try {
      const response = await api.get(`/${strategyId}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching pricing strategy: ${error.message}`);
    }
  }

  // Obtener todas las estrategias para un producto
  static async getPricingStrategiesByProduct(productId) {
    try {
      const response = await api.get(`/product/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching pricing strategies: ${error.message}`);
    }
  }

  // Calcular precio óptimo
  static async calculateOptimalPrice(productId, marketData) {
    try {
      const response = await api.post(`/optimal/${productId}`, marketData);
      return response.data;
    } catch (error) {
      throw new Error(`Error calculating optimal price: ${error.message}`);
    }
  }

  // Analizar historial de precios
  static async analyzePriceHistory(productId, params = {}) {
    try {
      const response = await api.get(`/history/${productId}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error analyzing price history: ${error.message}`);
    }
  }

  // Actualizar estrategia de precios
  static async updatePricingStrategy(strategyId, productId, updateData) {
    try {
      const validatedData = this.formatStrategyData(updateData);
      const validation = this.validateStrategyData(validatedData);
      
      if (!validation.isValid) {
        throw new Error(`Validation error: ${validation.errors.join(', ')}`);
      }

      const response = await api.put(`/${strategyId}/${productId}`, validatedData);
      return response.data;
    } catch (error) {
      throw new Error(`Error updating pricing strategy: ${error.message}`);
    }
  }

  // Eliminar estrategia de precios
  static async deletePricingStrategy(strategyId, productId) {
    try {
      const response = await api.delete(`/${strategyId}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error deleting pricing strategy: ${error.message}`);
    }
  }

  // Formato de datos para gráficos
  static formatChartData(priceData) {
    return {
      priceHistory: {
        labels: priceData.map(data => new Date(data.date).toLocaleDateString()),
        datasets: [{
          label: 'Price History',
          data: priceData.map(data => data.price),
          borderColor: '#4f46e5',
          fill: false
        }]
      },
      margins: {
        labels: priceData.map(data => new Date(data.date).toLocaleDateString()),
        datasets: [{
          label: 'Margin %',
          data: priceData.map(data => data.margin),
          borderColor: '#059669',
          fill: false
        }]
      }
    };
  }

  // Validar datos de estrategia
  static validateStrategyData(strategyData) {
    const errors = [];

    if (!strategyData.productId) {
      errors.push('Product ID is required');
    }

    if (typeof strategyData.basePrice !== 'number' || strategyData.basePrice < 0) {
      errors.push('Base price must be a valid positive number');
    }

    if (typeof strategyData.marginTarget !== 'number' || strategyData.marginTarget < 0) {
      errors.push('Margin target must be a valid positive number');
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  // Formatear datos de estrategia
  static formatStrategyData(strategyData) {
    return {
      productId: strategyData.productId,
      basePrice: Number(strategyData.basePrice),
      currentPrice: Number(strategyData.currentPrice),
      pricingModel: strategyData.pricingModel || 'fixed',
      marginTarget: Number(strategyData.marginTarget),
      competitivePricing: Array.isArray(strategyData.competitivePricing) 
        ? strategyData.competitivePricing 
        : [],
      discounts: Array.isArray(strategyData.discounts) 
        ? strategyData.discounts 
        : [],
      projectedMetrics: strategyData.projectedMetrics || {}
    };
  }

  // Calcular métricas de precio
  static calculatePriceMetrics(price, costs, marketData) {
    const margin = ((price - costs.total) / price) * 100;
    const marketAverage = marketData.competitorPrices.reduce((a, b) => a + b, 0) / marketData.competitorPrices.length;
    const competitiveness = ((marketAverage - price) / marketAverage) * 100;

    return {
      margin,
      competitiveness,
      potentialRevenue: price * marketData.estimatedVolume,
      marketPosition: price > marketAverage ? 'premium' : 'competitive'
    };
  }
}

export default PricingStrategyService;
