import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  TrendingUp, 
  TrendingDown,
  DollarSign,
  ArrowUpRight,
  ArrowDownRight,
  Download,
  Calendar,
  AlertCircle,
  Eye,
  EyeOff,
  Filter
} from 'lucide-react';
import { 
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const MetricCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

// Datos de ejemplo
const cashFlowData = [
  { month: 'Jan', income: 28000, expenses: 21000, balance: 7000 },
  { month: 'Feb', income: 32000, expenses: 24000, balance: 8000 },
  { month: 'Mar', income: 35000, expenses: 25000, balance: 10000 },
  { month: 'Apr', income: 31000, expenses: 23000, balance: 8000 },
  { month: 'May', income: 38000, expenses: 27000, balance: 11000 },
  { month: 'Jun', income: 42000, expenses: 28000, balance: 14000 },
];

const dailyFlowData = [
  { day: 'Mon', amount: 2100 },
  { day: 'Tue', amount: 1800 },
  { day: 'Wed', amount: 2400 },
  { day: 'Thu', amount: 2200 },
  { day: 'Fri', amount: 2600 },
  { day: 'Sat', amount: 1500 },
  { day: 'Sun', amount: 1200 },
];

const BalanceOverview = () => {
  const [timeRange, setTimeRange] = useState('6m');
  const [showBalance, setShowBalance] = useState(true);
  const currentBalance = 45000;
  const projectedBalance = 52000;

  return (
    <Box className="space-y-6">
      {/* Control Panel */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              label="Time Range"
              onChange={(e) => setTimeRange(e.target.value)}
            >
              <MenuItem value="1m">Last Month</MenuItem>
              <MenuItem value="3m">Last 3 Months</MenuItem>
              <MenuItem value="6m">Last 6 Months</MenuItem>
              <MenuItem value="1y">Last Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8} className="flex justify-end gap-2">
          <Tooltip title="Filter">
            <IconButton>
              <Filter size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Report">
            <IconButton>
              <Download size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title={showBalance ? "Hide Balance" : "Show Balance"}>
            <IconButton onClick={() => setShowBalance(!showBalance)}>
              {showBalance ? <EyeOff size={20} /> : <Eye size={20} />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {/* Balance Overview */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Box className="flex justify-between items-center mb-6">
              <Typography variant="h6">Cash Flow Overview</Typography>
              <Box className="flex gap-2">
                <Chip 
                  size="small" 
                  icon={<ArrowUpRight size={14} />}
                  label="Income" 
                  color="success"
                />
                <Chip 
                  size="small" 
                  icon={<ArrowDownRight size={14} />}
                  label="Expenses" 
                  color="error"
                />
                <Chip 
                  size="small" 
                  icon={<DollarSign size={14} />}
                  label="Balance" 
                  color="primary"
                />
              </Box>
            </Box>
            <Box className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={cashFlowData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <RechartsTooltip />
                  <Area 
                    type="monotone" 
                    dataKey="income" 
                    stackId="1"
                    stroke="#059669" 
                    fill="#059669"
                    fillOpacity={0.2}
                  />
                  <Area 
                    type="monotone" 
                    dataKey="expenses" 
                    stackId="2"
                    stroke="#dc2626" 
                    fill="#dc2626"
                    fillOpacity={0.2}
                  />
                  <Area 
                    type="monotone" 
                    dataKey="balance" 
                    stroke="#4f46e5" 
                    fill="#4f46e5"
                    fillOpacity={0.2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            {/* Current Balance */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Current Balance</Typography>
                <Box className="space-y-4">
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Available Balance
                    </Typography>
                    <Typography variant="h4">
                      {showBalance ? `$${currentBalance.toLocaleString()}` : '••••••'}
                    </Typography>
                    <Box className="flex items-center gap-1 mt-1">
                      <TrendingUp size={16} className="text-green-500" />
                      <Typography variant="body2" color="success.main">
                        +15% vs last month
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Projected Balance
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {showBalance ? `$${projectedBalance.toLocaleString()}` : '••••••'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      End of month
                    </Typography>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>

            {/* Daily Flow */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Daily Cash Flow</Typography>
                <Box className="h-48">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={dailyFlowData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <RechartsTooltip />
                      <Bar dataKey="amount" fill="#4f46e5" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </StyledPaper>
            </Grid>

            {/* Quick Stats */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Quick Stats</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MetricCard>
                      <Box className="flex items-center gap-2 mb-1">
                        <ArrowUpRight size={20} className="text-green-500" />
                        <Typography variant="body2">Income</Typography>
                      </Box>
                      <Typography variant="h6">
                        {showBalance ? '$42,000' : '••••••'}
                      </Typography>
                      <Typography variant="caption" color="success.main">
                        +12% this month
                      </Typography>
                    </MetricCard>
                  </Grid>
                  <Grid item xs={6}>
                    <MetricCard>
                      <Box className="flex items-center gap-2 mb-1">
                        <ArrowDownRight size={20} className="text-red-500" />
                        <Typography variant="body2">Expenses</Typography>
                      </Box>
                      <Typography variant="h6">
                        {showBalance ? '$28,000' : '••••••'}
                      </Typography>
                      <Typography variant="caption" color="error.main">
                        +5% this month
                      </Typography>
                    </MetricCard>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>

        {/* Alerts and Insights */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Financial Insights</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box className="p-4 bg-blue-50 rounded-lg">
                  <Box className="flex items-center gap-2 mb-2">
                    <TrendingUp size={20} className="text-blue-500" />
                    <Typography variant="subtitle2">Cash Flow Trend</Typography>
                  </Box>
                  <Typography variant="body2">
                    Positive cash flow trend with 15% increase in savings rate
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="p-4 bg-green-50 rounded-lg">
                  <Box className="flex items-center gap-2 mb-2">
                    <AlertCircle size={20} className="text-green-500" />
                    <Typography variant="subtitle2">Opportunity</Typography>
                  </Box>
                  <Typography variant="body2">
                    Current surplus could be invested for better returns
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className="p-4 bg-yellow-50 rounded-lg">
                  <Box className="flex items-center gap-2 mb-2">
                    <Calendar size={20} className="text-yellow-500" />
                    <Typography variant="subtitle2">Upcoming</Typography>
                  </Box>
                  <Typography variant="body2">
                    Large payment due in 5 days - ensure sufficient balance
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BalanceOverview;