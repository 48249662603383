import React, { useState, useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImageGridContainer = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginBottom: '8px',
  marginLeft: 'auto',
  boxSizing: 'border-box',
}));

// Helpers para los tamaños
const getImageSize = (imageCount) => {
  switch (imageCount) {
    case 1:
      return 280;
    case 2:
      return 200;
    case 3:
    case 4:
      return 180;
    default:
      return 160;
  }
};

const getMobileImageSize = (imageCount) => {
  switch (imageCount) {
    case 1:
      return 220;
    case 2:
      return 160;
    default:
      return 140;
  }
};

const ImageGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'imageCount'
})(({ theme, imageCount }) => {
  const size = getImageSize(imageCount);
  const mobileSize = getMobileImageSize(imageCount);
  
  return {
    display: 'grid',
    width: 'fit-content',
    gap: imageCount === 1 ? '8px' : '6px',
    // Ajustamos automáticamente las filas y columnas según la cantidad
    gridTemplateColumns:
      imageCount === 1
        ? `${size}px`
        : imageCount === 2
        ? `repeat(2, ${size}px)`
        : imageCount <= 4
        ? `repeat(2, ${size}px)`
        : `repeat(2, ${size}px)`, // Ajusta según cómo quieras más de 4
    gridTemplateRows:
      imageCount <= 2
        ? `${size}px`
        : `repeat(2, ${size}px)`,
    
    '@media (max-width: 600px)': {
      gridTemplateColumns:
        imageCount === 1
          ? `${mobileSize}px`
          : `repeat(2, ${mobileSize}px)`,
      gridTemplateRows:
        imageCount <= 2
          ? `${mobileSize}px`
          : `repeat(2, ${mobileSize}px)`,
      gap: '4px',
    }
  };
});

const ImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOverlay'
})(({ theme, isOverlay }) => ({
  aspectRatio: '1 / 1',
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gridColumn: isOverlay ? '2 / 3' : 'auto',
  gridRow: isOverlay ? '2 / 3' : 'auto',
}));

const StyledImage = styled('img')({
  position: 'absolute',
  minWidth: '100%',
  minHeight: '100%',
  width: 'auto',
  height: 'auto',
  objectFit: 'cover',
  objectPosition: 'center center',
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  }
});

const MoreImagesOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '20px',
  fontWeight: 'bold',
  cursor: 'pointer',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  }
});

const ModalContent = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '95vw',
  maxHeight: '95vh',
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ModalImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '95vh',
  objectFit: 'contain',
  borderRadius: '12px',
});

/**
 * Componente principal
 */
export default function AttachedImagesViewer({ files, imageBase64Data }) {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAllImages, setShowAllImages] = useState(false);

  // Al montar, procesamos las imágenes válidas y las guardamos en 'images'.
  useEffect(() => {
    const isValidImage = (file) => {
      const type = file.type || '';
      const ext = (file.name || '').toLowerCase();
      const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
      return (
        type.startsWith('image/') ||
        validExtensions.some((validExt) => ext.endsWith(validExt))
      );
    };

    let processed = [];
    
    if (files?.[0]?.s3Url) {
      // Caso: vienen con URL s3
      processed = files
        .filter(isValidImage)
        .map((file) => ({
          ...file,
          type: file.type || 'image/png',
          url: file.s3Url,
          isPermanent: true,
        }));
    } else if (Array.isArray(imageBase64Data) && imageBase64Data.length > 0) {
      // Caso: array de base64
      processed = imageBase64Data;
    } else if (Array.isArray(files) && files.length > 0) {
      // Caso: array de archivos
      processed = files.filter(isValidImage);
    }

    setImages(processed);
  }, [files, imageBase64Data]);

  // Si no hay imágenes válidas, no renderizamos nada.
  if (!images || images.length === 0) {
    return null;
  }

  const displayedImages = showAllImages ? images : images.slice(0, 4);
  const remainingImages = images.length - 4;

  /**
   * Elimina la imagen con 'indexEnElGrid' de nuestro array 'images' cuando falla la carga.
   */
  const handleImageError = (indexEnElGrid) => {
    setImages((prev) => {
      // localizamos la imagen en 'displayedImages' según el indexEnElGrid
      // y luego la removemos del array original 'prev'
      const actualImage = displayedImages[indexEnElGrid];

      // Buscamos su posición real en 'prev'
      const realIndex = prev.indexOf(actualImage);

      if (realIndex === -1) return prev;

      const newArr = [...prev];
      newArr.splice(realIndex, 1);
      return newArr;
    });
  };

  return (
    <ImageGridContainer>
      <ImageGrid imageCount={Math.min(images.length, 4)}>
        {displayedImages.map((imageData, index) => {
          const imageSrc = imageData.isPermanent
            ? imageData.url
            : `data:${
                imageData.type || 'image/png'
              };base64,${imageData.data || imageData}`;

          // Solo mostramos el overlay si hay más de 4 imágenes y estamos en la última "ranura".
          const showOverlay = !showAllImages && index === 3 && images.length > 4;

          return (
            <ImageContainer
              key={index}
              isOverlay={index === 3} // para aplicar reglas de grid
            >
              <StyledImage
                src={imageSrc}
                alt=""
                loading="lazy"
                // Si falla la carga, quitamos la imagen del array.
                onError={() => handleImageError(index)}
                onClick={() =>
                  showOverlay
                    ? setShowAllImages(true)
                    : setSelectedImage(imageData)
                }
              />
              {showOverlay && (
                <MoreImagesOverlay onClick={() => setShowAllImages(true)}>
                  +{remainingImages}
                </MoreImagesOverlay>
              )}
            </ImageContainer>
          );
        })}
      </ImageGrid>

      <Modal
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        aria-labelledby="image-modal"
        aria-describedby="full-size-image-view"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        }}
      >
        <ModalContent>
          {selectedImage && (
            <ModalImage
              src={
                selectedImage.isPermanent
                  ? selectedImage.url
                  : `data:${
                      selectedImage.type || 'image/png'
                    };base64,${selectedImage.data || selectedImage}`
              }
              alt="Full size view"
            />
          )}
        </ModalContent>
      </Modal>
    </ImageGridContainer>
  );
}
