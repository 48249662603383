/* ===============================================
 * D:\directiveai-react\frontend\src\components\dashboard\FullDashboard\dashboardStyles.js
 * =============================================== */

import { keyframes } from '@mui/material/styles';
import { colors } from '../../../theme';

const animations = {
  rotate: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `,
  glow: keyframes`
    0% {
      box-shadow: 0 0 5px var(--ui-accent);
    }
    50% {
      box-shadow: 0 0 20px var(--ui-accent);
    }
    100% {
      box-shadow: 0 0 5px var(--ui-accent);
    }
  `
};

export const getDashboardStyles = (theme, activeSection) => {
  const mode = theme?.palette?.mode || 'light';
  const themeColors = colors[mode] || colors.light;

  // Verificamos si la sección activa es "ups"
  const isUpsActive = activeSection === 'ups';

  // Helper para reemplazar bgPrimaryBase por #FFFFFF (claro) o #000000 (oscuro) si está en AGTUps
  const getUpsBackgroundColor = (defaultColor) => {
    return isUpsActive
      ? (mode === 'light' ? '#FFFFFF' : '#000000')
      : defaultColor;
  };

  return {
    fixedContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      transition: 'background-image 0.5s ease-in-out, background-color 0.5s ease-in-out',
      backgroundColor: themeColors.bg.primaryElevated,
      color: themeColors.label.primary
    },

    windowBar: {
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      backdropFilter: 'blur(40px)',
      color: themeColors.label.primary,
      fontFamily: "'Inter', sans-serif",
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      background: themeColors.ui.backgroundBlur
    },

    topBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      borderBottom: `1px solid ${themeColors.system.grey3}`,
      backgroundColor: themeColors.bg.primaryElevated,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1)
      }
    },

    navContainer: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 80px)',
        margin: '0 auto'
      }
    },

    contentArea: {
      flexGrow: 1,
      overflow: 'auto',
      padding: theme.spacing(0),
      backgroundColor: getUpsBackgroundColor(themeColors.grouped.bgPrimaryBase),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isUpsActive
          ? (mode === 'light' ? '#FFFFFF' : '#000000')
          : 'transparent'
      }
    },

    navigationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      backgroundColor: themeColors.bg.primaryElevated,
      backdropFilter: 'blur(10px)',
      borderRadius: '50px',
      margin: '0 auto',
      width: 'fit-content',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5),
        width: '100%'
      }
    },

    slidingBackground: {
      position: 'absolute',
      top: 5,
      left: 0,
      height: 'calc(100% - 10px)',
      borderRadius: '50px',
      backgroundColor: themeColors.fill.tertiary,
      transition: 'transform 0.3s ease, width 0.3s ease'
    },

    navButton: (isActive) => ({
      color: isActive ? themeColors.ui.accent : themeColors.label.primary,
      margin: theme.spacing(0, 0.5),
      textTransform: 'capitalize',
      borderRadius: '50px',
      zIndex: 1,
      padding: theme.spacing(0.5, 2),
      backgroundColor: 'transparent',
      boxShadow: 'none',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: themeColors.ui.accent
      },
      '&:active': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0, 0.25),
        fontSize: '0.8rem'
      }
    }),

    animatedIconButton: {
      color: themeColors.label.primary,
      transition: 'all 0.3s ease',
      '&:hover': {
        color: themeColors.ui.accent
      },
      '&.rotate': {
        animation: `${animations.rotate} 0.5s linear`
      },
      '&.glow': {
        animation: `${animations.glow} 1s ease-in-out`
      }
    },

    avatar: (isMobile) => ({
      cursor: 'pointer',
      width: isMobile ? 32 : 40,
      height: isMobile ? 32 : 40,
      border: `1px solid ${themeColors.system.grey3}`
    }),

    backgroundButton: (isMobile) => ({
      color: themeColors.label.primary,
      padding: isMobile ? '4px' : '8px',
      marginRight: isMobile ? '4px' : '8px',
      '&:hover': {
        color: themeColors.ui.accent
      }
    }),

    contentBox: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: getUpsBackgroundColor(themeColors.grouped.bgPrimaryBase),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        backgroundColor: isUpsActive
          ? (mode === 'light' ? '#FFFFFF' : '#000000')
          : 'transparent'
      }
    },

    chatBox: {
      flexGrow: 1,
      overflowY: 'auto',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: getUpsBackgroundColor(themeColors.grouped.bgPrimaryBase),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        marginTop: '-1px'
      }
    },

    homeBox: {
      flexGrow: 1,
      overflow: 'hidden',
      marginBottom: theme.spacing(2),
      width: '100%',
      backgroundColor: getUpsBackgroundColor(themeColors.grouped.bgPrimaryBase),
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        marginBottom: 0
      }
    },

    snackbarAlert: {
      borderRadius: 28,
      backgroundColor: mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(255, 255, 255, 0.98)',
      backdropFilter: 'blur(20px)',
      boxShadow: mode === 'dark'
        ? '0px 1px 3px rgba(0, 0, 0, 0.5)'
        : '0px 8px 32px rgba(0, 0, 0, 0.08)',
      padding: theme.spacing(2, 3),
      minWidth: '300px',
      border: 'none',
      '& .MuiAlert-icon': {
        color: mode === 'dark' ? '#fff' : '#0071e3',
        opacity: 0.9
      },
      '& .MuiAlert-message': {
        padding: theme.spacing(1, 0),
        fontSize: '0.95rem',
        color: mode === 'dark' ? '#fff' : '#000'
      },
      '& .MuiAlert-action': {
        padding: theme.spacing(0, 0, 0, 2),
        '& .MuiIconButton-root': {
          padding: 8,
          borderRadius: 20,
          color: mode === 'dark' ? '#fff' : '#0071e3',
          '&:hover': {
            backgroundColor: mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 113, 227, 0.1)'
          }
        }
      }
    },

    snackbarContainer: {
      '& .MuiSnackbarContent-root': {
        backgroundColor: 'transparent',
        padding: 0,
        minWidth: 'auto',
        boxShadow: 'none'
      }
    }
  };
};

export default getDashboardStyles;
