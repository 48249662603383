import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { IconButton, CircularProgress, Snackbar, Alert, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AudioLines, X } from 'lucide-react';

// Constantes
const MIN_RECORDING_TIME = 500; // 500ms
const FREE_MAX_RECORDING_TIME = 30000; // 30 segundos para usuarios normales
const PRO_MAX_RECORDING_TIME = 60000; // 60 segundos para usuarios PRO

// Componentes Estilizados
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  width: '32px',
  height: '32px',
  minWidth: '32px',
  minHeight: '32px',
  backgroundColor: '#0385FF',
  color: '#ffffff',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#026fcc',
  },
  '&.recording': {
    backgroundColor: '#ef4444',
    '&:hover': {
      backgroundColor: '#dc2626',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.12)'
      : 'rgba(255, 255, 255, 0.12)',
    color: theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.26)'
      : 'rgba(255, 255, 255, 0.26)',
  },
}));

const RecordingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  backgroundColor: 'transparent',
  borderRadius: '24px',
  padding: '4px 8px',
  width: '100%',
  transition: 'all 0.3s ease',
}));

const VisualizerContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  flexGrow: 1,
  height: '32px',
  padding: '0 8px',
  overflow: 'hidden',
  justifyContent: 'center',
});

const Timer = styled(Typography)(({ theme }) => ({
  minWidth: '48px',
  textAlign: 'center',
  fontSize: '0.875rem',
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
}));

// Función para dibujar rectángulos redondeados en canvas
const drawRoundedRect = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};

// Componente del Visualizador de Audio usando canvas con barras redondeadas
const AudioVisualizerCanvas = ({ analyserNode }) => {
  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    if (!analyserNode) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const bufferLength = analyserNode.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const bars = 20; // Número de barras
    const barWidth = 4; // Ancho de cada barra
    const barGap = 1; // Espacio entre barras
    const radius = 2; // Radio de las esquinas
    const gain = 1.5; // Factor de ganancia para aumentar la sensibilidad

    const canvasWidth = bars * (barWidth + barGap) + barGap;
    canvas.width = canvasWidth;
    canvas.height = 32;
    const centerY = canvas.height / 2;
    const maxBarHeight = canvas.height / 2 - 4;

    const draw = () => {
      animationFrameRef.current = requestAnimationFrame(draw);

      analyserNode.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let startX = barGap;

      for (let i = 0; i < bars; i++) {
        const index = Math.floor((i / bars) * bufferLength);
        const value = dataArray[index];

        const height = Math.min(Math.sqrt(value / 255) * maxBarHeight * gain, maxBarHeight);

        const distanceFromCenter = Math.abs(i - bars / 2) / (bars / 2);
        const opacity = 0.6 + (1 - distanceFromCenter) * 0.4;

        ctx.fillStyle = `rgba(239, 68, 68, ${opacity})`;

        // Dibujar la barra hacia arriba
        drawRoundedRect(ctx, startX, centerY - height, barWidth, height, radius);

        // Dibujar la barra hacia abajo
        drawRoundedRect(ctx, startX, centerY + 1, barWidth, height, radius);

        startX += barWidth + barGap;
      }
    };

    draw();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [analyserNode]);

  return (
    <VisualizerContainer>
      <canvas ref={canvasRef} width={120} height={32} style={{ display: 'block' }} />
    </VisualizerContainer>
  );
};

const TranscriptionButton = forwardRef(({ 
  onTranscriptionComplete, 
  onTranscriptionCancel,
  onTranscriptionStart,
  onRecordingStateChange,
  onClick,
  disabled,
  isFullWidth = false,
  isPro = false 
}, ref) => {
  // Estados
  const [showRecordingControls, setShowRecordingControls] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  
  // Referencias
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const streamRef = useRef(null);
  const recognitionRef = useRef(null);
  const transcriptionRef = useRef('');
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const startTimeRef = useRef(null);
  const maxRecordingTimerRef = useRef(null);
  const countdownIntervalRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const accumulatedTranscriptionRef = useRef('');
  const isRestartingRef = useRef(false);
  const isStoppingRef = useRef(false);
  const shouldSaveRef = useRef(false);

  const maxRecordingTime = isPro ? PRO_MAX_RECORDING_TIME : FREE_MAX_RECORDING_TIME;

  const cleanup = useCallback(() => {
    if (maxRecordingTimerRef.current) {
      clearTimeout(maxRecordingTimerRef.current);
      maxRecordingTimerRef.current = null;
    }

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }

    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => {
        track.stop();
      });
      streamRef.current = null;
    }

    if (recognitionRef.current) {
      try {
        recognitionRef.current.stop();
      } catch (e) {
      }
      recognitionRef.current = null;
    }

    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    mediaRecorderRef.current = null;
    chunksRef.current = [];
    analyserRef.current = null;
    setTimeRemaining(null);
    isStoppingRef.current = false;
    shouldSaveRef.current = false;
  }, []);

  const handleError = useCallback((message) => {
    setError(message);
    setShowRecordingControls(false);
    setIsProcessing(false);
    onRecordingStateChange?.(false);
    cleanup();
  }, [cleanup, onRecordingStateChange]);

  useImperativeHandle(ref, () => ({
    click: () => {
      startRecording();
    }
  }));

  const processAudioForStorage = useCallback(async (chunks, transcription) => {
    try {
      if (!shouldSaveRef.current) {
        return null;
      }

      const audioBlob = new Blob(chunks, { type: 'audio/webm' });

      if (audioBlob.size === 0) {
        throw new Error('No hay datos de audio disponibles');
      }

      const cleanedTranscription = transcription
        .replace(/\s+/g, ' ')
        .trim();

      if (!cleanedTranscription) {
        throw new Error('No se generó ninguna transcripción');
      }

      const audioUrl = URL.createObjectURL(audioBlob);

      const result = {
        audioUrl,
        audioBlob,
        duration: (Date.now() - startTimeRef.current) / 1000,
        transcription: cleanedTranscription
      };

      return result;
    } catch (error) {
      throw error;
    }
  }, []);

  const restartRecognition = useCallback(() => {
    if (!recognitionRef.current || !showRecordingControls || isRestartingRef.current || isStoppingRef.current) return;

    isRestartingRef.current = true;

    try {
      recognitionRef.current.stop();
      setTimeout(() => {
        if (recognitionRef.current && showRecordingControls && !isStoppingRef.current) {
          recognitionRef.current.start();
          isRestartingRef.current = false;
        }
      }, 100);
    } catch (e) {
      isRestartingRef.current = false;
    }
  }, [showRecordingControls]);

  const stopRecording = useCallback((shouldSave = true) => {
    if (isStoppingRef.current) {
      return;
    }

    isStoppingRef.current = true;
    shouldSaveRef.current = shouldSave;
    setShowRecordingControls(false);
    onRecordingStateChange?.(false);

    if (!shouldSave) {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        try {
          mediaRecorderRef.current.stop();
        } catch (e) {
        }
      }

      chunksRef.current = [];
      
      transcriptionRef.current = '';
      accumulatedTranscriptionRef.current = '';
      
      onTranscriptionCancel?.();
      return;
    }

    if (maxRecordingTimerRef.current) {
      clearTimeout(maxRecordingTimerRef.current);
      maxRecordingTimerRef.current = null;
    }

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }

    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
    }

    if (!mediaRecorderRef.current || mediaRecorderRef.current.state !== 'recording') {
      cleanup();
      return;
    }

    const duration = Date.now() - startTimeRef.current;

    if (duration < MIN_RECORDING_TIME) {
      handleError('Grabación demasiado corta');
      return;
    }

    try {
      const finalTranscription = transcriptionRef.current;

      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }

      mediaRecorderRef.current.stop();
    } catch (error) {
      handleError('Error al detener la grabación');
    }
  }, [handleError, cleanup, onRecordingStateChange, onTranscriptionCancel]);

  const setupSpeechRecognition = useCallback(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      throw new Error('El reconocimiento de voz no es compatible con este navegador');
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
    recognition.lang = 'es-ES';

    recognition.onstart = () => {
      if (!accumulatedTranscriptionRef.current) {
        accumulatedTranscriptionRef.current = '';
      }
    };

    recognition.onend = () => {
      if (showRecordingControls && !isRestartingRef.current && !isStoppingRef.current) {
        restartRecognition();
      }
    };

    recognition.onresult = (event) => {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }

      if (isStoppingRef.current) {
        return;
      }

      let finalTranscript = accumulatedTranscriptionRef.current;
      let interimTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          if (!finalTranscript.includes(transcript)) {
            finalTranscript = (finalTranscript + ' ' + transcript).trim();
          }
        } else {
          interimTranscript += transcript;
        }
      }

      accumulatedTranscriptionRef.current = finalTranscript;
      const updatedTranscription = (finalTranscript + ' ' + interimTranscript).trim();
      transcriptionRef.current = updatedTranscription;

      if (!isStoppingRef.current) {
        silenceTimeoutRef.current = setTimeout(() => {
          restartRecognition();
        }, 2000);
      }
    };

    recognition.onerror = (event) => {
      if (event.error === 'no-speech') {
        return;
      }
      
      if (event.error === 'network') {
        if (!isStoppingRef.current) {
          restartRecognition();
        }
        return;
      }

      handleError(`Error en reconocimiento de voz: ${event.error}`);
    };

    recognitionRef.current = recognition;

    try {
      recognition.start();
    } catch (error) {
      setTimeout(() => {
        try {
          if (!isStoppingRef.current) {
            recognition.start();
          }
        } catch (retryError) {
          handleError('No se pudo iniciar el reconocimiento de voz');
        }
      }, 100);
    }
  }, [handleError, showRecordingControls, restartRecognition]);

  const startRecording = useCallback(async () => {
    if (isProcessing) return;

    try {
      chunksRef.current = [];
      startTimeRef.current = Date.now();
      setError(null);
      transcriptionRef.current = '';
      accumulatedTranscriptionRef.current = '';
      isRestartingRef.current = false;
      isStoppingRef.current = false;
      shouldSaveRef.current = false;
      setTimeRemaining(maxRecordingTime / 1000);
      setShowRecordingControls(true);

      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: 48000,
          sampleSize: 16
        }
      });
      
      streamRef.current = stream;

      audioContextRef.current = new AudioContext();
      analyserRef.current = audioContextRef.current.createAnalyser();
      
      analyserRef.current.fftSize = 256;
      analyserRef.current.minDecibels = -100;
      analyserRef.current.maxDecibels = -20;
      analyserRef.current.smoothingTimeConstant = 0.65;
      
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);

      onTranscriptionStart?.();
      setupSpeechRecognition();

      const recorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=opus',
        audioBitsPerSecond: 128000
      });
      
      recorder.ondataavailable = (event) => {
        if (!isStoppingRef.current && event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      recorder.onstop = async () => {
        try {
          if (!shouldSaveRef.current) {
            cleanup();
            return;
          }

          if (!chunksRef.current.length || !transcriptionRef.current) {
            handleError('No se generó ninguna transcripción');
            return;
          }

          setIsProcessing(true);
          const finalTranscription = transcriptionRef.current;
          const result = await processAudioForStorage(chunksRef.current, finalTranscription);
          onTranscriptionComplete?.(result);
        } catch (error) {
          if (!shouldSaveRef.current) {
          } else {
            handleError(error.message);
          }
        } finally {
          setIsProcessing(false);
          cleanup();
          setTimeRemaining(null);
        }
      };

      recorder.onerror = (event) => {
        handleError('Ocurrió un error durante la grabación');
      };

      mediaRecorderRef.current = recorder;
      recorder.start(100);

      onRecordingStateChange?.(true);

      countdownIntervalRef.current = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 1) {
            stopRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      maxRecordingTimerRef.current = setTimeout(() => {
        if (!isStoppingRef.current) {
          stopRecording();
        }
      }, maxRecordingTime);

    } catch (error) {
      clearInterval(countdownIntervalRef.current);
      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        handleError('Acceso al micrófono denegado. Por favor, otorga permiso para grabar audio.');
      } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        handleError('No se encontró un micrófono. Por favor, conecta un micrófono y vuelve a intentarlo.');
      } else {
        handleError('No se pudo acceder al micrófono: ' + error.message);
      }
      onRecordingStateChange?.(false);
      setShowRecordingControls(false);
    }
  }, [
    maxRecordingTime,
    handleError,
    processAudioForStorage,
    onTranscriptionComplete,
    onTranscriptionStart,
    stopRecording,
    setupSpeechRecognition,
    cleanup,
    isProcessing,
    onRecordingStateChange
  ]);

  useEffect(() => {
    return () => {
      if (!isProcessing) {
        cleanup();
      }
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, [cleanup, isProcessing]);

  const buttonStyles = isFullWidth ? { 
    width: '100%', 
    display: 'flex',
    justifyContent: 'center'
  } : {};

  return (
    <Box sx={buttonStyles}>
      {!showRecordingControls ? (
        <StyledIconButton
          onClick={startRecording}
          disabled={disabled || isProcessing}
          title={`Voice Recording (máx ${maxRecordingTime / 1000}s)`}
          className={isProcessing ? 'processing' : ''}
        >
          {isProcessing ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <AudioLines size={24} />
          )}
        </StyledIconButton>
      ) : (
        <RecordingContainer>
          <StyledIconButton
            onClick={() => stopRecording(false)}
            sx={{ backgroundColor: 'error.main', '&:hover': { backgroundColor: 'error.dark' } }}
          >
            <X size={24} />
          </StyledIconButton>

          <AudioVisualizerCanvas analyserNode={analyserRef.current} />

          <Timer>
            {Math.floor(timeRemaining / 60)}:{String(Math.floor(timeRemaining % 60)).padStart(2, '0')}
          </Timer>

          <StyledIconButton
            onClick={() => stopRecording(true)}
            className="recording"
          >
            <AudioLines size={24} />
          </StyledIconButton>
        </RecordingContainer>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setError(null)} 
          severity="error" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default TranscriptionButton;