import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  IconButton, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Chip,
  Avatar,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
  FormControlLabel,
  Switch,
  Drawer,
  Divider,
  Tooltip,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Calendar,
  Clock,
  Bell,
  Plus,
  Edit2,
  Trash2,
  AlertCircle,
  DollarSign,
  Check,
  RefreshCcw,
  RepeatIcon,
  CalendarClock,
  Receipt,
  ArrowUpRight,
  Filter,
  MoreVertical,
  Send,
  RefreshCw,
  X
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const PaymentCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  gap: theme.spacing(1),
}));

// Datos de ejemplo
const upcomingPayments = [
  {
    id: 1,
    title: 'Software License',
    amount: 299.99,
    dueDate: '2024-03-01',
    status: 'pending',
    type: 'recurring',
    frequency: 'monthly',
    category: 'software',
    priority: 'high',
    reminders: true,
    description: 'Annual software license renewal',
    payee: 'Adobe Systems',
    automaticPayment: true,
    daysUntilDue: 3
  },
  {
    id: 2,
    title: 'Office Rent',
    amount: 1200.00,
    dueDate: '2024-03-05',
    status: 'scheduled',
    type: 'recurring',
    frequency: 'monthly',
    category: 'rent',
    priority: 'high',
    reminders: true,
    description: 'Monthly office space rent',
    payee: 'Business Center LLC',
    automaticPayment: true,
    daysUntilDue: 7
  },
  {
    id: 3,
    title: 'Internet Service',
    amount: 89.99,
    dueDate: '2024-03-10',
    status: 'pending',
    type: 'recurring',
    frequency: 'monthly',
    category: 'utilities',
    priority: 'medium',
    reminders: true,
    description: 'Monthly internet service payment',
    payee: 'ISP Provider',
    automaticPayment: false,
    daysUntilDue: 12
  }
];

const categories = [
  { id: 'software', name: 'Software & Services', color: '#4f46e5' },
  { id: 'rent', name: 'Rent & Lease', color: '#0891b2' },
  { id: 'utilities', name: 'Utilities', color: '#059669' },
  { id: 'supplies', name: 'Office Supplies', color: '#7c3aed' }
];

const frequencies = [
  { value: 'once', label: 'One-time' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'yearly', label: 'Yearly' }
];

const getDueDateColor = (daysUntilDue) => {
  if (daysUntilDue <= 3) return 'error';
  if (daysUntilDue <= 7) return 'warning';
  return 'success';
};

const UpcomingPayments = () => {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemindersDialog, setOpenRemindersDialog] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');

  const handleOpenMenu = (event, payment) => {
    event.stopPropagation();
    setSelectedPayment(payment);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setSelectedPayment(null);
  };

  const handleOpenDialog = (payment = null) => {
    setSelectedPayment(payment);
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleShowDetails = (payment) => {
    setSelectedPayment(payment);
    setOpenDetails(true);
    handleCloseMenu();
  };

  const getTotalUpcoming = () => {
    return upcomingPayments.reduce((total, payment) => total + payment.amount, 0);
  };

  const filteredPayments = upcomingPayments.filter(payment => {
    const matchesCategory = filterCategory === 'all' || payment.category === filterCategory;
    const matchesStatus = filterStatus === 'all' || payment.status === filterStatus;
    return matchesCategory && matchesStatus;
  });

  return (
    <Box className="space-y-6">
      <Grid container spacing={3}>
        {/* Summary Cards */}
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Box className="flex items-center gap-2 mb-2">
              <DollarSign size={20} className="text-blue-500" />
              <Typography variant="h6">Total Due</Typography>
            </Box>
            <Typography variant="h4">
              ${getTotalUpcoming().toLocaleString()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Next 30 days
            </Typography>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Box className="flex items-center gap-2 mb-2">
              <Calendar size={20} className="text-green-500" />
              <Typography variant="h6">Due This Week</Typography>
            </Box>
            <Typography variant="h4">2 Payments</Typography>
            <Typography variant="body2" color="text.secondary">
              Total $1,499.99
            </Typography>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Box className="flex items-center gap-2 mb-2">
              <RefreshCw size={20} className="text-purple-500" />
              <Typography variant="h6">Recurring</Typography>
            </Box>
            <Typography variant="h4">5 Active</Typography>
            <Typography variant="body2" color="text.secondary">
              Monthly payments
            </Typography>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Filters and Actions */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={filterCategory}
              label="Category"
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <MenuItem value="all">All Categories</MenuItem>
              {categories.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filterStatus}
              label="Status"
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <MenuItem value="all">All Status</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className="flex justify-end">
            <ActionButton
              variant="contained"
              startIcon={<Plus />}
              onClick={() => handleOpenDialog()}
            >
              Schedule Payment
            </ActionButton>
          </Box>
        </Grid>
      </Grid>

      {/* Payments List */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Upcoming Payments
        </Typography>

        {filteredPayments.map(payment => (
          <PaymentCard key={payment.id}>
            <Box className="flex justify-between items-start">
              <Box className="space-y-2">
                <Box className="flex items-center gap-2">
                  <Typography variant="subtitle1">{payment.title}</Typography>
                  {payment.type === 'recurring' && (
                    <Tooltip title="Recurring Payment">
                      <RepeatIcon size={16} className="text-purple-500" />
                    </Tooltip>
                  )}
                  {payment.automaticPayment && (
                    <Tooltip title="Automatic Payment">
                      <RefreshCcw size={16} className="text-green-500" />
                    </Tooltip>
                  )}
                </Box>
                
                <Box className="flex items-center gap-2">
                  <Chip
                    size="small"
                    label={payment.status}
                    color={payment.status === 'scheduled' ? 'success' : 'warning'}
                  />
                  <Chip
                    size="small"
                    label={`Due in ${payment.daysUntilDue} days`}
                    color={getDueDateColor(payment.daysUntilDue)}
                  />
                  {payment.reminders && (
                    <Tooltip title="Reminders Active">
                      <Bell size={16} className="text-blue-500" />
                    </Tooltip>
                  )}
                </Box>

                <Typography variant="body2" color="text.secondary">
                  {payment.payee}
                </Typography>
              </Box>

              <Box className="flex items-start gap-2">
                <Typography variant="h6" color="text.primary">
                  ${payment.amount.toLocaleString()}
                </Typography>
                <IconButton 
                  size="small"
                  onClick={(e) => handleOpenMenu(e, payment)}
                >
                  <MoreVertical size={16} />
                </IconButton>
              </Box>
            </Box>

            <Box className="mt-3">
              <LinearProgress 
                variant="determinate" 
                value={100 - (payment.daysUntilDue / 30 * 100)} 
                color={getDueDateColor(payment.daysUntilDue)}
              />
            </Box>
          </PaymentCard>
        ))}
      </StyledPaper>

      {/* Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleShowDetails(selectedPayment)}>
          <Receipt size={16} className="mr-2" />
          View Details
        </MenuItem>
        <MenuItem onClick={() => handleOpenDialog(selectedPayment)}>
          <Edit2 size={16} className="mr-2" />
          Edit Payment
        </MenuItem>
        <MenuItem onClick={() => setOpenRemindersDialog(true)}>
          <Bell size={16} className="mr-2" />
          Manage Reminders
        </MenuItem>
        <MenuItem className="text-red-500">
          <Trash2 size={16} className="mr-2" />
          Delete Payment
        </MenuItem>
      </Menu>

      {/* Payment Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedPayment ? 'Edit Payment' : 'Schedule New Payment'}
        </DialogTitle>
        <DialogContent>
          <Box className="space-y-4 mt-4">
            <TextField
              fullWidth
              label="Payment Title"
              defaultValue={selectedPayment?.title}
            />
            <TextField
              fullWidth
              label="Amount"
              type="number"
              defaultValue={selectedPayment?.amount}
              InputProps={{
                startAdornment: <DollarSign size={16} className="mr-2" />
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Due Date"
                  type="date"
                  defaultValue={selectedPayment?.dueDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    defaultValue={selectedPayment?.category}
                    label="Category"
                  >
                    {categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              fullWidth
              label="Payee"
              defaultValue={selectedPayment?.payee}
            />
            <FormControl fullWidth>
              <InputLabel>Payment Type</InputLabel>
              <Select
                defaultValue={selectedPayment?.type || 'once'}
                label="Payment Type"
              >
                {frequencies.map(freq => (
                  <MenuItem key={freq.value} value={freq.value}>
                    {freq.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={3}
              defaultValue={selectedPayment?.description}
            />
            <Box className="space-y-2">
              <FormControlLabel
                control={
                  <Switch 
                    defaultChecked={selectedPayment?.automaticPayment} 
                  />
                }
                label="Enable Automatic Payment"
              />
              <FormControlLabel
                control={
                  <Switch 
                    defaultChecked={selectedPayment?.reminders}
                  />
                }
                label="Enable Reminders"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={() => setOpenDialog(false)}
          >
            {selectedPayment ? 'Save Changes' : 'Schedule Payment'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reminders Dialog */}
      <Dialog
        open={openRemindersDialog}
        onClose={() => setOpenRemindersDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Manage Reminders</DialogTitle>
        <DialogContent>
          <Box className="space-y-4 mt-4">
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Email Notifications"
            />

            <FormControl fullWidth>
              <InputLabel>First Reminder</InputLabel>
              <Select
                defaultValue="3"
                label="First Reminder"
              >
                <MenuItem value="1">1 day before</MenuItem>
                <MenuItem value="3">3 days before</MenuItem>
                <MenuItem value="7">1 week before</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Second Reminder</InputLabel>
              <Select
                defaultValue="1"
                label="Second Reminder"
              >
                <MenuItem value="1">1 day before</MenuItem>
                <MenuItem value="2">2 days before</MenuItem>
                <MenuItem value="3">3 days before</MenuItem>
              </Select>
            </FormControl>
            
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Remind on due date"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRemindersDialog(false)}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={() => setOpenRemindersDialog(false)}
          >
            Save Preferences
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment Details Drawer */}
      <Drawer
        anchor="right"
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      >
        <Box sx={{ width: 400 }} className="p-6">
          {selectedPayment && (
            <Box className="space-y-6">
              <Box className="flex justify-between items-start">
                <Typography variant="h6">Payment Details</Typography>
                <IconButton 
                  size="small" 
                  onClick={() => setOpenDetails(false)}
                >
                  <X size={20} />
                </IconButton>
              </Box>

              <Box className="space-y-4">
                <Box>
                  <Typography variant="overline" className="text-gray-500">
                    Amount
                  </Typography>
                  <Typography variant="h4">
                    ${selectedPayment.amount.toLocaleString()}
                  </Typography>
                </Box>

                <Divider />

                <Box className="space-y-2">
                  <Box className="flex justify-between">
                    <Typography color="text.secondary">Status</Typography>
                    <Chip 
                      size="small"
                      label={selectedPayment.status}
                      color={selectedPayment.status === 'scheduled' ? 'success' : 'warning'}
                    />
                  </Box>
                  <Box className="flex justify-between">
                    <Typography color="text.secondary">Due Date</Typography>
                    <Typography>
                      {new Date(selectedPayment.dueDate).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box className="flex justify-between">
                    <Typography color="text.secondary">Category</Typography>
                    <Typography>
                      {categories.find(c => c.id === selectedPayment.category)?.name}
                    </Typography>
                  </Box>
                  <Box className="flex justify-between">
                    <Typography color="text.secondary">Payment Type</Typography>
                    <Typography>
                      {selectedPayment.type.charAt(0).toUpperCase() + selectedPayment.type.slice(1)}
                    </Typography>
                  </Box>
                  {selectedPayment.type === 'recurring' && (
                    <Box className="flex justify-between">
                      <Typography color="text.secondary">Frequency</Typography>
                      <Typography>{selectedPayment.frequency}</Typography>
                    </Box>
                  )}
                </Box>

                <Divider />

                <Box className="space-y-2">
                  <Typography variant="subtitle2">Payment Schedule</Typography>
                  <Box className="p-3 bg-gray-50 rounded-lg">
                    <Box className="flex items-center gap-2 mb-2">
                      <CalendarClock size={16} className="text-blue-500" />
                      <Typography variant="body2">
                        Next payment due in {selectedPayment.daysUntilDue} days
                      </Typography>
                    </Box>
                    <LinearProgress 
                      variant="determinate" 
                      value={100 - (selectedPayment.daysUntilDue / 30 * 100)}
                      color={getDueDateColor(selectedPayment.daysUntilDue)}
                    />
                  </Box>
                </Box>

                <Box className="space-y-2">
                  <Typography variant="subtitle2">Payment Settings</Typography>
                  <Box className="flex gap-2">
                    {selectedPayment.automaticPayment && (
                      <Chip
                        size="small"
                        icon={<RefreshCcw size={14} />}
                        label="Auto-payment enabled"
                        color="success"
                      />
                    )}
                    {selectedPayment.reminders && (
                      <Chip
                        size="small"
                        icon={<Bell size={14} />}
                        label="Reminders active"
                        color="info"
                      />
                    )}
                  </Box>
                </Box>

                <Box className="space-y-2">
                  <Typography variant="subtitle2">Notes</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {selectedPayment.description}
                  </Typography>
                </Box>
              </Box>

              <Box className="flex gap-2">
                <ActionButton
                  fullWidth
                  variant="contained"
                  startIcon={<Send size={16} />}
                >
                  Pay Now
                </ActionButton>
                <ActionButton
                  fullWidth
                  variant="outlined"
                  startIcon={<Edit2 size={16} />}
                  onClick={() => {
                    setOpenDetails(false);
                    handleOpenDialog(selectedPayment);
                  }}
                >
                  Edit
                </ActionButton>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default UpcomingPayments;