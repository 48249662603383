import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, InputBase, IconButton, Paper } from '@mui/material';
import { Search as SearchIcon, X as CloseIcon } from 'lucide-react';
import AGTFoundService from '../services/AGTUps-product-services/AGTFound/AGTFoundService';

const SearchWrapper = styled(Paper)(({ theme }) => {
  // Detectar si el modo es 'light' o 'dark'
  const isLight = theme.palette.mode === 'light';

  // Definir colores de fondo para el wrapper
  // de acuerdo al modo actual del tema
  const backgroundColor = isLight
    ? 'rgba(0, 0, 0, 0.05)'    // por ejemplo, un gris muy suave en modo claro
    : 'rgba(255, 255, 255, 0.1)'; // el color que usabas en modo oscuro

  const backgroundFocusColor = isLight
    ? 'rgba(0, 0, 0, 0.1)'
    : 'rgba(255, 255, 255, 0.15)';

  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor,
    borderRadius: theme.shape.borderRadius * 8,
    padding: theme.spacing(0.5, 2),
    transition: 'all 0.3s ease',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    border: 'none',
    '&:focus-within': {
      backgroundColor: backgroundFocusColor,
    },
  };
});

const SearchInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  // En lugar de un color fijo, usamos el primario del texto según el tema
  color: theme.palette.text.primary,
  fontSize: '1.1rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    '&::placeholder': {
      // El placeholder también lo tomamos del text.primary con opacidad reducida
      color: theme.palette.text.primary,
      opacity: 0.5,
    },
  },
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  // Para íconos, podemos usar un color "degradado" o un color del theme,
  // ajusta a tu gusto si quieres mayor contraste
  color: theme.palette.text.secondary,
}));

const AGTFound = ({ 
  placeholder = "¿Qué necesita tu negocio hoy?", 
  value = '',
  onChange = () => {},
  onResults = () => {},
  setIsSearching = () => {}
}) => {
  const handleSearch = async () => {
    if (!value.trim()) return;

    setIsSearching(true);
    onResults({ recommendations: [] });

    try {
      const result = await AGTFoundService.search(value);
      onResults(result);
    } catch (error) {
      console.error('Error en búsqueda:', error);
      onResults({ error: 'No se pudo procesar tu búsqueda' });
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleClear = () => {
    onChange('');
    onResults({ recommendations: [] });
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <SearchWrapper elevation={0}>
        <SearchInput
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          fullWidth
        />
        <IconButton onClick={handleSearch} aria-label="search">
          <SearchIcon size={22} />
        </IconButton>
        {value && (
          <ClearButton 
            onClick={handleClear}
            size="small"
            aria-label="clear search"
          >
            <CloseIcon size={18} />
          </ClearButton>
        )}
      </SearchWrapper>
    </Box>
  );
};

export default AGTFound;
