import React, { useState } from 'react';
import { Box } from '@mui/material';
import { X } from 'lucide-react';
import { styled } from '@mui/material/styles';
import FilePreviewModal from './FilePreviewModal';

const ImagePreviewItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100px',
  height: '100px',
  borderRadius: '8px',
  overflow: 'hidden',
  flexShrink: 0,
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.1)'}`,
  transition: 'transform 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.02)',
    '& .remove-button': {
      opacity: 1,
    }
  }
}));

const PreviewImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block',
});

const RemoveButton = styled('button')({
  position: 'absolute',
  top: '4px',
  right: '4px',
  padding: '4px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  opacity: 0,
  transition: 'all 0.2s ease',
  zIndex: 2,
  '&:hover': {
    transform: 'scale(1.1)',
  }
});

const ImageThumbnail = ({ file, onRemove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const previewUrl = React.useMemo(() => {
    if (!file || !file.data) return '';
    return `data:${file.type};base64,${file.data}`;
  }, [file]);

  const handleRemove = (e) => {
    e.stopPropagation();
    if (onRemove) {
      onRemove(file);
    }
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <ImagePreviewItem onClick={handleClick}>
        <PreviewImage 
          src={previewUrl} 
          alt={file.name} 
        />
        <RemoveButton
          className="remove-button"
          onClick={handleRemove}
        >
          <X size={16} color="#fff" />
        </RemoveButton>
      </ImagePreviewItem>

      <FilePreviewModal 
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        file={file}
      />
    </>
  );
};

export default ImageThumbnail;
