// triggers.js

// Lista de palabras/expresiones que disparan la necesidad de una imagen nueva
const VISUAL_TRIGGERS = [
    // Verbos de visualización directa
    'muéstrame',
    'enseña',
    'mira',
    'observa',
    'visualiza',
    'examina',
    'analiza',
    'revisa',
    'inspecciona',
    'identifica',
    'reconoce',
    'detecta',
    'distingue',

    // Preguntas de ubicación
    'en dónde está',
    '¿dónde está',
    'dónde se encuentra',
    'ubica',
    'localiza',
    
    // Expresiones de demostración
    'cómo se ve',
    'qué aspecto tiene',
    'qué apariencia tiene',
    'cómo luce',
    'qué forma tiene',
    'de qué color es',
    'qué tamaño tiene',
    
    // Indicadores de referencia visual
    'aquí',
    'allí',
    'allá',
    'acá',
    'ahí',
    'este objeto',
    'esta imagen',
    'esta foto',
    'este documento',
    'esta escena',
    
    // Solicitudes de descripción visual
    'describe lo que ves',
    'qué puedes ver',
    'qué observas',
    'qué notas',
    'qué detectas',
    'puedes distinguir',
    
    // Acciones de búsqueda visual
    'busca',
    'encuentra',
    'localiza',
    'señala',
    'marca',
    'resalta',
    
    // Expresiones de comparación visual
    'compara',
    'diferencia entre',
    'similitud entre',
    'parece',
    'se asemeja a',
    
    // Solicitudes de análisis detallado
    'analiza esta',
    'examina esta',
    'revisa esta',
    'estudia esta',
    'interpreta esta',
    
    // Verificación visual
    'confirma si',
    'verifica si',
    'comprueba si',
    'valida si',
    
    // Expresiones de contenido visual
    'contiene',
    'muestra',
    'aparece',
    'se visualiza',
    'se observa',
    
    // Indicadores de estado visual
    'está visible',
    'se puede ver',
    'es visible',
    'aparente',
    
    // Solicitudes de procesamiento de imagen
    'procesa esta imagen',
    'analiza esta foto',
    'escanea este',
    'lee este',
    'interpreta este',
    
    // Preguntas sobre características visuales
    '¿cómo es',
    '¿qué tipo de',
    '¿cuántos',
    '¿de qué',
    
    // Expresiones de atención visual
    'fíjate en',
    'presta atención a',
    'enfócate en',
    'céntrate en'
];

/**
 * Revisa si la transcripción contiene alguna de las expresiones indicadas.
 * Devuelve true si se detecta algún disparador visual, false en caso contrario.
 * @param {string} transcript - El texto a analizar
 * @returns {boolean} - True si se encuentra un disparador visual
 */
export function hasVisualTrigger(transcript) {
    if (!transcript) return false;

    // Convertimos la cadena a minúsculas y eliminamos acentos para una comparación más robusta
    const normalizedTranscript = transcript.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    // Normalizamos también los triggers
    const normalizedTriggers = VISUAL_TRIGGERS.map(trigger => 
        trigger.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
    );

    // Retorna true en cuanto encuentre alguna de las palabras/expresiones
    return normalizedTriggers.some(trigger => normalizedTranscript.includes(trigger));
}

/**
 * Obtiene todos los disparadores visuales encontrados en el texto
 * @param {string} transcript - El texto a analizar
 * @returns {string[]} - Array con los disparadores encontrados
 */
export function getFoundTriggers(transcript) {
    if (!transcript) return [];

    const normalizedTranscript = transcript.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    return VISUAL_TRIGGERS.filter(trigger => {
        const normalizedTrigger = trigger.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        return normalizedTranscript.includes(normalizedTrigger);
    });
}

/**
 * Verifica si una palabra específica es un disparador visual
 * @param {string} word - La palabra a verificar
 * @returns {boolean} - True si la palabra es un disparador visual
 */
export function isVisualTrigger(word) {
    if (!word) return false;
    
    const normalizedWord = word.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    const normalizedTriggers = VISUAL_TRIGGERS.map(trigger => 
        trigger.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
    );

    return normalizedTriggers.includes(normalizedWord);
}