import axios from 'axios';
import API_CONFIG from './config';

class ProfileService {
  constructor() {
    this.api = null;
    this.endpoints = API_CONFIG.ENDPOINTS;
    this.CACHE_KEY_PREFIX = 'agtups_profile_';
    this.CACHE_DURATION = 24 * 60 * 60 * 1000;
  }

  initialize({ uid }) {
    this.api = API_CONFIG.createAxiosInstance();
    this.uid = uid;
    this.CACHE_KEY = `${this.CACHE_KEY_PREFIX}${uid}`;
  }

  _clearCache() {
    if (this.CACHE_KEY) {
      localStorage.removeItem(this.CACHE_KEY);
      console.log('[ProfileService] 🗑️ Caché limpiado para:', this.CACHE_KEY);
    }
  }

  _updateCache(profileData) {
    if (this.CACHE_KEY && profileData) {
      const dataToStore = {
        profile: profileData,
        timestamp: Date.now()
      };
      localStorage.setItem(this.CACHE_KEY, JSON.stringify(dataToStore));
      console.log('[ProfileService] 💾 Caché actualizado:', dataToStore);
    }
  }

  _getCachedProfile() {
    if (!this.CACHE_KEY) return null;
    
    try {
      const cachedData = localStorage.getItem(this.CACHE_KEY);
      if (!cachedData) return null;

      const { profile, timestamp } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > this.CACHE_DURATION;

      if (isExpired) {
        this._clearCache();
        return null;
      }

      return profile;
    } catch (error) {
      console.error('[ProfileService] Error reading cache:', error);
      this._clearCache();
      return null;
    }
  }

  async getProfile() {
    try {
      console.log('[ProfileService] 🔍 Solicitando perfil...');
      
      const cachedProfile = this._getCachedProfile();
      if (cachedProfile) {
        console.log('[ProfileService] ⚡ Perfil recuperado del caché:', cachedProfile);
        
        this.api.get(API_CONFIG.ENDPOINTS.PROFILE)
          .then(response => {
            if (response.data.success && response.data.data) {
              this._updateCache(response.data.data);
            }
          })
          .catch(error => {
            console.error('[ProfileService] Error en actualización background:', error);
          });
        
        return {
          success: true,
          data: cachedProfile,
          exists: true,
          fromCache: true
        };
      }
      
      const response = await this.api.get(API_CONFIG.ENDPOINTS.PROFILE);
      
      if (response.data.success && response.data.data) {
        this._updateCache(response.data.data);
        
        console.log('[ProfileService] ✅ Perfil obtenido del servidor:', response.data);
        
        return {
          success: true,
          data: response.data.data,
          exists: true
        };
      }
      
      return {
        success: true,
        exists: false,
        isNewProfile: true,
        data: null
      };
      
    } catch (error) {
      if (error.response?.status === 404 && 
          error.response?.data?.error === 'PROFILE_NOT_FOUND') {
        this._clearCache();
        
        console.log('[ProfileService] 🆕 No se encontró perfil - necesita crear uno nuevo');
        return {
          success: true,
          exists: false,
          isNewProfile: true,
          data: null
        };
      }

      console.error('[ProfileService] ❌ Error obteniendo perfil:', error);
      return {
        success: false,
        exists: false,
        error: this.handleError(error)
      };
    }
  }

  async createProfile(profileData) {
    try {
      console.log('[ProfileService] 📝 Creando perfil nuevo...', profileData);

      const validation = await this.validateProfileData(profileData);
      if (!validation.isValid) {
        throw new Error(`Datos inválidos: ${validation.errors.join(', ')}`);
      }

      const profile = {
        ...profileData,
        userId: this.uid,
        status: 'active',
        forceCreate: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        metadata: {
          setupComplete: true,
          setupCompletedAt: new Date().toISOString()
        }
      };

      const response = await this.api.post(API_CONFIG.ENDPOINTS.PROFILE, profile);

      if (response.data.success) {
        this._updateCache(response.data.data);
        console.log('[ProfileService] ✅ Perfil creado exitosamente:', response.data);
        return {
          success: true,
          data: response.data,
          exists: true
        };
      }

      throw new Error('Error en la respuesta del servidor al crear perfil');
    } catch (error) {
      console.error('[ProfileService] ❌ Error creando perfil:', error);
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  async updateProfile(profileId, profileData) {
    try {
      console.log('[ProfileService] 📝 Actualizando perfil...', {
        profileId,
        updates: profileData
      });

      if (!profileId) {
        throw new Error('Profile ID es requerido para actualizar');
      }

      const validation = await this.validateProfileData(profileData);
      if (!validation.isValid) {
        throw new Error(`Datos inválidos: ${validation.errors.join(', ')}`);
      }

      const response = await this.api.put(
        `${API_CONFIG.ENDPOINTS.PROFILE}/${profileId}`,
        {
          ...profileData,
          updatedAt: new Date().toISOString()
        }
      );

      if (response.data.success) {
        this._updateCache(response.data.data);
        console.log('[ProfileService] ✅ Perfil actualizado exitosamente:', response.data);
        return {
          success: true,
          data: response.data,
          exists: true
        };
      }

      throw new Error('Error en la respuesta del servidor al actualizar perfil');
    } catch (error) {
      console.error('[ProfileService] ❌ Error actualizando perfil:', error);
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  async deleteProfile(profileId) {
    try {
      console.log('[ProfileService] 🗑️ Eliminando perfil...', { profileId });

      if (!profileId) {
        throw new Error('Profile ID es requerido para eliminar');
      }

      const response = await this.api.delete(`${API_CONFIG.ENDPOINTS.PROFILE}/${profileId}`);

      if (response.data.success) {
        this._clearCache();
        console.log('[ProfileService] ✅ Perfil eliminado exitosamente');
        return {
          success: true,
          data: response.data,
          message: 'Perfil eliminado exitosamente'
        };
      }

      throw new Error('Error en la respuesta del servidor al eliminar perfil');
    } catch (error) {
      console.error('[ProfileService] ❌ Error eliminando perfil:', error);
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  handleError(error) {
    let errorMessage = 'Error inesperado';
    let errorDetails = null;

    if (error.response?.data?.error) {
      errorMessage = error.response.data.error;
      errorDetails = error.response.data.details;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error('[ProfileService] Error details:', {
      message: errorMessage,
      details: errorDetails,
      originalError: error
    });

    return {
      message: errorMessage,
      details: errorDetails
    };
  }

  async validateProfileData(profileData) {
    const errors = [];

    if (!profileData.companyName?.trim()) {
      errors.push('Nombre de empresa es requerido');
    }

    if (!profileData.industry?.trim()) {
      errors.push('Industria es requerida');
    }

    if (profileData.contactEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profileData.contactEmail)) {
      errors.push('Formato de email inválido');
    }

    if (profileData.website && !/^https?:\/\/.+/.test(profileData.website)) {
      errors.push('URL del sitio web debe comenzar con http:// o https://');
    }

    if (profileData.business && !profileData.business.stage) {
      errors.push('Etapa del negocio es requerida');
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  async getProfileAnalytics(profileId) {
    try {
      console.log('[ProfileService] 📊 Obteniendo analytics del perfil...', { profileId });

      if (!profileId) {
        throw new Error('Profile ID es requerido para obtener analytics');
      }

      const response = await this.api.get(`${API_CONFIG.ENDPOINTS.PROFILE}/${profileId}/analytics`);

      if (response.data.success) {
        console.log('[ProfileService] ✅ Analytics obtenidos exitosamente:', response.data);
        return {
          success: true,
          data: response.data
        };
      }

      throw new Error('Error en la respuesta del servidor al obtener analytics');
    } catch (error) {
      console.error('[ProfileService] ❌ Error obteniendo analytics:', error);
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  async refreshProfile() {
    try {
      console.log('[ProfileService] 🔄 Actualizando datos del perfil...');
      
      const response = await this.api.get(API_CONFIG.ENDPOINTS.PROFILE);
      
      if (response.data.success && response.data.data) {
        this._updateCache(response.data.data);
        console.log('[ProfileService] ✅ Datos del perfil actualizados');
        return {
          success: true,
          data: response.data.data
        };
      }
      
      throw new Error('Error actualizando datos del perfil');
    } catch (error) {
      console.error('[ProfileService] ❌ Error en actualización de datos:', error);
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }
}

const profileService = new ProfileService();
export default profileService;