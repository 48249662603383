import React from 'react';
import { Box, Select, MenuItem, Typography, Chip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Sparkles, ChevronDown } from 'lucide-react';

const ModelSelector = ({ selectedModel, onModelChange, disabled, isPro }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Array de modelos con sus nombres completos
  const models = [
    {
      id: 'claude-3-haiku-20240307',
      name: 'AGT',
      description: 'Great for most tasks',
      proOnly: false
    },
    {
      id: 'claude-3-5-sonnet-20241022',
      name: 'AGT Pro',
      description: 'Most intelligent model',
      proOnly: true
    },
    {
      id: 'agt-ultra-20241227',
      name: 'AGT Ultra',
      description: 'Advanced reasoning model',
      proOnly: true
    }
  ];

  // Función para convertir el nombre completo en el abreviado
  const getAbbreviatedName = (fullName) => {
    if (fullName === 'AGT') return 'AGT';
    if (fullName === 'AGT Pro') return 'Pro';
    if (fullName === 'AGT Ultra') return 'Ultra';
    return fullName;
  };

  const CustomChevronIcon = () => {
    return (
      <ChevronDown
        size={20}
        style={{
          color: disabled
            ? 'rgba(0, 0, 0, 0.26)'
            : theme.palette.mode === 'dark'
              ? '#fff'
              : '#000',
          opacity: 0.7,
          marginRight: '8px'
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(50% - 4px)',
          minWidth: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
          width: '200px'
        },
        '&:hover': {
          '& .MuiSelect-select': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.05)'
                : 'rgba(0, 0, 0, 0.03)'
          }
        }
      }}
    >
      <Sparkles
        size={16}
        style={{
          position: 'absolute',
          left: '12px',
          color: '#0385FF',
          zIndex: 1
        }}
      />

      <Select
        value={selectedModel}
        onChange={(e) => onModelChange(e.target.value)}
        disabled={disabled}
        IconComponent={CustomChevronIcon}
        sx={{
          width: '100%',
          '& .MuiSelect-select': {
            paddingLeft: '40px !important',
            paddingRight: '32px !important',
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.03)'
                : 'rgba(0, 0, 0, 0.02)',
            borderRadius: '16px',
            border: `1px solid ${
              theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.08)'
            }`,
            transition: 'all 0.2s ease',
            '&:focus': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
              borderColor: '#0385FF',
              boxShadow: `0 0 0 2px #0385FF25`
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiSelect-select .MuiTypography-root': {
            whiteSpace: 'normal'
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              mt: 1,
              borderRadius: '16px',
              backgroundColor:
                theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
              boxShadow:
                theme.palette.mode === 'dark'
                  ? '0 4px 20px rgba(0, 0, 0, 0.5)'
                  : '0 4px 20px rgba(0, 0, 0, 0.15)',
              border: `1px solid ${
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.08)'
              }`,
              '& .MuiMenuItem-root': {
                padding: '12px 16px',
                borderRadius: '12px',
                margin: '4px',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.05)'
                      : 'rgba(0, 0, 0, 0.03)'
                }
              }
            }
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top'
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom'
          }
        }}
        // Aquí definimos cómo se muestra el valor seleccionado
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    whiteSpace: 'nowrap'
                  }}
                >
                  Choose model
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    color: 'text.secondary',
                    whiteSpace: 'nowrap'
                  }}
                >
                  AGT Normal is default
                </Typography>
              </Box>
            );
          }

          // Buscamos el objeto del modelo seleccionado
          const selectedModelObj = models.find((m) => m.id === selected);
          if (!selectedModelObj) return null;

          // Mostramos el nombre abreviado, de acuerdo a la lógica
          const displayName = getAbbreviatedName(selectedModelObj.name);

          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  whiteSpace: 'nowrap'
                }}
              >
                {displayName}
              </Typography>
              {!isPro && selectedModelObj.proOnly && (
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    color: 'text.secondary',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Upgrade to Pro
                </Typography>
              )}
            </Box>
          );
        }}
      >
        {/* Aquí se muestra el menú con los nombres completos */}
        {models.map((model) => (
          <MenuItem
            key={model.id}
            value={model.id}
            disabled={model.proOnly && !isPro}
            sx={{
              opacity: model.proOnly && !isPro ? 0.5 : 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                {model.name}
              </Typography>
              {model.proOnly && (
                <Chip
                  label="PRO"
                  size="small"
                  sx={{
                    height: '20px',
                    backgroundColor: '#0385FF',
                    color: 'white',
                    fontSize: '0.625rem',
                    fontWeight: 600,
                    borderRadius: '10px',
                    '& .MuiChip-label': {
                      px: 1
                    }
                  }}
                />
              )}
            </Box>
            <Typography
              sx={{ fontSize: '0.75rem', color: 'text.secondary', mt: 0.5 }}
            >
              {model.description}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default ModelSelector;
