// ProjectContextProvider.js
import React, { createContext, useContext, useState, useCallback } from 'react';

// Crear el contexto
const ProjectContext = createContext(null);

export const ProjectContextProvider = ({ children }) => {
  // Estados compartidos
  const [projectChats, setProjectChats] = useState({});
  const [projectContexts, setProjectContexts] = useState({});
  const [projectInstructions, setProjectInstructions] = useState({});

  // Callbacks para actualizar chats
  const updateProjectChats = useCallback((projectId, newChats) => {
    setProjectChats(prev => ({
      ...prev,
      [projectId]: newChats
    }));
  }, []);

  // Callback para añadir un nuevo chat
  const addProjectChat = useCallback((projectId, newChat) => {
    setProjectChats(prev => ({
      ...prev,
      [projectId]: [newChat, ...(prev[projectId] || [])]
    }));
  }, []);

  // Callback para eliminar un chat
  const removeProjectChat = useCallback((projectId, chatId) => {
    setProjectChats(prev => ({
      ...prev,
      [projectId]: prev[projectId]?.filter(chat => chat.id !== chatId) || []
    }));
  }, []);

  // Callbacks para contexto
  const updateProjectContext = useCallback((projectId, contextData) => {
    setProjectContexts(prev => ({
      ...prev,
      [projectId]: contextData
    }));
  }, []);

  // Callbacks para instrucciones
  const updateProjectInstructions = useCallback((projectId, instructionsData) => {
    setProjectInstructions(prev => ({
      ...prev,
      [projectId]: instructionsData
    }));
  }, []);

  const value = {
    projectChats,
    projectContexts,
    projectInstructions,
    updateProjectChats,
    addProjectChat,
    removeProjectChat,
    updateProjectContext,
    updateProjectInstructions
  };

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext debe usarse dentro de ProjectContextProvider');
  }
  return context;
};

// HOC para envolver componentes que necesitan acceso al contexto
export const withProjectContext = (WrappedComponent) => {
  return function WithProjectContext(props) {
    return (
      <ProjectContextProvider>
        <WrappedComponent {...props} />
      </ProjectContextProvider>
    );
  };
};