import React from 'react';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { keyframes } from '@emotion/react';
import AnimatedCodeBlock from './AnimatedCodeBlock';

const textFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    filter: blur(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
`;

const AnimatedImageContainer = styled('div')(({ theme, isNew = false, delay = 0 }) => ({
  opacity: isNew ? 0 : 1,
  animation: isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
  animationDelay: `${delay}ms`,
  margin: '1em 0',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
  }
}));

const StyledReactMarkdown = styled(ReactMarkdown, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['isNew', 'baseDelay'].includes(prop)
})(({ theme, isNew = false }) => ({
  width: '100%',
  '& p, & li, & blockquote, & h1, & h2, & h3, & h4, & h5, & h6': {
    opacity: isNew ? 0 : 1,
    animation: isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
    margin: '0 0 16px 0',
    lineHeight: '1.75',
    '& .word': {
      display: 'inline-block',
      opacity: isNew ? 0 : 1,
      animation: isNew ? `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards` : 'none',
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  '& ul, & ol': {
    margin: '0 0 16px 1.5em',
    padding: 0,
  },
  '& li': {
    margin: '0 0 8px 0',
    lineHeight: '1.75',
    '& > p': {
      margin: '0 0 8px 0',
    },
  },
  '& blockquote': {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    paddingLeft: '1em',
    margin: '1em 0',
    fontStyle: 'italic',
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '1em',
  },
  '& th, & td': {
    border: `1px solid ${theme.palette.divider}`,
    padding: '0.5em',
    textAlign: 'left',
  },
  '& th': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.05)' 
      : 'rgba(0, 0, 0, 0.05)',
  },
  '& hr': {
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '1em 0',
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& pre': {
    margin: '16px 0',
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& pre > code': {
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& :first-of-type': {
    marginTop: 0,
  },
  '& :last-of-type': {
    marginBottom: 0,
  },
}));

const createCustomRenderers = (isNew, baseDelay = 0, theme) => ({
  text: ({ children }) => {
    if (!isNew || typeof children !== 'string') return children;
    
    return children.split(' ').map((word, index) => (
      <span
        key={index}
        className="word"
        style={{
          animationDelay: `${baseDelay + index * 40}ms`,
        }}
      >
        {word}{' '}
      </span>
    ));
  },

  code: ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : '';
  
    if (!inline && language) {
      return (
        <AnimatedCodeBlock 
          code={String(children).trim()}
          language={language}
          isNew={isNew}
        />
      );
    }

    return (
      <code 
        className={className} 
        style={{
          padding: '2px 4px',
          borderRadius: '4px',
          fontSize: '0.9em',
          backgroundColor: theme?.palette.mode === 'dark' 
            ? 'rgba(255, 255, 255, 0.1)' 
            : 'rgba(0, 0, 0, 0.1)',
          fontFamily: 'var(--font-mono)',
        }}
        {...props}
      >
        {children}
      </code>
    );
  },

  img: ({ src, alt, ...props }) => (
    <AnimatedImageContainer isNew={isNew} delay={baseDelay}>
      <img 
        src={src} 
        alt={alt || ''} 
        loading="lazy"
        {...props} 
      />
    </AnimatedImageContainer>
  ),

  a: ({ node, children, href, ...props }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: theme?.palette.primary.main,
        textDecoration: 'none',
      }}
      {...props}
    >
      {children}
    </a>
  ),

  pre: ({ node, children, ...props }) => children,

  h1: ({ node, children, ...props }) => (
    <h1 {...props} style={{ fontSize: '2em', fontWeight: 600 }}>{children}</h1>
  ),

  h2: ({ node, children, ...props }) => (
    <h2 {...props} style={{ fontSize: '1.5em', fontWeight: 600 }}>{children}</h2>
  ),

  h3: ({ node, children, ...props }) => (
    <h3 {...props} style={{ fontSize: '1.17em', fontWeight: 600 }}>{children}</h3>
  ),

  h4: ({ node, children, ...props }) => (
    <h4 {...props} style={{ fontSize: '1em', fontWeight: 600 }}>{children}</h4>
  ),

  h5: ({ node, children, ...props }) => (
    <h5 {...props} style={{ fontSize: '0.83em', fontWeight: 600 }}>{children}</h5>
  ),

  h6: ({ node, children, ...props }) => (
    <h6 {...props} style={{ fontSize: '0.67em', fontWeight: 600 }}>{children}</h6>
  ),
});

const MagicalMessageRenderer = ({ 
  content, 
  isNew = false, 
  theme, 
  baseDelay = 0,
}) => {
  return (
    <StyledReactMarkdown
      remarkPlugins={[]}
      rehypePlugins={[rehypeRaw]}
      components={createCustomRenderers(isNew, baseDelay, theme)}
      className="magical-message"
    >
      {content}
    </StyledReactMarkdown>
  );
};

export default MagicalMessageRenderer;