import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  LinearProgress,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  AvatarGroup,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Button,
  Badge,
  Tab,
  Tabs,
  useTheme
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  TrendingUp,
  Users,
  Target,
  ShoppingCart,
  Calendar,
  Clock,
  BarChart2,
  Download,
  ArrowUp,
  ArrowDown,
  Globe,
  Share2,
  UserCheck,
  UserPlus,
  DollarSign,
  Award,
  Zap,
  Star,
  Package,
  RefreshCw,
  Settings,
  ChevronRight,
  AlertCircle,
  Activity,
  PieChart as PieChartIcon
} from 'lucide-react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Legend,
  Line,
  LineChart
} from 'recharts';

import SalesStrategyService from '../../services/AGTUps-product-services/salesStrategyService';
import ProductSelector from './ProductSelector';

/**
 * Sistema de colores al estilo Apple (light/dark).
 */
const appleColors = {
  light: {
    label: {
      primary: '#000000'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      green: '#31C859', // Éxito (modo claro)
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: {
      primaryElevated: '#FFFFFF'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#007BFE', // Azul de iOS
      base: '#F2F2F7'
    }
  },
  dark: {
    label: {
      primary: '#FFFFFF'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      green: '#2DD257', // Éxito (modo oscuro)
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: {
      primaryElevated: '#000000'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#0385FF',
      base: '#272729'
    }
  }
};

/**
 * Función para obtener los colores de navegación según el modo (light/dark).
 */
const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const c = mode === 'light' ? appleColors.light : appleColors.dark;
  return {
    background: c.bg.primaryElevated,
    textDefault: c.label.primary,
    textSecondary: c.system.grey,
    cardBackground: c.ui.base,
    borderColor: c.system.grey3,
    accent: c.ui.accent,
    success: c.system.green,
    error: '#FF3B30',  // rojo iOS
    warning: '#FF9500' // naranja/amarillo iOS
  };
};

/**
 * Paleta de colores adicional para gráficas (puedes ajustarla si deseas).
 */
const CHART_COLORS = {
  primary: '#4f46e5',
  secondary: '#0891b2',
  success: '#059669',
  warning: '#eab308',
  error: '#dc2626',
  purple: '#7c3aed',
  pink: '#db2777'
};

/**
 * StyledPaper con estilo Apple (borde suave, transparencia y blur).
 */
const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    backdropFilter: 'blur(20px)',
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    border: `1px solid ${navColors.borderColor}`,
    transition: 'all 0.3s ease',
    boxShadow: 'none',
    '&:hover': {
      borderColor: navColors.accent
    }
  };
});

/**
 * MetricCard para las métricas (ligeramente translúcido).
 */
const MetricCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2),
    borderRadius: '12px',
    border: `1px solid ${navColors.borderColor}`,
    backdropFilter: 'blur(10px)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      borderColor: navColors.accent,
      transform: 'translateY(-2px)'
    }
  };
});

/**
 * ChannelCard para las cards de canales (con estilo Apple).
 */
const ChannelCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2),
    borderRadius: '12px',
    border: `1px solid ${navColors.borderColor}`,
    transition: 'all 0.2s ease-in-out',
    backdropFilter: 'blur(10px)',
    '&:hover': {
      borderColor: navColors.accent,
      backgroundColor: alpha(navColors.cardBackground, 0.7)
    }
  };
});

/**
 * Overlay de carga (cuando actualizamos datos).
 */
const LoadingOverlay = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 0.5)'
        : 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    borderRadius: '16px'
  };
});

const SalesStrategy = ({ products }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);

  // Estado local para selección de producto y otros
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [timeRange, setTimeRange] = useState('6m');
  const [activeTab, setActiveTab] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState('all');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [salesPerformance, setSalesPerformance] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (selectedProduct) {
      loadSalesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, timeRange]);

  const loadSalesData = async () => {
    try {
      setLoading(true);
      setError(null);

      const [strategyResponse, performanceResponse] = await Promise.all([
        SalesStrategyService.getSalesStrategiesByProduct(selectedProduct.id, {
          timeRange
        }),
        SalesStrategyService.analyzeSalesPerformance(selectedProduct.id, {
          timeRange
        })
      ]);

      setStrategyData(strategyResponse.data);
      setSalesPerformance(performanceResponse.data);
      setLastUpdated(new Date());
    } catch (err) {
      setError(`Error loading sales data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      await loadSalesData();
    } finally {
      setUpdating(false);
    }
  };

  const handleExportData = async () => {
    try {
      if (!selectedProduct) return;
      const data = {
        strategy: strategyData,
        performance: salesPerformance,
        product: selectedProduct,
        exportDate: new Date().toISOString(),
        timeRange
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `sales-strategy-${selectedProduct.id}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error exporting data: ${err.message}`);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Formatear datos de ventas (para gráficas)
  const formattedSalesData = useMemo(() => {
    if (!salesPerformance) return null;
    return SalesStrategyService.formatChartData(salesPerformance);
  }, [salesPerformance]);

  return (
    <Box
      sx={{
        color: navColors.textDefault,
        backgroundColor: navColors.background,
        minHeight: '100vh',
        '& > *:not(:last-child)': {
          mb: 4
        }
      }}
    >
      {/* Selector de productos */}
      <ProductSelector
        products={products}
        selectedProduct={selectedProduct}
        onProductSelect={setSelectedProduct}
        compact
      />

      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{
            borderRadius: '12px',
            backgroundColor: alpha(navColors.error, 0.1),
            border: `1px solid ${alpha(navColors.error, 0.2)}`,
            backdropFilter: 'blur(10px)',
            mt: 2,
            color: navColors.error
          }}
        >
          {error}
        </Alert>
      )}

      {/* Si no hay producto seleccionado */}
      {!selectedProduct ? (
        <StyledPaper sx={{ textAlign: 'center', py: 8 }}>
          <Package
            size={48}
            style={{
              marginBottom: '16px',
              color: alpha(navColors.textSecondary, 0.6)
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            Select a Product
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: navColors.textSecondary }}
          >
            Choose a product from above to view sales strategy
          </Typography>
        </StyledPaper>
      ) : (
        <Grid container spacing={4}>
          {/* Controles superiores: Time Range, Sales Channel, etc. */}
          <Grid item xs={12} container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: navColors.textSecondary,
                    '&.Mui-focused': { color: navColors.accent }
                  }}
                >
                  Time Range
                </InputLabel>
                <Select
                  value={timeRange}
                  label="Time Range"
                  onChange={(e) => setTimeRange(e.target.value)}
                  disabled={loading}
                  sx={{
                    backgroundColor: alpha(navColors.cardBackground, 0.5),
                    borderRadius: '12px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: navColors.borderColor
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: navColors.accent
                    }
                  }}
                >
                  <MenuItem value="1m">Last Month</MenuItem>
                  <MenuItem value="3m">Last 3 Months</MenuItem>
                  <MenuItem value="6m">Last 6 Months</MenuItem>
                  <MenuItem value="1y">Last Year</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: navColors.textSecondary,
                    '&.Mui-focused': { color: navColors.accent }
                  }}
                >
                  Sales Channel
                </InputLabel>
                <Select
                  value={selectedChannel}
                  label="Sales Channel"
                  onChange={(e) => setSelectedChannel(e.target.value)}
                  disabled={loading}
                  sx={{
                    backgroundColor: alpha(navColors.cardBackground, 0.5),
                    borderRadius: '12px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: navColors.borderColor
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: navColors.accent
                    }
                  }}
                >
                  <MenuItem value="all">All Channels</MenuItem>
                  <MenuItem value="direct">Direct Sales</MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="partners">Partners</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Tooltip
                title={
                  lastUpdated
                    ? `Last updated: ${lastUpdated.toLocaleString()}`
                    : 'Never updated'
                }
              >
                <IconButton onClick={handleRefreshData} disabled={updating || loading}>
                  <RefreshCw
                    size={20}
                    style={{
                      color: navColors.accent,
                      animation: updating ? 'spin 1s linear infinite' : 'none'
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export Strategy">
                <IconButton onClick={handleExportData} disabled={loading}>
                  <Download size={20} style={{ color: navColors.accent }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Settings">
                <IconButton disabled={loading}>
                  <Settings size={20} style={{ color: navColors.accent }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* Columna izquierda: Sales Performance Overview */}
          <Grid item xs={12} md={8}>
            <StyledPaper sx={{ position: 'relative' }}>
              {updating && (
                <LoadingOverlay>
                  <CircularProgress />
                </LoadingOverlay>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3
                }}
              >
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, letterSpacing: '-0.02em', mb: 0.5 }}
                  >
                    {selectedProduct.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                    Sales Performance Overview
                  </Typography>
                </Box>
                <Badge
                  color="primary"
                  badgeContent={salesPerformance?.status || 'Active'}
                  sx={{
                    '& .MuiBadge-badge': {
                      fontSize: '0.75rem',
                      transform: 'scale(1)',
                      transformOrigin: 'center'
                    }
                  }}
                >
                  <Chip
                    icon={<DollarSign size={16} />}
                    label={`$${selectedProduct.price}`}
                    variant="outlined"
                    sx={{
                      borderRadius: '8px',
                      color: navColors.accent,
                      border: `1px solid ${alpha(navColors.accent, 0.3)}`
                    }}
                  />
                </Badge>
              </Box>

              <Box sx={{ height: 300 }}>
                {formattedSalesData ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={formattedSalesData.salesTrend}>
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={alpha(navColors.textDefault, 0.1)}
                      />
                      <XAxis
                        dataKey="date"
                        tick={{ fill: navColors.textDefault, fontSize: 12 }}
                      />
                      <YAxis tick={{ fill: navColors.textDefault, fontSize: 12 }} />
                      <RechartsTooltip
                        contentStyle={{
                          backgroundColor: alpha(navColors.cardBackground, 0.8),
                          borderRadius: '8px',
                          border: `1px solid ${navColors.borderColor}`,
                          color: navColors.textDefault
                        }}
                      />
                      <Area
                        type="monotone"
                        dataKey="sales"
                        stroke={CHART_COLORS.primary}
                        fill={alpha(CHART_COLORS.primary, 0.2)}
                        name="Sales Volume"
                      />
                      <Area
                        type="monotone"
                        dataKey="target"
                        stroke={navColors.success}
                        strokeDasharray="5 5"
                        fill="none"
                        name="Sales Target"
                      />
                      <Legend wrapperStyle={{ color: navColors.textDefault }} />
                    </AreaChart>
                  </ResponsiveContainer>
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: navColors.textSecondary
                    }}
                  >
                    <Typography>No sales trend data available</Typography>
                  </Box>
                )}
              </Box>
            </StyledPaper>
          </Grid>

          {/* Columna derecha: Performance Metrics + Top Performers */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography
                    variant="h6"
                    sx={{ mb: 2, fontWeight: 600, letterSpacing: '-0.02em' }}
                  >
                    Performance Metrics
                  </Typography>
                  {salesPerformance ? (
                    <Box sx={{ display: 'grid', rowGap: 2 }}>
                      {/* Monthly Sales */}
                      <MetricCard>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Monthly Sales
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: salesPerformance.monthly.growth >= 0
                                ? navColors.success
                                : navColors.error
                            }}
                          >
                            {salesPerformance.monthly.growth >= 0 ? (
                              <ArrowUp size={18} />
                            ) : (
                              <ArrowDown size={18} />
                            )}
                            <Typography variant="body2">
                              {salesPerformance.monthly.growth}%
                            </Typography>
                          </Box>
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 700 }}>
                          ${salesPerformance.monthly.total.toLocaleString()}
                        </Typography>
                      </MetricCard>

                      {/* Conversion Rate */}
                      <MetricCard>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Conversion Rate
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: salesPerformance.conversion.trend >= 0
                                ? navColors.success
                                : navColors.error
                            }}
                          >
                            {salesPerformance.conversion.trend >= 0 ? (
                              <ArrowUp size={18} />
                            ) : (
                              <ArrowDown size={18} />
                            )}
                            <Typography variant="body2">
                              {Math.abs(salesPerformance.conversion.trend)}%
                            </Typography>
                          </Box>
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 700 }}>
                          {salesPerformance.conversion.rate}%
                        </Typography>
                      </MetricCard>

                      {/* Avg. Deal Size */}
                      <MetricCard>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Avg. Deal Size
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5,
                              color: salesPerformance.avgDealSize.trend >= 0
                                ? navColors.success
                                : navColors.error
                            }}
                          >
                            {salesPerformance.avgDealSize.trend >= 0 ? (
                              <ArrowUp size={18} />
                            ) : (
                              <ArrowDown size={18} />
                            )}
                            <Typography variant="body2">
                              {Math.abs(salesPerformance.avgDealSize.trend)}%
                            </Typography>
                          </Box>
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 700 }}>
                          ${salesPerformance.avgDealSize.value}
                        </Typography>
                      </MetricCard>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: 'center', py: 2 }}>
                      <CircularProgress />
                    </Box>
                  )}
                </StyledPaper>
              </Grid>

              <Grid item xs={12}>
                <StyledPaper>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, letterSpacing: '-0.02em' }}
                    >
                      Top Performers
                    </Typography>
                    <AvatarGroup max={3}>
                      {salesPerformance?.topPerformers?.map((performer) => (
                        <Avatar
                          key={performer.id}
                          alt={performer.name}
                          src={performer.avatar}
                        />
                      ))}
                    </AvatarGroup>
                  </Box>
                  <Box sx={{ display: 'grid', rowGap: 2 }}>
                    {salesPerformance?.topPerformers
                      ?.slice(0, 2)
                      .map((performer, index) => (
                        <Box
                          key={performer.id}
                          sx={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Avatar
                              sx={{
                                bgcolor:
                                  index === 0 ? 'primary.main' : 'secondary.main'
                              }}
                            >
                              {performer.name.charAt(0)}
                            </Avatar>
                            <Box>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                {performer.name}
                              </Typography>
                              <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                {performer.dealsClosed} deals closed
                              </Typography>
                            </Box>
                          </Box>
                          <Award
                            size={20}
                            style={{
                              color: index === 0 ? '#FFD700' : alpha(navColors.textDefault, 0.3)
                            }}
                          />
                        </Box>
                      ))}
                  </Box>
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>

          {/* Fila inferior: Sales Channel Distribution + Sales Funnel */}
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: 600, letterSpacing: '-0.02em' }}
              >
                Sales Channel Distribution
              </Typography>
              <Box sx={{ height: 320 }}>
                {formattedSalesData?.channelDistribution ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <RechartsPieChart>
                      <Pie
                        data={formattedSalesData.channelDistribution}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={100}
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {formattedSalesData.channelDistribution.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              Object.values(CHART_COLORS)[
                                index % Object.keys(CHART_COLORS).length
                              ]
                            }
                          />
                        ))}
                      </Pie>
                      <RechartsTooltip
                        contentStyle={{
                          backgroundColor: alpha(navColors.cardBackground, 0.8),
                          borderRadius: '8px',
                          border: `1px solid ${navColors.borderColor}`,
                          color: navColors.textDefault
                        }}
                      />
                      <Legend
                        wrapperStyle={{ color: navColors.textDefault }}
                      />
                    </RechartsPieChart>
                  </ResponsiveContainer>
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: navColors.textSecondary
                    }}
                  >
                    <Typography>No channel distribution data available</Typography>
                  </Box>
                )}
              </Box>
            </StyledPaper>
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: 600, letterSpacing: '-0.02em' }}
              >
                Sales Funnel
              </Typography>
              <Box sx={{ display: 'grid', rowGap: 2 }}>
                {salesPerformance?.funnel ? (
                  Object.entries(salesPerformance.funnel).map(([stage, data]) => (
                    <Box key={stage}>
                      <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
                      >
                        <Typography variant="body2">{stage}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="body2">{data.count}</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                data.conversionRate >= data.target
                                  ? navColors.success
                                  : navColors.error
                            }}
                          >
                            {data.conversionRate}%
                          </Typography>
                        </Box>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        value={data.conversionRate}
                        sx={{
                          height: 6,
                          borderRadius: 3,
                          backgroundColor: alpha(navColors.borderColor, 0.2),
                          '& .MuiLinearProgress-bar': {
                            borderRadius: 3,
                            backgroundColor:
                              data.conversionRate >= data.target
                                ? navColors.success
                                : navColors.warning
                          }
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box sx={{ textAlign: 'center', py: 2 }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </StyledPaper>
          </Grid>

          {/* Recommended Actions */}
          <Grid item xs={12}>
            <StyledPaper>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: 600, letterSpacing: '-0.02em' }}
              >
                Recommended Actions
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <ChannelCard>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <UserPlus size={20} style={{ color: navColors.accent }} />
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        Lead Generation
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
                      {salesPerformance?.recommendations?.leadGen ||
                        'Increase lead generation efforts in direct sales channel'}
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      sx={{
                        borderRadius: '12px',
                        backgroundColor: navColors.accent,
                        color: '#fff',
                        textTransform: 'none',
                        fontWeight: 600,
                        '&:hover': {
                          backgroundColor: alpha(navColors.accent, 0.9)
                        }
                      }}
                    >
                      Start Campaign
                    </Button>
                  </ChannelCard>
                </Grid>

                <Grid item xs={12} md={4}>
                  <ChannelCard>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Zap size={20} style={{ color: '#AF52DE' }} />
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        Conversion Optimization
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
                      {salesPerformance?.recommendations?.conversion ||
                        'Implement new sales training program to improve close rates'}
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      sx={{
                        borderRadius: '12px',
                        backgroundColor: navColors.accent,
                        color: '#fff',
                        textTransform: 'none',
                        fontWeight: 600,
                        '&:hover': {
                          backgroundColor: alpha(navColors.accent, 0.9)
                        }
                      }}
                    >
                      View Program
                    </Button>
                  </ChannelCard>
                </Grid>

                <Grid item xs={12} md={4}>
                  <ChannelCard>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                      <Star size={20} style={{ color: '#FFD700' }} />
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        Customer Retention
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
                      {salesPerformance?.recommendations?.retention ||
                        'Launch customer loyalty program to increase repeat sales'}
                    </Typography>
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      sx={{
                        borderRadius: '12px',
                        backgroundColor: navColors.accent,
                        color: '#fff',
                        textTransform: 'none',
                        fontWeight: 600,
                        '&:hover': {
                          backgroundColor: alpha(navColors.accent, 0.9)
                        }
                      }}
                    >
                      Launch Program
                    </Button>
                  </ChannelCard>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          {/* Tabs: Geographic / Seasonal / Customer Segments */}
          <Grid item xs={12}>
            <StyledPaper>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  mb: 2,
                  borderBottom: `1px solid ${navColors.borderColor}`,
                  '& .MuiTab-root': {
                    textTransform: 'none'
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: navColors.accent
                  }
                }}
              >
                <Tab
                  icon={<Globe size={16} />}
                  label="Geographic"
                  iconPosition="start"
                />
                <Tab
                  icon={<Calendar size={16} />}
                  label="Seasonal"
                  iconPosition="start"
                />
                <Tab
                  icon={<UserCheck size={16} />}
                  label="Customer Segments"
                  iconPosition="start"
                />
              </Tabs>

              {activeTab === 0 && (
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                    Geographic Distribution of Sales
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          p: 2,
                          borderRadius: '12px',
                          backgroundColor: alpha(navColors.accent, 0.1)
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                          Top Performing Regions
                        </Typography>
                        <Box sx={{ display: 'grid', rowGap: 2 }}>
                          {salesPerformance?.geographic?.topRegions.map((region) => (
                            <Box key={region.name}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  mb: 0.5
                                }}
                              >
                                <Typography variant="body2">{region.name}</Typography>
                                <Typography variant="body2" sx={{ color: navColors.accent }}>
                                  {region.contribution}%
                                </Typography>
                              </Box>
                              <LinearProgress
                                variant="determinate"
                                value={region.contribution}
                                sx={{
                                  height: 6,
                                  borderRadius: 3,
                                  backgroundColor: alpha(navColors.borderColor, 0.2),
                                  '& .MuiLinearProgress-bar': {
                                    borderRadius: 3,
                                    backgroundColor: navColors.accent
                                  }
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          p: 2,
                          borderRadius: '12px',
                          backgroundColor: alpha(navColors.success, 0.1)
                        }}
                      >
                        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                          Growth Opportunities
                        </Typography>
                        {salesPerformance?.geographic?.opportunities.map((opp) => (
                          <Box
                            key={opp.region}
                            sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                          >
                            <Target size={16} style={{ color: navColors.success }} />
                            <Typography variant="body2">
                              {opp.description}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {activeTab === 1 && (
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                    Seasonal Sales Patterns
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    {formattedSalesData?.seasonal ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={formattedSalesData.seasonal}>
                          <CartesianGrid
                            strokeDasharray="3 3"
                            stroke={alpha(navColors.textDefault, 0.1)}
                          />
                          <XAxis
                            dataKey="period"
                            tick={{ fill: navColors.textDefault, fontSize: 12 }}
                          />
                          <YAxis tick={{ fill: navColors.textDefault, fontSize: 12 }} />
                          <RechartsTooltip
                            contentStyle={{
                              backgroundColor: alpha(navColors.cardBackground, 0.8),
                              borderRadius: '8px',
                              border: `1px solid ${navColors.borderColor}`,
                              color: navColors.textDefault
                            }}
                          />
                          <Bar dataKey="sales" fill={CHART_COLORS.primary} />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box
                        sx={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: navColors.textSecondary
                        }}
                      >
                        <Typography>No seasonal data available</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {activeTab === 2 && (
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                    Customer Segment Analysis
                  </Typography>
                  <Grid container spacing={3}>
                    {salesPerformance?.segments.map((segment) => (
                      <Grid item xs={12} md={4} key={segment.name}>
                        <MetricCard>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              mb: 1
                            }}
                          >
                            <Users
                              size={20}
                              style={{ color: CHART_COLORS[segment.color] || navColors.accent }}
                            />
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600 }}
                            >
                              {segment.name}
                            </Typography>
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: 700, mb: 0.5 }}
                          >
                            {segment.revenue}%
                          </Typography>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Revenue Share
                          </Typography>
                        </MetricCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </StyledPaper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SalesStrategy;
