import { useMemo, useEffect, useRef } from 'react';

export const useAudioSupport = () => {
  // Referencia para mantener el estado entre renderizados
  const supportStateRef = useRef(null);

  const checkAudioSupport = () => {
    try {
      // Verificar APIs críticas de forma directa
      const hasMediaRecorder = typeof MediaRecorder !== 'undefined';
      const hasSpeechRecognition = typeof window.SpeechRecognition !== 'undefined' || 
                                  typeof window.webkitSpeechRecognition !== 'undefined';
      const hasAudioContext = typeof (window.AudioContext || window.webkitAudioContext) !== 'undefined';
      const hasMediaDevices = !!(navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function');

      // Verificación específica de Safari iOS usando características en lugar de user agent
      const isSafariIOS = Boolean(
        window.webkit?.messageHandlers && // Característica específica de WebKit
        !window.chrome && // No Chrome
        navigator.vendor === 'Apple Computer, Inc.' // Vendor de Safari
      );

      // Verificar soporte de codec opus
      let hasCodecSupport = false;
      try {
        if (MediaRecorder && MediaRecorder.isTypeSupported) {
          hasCodecSupport = MediaRecorder.isTypeSupported('audio/webm;codecs=opus');
        }
      } catch (e) {
        console.debug('Codec check error:', e);
      }

      // Estado de soporte general
      const isSupported = Boolean(
        hasMediaRecorder &&
        hasSpeechRecognition &&
        hasAudioContext &&
        hasMediaDevices &&
        hasCodecSupport &&
        !isSafariIOS
      );

      // Solo logueamos si estamos en desarrollo
      if (process.env.NODE_ENV === 'development') {
        console.debug('Audio Support Check:', {
          hasMediaRecorder,
          hasSpeechRecognition,
          hasAudioContext,
          hasMediaDevices,
          hasCodecSupport,
          isSafariIOS,
          isSupported,
          timestamp: new Date().toISOString()
        });
      }

      return isSupported;
    } catch (error) {
      console.warn('Error checking audio support:', error);
      return false;
    }
  };

  // Usamos useMemo para cachear el resultado y evitar cálculos innecesarios
  const isSupported = useMemo(() => {
    // Si ya tenemos un estado previo, lo usamos
    if (supportStateRef.current !== null) {
      return supportStateRef.current;
    }

    // Si no, hacemos la verificación
    const support = checkAudioSupport();
    supportStateRef.current = support;
    return support;
  }, []);

  // Efecto para actualizar el soporte cuando la ventana gane foco
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const newSupport = checkAudioSupport();
        if (newSupport !== supportStateRef.current) {
          supportStateRef.current = newSupport;
        }
      }
    };

    const handleFocus = () => {
      const newSupport = checkAudioSupport();
      if (newSupport !== supportStateRef.current) {
        supportStateRef.current = newSupport;
      }
    };

    // Suscribirse a eventos
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      // Limpieza
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return isSupported;
};

export default useAudioSupport;