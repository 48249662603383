import React from 'react';
import { Dialog, DialogContent, Typography, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { X, MonitorCheck } from 'lucide-react';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    padding: '24px',
    maxWidth: '400px',
    margin: '16px',
    backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1e' : '#ffffff',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: '16px',
  backgroundColor: theme.palette.mode === 'dark' ? '#2c2c2e' : '#f5f5f7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto 24px auto', // Centra el cuadro horizontalmente y añade margen inferior
  '& svg': {
    fontSize: '32px',
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#1d1d1f',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '12px',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#1d1d1f',
  textAlign: 'center',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  lineHeight: 1.5,
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
  textAlign: 'center',
  marginBottom: '24px',
}));

const IncompatibilityModal = ({ open, onClose }) => {
  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      aria-labelledby="incompatibility-dialog-title"
    >
      <CloseButton onClick={onClose} aria-label="close">
        <X size={24} />
      </CloseButton>

      <DialogContent 
        sx={{ 
          textAlign: 'center', 
          pt: 2, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', // Centra el contenido horizontalmente
        }}
      >
        <IconWrapper>
          <MonitorCheck size={32} />
        </IconWrapper>

        <Title id="incompatibility-dialog-title">
          Desktop Version Required
        </Title>

        <Description>
          Voice recording is currently only supported in the desktop version of AGT. 
          For the best experience, please use Chrome, Firefox, or Edge on your computer.
        </Description>

        <Typography 
          variant="caption" 
          sx={{ 
            display: 'block',
            color: 'text.secondary',
            mt: 2 
          }}
        >
          We're working on expanding support to more platforms.
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};

export default IncompatibilityModal;
