import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { 
  FileText, 
  FileSpreadsheet, 
  FileImage, 
  FileCode,
  File,
  X 
} from 'lucide-react';
import FilePreviewModal from './FilePreviewModal';

const FilePreviewItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fileType',
})(({ theme, fileType }) => ({
  position: 'relative',
  width: '100px',
  height: '100px',
  borderRadius: '8px',
  overflow: 'hidden',
  flexShrink: 0,
  background: (() => {
    const baseColor = (() => {
      switch (fileType) {
        case 'pdf':
          return '#FF4D4D';  // Red for PDF
        case 'excel':
          return '#4CAF50';  // Green for Excel/CSV
        case 'image':
          return '#9C27B0';  // Purple for images
        case 'text':
          return '#757575';  // Gray for text files
        case 'word':
          return '#0385FF';  // Blue for Word
        default:
          return '#FFC107';  // Yellow para archivos desconocidos
      }
    })();
    return `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), ${baseColor}`;
  })(),
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)'
  }`,
  transition: 'transform 0.2s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#ffffff',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.02)',
    '& .remove-button': {
      opacity: 1,
    },
  },
}));

const FileIcon = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  marginBottom: '4px',
});

const FileLabel = styled(Box)({
  fontSize: '12px',
  fontWeight: '500',
  color: '#ffffff',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '80px',
  textAlign: 'center',
  padding: '0 4px',
});

const RemoveButton = styled('button')({
  position: 'absolute',
  top: '4px',
  right: '4px',
  padding: '4px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  opacity: 0,
  transition: 'all 0.2s ease',
  zIndex: 2,
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

function getFileExtension(filename = '') {
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop().toLowerCase() : '';
}

function getFileLabelAndIcon(file) {
  const ext = getFileExtension(file?.name || '');
  const iconProps = { size: 32, color: '#fff' };

  switch (ext) {
    case 'pdf':
      return { 
        label: 'PDF', 
        icon: <FileText {...iconProps} />,
        type: 'pdf'
      };
    case 'doc':
    case 'docx':
      return { 
        label: 'DOC', 
        icon: <FileText {...iconProps} />,
        type: 'word'
      };
    case 'xls':
    case 'xlsx':
    case 'csv':
      return { 
        label: 'XLS', 
        icon: <FileSpreadsheet {...iconProps} />,
        type: 'excel'
      };
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'webp':
      return { 
        label: 'IMG', 
        icon: <FileImage {...iconProps} />,
        type: 'image'
      };
    case 'txt':
    case 'odc':
      return { 
        label: 'TXT', 
        icon: <FileCode {...iconProps} />,
        type: 'text'
      };
    default:
      return { 
        label: ext.toUpperCase() || 'FILE', 
        icon: <File {...iconProps} />,
        type: 'default'
      };
  }
}

const FileThumbnail = ({ file, onRemove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove?.(file);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const { label, icon, type } = getFileLabelAndIcon(file);

  return (
    <>
      <FilePreviewItem onClick={handleClick} fileType={type}>
        <FileIcon>
          {icon}
        </FileIcon>
        <FileLabel>{label}</FileLabel>

        <RemoveButton className="remove-button" onClick={handleRemove}>
          <X size={16} color="#fff" />
        </RemoveButton>
      </FilePreviewItem>

      <FilePreviewModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        file={file}
      />
    </>
  );
};

export default FileThumbnail;