import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Chip, 
  Avatar, 
  AvatarGroup, 
  TextField,
  useMediaQuery,
  Tabs,
  Tab 
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  Brain, 
  MessageSquare,
  FileText,
  TrendingUp,
  PieChart,
  Target,
  Clock,
  Calendar,
  Book,
  Lightbulb,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const ExpertCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const ConsultationCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(40, 40, 42, 0.94)'
    : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(118, 118, 128, 0.24)'
    : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow: theme.palette.mode === 'dark'
    ? 'none'
    : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)'
    : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  }
}));

const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  }
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background: theme.palette.mode === 'dark' 
      ? '#1C1C1E'
      : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden',
  }
}));

const AGTCouncil = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(0);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);
  const [consultationText, setConsultationText] = useState('');

  const navigationTabs = [
    { id: 0, label: 'Consult', disabled: false },
    { id: 1, label: 'Recent', disabled: false },
    { id: 2, label: 'Stats', disabled: false },
    { id: 3, label: 'Sessions', disabled: false }
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView !== isMobile) {
        setVisibleRange({ start: 0, end: 3 });
      }
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleRange({ start: 0, end: navigationTabs.length });
      return;
    }

    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return;

    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
    } else if (activeIdx >= navigationTabs.length - 3) {
      setVisibleRange({ 
        start: navigationTabs.length - 3, 
        end: navigationTabs.length 
      });
    } else {
      setVisibleRange({
        start: activeIdx - 1,
        end: activeIdx + 2
      });
    }
  }, [activeTab, navigationTabs.length, isMobile]);

  const getIndicatorStyle = useCallback(() => {
    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return {};
    
    const visibleIdx = activeIdx - visibleRange.start;
    const offset = visibleIdx * (74 + 4);
    return {
      transform: `translateX(${offset}px)`,
    };
  }, [activeTab, visibleRange.start, navigationTabs]);

  const handleTabChange = useCallback((event, newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      const tab = navigationTabs.find(tab => tab.id === newValue);
      
      if (tab?.disabled) {
        return;
      }

      lastNavigationTime.current = now;
      setActiveTab(newValue);
    }
  }, [navigationTabs]);

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Start a Consultation</Typography>
                <Box className="space-y-4">
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Describe your business challenge or decision you need help with..."
                    value={consultationText}
                    onChange={(e) => setConsultationText(e.target.value)}
                  />
                  <Box className="flex gap-2">
                    <ActionButton>
                      <Brain size={16} />
                      Get Expert Advice
                    </ActionButton>
                    <ActionButton>
                      <Calendar size={16} />
                      Schedule Meeting
                    </ActionButton>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Expert Panel</Typography>
                <Box className="space-y-3">
                  <ExpertCard>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>JD</Avatar>
                    <Box>
                      <Typography variant="subtitle2">Dr. John Doe</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Business Strategy
                      </Typography>
                    </Box>
                  </ExpertCard>
                  <ExpertCard>
                    <Avatar sx={{ bgcolor: 'secondary.main' }}>MS</Avatar>
                    <Box>
                      <Typography variant="subtitle2">Maria Smith</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Market Analysis
                      </Typography>
                    </Box>
                  </ExpertCard>
                  <ExpertCard>
                    <Avatar sx={{ bgcolor: 'error.main' }}>RJ</Avatar>
                    <Box>
                      <Typography variant="subtitle2">Robert Johnson</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Financial Advisory
                      </Typography>
                    </Box>
                  </ExpertCard>
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Recent Consultations</Typography>
            <ConsultationCard>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className="flex justify-between items-start mb-2">
                    <Box>
                      <Typography variant="subtitle1">Market Expansion Strategy</Typography>
                      <Typography variant="caption" color="text.secondary">
                        <Clock size={14} className="inline mr-1" />
                        Completed 2 hours ago
                      </Typography>
                    </Box>
                    <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
                      <Avatar>JD</Avatar>
                      <Avatar>MS</Avatar>
                      <Avatar>RJ</Avatar>
                    </AvatarGroup>
                  </Box>
                  <Box className="mt-3 space-y-2">
                    <Typography variant="body2">
                      Comprehensive analysis of potential markets and entry strategies...
                    </Typography>
                    <Box className="flex gap-2">
                      <Chip size="small" label="Strategy" />
                      <Chip size="small" label="Market Analysis" />
                      <Chip size="small" label="Growth" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ConsultationCard>
            <ConsultationCard>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className="flex justify-between items-start mb-2">
                    <Box>
                      <Typography variant="subtitle1">Product Pricing Review</Typography>
                      <Typography variant="caption" color="text.secondary">
                        <Clock size={14} className="inline mr-1" />
                        Completed yesterday
                      </Typography>
                    </Box>
                    <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
                      <Avatar>MS</Avatar>
                      <Avatar>RJ</Avatar>
                    </AvatarGroup>
                  </Box>
                  <Box className="mt-3 space-y-2">
                    <Typography variant="body2">
                      Analysis of current pricing strategy and recommendations...
                    </Typography>
                    <Box className="flex gap-2">
                      <Chip size="small" label="Pricing" />
                      <Chip size="small" label="Revenue" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ConsultationCard>
          </StyledPaper>
        );

      case 2:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Quick Stats</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box className="p-3 bg-blue-50 rounded-lg text-center">
                  <Brain size={24} className="mx-auto mb-1 text-blue-500" />
                  <Typography variant="h6">24</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Consultations
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-green-50 rounded-lg text-center">
                  <Lightbulb size={24} className="mx-auto mb-1 text-green-500" />
                  <Typography variant="h6">89%</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Success Rate
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-purple-50 rounded-lg text-center">
                  <Clock size={24} className="mx-auto mb-1 text-purple-500" />
                  <Typography variant="h6">2.5h</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Avg. Response
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="p-3 bg-yellow-50 rounded-lg text-center">
                  <Book size={24} className="mx-auto mb-1 text-yellow-500" />
                  <Typography variant="h6">15</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Reports
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        );

      case 3:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Upcoming Sessions</Typography>
            <Box className="space-y-3">
              <Box className="p-3 border rounded-lg">
                <Box className="flex justify-between items-center mb-1">
                  <Typography variant="subtitle2">Strategy Review</Typography>
                  <Chip label="Today" color="primary" size="small" />
                </Box>
                <Typography variant="caption" color="text.secondary">
                  2:30 PM - 3:30 PM
                </Typography>
              </Box>
              <Box className="p-3 border rounded-lg">
                <Box className="flex justify-between items-center mb-1">
                  <Typography variant="subtitle2">Financial Planning</Typography>
                  <Chip label="Tomorrow" color="secondary" size="small" />
                </Box>
                <Typography variant="caption" color="text.secondary">
                  10:00 AM - 11:00 AM
                </Typography>
              </Box>
            </Box>
          </StyledPaper>
        );

      default:
        return null;
    }
  }, [activeTab, consultationText]);

  return (
    <Box className="space-y-4">
      <NavigationContainer>
        <Box>
          {isMobile && activeTab !== 0 && (
            <NavigationButton
              onClick={() => handleTabChange(null, 0)}
              aria-label="Go back"
            >
              <ChevronLeft size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}

          <StyledTabs
            ref={tabsRef}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="council navigation tabs"
          >
            <TabIndicator style={getIndicatorStyle()} />
            {navigationTabs
              .slice(visibleRange.start, visibleRange.end)
              .map((tab) => (
                <StyledTab 
                  key={tab.id}
                  label={tab.label}
                  disabled={tab.disabled}
                  disableRipple
                  value={tab.id}
                />
              ))}
          </StyledTabs>

          {isMobile && visibleRange.end < navigationTabs.length && (
            <NavigationButton
              onClick={() => {
                const nextTab = navigationTabs[visibleRange.end];
                handleTabChange(null, nextTab.id);
              }}
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}
        </Box>
      </NavigationContainer>

      {renderContent()}
    </Box>
  );
};

export default AGTCouncil;