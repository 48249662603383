// AGTVoice.js
import React, { useEffect, useRef, useState, useCallback } from 'react';

const VOICE_STATES = {
  IDLE: 'IDLE',
  PREPARING: 'PREPARING', 
  PLAYING: 'PLAYING',
  CLEANING: 'CLEANING'
};

const AGTVoice = ({ message, onVoiceEnd, isEnabled, onError, onPlayStart }) => {
  const audioRef = useRef(null);
  const audioUrlRef = useRef(null);
  const messageIdRef = useRef(null);
  const mountedRef = useRef(true);
  const [voiceState, setVoiceState] = useState(VOICE_STATES.IDLE);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const cleanupAudio = useCallback(() => {
    if (!mountedRef.current || voiceState === VOICE_STATES.CLEANING) return;
    
    try {
      setVoiceState(VOICE_STATES.CLEANING);

      if (audioRef.current) {
        audioRef.current.onended = null;
        audioRef.current.onerror = null;
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current.load();
        audioRef.current = null;
      }

      if (audioUrlRef.current) {
        URL.revokeObjectURL(audioUrlRef.current);
        audioUrlRef.current = null;
      }

      if (mountedRef.current) {
        onPlayStart?.(false);
      }

      messageIdRef.current = null;

      if (mountedRef.current) {
        setVoiceState(VOICE_STATES.IDLE);
      }
    } catch (error) {
      if (mountedRef.current) {
        setVoiceState(VOICE_STATES.IDLE);
      }
    }
  }, [onPlayStart, voiceState]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (voiceState !== VOICE_STATES.IDLE || !mountedRef.current) return;

      const conditions = {
        isEnabled,
        hasMessage: !!message,
        hasAudioBlob: !!message?.audioBlob,
        hasId: !!message?.id,
        isNotUser: !message?.isUser,
        isMounted: mountedRef.current
      };

      // Verifica que todos sean true
      if (!Object.values(conditions).every(Boolean)) {
        return;
      }

      try {
        setVoiceState(VOICE_STATES.PREPARING);

        let audioBlob;
        if (message.audioBlob && message.audioBlob.type === 'Buffer') {
          const uint8Array = new Uint8Array(message.audioBlob.data);
          audioBlob = new Blob([uint8Array], { type: 'audio/mpeg' });
        } else {
          audioBlob = message.audioBlob;
        }

        if (!(audioBlob instanceof Blob)) {
          throw new Error('Invalid audio format');
        }

        audioUrlRef.current = URL.createObjectURL(audioBlob);
        audioRef.current = new Audio(audioUrlRef.current);
        messageIdRef.current = message.id;

        audioRef.current.onended = () => {
          if (mountedRef.current) {
            cleanupAudio();
            onVoiceEnd?.();
          }
        };

        audioRef.current.onerror = (error) => {
          if (mountedRef.current) {
            onError?.('Error en reproducción de audio');
            cleanupAudio();
          }
        };

        if (mountedRef.current) {
          onPlayStart?.(true);
          setVoiceState(VOICE_STATES.PLAYING);
          await audioRef.current.play();
        }
      } catch (error) {
        if (mountedRef.current) {
          if (error.name === 'NotAllowedError') {
            onError?.('Reproducción de audio no permitida');
          } else if (!error.message?.includes('interrupted')) {
            onError?.('Error al iniciar audio');
          }
          cleanupAudio();
        }
      }
    };

    initializeAudio();
  }, [
    voiceState,
    message?.id,
    isEnabled,
    cleanupAudio,
    onVoiceEnd,
    onError,
    onPlayStart,
    message
  ]);

  // Cleanup on unmount o cuando isEnabled cambia a false
  useEffect(() => {
    if (!isEnabled || !mountedRef.current) {
      cleanupAudio();
    }
  }, [isEnabled, cleanupAudio]);

  return null;
};

export default React.memo(AGTVoice);
