import React from 'react';
import { Box, Select, MenuItem, Typography, Chip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Speech, ChevronDown } from 'lucide-react';

const PersonalitySelector = ({ selectedPersonality, onPersonalityChange, disabled, isPro }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const personalities = [
    {
      id: 'normal',
      name: 'Neutral',
      description: 'Default assistant behavior',
      proOnly: false
    },
    {
      id: 'charismatic',
      name: 'Charismatic',
      description: 'Warm, sociable with high emotional energy',
      proOnly: true
    },
    {
      id: 'stoic',
      name: 'Stoic',
      description: 'Logical, direct and professionally oriented',
      proOnly: true
    }
  ];

  const CustomChevronIcon = () => {
    return (
      <ChevronDown
        size={20}
        style={{
          color: disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.mode === 'dark' ? '#fff' : '#000',
          opacity: 0.7,
          marginRight: '8px'
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(50% - 4px)',
          minWidth: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
          width: '200px',
        },
        '&:hover': {
          '& .MuiSelect-select': {
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.05)' 
              : 'rgba(0, 0, 0, 0.03)'
          }
        }
      }}
    >
      <Speech
        size={16}
        style={{
          position: 'absolute',
          left: '12px',
          color: '#0385FF',
          zIndex: 1
        }}
      />

      <Select
        value={selectedPersonality}
        onChange={(e) => onPersonalityChange(e.target.value)}
        disabled={disabled}
        IconComponent={CustomChevronIcon}
        sx={{
          width: '100%',
          '& .MuiSelect-select': {
            paddingLeft: '40px !important',
            paddingRight: '32px !important',
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
            backgroundColor: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.03)' 
              : 'rgba(0, 0, 0, 0.02)',
            borderRadius: '16px',
            border: `1px solid ${theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.1)' 
              : 'rgba(0, 0, 0, 0.08)'}`,
            transition: 'all 0.2s ease',
            '&:focus': {
              backgroundColor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.05)' 
                : 'rgba(0, 0, 0, 0.03)',
              borderColor: '#0385FF',
              boxShadow: `0 0 0 2px #0385FF25`
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiSelect-select .MuiTypography-root': {
            whiteSpace: 'normal'
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              mt: 1,
              borderRadius: '16px',
              backgroundColor: theme.palette.mode === 'dark' 
                ? '#1a1a1a' 
                : '#ffffff',
              boxShadow: theme.palette.mode === 'dark'
                ? '0 4px 20px rgba(0, 0, 0, 0.5)'
                : '0 4px 20px rgba(0, 0, 0, 0.15)',
              border: `1px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.08)'}`,
              '& .MuiMenuItem-root': {
                padding: '12px 16px',
                borderRadius: '12px',
                margin: '4px',
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'rgba(0, 0, 0, 0.03)'
                }
              }
            }
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top'
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom'
          }
        }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 500, whiteSpace: 'nowrap' }}>
                  Choose personality
                </Typography>
                <Typography sx={{ fontSize: '0.75rem', color: 'text.secondary', whiteSpace: 'nowrap' }}>
                  Neutral is default
                </Typography>
              </Box>
            );
          }

          const selectedPersonality = personalities.find(p => p.id === selected);
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 500, whiteSpace: 'nowrap' }}>
                {selectedPersonality?.name}
              </Typography>
              {!isPro && selectedPersonality?.proOnly && (
                <Typography sx={{ fontSize: '0.75rem', color: 'text.secondary', whiteSpace: 'nowrap' }}>
                  Upgrade to Pro
                </Typography>
              )}
            </Box>
          );
        }}
      >
        {personalities.map((personality) => (
          <MenuItem
            key={personality.id}
            value={personality.id}
            disabled={personality.proOnly && !isPro}
            sx={{
              opacity: personality.proOnly && !isPro ? 0.5 : 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                {personality.name}
              </Typography>
              {personality.proOnly && (
                <Chip
                  label="PRO"
                  size="small"
                  sx={{
                    height: '20px',
                    backgroundColor: '#0385FF',
                    color: 'white',
                    fontSize: '0.625rem',
                    fontWeight: 600,
                    borderRadius: '10px',
                    '& .MuiChip-label': {
                      px: 1,
                    }
                  }}
                />
              )}
            </Box>
            <Typography sx={{ fontSize: '0.75rem', color: 'text.secondary', mt: 0.5 }}>
              {personality.description}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default PersonalitySelector;