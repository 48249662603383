import React from 'react';
import { Box, Typography, Paper, Fade, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Globe, Clock, ArrowRight } from 'lucide-react';
import AGTFound from './AGTFound';

const ResultContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  maxWidth: '800px',
  margin: '0 auto',
  marginTop: theme.spacing(2)
}));

const ModuleRecommendation = styled(Paper)(({ theme, priority, color }) => ({
  padding: theme.spacing(3),
  backgroundColor: `${color}15`,
  borderRadius: theme.shape.borderRadius * 2,
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  border: `1px solid ${color}30`,
  '&:hover': {
    backgroundColor: `${color}25`,
    transform: 'translateY(-2px)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '4px',
    height: '100%',
    backgroundColor: color || (
      priority === 'HIGH' ? '#4CAF50' : 
      priority === 'MEDIUM' ? '#FFA726' : 
      '#78909C'
    )
  }
}));

const UrlPath = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.5)',
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5)
}));

const PriorityBadge = styled(Box)(({ theme, priority }) => ({
  display: 'inline-flex',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius * 4,
  fontSize: '0.75rem',
  fontWeight: 500,
  marginLeft: theme.spacing(2),
  backgroundColor: priority === 'HIGH' ? 'rgba(76, 175, 80, 0.2)' : 
                  priority === 'MEDIUM' ? 'rgba(255, 167, 38, 0.2)' : 
                  'rgba(120, 144, 156, 0.2)',
  color: priority === 'HIGH' ? '#4CAF50' : 
         priority === 'MEDIUM' ? '#FFA726' : 
         '#78909C'
}));

const MetaData = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(2),
  color: 'rgba(255, 255, 255, 0.6)',
  fontSize: '0.875rem'
}));

const LoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(8),
  gap: theme.spacing(2),
  color: 'rgba(255, 255, 255, 0.7)'
}));

const moduleColors = {
  product: '#415B7E',
  cashflow: '#DC9844',
  council: '#4A4F81',
  supplier: '#25505A',
  growth: '#FFB692',
  profile: '#BC4C4A'
};

const AGTFoundResults = ({ 
  results = [], 
  onResultClick = () => {}, 
  isLoading = false,
  searchQuery = '',
  onSearchChange = () => {},
  onSearchResults = () => {},
  setIsSearching = () => {}
}) => {
  const showResults = results && results.length > 0;
  const showLoader = isLoading || (!showResults && searchQuery);

  return (
    <ResultContainer>
      <Box sx={{ mb: 4 }}>
        <AGTFound
          value={searchQuery}
          onChange={onSearchChange}
          onResults={onSearchResults}
          setIsSearching={setIsSearching}
          placeholder="Realiza una nueva búsqueda..."
        />
      </Box>

      <Fade in={true}>
        <Box>
          {showLoader && (
            <LoaderContainer>
              <CircularProgress size={40} />
              <Typography variant="body1">
                Buscando resultados...
              </Typography>
            </LoaderContainer>
          )}

          {showResults && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography 
                sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontSize: '0.9rem',
                  mb: 2 
                }}
              >
                {results.length} resultado{results.length !== 1 ? 's' : ''} encontrado{results.length !== 1 ? 's' : ''}
              </Typography>

              {results.map((result, index) => (
                <ModuleRecommendation 
                  key={index}
                  priority={result.priority}
                  color={moduleColors[result.moduleId.toLowerCase()]}
                  onClick={() => onResultClick({ id: result.moduleId })}
                  elevation={0}
                >
                  <UrlPath>
                    <Globe size={16} />
                    <span>agtapp.com › ups › {result.moduleId.toLowerCase()}</span>
                  </UrlPath>

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                    <Typography 
                      variant="h6" 
                      className="result-title"
                      sx={{ 
                        color: 'white',
                        fontWeight: 500,
                        letterSpacing: '0.5px'
                      }}
                    >
                      AGT {result.moduleId.charAt(0).toUpperCase() + result.moduleId.slice(1)}
                    </Typography>
                    <PriorityBadge priority={result.priority}>
                      {result.priority}
                    </PriorityBadge>
                  </Box>

                  <Typography 
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '0.9rem',
                      lineHeight: 1.5,
                      mb: 1
                    }}
                  >
                    {result.reason}
                  </Typography>

                  <Box 
                    sx={{ 
                      mt: 2,
                      p: 1.5,
                      borderRadius: 1,
                      backgroundColor: 'rgba(255, 255, 255, 0.05)'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ArrowRight size={18} color="rgba(255, 255, 255, 0.7)" />
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          color: 'rgba(255, 255, 255, 0.7)',
                          fontWeight: 500
                        }}
                      >
                        Siguiente paso:
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        ml: 3.5,
                        mt: 0.5,
                        fontSize: '0.875rem',
                        color: 'rgba(255, 255, 255, 0.6)'
                      }}
                    >
                      {result.nextStep}
                    </Typography>
                  </Box>

                  <MetaData>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1
                    }}>
                      <Clock size={16} />
                      <Typography variant="body2">
                        Actualizado recientemente
                      </Typography>
                    </Box>
                  </MetaData>
                </ModuleRecommendation>
              ))}
            </Box>
          )}
        </Box>
      </Fade>
    </ResultContainer>
  );
};

export default AGTFoundResults;