/**
 * AGTMakerExportDocx.js
 *
 * Versión orientada a docx (v8+), replicando la lógica principal de AGTMakerExport.js
 * pero adaptada a la librería "docx".
 */

import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    HeadingLevel,
    AlignmentType,
    Media,
    Table,
    TableRow,
    TableCell,
    WidthType,
    BorderStyle,
    ShadingType,
  } from 'docx';
  
  import { saveAs } from 'file-saver'; // Para guardar el .docx en el navegador
  
  /**
   * Lógica de parseo de contenido Markdown línea a línea.
   * (Igual que en tu PDFExporter)
   */
  function parseMarkdownBlocks(content) {
    const lines = content.split('\n');
    let blocks = [];
    let currentBlock = [];
    let inCodeBlock = false;
    let codeFenceLanguage = '';
  
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
  
      // 1) Detectar inicio/cierre de bloque de código con triple backticks: ```
      const codeFenceMatch = line.match(/^```(\S*)/);
  
      if (codeFenceMatch) {
        if (inCodeBlock) {
          // Cierre de bloque
          const codeContent = currentBlock.join('\n');
          blocks.push({
            type: 'code',
            language: codeFenceLanguage,
            content: codeContent,
          });
  
          // Reset
          currentBlock = [];
          inCodeBlock = false;
          codeFenceLanguage = '';
        } else {
          // Apertura de bloque
          inCodeBlock = true;
          codeFenceLanguage = codeFenceMatch[1].trim();
          currentBlock = [];
        }
      } else if (inCodeBlock) {
        // Dentro de un bloque de código
        currentBlock.push(line);
      } else {
        // Fuera de bloque de código
        if (line.trim() === '') {
          // Fin de párrafo
          if (currentBlock.length > 0) {
            blocks.push({
              type: 'paragraph',
              content: currentBlock.join('\n'),
            });
            currentBlock = [];
          }
        } else {
          currentBlock.push(line);
        }
      }
    }
  
    // Al final, si queda algo, lo empujamos como párrafo
    if (currentBlock.length > 0) {
      blocks.push({
        type: 'paragraph',
        content: currentBlock.join('\n'),
      });
    }
  
    return blocks;
  }
  
  /**
   * Clase principal para generar un DOCX con estructura equivalente a PDFExporter,
   * usando la librería "docx" (v8+).
   */
  class DOCXExporter {
    constructor(title = '', author = '', institution = '', date = '') {
      // Metadatos básicos
      this.title = title || 'Document';
      this.author = author || 'AGT Maker';
      this.institution = institution || '';
      this.date = date || new Date().toLocaleDateString();
  
      // Contadores
      this.tableCount = 0;
      this.figureCount = 0;
      this.equationCount = 0;
  
      // Aquí guardaremos todo el contenido (párrafos, tablas, etc.)
      this.content = [];
  
      // Aquí guardaremos entradas de TOC si quieres generarla manualmente
      this.tocEntries = [];
    }
  
    /**
     * Añade una "portada" básica.
     */
    addCoverPage() {
      const coverParagraphs = [];
  
      // Título principal
      if (this.title) {
        coverParagraphs.push(
          new Paragraph({
            text: this.title.toUpperCase(),
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            spacing: { after: 400 },
          })
        );
      }
  
      // Autor
      if (this.author) {
        coverParagraphs.push(
          new Paragraph({
            text: this.author,
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
            spacing: { after: 200 },
          })
        );
      }
  
      // Institución
      if (this.institution) {
        coverParagraphs.push(
          new Paragraph({
            text: this.institution,
            heading: HeadingLevel.HEADING_3,
            alignment: AlignmentType.CENTER,
            spacing: { after: 200 },
          })
        );
      }
  
      // Fecha
      coverParagraphs.push(
        new Paragraph({
          text: this.date,
          heading: HeadingLevel.HEADING_4,
          alignment: AlignmentType.CENTER,
          spacing: { after: 200 },
        })
      );
  
      this.content.push(...coverParagraphs);
  
      // Emulamos un salto de página (simplemente un Paragraph con pageBreakBefore)
      this.content.push(
        new Paragraph({
          pageBreakBefore: true,
        })
      );
    }
  
    /**
     * Añade página de Table of Contents (TOC) (placeholder).
     */
    addTableOfContentsPage() {
      this.content.push(
        new Paragraph({
          text: 'Table of Contents',
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
          spacing: { after: 300 },
          pageBreakBefore: true,
        })
      );
  
      // Placeholder
      this.content.push(
        new Paragraph({
          text: '--- (Se generará al final) ---',
          alignment: AlignmentType.CENTER,
          spacing: { after: 300 },
        })
      );
    }
  
    /**
     * Genera la TOC manualmente (no autogenerada).
     */
    generateTableOfContents() {
      // Título "Table of Contents"
      this.content.push(
        new Paragraph({
          text: 'Table of Contents',
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
          spacing: { after: 300 },
          pageBreakBefore: true,
        })
      );
  
      // Convertimos las tocEntries a párrafos
      this.tocEntries.forEach(({ text, level }) => {
        const indent = level > 1 ? (level - 1) * 500 : 0;
        this.content.push(
          new Paragraph({
            children: [new TextRun({ text })],
            spacing: { after: 100 },
            indent: { left: indent },
          })
        );
      });
    }
  
    /**
     * Añade un párrafo con soporte básico para negritas `**...**`.
     */
    addParagraph(text) {
      const tokens = text.split(/\*\*(.*?)\*\*/g);
      const children = tokens.map((token, index) => {
        const isBold = index % 2 === 1;
        return new TextRun({ text: token, bold: isBold });
      });
  
      const paragraph = new Paragraph({
        children,
        spacing: { after: 200 },
      });
      this.content.push(paragraph);
    }
  
    /**
     * Añade cita en fuente más pequeña, italic, indentada.
     */
    addCitation(text) {
      this.content.push(
        new Paragraph({
          children: [
            new TextRun({
              text,
              italics: true,
              size: 20, // 10pt => docx usa "half-points"
            }),
          ],
          indent: { left: 720 }, // ~1cm
          spacing: { after: 200 },
        })
      );
    }
  
    /**
     * Añade una tabla simple.
     */
    addTable(headers, rows, title = '') {
      this.tableCount++;
      if (title) {
        this.content.push(
          new Paragraph({
            text: `Table ${this.tableCount}: ${title}`,
            alignment: AlignmentType.CENTER,
            spacing: { after: 200 },
          })
        );
      }
  
      // Construimos la tabla
      const tableRows = [];
  
      // Encabezados
      const headerCells = headers.map((h) => {
        return new TableCell({
          children: [new Paragraph({ text: h, bold: true })],
          shading: {
            type: ShadingType.CLEAR,
            fill: 'D3D3D3', // gris clarito
          },
        });
      });
      tableRows.push(new TableRow({ children: headerCells }));
  
      // Filas
      rows.forEach((row) => {
        const cells = row.map((cell) => {
          return new TableCell({
            children: [new Paragraph({ text: String(cell) })],
          });
        });
        tableRows.push(new TableRow({ children: cells }));
      });
  
      const table = new Table({
        rows: tableRows,
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          top: { style: BorderStyle.SINGLE, size: 1 },
          bottom: { style: BorderStyle.SINGLE, size: 1 },
          left: { style: BorderStyle.SINGLE, size: 1 },
          right: { style: BorderStyle.SINGLE, size: 1 },
          insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
          insideVertical: { style: BorderStyle.SINGLE, size: 1 },
        },
      });
  
      this.content.push(table);
      // Espacio extra (salto "suave")
      this.content.push(new Paragraph({ text: '', spacing: { after: 200 } }));
    }
  
    /**
     * Añade imagen base64 como figura.
     */
    addImage(dataUrl, caption = '', source = '') {
      this.figureCount++;
  
      if (caption) {
        this.content.push(
          new Paragraph({
            text: `Figure ${this.figureCount}: ${caption}`,
            alignment: AlignmentType.CENTER,
            spacing: { after: 200 },
          })
        );
      }
  
      // docx necesita un buffer
      const base64Data = dataUrl.replace(/^data:image\/\w+;base64,/, '');
      const imageBuffer = Buffer.from(base64Data, 'base64');
      const imageRun = Media.addImage(
        // El primer argumento es el "Document", pero lo construiremos en save().
        // => Para simplificar, guardamos la info en un array, ver "Hack" más abajo
        // En docx v8, lo normal es:  Media.addImage(currentDoc, imageBuffer)
        // Pero no tenemos "currentDoc" aún, lo generaremos en save().
        // => Haremos "arrastrar" la data para cuando creemos el doc.
        null, // <--- Hack temporal: lo "inyectaremos" en save() con un replace
        imageBuffer
      );
  
      // Reemplazamos el "document" en .save()
      const paragraphWithImage = new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [imageRun],
      });
  
      this.content.push(paragraphWithImage);
  
      if (source) {
        this.content.push(
          new Paragraph({
            text: `Source: ${source}`,
            italic: true,
            alignment: AlignmentType.CENTER,
            spacing: { after: 200 },
          })
        );
      }
  
      return this.figureCount;
    }
  
    /**
     * Añade ecuación como texto (placeholder).
     */
    addEquation(latex, numbered = true) {
      if (numbered) this.equationCount++;
      const eqText = `[${latex}]${numbered ? ` (${this.equationCount})` : ''}`;
      const eqParagraph = new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [new TextRun(eqText)],
        spacing: { after: 200 },
      });
      this.content.push(eqParagraph);
    }
  
    /**
     * Bullet list.
     */
    addBulletList(items, level = 0) {
      items.forEach((item) => {
        this.content.push(
          new Paragraph({
            text: item,
            bullet: { level },
            spacing: { after: 100 },
          })
        );
      });
      this.content.push(new Paragraph({ text: '', spacing: { after: 200 } }));
    }
  
    /**
     * Numbered list.
     */
    addNumberedList(items, level = 0) {
      items.forEach((item) => {
        this.content.push(
          new Paragraph({
            text: item,
            numbering: {
              reference: 'my-numbering-ref',
              level,
            },
            spacing: { after: 100 },
          })
        );
      });
      this.content.push(new Paragraph({ text: '', spacing: { after: 200 } }));
    }
  
    /**
     * Imprimir un bloque de código (simplificado).
     */
    printCodeBlock(codeString) {
      const lines = codeString.split('\n');
      lines.forEach((line) => {
        const paragraph = new Paragraph({
          children: [
            new TextRun({
              text: line,
              font: 'Courier New',
              size: 20, // 10pt
            }),
          ],
          shading: {
            type: ShadingType.CLEAR,
            fill: 'F5F5F5',
          },
          spacing: { after: 100 },
        });
        this.content.push(paragraph);
      });
      this.content.push(new Paragraph({ text: '', spacing: { after: 200 } }));
    }
  
    /**
     * Al final, creamos el Document con las secciones y guardamos.
     */
    async save(filename = 'document.docx') {
      // 1) Creamos un Document en docx v8, indicando las secciones.
      //    Nota: "sections" es un array donde cada sección tiene un children: []
      //    y ya no necesitamos "this.doc = new Document(...)" en el constructor.
      //    En docx v8 es obligatorio (o al menos habitual) definir sections.
      const doc = new Document({
        creator: this.author,
        title: this.title,
        description: this.institution,
        // Podemos colocar las secciones en un solo array si no necesitas multiples secciones
        sections: [
          {
            children: this.content.map((child) => {
              // Si el child es un "Paragraph" con "Media.addImage(null, ...)" => inyectar doc
              // Hack: Reemplazar "null" con "doc"
              if (child.options && child.options.children && child.options.children.length > 0) {
                child.options.children.forEach((run) => {
                  if (run.type === 'image' && run.data && run.data.document === null) {
                    run.data.document = doc; // inyectamos el doc
                  }
                });
              }
              return child;
            }),
          },
        ],
      });
  
      // 2) Convertimos a Blob
      const blob = await Packer.toBlob(doc);
      saveAs(blob, filename);
    }
  }
  
  /**
   * exportToDocx()
   * Similar a exportToPdf(), pero usando DOCXExporter.
   */
  const exportToDocx = async (content, diagramImages = {}, metadata = {}) => {
    // 1) Instanciamos el DOCXExporter
    const docx = new DOCXExporter(
      metadata.title || 'Document',
      metadata.author || '',
      metadata.institution || '',
      metadata.date || new Date().toLocaleDateString()
    );
  
    // 2) Insertamos diagramas como imágenes
    const mermaidToFigureMap = {};
    Object.entries(diagramImages).forEach(([_, image]) => {
      const figIndex = docx.addImage(image.dataUrl, image.title || '', 'Own elaboration');
      if (image.originalCode) {
        const normalizedCode = image.originalCode.trim();
        mermaidToFigureMap[normalizedCode] = figIndex;
      }
    });
  
    // 3) Parsear contenido en bloques
    const blocks = parseMarkdownBlocks(content);
  
    // 4) Procesar cada bloque
    blocks.forEach((block) => {
      if (block.type === 'code') {
        const code = block.content;
        const language = (block.language || '').toLowerCase();
  
        if (language === 'mermaid') {
          docx.printCodeBlock(code);
          // Vínculo a la figura?
          const normalizedCode = code.trim();
          const figureIndex = mermaidToFigureMap[normalizedCode];
          if (figureIndex) {
            docx.content.push(
              new Paragraph({
                text: `VÉASE FIGURA ${figureIndex}`,
                alignment: AlignmentType.CENTER,
                spacing: { after: 200 },
              })
            );
          }
        } else {
          docx.printCodeBlock(code);
        }
      } else if (block.type === 'paragraph') {
        const trimmed = block.content.trim();
  
        if (trimmed.startsWith('>')) {
          // Cita
          const quote = trimmed.replace(/^>\s+/, '');
          docx.addCitation(quote);
        } else {
          // Heading?
          const headingMatch = trimmed.match(/^#{1,5}\s+(.+)$/);
          if (headingMatch) {
            const prefix = headingMatch[0].match(/^#{1,5}/)[0];
            const level = prefix.length;
            const titleText = headingMatch[0].replace(/^#{1,5}\s+/, '').trim();
  
            let docxHeadingLevel = HeadingLevel.HEADING_1;
            switch (level) {
              case 1:
                docxHeadingLevel = HeadingLevel.HEADING_1;
                break;
              case 2:
                docxHeadingLevel = HeadingLevel.HEADING_2;
                break;
              case 3:
                docxHeadingLevel = HeadingLevel.HEADING_3;
                break;
              case 4:
                docxHeadingLevel = HeadingLevel.HEADING_4;
                break;
              case 5:
                docxHeadingLevel = HeadingLevel.HEADING_5;
                break;
              default:
                docxHeadingLevel = HeadingLevel.HEADING_1;
            }
  
            // Guardar en TOC manual si quieres
            docx.tocEntries.push({
              text: titleText,
              level,
            });
  
            docx.content.push(
              new Paragraph({
                text: titleText,
                heading: docxHeadingLevel,
                spacing: { after: 200 },
              })
            );
          } else {
            // Párrafo normal
            docx.addParagraph(trimmed);
          }
        }
      }
    });
  
    // (Opcional) docx.generateTableOfContents();
    // 5) Guardar
    await docx.save(metadata.filename || 'document.docx');
  };
  
  export default {
    DOCXExporter,
    exportToDocx,
  };
  