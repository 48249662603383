import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Chip, 
  LinearProgress, 
  Avatar,
  useMediaQuery,
  Tabs,
  Tab 
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  TrendingUp, 
  Target,
  Award,
  BarChart2,
  Users,
  Rocket,
  Flag,
  CheckCircle,
  Clock,
  AlertCircle,
  PieChart,
  Zap,
  MessageSquare,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const MilestoneCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
}));

const GrowthMetricCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  height: '100%',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(40, 40, 42, 0.94)'
      : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  },
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(118, 118, 128, 0.24)'
      : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow:
    theme.palette.mode === 'dark'
      ? 'none'
      : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  },
}));

const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  },
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background:
      theme.palette.mode === 'dark'
        ? '#1C1C1E'
        : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden',
  },
}));

const AGTGrowth = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(0);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);
  const [activeStage] = useState('Scale-Up');

  const navigationTabs = [
    { id: 0, label: 'Overview', disabled: false },
    { id: 1, label: 'Metrics', disabled: false },
    { id: 2, label: 'Strategy', disabled: false },
    { id: 3, label: 'Advisors', disabled: false },
  ];

  const growthStages = {
    'Pre-Seed': 0,
    Seed: 1,
    Early: 2,
    'Scale-Up': 3,
    Expansion: 4,
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView !== isMobile) {
        setVisibleRange({ start: 0, end: 3 });
      }
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleRange({ start: 0, end: navigationTabs.length });
      return;
    }

    const activeIdx = navigationTabs.findIndex((tab) => tab.id === activeTab);
    if (activeIdx === -1) return;

    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
    } else if (activeIdx >= navigationTabs.length - 3) {
      setVisibleRange({
        start: navigationTabs.length - 3,
        end: navigationTabs.length,
      });
    } else {
      setVisibleRange({
        start: activeIdx - 1,
        end: activeIdx + 2,
      });
    }
  }, [activeTab, navigationTabs.length, isMobile]);

  const getIndicatorStyle = useCallback(() => {
    const activeIdx = navigationTabs.findIndex((tab) => tab.id === activeTab);
    if (activeIdx === -1) return {};

    const visibleIdx = activeIdx - visibleRange.start;
    const offset = visibleIdx * (74 + 4); // 74 (ancho de cada tab) + 4 (gap)
    return {
      transform: `translateX(${offset}px)`,
    };
  }, [activeTab, visibleRange.start, navigationTabs]);

  const handleTabChange = useCallback(
    (event, newValue) => {
      const now = Date.now();
      if (now - lastNavigationTime.current >= 300) {
        const tab = navigationTabs.find((t) => t.id === newValue);

        if (tab?.disabled) {
          return;
        }

        lastNavigationTime.current = now;
        setActiveTab(newValue);
      }
    },
    [navigationTabs]
  );

  // *** Corrección: se añaden las dependencias adecuadas (activeTab, activeStage, growthStages) ***
  const renderContent = useCallback(() => {
    switch (activeTab) {
      case 0:
        return (
          <StyledPaper>
            <Box className="flex justify-between items-start mb-6">
              <Box>
                <Typography variant="h6" gutterBottom>
                  Current Growth Stage
                </Typography>
                <Typography variant="h4" className="mb-2">
                  {activeStage}
                </Typography>
                <Box className="flex gap-2">
                  <Chip icon={<Rocket size={14} />} label="High Growth" color="success" />
                  <Chip icon={<Target size={14} />} label="Series A Ready" color="primary" />
                </Box>
              </Box>
              <ActionButton>
                <BarChart2 size={16} />
                View Detailed Analysis
              </ActionButton>
            </Box>

            <Box className="mt-6">
              <Box className="flex justify-between mb-2">
                {Object.keys(growthStages).map((stage) => (
                  <Typography
                    key={stage}
                    variant="caption"
                    color={stage === activeStage ? 'primary' : 'text.secondary'}
                    sx={{ fontWeight: stage === activeStage ? 600 : 400 }}
                  >
                    {stage}
                  </Typography>
                ))}
              </Box>
              <LinearProgress
                variant="determinate"
                value={((growthStages[activeStage] + 1) / Object.keys(growthStages).length) * 100}
                sx={{ height: 8, borderRadius: 4 }}
              />
            </Box>
          </StyledPaper>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>
                  Growth Metrics
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <GrowthMetricCard>
                      <Box className="flex items-center gap-2 mb-3">
                        <TrendingUp size={20} className="text-green-500" />
                        <Typography variant="subtitle1">Monthly Revenue Growth</Typography>
                      </Box>
                      <Typography variant="h4" className="mb-2" color="success.main">
                        +32%
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        15% higher than industry average
                      </Typography>
                    </GrowthMetricCard>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <GrowthMetricCard>
                      <Box className="flex items-center gap-2 mb-3">
                        <Users size={20} className="text-blue-500" />
                        <Typography variant="subtitle1">Customer Acquisition</Typography>
                      </Box>
                      <Typography variant="h4" className="mb-2">
                        +126
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        New customers this month
                      </Typography>
                    </GrowthMetricCard>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <GrowthMetricCard>
                      <Box className="flex items-center gap-2 mb-3">
                        <Award size={20} className="text-purple-500" />
                        <Typography variant="subtitle1">Market Position</Typography>
                      </Box>
                      <Typography variant="h4" className="mb-2">
                        Top 10%
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        In your industry segment
                      </Typography>
                    </GrowthMetricCard>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <GrowthMetricCard>
                      <Box className="flex items-center gap-2 mb-3">
                        <PieChart size={20} className="text-orange-500" />
                        <Typography variant="subtitle1">Resource Efficiency</Typography>
                      </Box>
                      <Typography variant="h4" className="mb-2">
                        94%
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Operational efficiency score
                      </Typography>
                    </GrowthMetricCard>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>
                  Next Milestones
                </Typography>
                <MilestoneCard>
                  <Box className="flex justify-between items-center mb-2">
                    <Box className="flex items-center gap-2">
                      <Flag size={16} className="text-blue-500" />
                      <Typography variant="subtitle2">Series A Funding</Typography>
                    </Box>
                    <Chip label="Priority" color="error" size="small" />
                  </Box>
                  <Box className="space-y-2">
                    <Box className="flex justify-between">
                      <Typography variant="caption" color="text.secondary">
                        Progress
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        75%
                      </Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={75} />
                  </Box>
                </MilestoneCard>

                <MilestoneCard>
                  <Box className="flex justify-between items-center mb-2">
                    <Box className="flex items-center gap-2">
                      <Users size={16} className="text-green-500" />
                      <Typography variant="subtitle2">Team Expansion</Typography>
                    </Box>
                    <Chip label="In Progress" color="primary" size="small" />
                  </Box>
                  <Box className="space-y-2">
                    <Box className="flex justify-between">
                      <Typography variant="caption" color="text.secondary">
                        Progress
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        40%
                      </Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={40} />
                  </Box>
                </MilestoneCard>

                <MilestoneCard>
                  <Box className="flex justify-between items-center mb-2">
                    <Box className="flex items-center gap-2">
                      <Target size={16} className="text-purple-500" />
                      <Typography variant="subtitle2">Market Expansion</Typography>
                    </Box>
                    <Chip label="Planned" color="default" size="small" />
                  </Box>
                  <Box className="space-y-2">
                    <Box className="flex justify-between">
                      <Typography variant="caption" color="text.secondary">
                        Progress
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        15%
                      </Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={15} />
                  </Box>
                </MilestoneCard>
              </StyledPaper>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Growth Strategy Recommendations
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box className="p-4 border rounded-lg">
                  <Box className="flex items-center gap-2 mb-3">
                    <Zap size={20} className="text-yellow-500" />
                    <Typography variant="subtitle1">High-Impact Opportunities</Typography>
                  </Box>
                  <Box className="space-y-3">
                    <Box className="flex items-center gap-3">
                      <CheckCircle size={16} className="text-green-500" />
                      <Typography variant="body2">
                        Expand current market presence through strategic partnerships
                      </Typography>
                    </Box>
                    <Box className="flex items-center gap-3">
                      <Clock size={16} className="text-blue-500" />
                      <Typography variant="body2">
                        Implement automated customer onboarding to scale efficiently
                      </Typography>
                    </Box>
                    <Box className="flex items-center gap-3">
                      <AlertCircle size={16} className="text-orange-500" />
                      <Typography variant="body2">
                        Develop new revenue streams through product diversification
                      </Typography>
                    </Box>
                  </Box>
                  <ActionButton className="mt-4">
                    <Target size={16} />
                    Create Action Plan
                  </ActionButton>
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        );
      case 3:
        return (
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              Growth Advisors
            </Typography>
            <Box className="space-y-3">
              {[
                { name: 'Sarah Johnson', role: 'Growth Strategy', avatar: 'SJ' },
                { name: 'Mike Chen', role: 'Market Expansion', avatar: 'MC' },
                { name: 'Lisa Thompson', role: 'Financial Planning', avatar: 'LT' },
              ].map((advisor) => (
                <Box key={advisor.name} className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                  <Avatar>{advisor.avatar}</Avatar>
                  <Box>
                    <Typography variant="subtitle2">{advisor.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {advisor.role}
                    </Typography>
                  </Box>
                  <ActionButton className="ml-auto py-1 px-2">
                    <MessageSquare size={14} />
                  </ActionButton>
                </Box>
              ))}
            </Box>
          </StyledPaper>
        );
      default:
        return null;
    }
  }, [activeTab, activeStage, growthStages]);

  return (
    <Box className="space-y-4">
      <NavigationContainer>
        <Box>
          {isMobile && activeTab !== 0 && (
            <NavigationButton
              onClick={() => handleTabChange(null, 0)}
              aria-label="Go back"
            >
              <ChevronLeft size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}

          <StyledTabs
            ref={tabsRef}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="growth navigation tabs"
          >
            <TabIndicator style={getIndicatorStyle()} />
            {navigationTabs.slice(visibleRange.start, visibleRange.end).map((tab) => (
              <StyledTab
                key={tab.id}
                label={tab.label}
                disabled={tab.disabled}
                disableRipple
                value={tab.id}
              />
            ))}
          </StyledTabs>

          {isMobile && visibleRange.end < navigationTabs.length && (
            <NavigationButton
              onClick={() => {
                const nextTab = navigationTabs[visibleRange.end];
                handleTabChange(null, nextTab.id);
              }}
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}
        </Box>
      </NavigationContainer>

      {renderContent()}
    </Box>
  );
};

export default AGTGrowth;
