// MagicalMessage.jsx

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography as MuiTypography, IconButton, Tooltip, Link, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { ThumbsUp, ThumbsDown, Copy, PencilLine, Search, Paperclip } from 'lucide-react';
import { sendFeedback } from '../../services/AGTService';
import InlineImageSearch from '../../search/InlineImageSearch';
import { getThemeStyles } from '../../themes/themeConfig';
import DirectiveLogo from '../../common/DirectiveLogo';
import { FeedbackModalsContainer } from '../feedback/FeedbackModalsContainer';
import AGTMakerButton from '../AGTMaker/AGTMakerButton';
import MagicalMessageRenderer from './MagicalMessageRenderer';
import AudioPlayer from './AudioPlayer';
import UserAudioMessage from './UserAudioMessage';
import AttachedImagesViewer from './AttachedImagesViewer';
import AttachedPDFsViewer from './AttachedPDFsViewer';
import VisionMessage from './VisionMessage';

// Definición de animaciones
const textFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
    filter: blur(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
`;

const glowAnimation = keyframes`
  0% { 
    filter: blur(0px) brightness(1);
    box-shadow: 0 0 0px rgba(255, 255, 255, 0);
  }
  50% { 
    filter: blur(3px) brightness(1.2);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
  }
  100% { 
    filter: blur(0px) brightness(1);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  }
`;

// Componentes estilizados
const ImageContainer = styled(Box)`
  position: relative;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  
  &.loaded {
    opacity: 1;
  }
`;

const BaseImage = styled('img')`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
`;

const GlowEffect = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${glowAnimation} 1.5s ease-in-out;
  animation-delay: 0.2s;
  border-radius: 8px;
  pointer-events: none;
`;

const MessageContainer = styled(Box, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['isUser'].includes(prop)
})(({ theme, isUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: isUser ? 'flex-end' : 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  paddingRight: isUser ? '16px' : '0',
  paddingLeft: isUser ? '30%' : '16px',
  marginBottom: '0px',
  opacity: 1,
  transform: 'none',
  '&:last-child': {
    marginBottom: '0px',
  },
  '@media (max-width: 1200px)': {
    paddingRight: isUser ? '16px' : '0',
    paddingLeft: isUser ? '30%' : '0px',
  },
  '@media (max-width: 600px)': {
    paddingRight: isUser ? '8px' : '0',
    paddingLeft: isUser ? '30%' : '0px',
  },
  ...getThemeStyles('MessageContainer', theme.palette.mode),
}));

const MessageContent = styled(Box, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['isUser', 'isAudioMessage'].includes(prop)
})(({ theme, isUser, isAudioMessage }) => ({
  flexGrow: 0,
  maxWidth: isUser ? '70%' : '100%',
  width: isUser ? 'fit-content' : '100%',
  padding: '12px 16px',
  borderRadius: '30px',
  position: 'relative',
  marginLeft: isUser ? 'auto' : '0',
  marginRight: isUser ? '0' : '0',
  lineHeight: '1.75',
  opacity: 1,
  transform: 'none',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  
  '@media (max-width: 1200px)': {
    width: isUser ? 'fit-content' : 'calc(100% - 44px)',
    maxWidth: isUser ? '70%' : 'calc(100% - 44px)',
  },
  '@media (max-width: 600px)': {
    width: isUser ? 'fit-content' : 'calc(100% - 34px)',
    maxWidth: isUser ? '70%' : 'calc(100% - 34px)',
  },
  ...(isUser
    ? {
        ...(isAudioMessage 
          ? {
              backgroundColor: '#0385FF',
              color: '#ffffff',
            }
          : getThemeStyles('UserMessageContent', theme.palette.mode)
        ),
        alignSelf: 'flex-end',
        '& > *': {
          margin: '0',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
        },
      }
    : {
        ...getThemeStyles('MessageContent', theme.palette.mode),
        '& > *': {
          margin: '0',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
        },
    }),
}));

// -------------------------------------------------------------------------------------------
// AÑADIMOS position: relative AL MessageWrapper PARA PODER HACER EL HOVER
// -------------------------------------------------------------------------------------------
const MessageWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-right: 0;
  margin-left: 0;
  padding-top: 8px;
  position: relative; // <-- CAMBIO

  // Al hacer hover, mostramos la sección de feedback
  &:hover .feedback-container { // <-- CAMBIO
    opacity: 1;
    pointer-events: auto;
  }

  @media (max-width: 1200px) {
    padding-right: 16px;
    width: 100%;
  }
  
  @media (max-width: 600px) {
    padding-right: 8px;
  }
`;

const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  marginLeft: '0px',
  marginTop: '10px',
  opacity: 1,
  '@media (max-width: 1200px)': {
    marginRight: '4px',
  },
  '@media (max-width: 600px)': {
    marginRight: '0px',
  },
  ...getThemeStyles('AssistantIconContainer', theme.palette.mode),
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#000000 !important'
    : '#fff !important',
  border: theme.palette.mode === 'dark' 
    ? '1px solid rgba(255, 255, 255, 0.1)' 
    : '1px solid rgba(0, 0, 0, 0.1)'
}));

// -------------------------------------------------------------------------------------------
// CAMBIAMOS EL FEEDBACKCONTAINER PARA QUE ESTE OCULTO POR DEFECTO
// -------------------------------------------------------------------------------------------
const FeedbackContainer = styled(Box)` 
  position: absolute;
  left: 15px;
  bottom: -5px;
  display: flex;
  gap: 5px;
  opacity: 0; // <-- CAMBIO
  pointer-events: none; // <-- CAMBIO
  transition: opacity 0.3s ease; // <-- CAMBIO
  
  @media (max-width: 1200px) {
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: 8px;
    justify-content: flex-start;
  }
`;

const FeedbackButton = styled(IconButton, {
  shouldComponentUpdate: true,
  shouldForwardProp: (prop) => !['active'].includes(prop)
})(({ theme, active }) => ({
  padding: '2px',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  ...getThemeStyles('FeedbackButton', theme.palette.mode),
  color: active ? '#0385FF' : 'inherit',
}));

const Typography = styled(MuiTypography)`
  line-height: 1.75;
  white-space: pre-wrap;
  word-break: break-word;
`;

const FileAttachmentContainer = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  padding: '8px',
  borderRadius: '8px',
  opacity: 0,
  animation: `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
  animationDelay: '0.2s',
  ...getThemeStyles('FileAttachmentContainer', theme.palette.mode),
}));

const AudioMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  opacity: 0,
  animation: `${textFadeIn} 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
  animationDelay: '0.2s',
  ...getThemeStyles('AudioMessageContainer', theme.palette.mode),
}));

const TranscriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
  marginTop: '4px',
  fontStyle: 'italic',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '6px 12px',
  margin: '10px 0',
  borderRadius: '20px',
  transition: 'all 0.2s ease',
  '& .lucide': {
    marginLeft: '8px',
  },
  '&:hover': {
    transform: 'translateY(-1px)',
  },
  ...getThemeStyles('StyledButton', theme.palette.mode),
}));

const generateUniqueId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

// Sección de botones de feedback
const FeedbackButtonsSection = ({ feedback, handleFeedback, copied, handleCopy, hasAGTMaker, onOpenAGTMaker }) => (
  <FeedbackContainer className="feedback-container"> {/* <-- Agregamos clase para el hover */}
    <Tooltip title="Like" placement="top">
      <FeedbackButton 
        onClick={() => handleFeedback('like')} 
        active={feedback === 'like'}
        size="small"
      >
        <ThumbsUp size={18} />
      </FeedbackButton>
    </Tooltip>
    <Tooltip title="Dislike" placement="top">
      <FeedbackButton 
        onClick={() => handleFeedback('dislike')} 
        active={feedback === 'dislike'}
        size="small"
      >
        <ThumbsDown size={18} />
      </FeedbackButton>
    </Tooltip>
    <Tooltip title={copied ? "Copied" : "Copy message"} placement="top">
      <FeedbackButton 
        onClick={handleCopy}
        size="small"
      >
        <Copy size={18} />
      </FeedbackButton>
    </Tooltip>
    {hasAGTMaker && (
      <Tooltip title="Open AGT Maker" placement="top">
        <FeedbackButton 
          onClick={onOpenAGTMaker}
          size="small"
        >
          <PencilLine size={18} />
        </FeedbackButton>
      </Tooltip>
    )}
  </FeedbackContainer>
);

// Componente de imagen animada
const AnimatedImage = ({ src, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  return (
    <ImageContainer className={isLoaded ? 'loaded' : ''}>
      <BaseImage
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        loading="lazy"
        {...props}
      />
      {isLoaded && <GlowEffect />}
    </ImageContainer>
  );
};

// Componente de búsqueda de imagen bajo demanda
const OnDemandImageSearch = ({ initialQuery, onImageSelect, showAutomatically }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(showAutomatically);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSearchClick = useCallback(() => {
    setIsSearchVisible(true);
  }, []);

  const handleImageSelect = useCallback((image) => {
    setSelectedImage(image);
    onImageSelect(image);
    setIsSearchVisible(false);
  }, [onImageSelect]);

  if (selectedImage) {
    return (
      <div>
        <AnimatedImage 
          src={selectedImage.link} 
          alt={selectedImage.title}
        />
        <Typography variant="caption">
          <Link href={selectedImage.image.contextLink} target="_blank" rel="noopener noreferrer">
            View on web
          </Link>
        </Typography>
      </div>
    );
  }

  if (isSearchVisible) {
    return <InlineImageSearch onImageSelect={handleImageSelect} initialQuery={initialQuery} />;
  }

  return (
    <StyledButton
      startIcon={<Search size={24} style={{ marginRight: '4px' }} />}
      onClick={handleSearchClick}
    >
      Open AGT Search
    </StyledButton>
  );
};

// Componente de contenido de mensaje de audio
const AudioMessageContent = ({ audioUrl, transcription }) => {
  return (
    <AudioMessageContainer>
      <AudioPlayer audioUrl={audioUrl} />
      {transcription && (
        <TranscriptionText>
          {transcription}
        </TranscriptionText>
      )}
    </AudioMessageContainer>
  );
};

// Función para procesar el contenido del mensaje
const processMessageContent = (message, isNew = false) => {
  if (!message) return { text: '', type: 'text', isNew };

  try {
    let processedMessage;
    
    // Parsear mensaje de tipo string si es necesario
    if (typeof message === 'string') {
      try {
        processedMessage = JSON.parse(message);
        // Manejar caso especial donde text es un objeto
        if (processedMessage.text && typeof processedMessage.text === 'object') {
          processedMessage = processedMessage.text;
        }
      } catch {
        processedMessage = message;
      }
    } else {
      processedMessage = message;
    }

    // Extraer metadata y archivos
    const metadata = processedMessage.messageMetadata || processedMessage.metadata || {};
    const files = metadata.files || processedMessage.files || [];
    const storage = metadata.storage || processedMessage.storage || [];

    // Mensajes de audio
    if (processedMessage.type === 'audio_message') {
      return {
        type: 'audio_message',
        text: processedMessage.text || processedMessage.transcription,
        audioUrl: processedMessage.audioUrl,
        duration: processedMessage.duration,
        audioReference: processedMessage.audioReference,
        transcription: processedMessage.text || processedMessage.transcription,
        timestamp: processedMessage.timestamp || new Date().toISOString(),
        isNew
      };
    }

    // Verificar AGTVision de múltiples formas para mayor robustez
    if (processedMessage.source === 'AGTVision' || 
        (processedMessage.metadata && processedMessage.metadata.source === 'AGTVision') ||
        (processedMessage.files && processedMessage.files.some(f => f.source === 'AGTVision')) ||
        (typeof processedMessage.text === 'string' && processedMessage.text.includes('AGTVision:'))) {
      
      // Extraer el texto del contenido correctamente
      let visionText = '';
      if (typeof processedMessage.text === 'string') {
        try {
          const parsedText = JSON.parse(processedMessage.text);
          visionText = parsedText.text || processedMessage.text;
        } catch {
          visionText = processedMessage.text;
        }
      }

      return {
        type: 'vision',
        text: visionText,
        files: processedMessage.files?.map(file => ({
          ...file,
          source: 'AGTVision',
          captureType: processedMessage.captureType || 'camera'
        })) || [],
        captureType: processedMessage.captureType || 'camera',
        source: 'AGTVision',
        metadata: {
          ...processedMessage.metadata,
          captureType: processedMessage.captureType || 'camera',
          source: 'AGTVision',
          timestamp: processedMessage.metadata?.timestamp || new Date().toISOString(),
          transcriptionSource: processedMessage.metadata?.transcriptionSource || 'webSpeechAPI',
          deviceInfo: processedMessage.metadata?.deviceInfo || 'web'
        },
        isNew,
        voicePreference: processedMessage.voicePreference
      };
    }

    // Mensajes con archivos adjuntos y storage
    if (files.length > 0 || storage.length > 0) {
      const processedFiles = files.map(file => {
        const storageRef = storage.find(s => s.originalName === file.name);
        return {
          ...file,
          type: file.type || 'image/png',
          url: storageRef?.s3Url || file.s3Url || file.url,
          isPermanent: true,
          storage: storageRef
        };
      });
  
      return {
        type: 'text',
        text: processedMessage.text || processedMessage.messageText || '',
        files: processedFiles,
        storage: storage,
        isNew,
        messageMetadata: {
          ...metadata,
          files: processedFiles,
          storage: storage
        }
      };
    }

    // Mensajes que comienzan con "Image: "
    if (typeof processedMessage === 'string' && processedMessage.startsWith('Image: ')) {
      const imageUrl = processedMessage.replace('Image: ', '').trim();
      return {
        type: 'selected_image',
        data: {
          src: imageUrl,
          alt: 'Selected image',
          contextLink: imageUrl
        },
        text: processedMessage,
        isNew
      };
    }

    // Mensajes de imagen seleccionada
    if (processedMessage.type === 'selected_image' && processedMessage.data) {
      return {
        type: 'selected_image',
        data: {
          src: processedMessage.data.src,
          alt: processedMessage.data.alt || 'Selected image',
          contextLink: processedMessage.data.contextLink
        },
        text: processedMessage.text || processedMessage.data.alt || 'Selected image',
        isNew
      };
    }

    // Mensajes AGT
    if (typeof processedMessage === 'string' && processedMessage.includes('<agt>')) {
      return {
        type: 'text',
        text: processedMessage,
        hasAgt: true,
        isNew
      };
    }

    // Mensajes de búsqueda de imágenes
    if (processedMessage.type === 'image_search') {
      return {
        type: 'image_search',
        query: processedMessage.query,
        text: processedMessage.text || processedMessage.query,
        isNew
      };
    }

    // Mensajes con metadata especial
    if (metadata && Object.keys(metadata).length > 0) {
      return {
        type: 'text',
        text: processedMessage.text || processedMessage.content || processedMessage,
        metadata: metadata,
        files: files,
        storage: storage,
        isNew,
        messageMetadata: metadata
      };
    }

    // Mensajes de texto estándar
    const text = processedMessage.text || processedMessage;
    return {
      type: 'text',
      text: typeof text === 'string' ? text : JSON.stringify(text),
      isNew,
      messageMetadata: metadata
    };

  } catch (error) {
    console.error('Error processing message content:', {
      error: error.message,
      message: typeof message === 'object' ? JSON.stringify(message) : message,
      stack: error.stack
    });

    // Fallback seguro
    return {
      type: 'text',
      text: typeof message === 'string' ? message : String(message || ''),
      isNew,
      error: true
    };
  }
};

const filterFilesByType = (files) => {
  if (!Array.isArray(files)) {
    return {
      images: [],
      otherFiles: []
    };
  }
  
  return {
    images: files.filter(file => {
      // Verificación estricta para imágenes
      const type = file.type || '';
      const ext = (file.name || '').toLowerCase();
      return (
        type.startsWith('image/') || 
        ext.endsWith('.jpg') || 
        ext.endsWith('.jpeg') || 
        ext.endsWith('.png') || 
        ext.endsWith('.gif') || 
        ext.endsWith('.webp')
      );
    }),
    otherFiles: files.filter(file => {
      const type = file.type || '';
      const ext = (file.name || '').toLowerCase();
      // Si NO es una imagen, es un "otro archivo"
      return !(
        type.startsWith('image/') || 
        ext.endsWith('.jpg') || 
        ext.endsWith('.jpeg') || 
        ext.endsWith('.png') || 
        ext.endsWith('.gif') || 
        ext.endsWith('.webp')
      );
    })
  };
};

// Función para detectar mensajes AGTVision
const isAGTVisionMessage = (message) => {
  if (!message) return false;
  try {
    const processedMessage = typeof message === 'string' ? JSON.parse(message) : message;
    return (
      processedMessage.source === 'AGTVision' ||
      processedMessage.text?.includes('AGTVision:') ||
      (processedMessage.files && processedMessage.files.some(f => f.source === 'AGTVision'))
    );
  } catch {
    return typeof message === 'string' && message.includes('AGTVision:');
  }
};

// Componente principal MagicalMessage
const MagicalMessage = ({ 
  content, 
  isUser, 
  isLastMessage,
  onFeedback, 
  userId, 
  messageId,
  hasAGTMaker,
  onOpenAGTMaker,
  isLoading,
  onUpdateMessage,
  lastImageRequestId,
  files,
  imageBase64Data,
  theme
}) => {
  const initialProcessedContent = useMemo(() => {
    return processMessageContent(content, isLastMessage);
  }, [content, isLastMessage]);

  const [processedContent, setProcessedContent] = useState([]);
  const [copied, setCopied] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [openLikeModal, setOpenLikeModal] = useState(false);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [dislikeReason, setDislikeReason] = useState('Other');

  // Determinar si el mensaje es de tipo audio
  const isAudioMessage = useMemo(() => {
    if (!content) return false;
    try {
      const parsedContent = typeof content === 'string' ? JSON.parse(content) : content;
      return parsedContent.type === 'audio_message';
    } catch {
      return false;
    }
  }, [content]);

  // Determinar si el mensaje es de tipo AGTVision
  const isAGTVision = isAGTVisionMessage(content);

  const handleCopy = useCallback(() => {
    let textToCopy = '';
    if (initialProcessedContent.type === 'audio_message') {
      textToCopy = initialProcessedContent.text || 'Audio message';
    } else {
      const processedMessage = processMessageContent(content);
      textToCopy = processedMessage.text || '';
    }
    
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [content, initialProcessedContent]);

  const handleFeedback = useCallback((value) => {
    setFeedback(value);
    if (value === 'like') {
      setOpenLikeModal(true);
    } else if (value === 'dislike') {
      setOpenDislikeModal(true);
    }
  }, []);

  const handleSubmitFeedback = useCallback(async () => {
    try {
      if (!userId || !messageId || !feedback) {
        throw new Error('Missing required fields for feedback');
      }

      await sendFeedback(
        userId,
        messageId,
        feedback,
        feedbackDetails,
        feedback === 'dislike' ? dislikeReason : null
      );

      if (onFeedback) {
        onFeedback({
          type: feedback,
          details: feedbackDetails,
          reason: feedback === 'dislike' ? dislikeReason : null,
        });
      }

      setOpenLikeModal(false);
      setOpenDislikeModal(false);
      setFeedbackDetails('');
      setDislikeReason('Other');
    } catch (error) {
      alert('There was an error sending feedback. Please try again.');
    }
  }, [userId, messageId, feedback, feedbackDetails, dislikeReason, onFeedback]);

  const handleImageSelect = useCallback((image) => {
    const imageData = {
      type: 'selected_image',
      data: {
        src: image.link,
        alt: image.title,
        contextLink: image.image.contextLink
      }
    };
    onUpdateMessage(messageId, imageData);
  }, [onUpdateMessage, messageId]);

  useEffect(() => {
    if (initialProcessedContent.type === 'vision') {
      setProcessedContent([
        <VisionMessage
          key="vision"
          isUser={isUser}
          content={initialProcessedContent.text}
          files={initialProcessedContent.files}
          metadata={initialProcessedContent.metadata}
          hideImages={isAGTVision}
        />
      ]);
      return;
    }

    if (initialProcessedContent.type === 'audio_message') {
      if (isUser) {
        setProcessedContent([
          <UserAudioMessage 
            key="audio"
            audioUrl={initialProcessedContent.audioUrl}
            duration={initialProcessedContent.duration}
          />
        ]);
      } else {
        setProcessedContent([
          <AudioMessageContent 
            key="audio"
            audioUrl={initialProcessedContent.audioUrl}
            transcription={initialProcessedContent.text}
          />
        ]);
      }
      return;
    }

    if (initialProcessedContent.type === 'selected_image') {
      setProcessedContent([
        <React.Fragment key="image">
          <AnimatedImage 
            src={initialProcessedContent.data.src} 
            alt={initialProcessedContent.data.alt}
          />
          {!isUser && (
            <>
              <br />
              <Link 
                href={initialProcessedContent.data.contextLink} 
                target="_blank" 
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-block',
                  marginTop: '8px',
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  }
                }}
              >
                View on web
              </Link>
            </>
          )}
        </React.Fragment>
      ]);
      return;
    }

    if (!isUser) {
      const text = String(initialProcessedContent.text || '');
      const processedParts = [];
      let currentIndex = 0;
      let textBuffer = '';

      while (currentIndex < text.length) {
        const agtStartIndex = text.indexOf('<agt>', currentIndex);
        
        if (agtStartIndex === -1) {
          textBuffer += text.slice(currentIndex);
          break;
        }

        textBuffer += text.slice(currentIndex, agtStartIndex);
        
        if (textBuffer) {
          processedParts.push(
            <MagicalMessageRenderer
              key={`msg-${generateUniqueId()}`}
              content={textBuffer}
              isNew={initialProcessedContent.isNew}
              theme={theme}
              baseDelay={processedParts.length * 40}
            />
          );
          textBuffer = '';
        }

        processedParts.push(
          <AGTMakerButton
            key={`agt-${generateUniqueId()}`}
            onOpenAGTMaker={onOpenAGTMaker}
            isNew={initialProcessedContent.isNew}
          />
        );
    
        const agtEndIndex = text.indexOf('</agt>', agtStartIndex);
        if (agtEndIndex === -1) {
          currentIndex = text.length;
        } else {
          currentIndex = agtEndIndex + 6;
        }
      }
    
      if (textBuffer) {
        processedParts.push(
          <MagicalMessageRenderer
            key={`msg-${generateUniqueId()}`}
            content={textBuffer}
            isNew={initialProcessedContent.isNew}
            theme={theme}
            baseDelay={processedParts.length * 40}
          />
        );
      }
    
      setProcessedContent(processedParts);
    } else {
      setProcessedContent([
        <Typography 
          key="text" 
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        >
          {initialProcessedContent.text}
        </Typography>
      ]);
    }
  }, [
    initialProcessedContent,
    isUser,
    messageId,
    lastImageRequestId,
    onOpenAGTMaker,
    content,
    handleImageSelect,
    theme,
    isAGTVision
  ]);

  return (
    <MessageContainer isUser={isUser}>
      {isUser ? (
        <>
          {files?.length > 0 && !isAGTVision && (
            <>
              {(() => {
                const { images, otherFiles } = filterFilesByType(files);

                return (
                  <>
                    {/* Solo pasamos imágenes al AttachedImagesViewer */}
                    {images.length > 0 && (
                      <AttachedImagesViewer 
                        files={images}
                        imageBase64Data={imageBase64Data}
                        isUser={isUser}
                      />
                    )}

                    {/* Y otros archivos al AttachedPDFsViewer */}
                    {otherFiles.length > 0 && (
                      <AttachedPDFsViewer 
                        files={otherFiles}
                        isUser={isUser}
                      />
                    )}
                  </>
                );
              })()}
            </>
          )}

          <MessageContent isUser={isUser} isAudioMessage={isAudioMessage}>
            {isAGTVisionMessage(content) ? (
              <VisionMessage
                isUser={isUser}
                content={typeof content === 'string' ? content : content.text}
                files={files}
                metadata={{
                  captureType: content.captureType || 'camera',
                  source: 'AGTVision',
                  timestamp: content.metadata?.timestamp || new Date().toISOString(),
                  transcriptionSource: content.metadata?.transcriptionSource,
                  deviceInfo: content.metadata?.deviceInfo || 'web'
                }}
                hideImages={isAGTVision}
              />
            ) : (
              processedContent
            )}
          </MessageContent>
        </>
      ) : (
        <MessageWrapper>
          <AssistantIconContainer>
            <DirectiveLogo size={18} className="text-current" />
          </AssistantIconContainer>
          <MessageContent isUser={isUser}>
            {isAGTVisionMessage(content) ? (
              <VisionMessage
                isUser={isUser}
                content={typeof content === 'string' ? content : content.text}
                imageData={{
                  url: imageBase64Data ? `data:image/png;base64,${imageBase64Data}` : null,
                  captureType: content.captureType || 'camera',
                  source: 'AGTVision',
                  metadata: content.metadata
                }}
                hideImages={isAGTVision}
              />
            ) : (
              processedContent
            )}
            {!isAGTVision && (
              <FeedbackButtonsSection
                feedback={feedback}
                handleFeedback={handleFeedback}
                copied={copied}
                handleCopy={handleCopy}
                hasAGTMaker={hasAGTMaker}
                onOpenAGTMaker={onOpenAGTMaker}
              />
            )}
          </MessageContent>
        </MessageWrapper>
      )}

      {!isAGTVision && (
        <FeedbackModalsContainer
          openLikeModal={openLikeModal}
          openDislikeModal={openDislikeModal}
          setOpenLikeModal={setOpenLikeModal}
          setOpenDislikeModal={setOpenDislikeModal}
          feedbackDetails={feedbackDetails}
          setFeedbackDetails={setFeedbackDetails}
          dislikeReason={dislikeReason}
          setDislikeReason={setDislikeReason}
          handleSubmitFeedback={handleSubmitFeedback}
        />
      )}
    </MessageContainer>
  );
};

// Memoización del componente para optimizar rendimiento
const MemoizedMagicalMessage = React.memo(MagicalMessage, (prevProps, nextProps) => {
  const isEqual = (
    prevProps.content === nextProps.content &&
    prevProps.isUser === nextProps.isUser &&
    prevProps.isLastMessage === nextProps.isLastMessage &&
    prevProps.userId === nextProps.userId &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.hasAGTMaker === nextProps.hasAGTMaker &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.lastImageRequestId === nextProps.lastImageRequestId &&
    JSON.stringify(prevProps.files) === JSON.stringify(nextProps.files) &&
    JSON.stringify(prevProps.imageBase64Data) === JSON.stringify(nextProps.imageBase64Data)
  );
  return isEqual;
});

MemoizedMagicalMessage.displayName = 'MagicalMessage';

export default MemoizedMagicalMessage;
