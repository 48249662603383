import React from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { Sparkles } from 'lucide-react';

const AGTEnhanceButton = ({
  onEnhance,
  isLoading,
  disabled = false,
  sx = {}
}) => {
  return (
    <Tooltip
      title="Automatically enhance your profile descriptions using AI"
      arrow
      placement="top"
    >
      <span>
        <Button
          variant="outlined"
          startIcon={isLoading ? <CircularProgress size={20} /> : <Sparkles size={20} />}
          onClick={onEnhance}
          disabled={disabled || isLoading}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderRadius: '28px',
            color: '#0385FF',
            borderColor: '#0385FF',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(3,133,255,0.08)',
              borderColor: '#0266cc'
            },
            ...sx
          }}
        >
          Enhance with AGT
        </Button>
      </span>
    </Tooltip>
  );
};

export default AGTEnhanceButton;
