import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Tooltip, Box, Typography } from '@mui/material';
import { Search } from 'lucide-react';
import { styled } from '@mui/material/styles';

const BlueIconButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$active'
})(({ theme, $active, disabled }) => {
  const getBackgroundColor = () => {
    if (disabled && $active) {
      return theme.palette.mode === 'light' 
        ? 'rgba(0, 0, 0, 0.12)' 
        : 'rgba(255, 255, 255, 0.12)';
    }
    return $active ? '#0385FF' : 'transparent';
  };

  const getColor = () => {
    if (disabled) {
      return theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.26)'
        : 'rgba(255, 255, 255, 0.26)';
    }
    return $active ? '#ffffff' : '#0385FF';
  };

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    height: '32px',
    backgroundColor: getBackgroundColor(),
    color: getColor(),
    transition: 'all 0.3s ease',
    margin: '0',
    borderRadius: '16px',
    cursor: disabled ? 'default' : 'pointer',
    width: $active ? '140px' : '32px',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: disabled && $active ? getBackgroundColor() : ($active ? '#026fcc' : 'transparent'),
    }
  };
});

const ContentContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: '100%',
  gap: '8px',
  paddingRight: '16px',
});

const SearchText = styled(Typography)({
  whiteSpace: 'nowrap',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1,
});

const AGTSearch = forwardRef(({ disabled = false, onToggleSearch }, ref) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      const newState = !active;
      setActive(newState);
      if (onToggleSearch) {
        onToggleSearch(newState);
      }
    }
  };

  // Exponemos el método click a través del ref
  useImperativeHandle(ref, () => ({
    click: () => {
      handleClick();
    }
  }));

  return (
    <Tooltip title={active ? '' : 'AGT Search'} placement="top">
      <BlueIconButton
        onClick={handleClick}
        $active={active}
        disabled={disabled}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: active ? 0 : 1,
            transition: 'all 0.2s ease',
            color: 'inherit'
          }}
        >
          <Search size={20} />
        </Box>

        <ContentContainer
          sx={{
            opacity: active ? 1 : 0,
            transition: 'opacity 0.2s ease',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '24px',
              marginLeft: '4px',
            }}
          >
            <Search size={20} />
          </Box>
          <SearchText
            sx={{
              opacity: active ? 1 : 0,
              transition: 'opacity 0.2s ease',
              pointerEvents: 'none',
              color: 'inherit'
            }}
          >
            AGT Search
          </SearchText>
        </ContentContainer>
      </BlueIconButton>
    </Tooltip>
  );
});

export default AGTSearch;