import React from 'react';
import { Box, Typography, Avatar, Button, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsIcon from '@mui/icons-material/Notifications';

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1e' : theme.palette.background.paper,
  borderRadius: '16px',
  color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
  maxWidth: '500px',
  width: '90%',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  }
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? '#2c2c2e' : theme.palette.background.paper,
  borderRadius: '16px',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#3c3c3e' : theme.palette.action.hover,
  }
}));

const ProfileImage = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: 50,
    height: 50,
  }
}));

const SettingsList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2c2c2e' : theme.palette.background.paper,
  borderRadius: '16px',
  marginBottom: theme.spacing(2),
  padding: 0,
}));

// Modificado para usar data-last-item en lugar de isLastItem
const SettingsListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  '&[data-last-item="true"]': {
    borderBottom: 'none',
  },
  '&[data-last-item="false"]': {
    borderBottom: `0.5px solid ${theme.palette.mode === 'dark' ? '#3c3c3e' : '#e5e5ea'}`,
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#3c3c3e' : theme.palette.action.hover,
  }
}));

const IconContainer = styled(Box)(({ theme, bgcolor }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    color: 'white',
    fontSize: '1.2rem',
  }
}));

// Removida la dependencia de getThemeStyles
const SignOutButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? '#2c2c2e' : theme.palette.background.paper,
  color: '#ff453a',
  borderRadius: '16px',
  padding: theme.spacing(1.5),
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#3c3c3e' : theme.palette.action.hover,
  }
}));

const StyledChevronIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#636366' : '#c7c7cc',
}));

const ProfileDashboard = ({ user, onLogout }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box sx={{ 
      p: 2, 
      bgcolor: isDarkMode ? '#000000' : '#f2f2f7',
      minHeight: '100vh'
    }}>
      <ProfileContainer>
        <ProfileHeader>
          <ProfileImage src={user?.photoURL} alt={user?.displayName}>
            {!user?.photoURL && <PersonIcon />}
          </ProfileImage>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ 
              fontWeight: 500,
              color: isDarkMode ? '#ffffff' : '#000000'
            }}>
              {user?.displayName || 'User'}
            </Typography>
            <Typography variant="body2" sx={{ 
              color: isDarkMode ? '#ebebf0' : '#65656b'
            }}>
              AGT Profile
            </Typography>
          </Box>
          <StyledChevronIcon />
        </ProfileHeader>

        <SettingsList>
          <SettingsListItem button data-last-item="false">
            <IconContainer bgcolor="#007AFF">
              <PersonIcon />
            </IconContainer>
            <ListItemText 
              primary="Profile Settings" 
              sx={{
                '& .MuiListItemText-primary': {
                  color: isDarkMode ? '#ffffff' : '#000000'
                }
              }}
            />
            <ListItemSecondaryAction>
              <StyledChevronIcon />
            </ListItemSecondaryAction>
          </SettingsListItem>

          <SettingsListItem button data-last-item="false">
            <IconContainer bgcolor="#5856D6">
              <SecurityIcon />
            </IconContainer>
            <ListItemText 
              primary="Privacy & Security"
              sx={{
                '& .MuiListItemText-primary': {
                  color: isDarkMode ? '#ffffff' : '#000000'
                }
              }}
            />
            <ListItemSecondaryAction>
              <StyledChevronIcon />
            </ListItemSecondaryAction>
          </SettingsListItem>

          <SettingsListItem button data-last-item="true">
            <IconContainer bgcolor="#FF9500">
              <NotificationsIcon />
            </IconContainer>
            <ListItemText 
              primary="Notifications"
              sx={{
                '& .MuiListItemText-primary': {
                  color: isDarkMode ? '#ffffff' : '#000000'
                }
              }}
            />
            <ListItemSecondaryAction>
              <StyledChevronIcon />
            </ListItemSecondaryAction>
          </SettingsListItem>
        </SettingsList>

        <SignOutButton 
          fullWidth 
          onClick={onLogout}
          variant="contained"
          disableElevation
        >
          Sign Out
        </SignOutButton>
      </ProfileContainer>
    </Box>
  );
};

export default ProfileDashboard;