import React from 'react';
import { AppleFeedbackModal } from './AppleFeedbackModal';

export const FeedbackModalsContainer = ({
  openLikeModal,
  openDislikeModal,
  setOpenLikeModal,
  setOpenDislikeModal,
  feedbackDetails,
  setFeedbackDetails,
  dislikeReason,
  setDislikeReason,
  handleSubmitFeedback
}) => {
  return (
    <>
      <AppleFeedbackModal
        open={openLikeModal}
        onClose={() => setOpenLikeModal(false)}
        title="What did you like about this response?"
        value={feedbackDetails}
        onChange={(e) => setFeedbackDetails(e.target.value)}
        placeholder="Your feedback helps us improve (optional)"
        onSubmit={() => {
          handleSubmitFeedback();
          setOpenLikeModal(false);
        }}
      />

      <AppleFeedbackModal
        open={openDislikeModal}
        onClose={() => setOpenDislikeModal(false)}
        title="What can we improve?"
        value={feedbackDetails}
        onChange={(e) => setFeedbackDetails(e.target.value)}
        placeholder="Help us understand what we can improve"
        onSubmit={() => {
          handleSubmitFeedback();
          setOpenDislikeModal(false);
        }}
        showSelect={true}
        selectValue={dislikeReason}
        onSelectChange={(e) => setDislikeReason(e.target.value)}
      />
    </>
  );
};
