// D:\directiveai-react\frontend\src\components\chat\projects\ProjectsSection.js

import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle} from 'react';
import { 
  Box, 
  Typography, 
  Dialog, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  List
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery'; // <--- Importación para detectar tamaño de pantalla
import { 
  Folder, 
  FolderPlus, 
  Plus, 
  X, 
  Lightbulb, 
  MoreHorizontal,
  ChevronRight,
  ChevronDown,
  Shapes
} from 'lucide-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { getThemeStyles } from '../../themes/themeConfig';
import { 
  getProjects, 
  createProject, 
  deleteProject, 
  getProjectChats,
  deleteChat
} from '../../services/AGTService';
import { useProjectContext } from './ProjectContextProvider';

// Styled Components
const CategoryChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '20px',
  padding: '0 8px',
  borderRadius: '10px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(3, 133, 255, 0.1)' 
    : 'rgba(3, 133, 255, 0.08)',
  color: '#0385FF',
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '8px',
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
}));

const ProjectsSubheader = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  position: 'static',
  padding: '8px 16px',
  marginTop: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

const ProjectItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$selected'
})(({ theme, $selected }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  height: 36,
  padding: '0 8px 0 12px',
  margin: '0 4px',
  cursor: 'pointer',
  borderRadius: '12px',
  transition: 'background-color 0.2s',
  backgroundColor: $selected ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: $selected 
      ? theme.palette.action.selected 
      : theme.palette.action.hover,
  },
  '& svg': {
    width: '20px',
    height: '20px',
    color: '#0385FF',
  },
  '& .MuiTypography-root': {
    color: $selected 
      ? '#0385FF'
      : theme.palette.mode === 'dark' 
        ? '#ffffffde'
        : '#000000de',
    fontSize: '14px',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#ffffff',
  borderRadius: '24px',
  padding: '40px',
  width: '100%',
  maxWidth: '560px',
  position: 'relative',
  minHeight: '400px',
  display: 'flex',
  flexDirection: 'column',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  padding: '4px',
  color: theme.palette.text.secondary,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: '24px',
    maxWidth: '560px',
    width: '100%',
    borderRadius: '24px',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

const AddButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  color: '#0385FF',
  '&:hover': {
    backgroundColor: 'rgba(3, 133, 255, 0.1)',
  },
  '& svg': {
    width: '16px',
    height: '16px',
  },
}));

const MoreButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  marginLeft: 'auto',
  '& svg': {
    fontSize: '20px',
    color: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.7)' 
      : 'rgba(0, 0, 0, 0.7)',
  }
}));

const SeeMoreButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  height: 36,
  padding: '0 12px 0 24px',
  margin: '0 8px',
  cursor: 'pointer',
  borderRadius: '12px',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& svg': {
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
  },
  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  }
}));

const StyledInput = styled('input')(({ theme, error }) => ({
  width: '100%',
  padding: '16px',
  borderRadius: '12px',
  border: `1px solid ${
    error ? '#ff1744' 
    : theme.palette.mode === 'dark' 
      ? '#404040' 
      : '#e0e0e0'
  }`,
  backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#f5f5f7',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  fontSize: '16px',
  outline: 'none',
  transition: 'all 0.2s',
  marginTop: '16px',
  '&:focus': {
    borderColor: '#0385FF',
    boxShadow: '0 0 0 4px rgba(3, 133, 255, 0.15)',
    backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  },
  '&::placeholder': {
    color: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.5)' 
      : 'rgba(0, 0, 0, 0.4)',
  },
}));

const InfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 191, 0, 0.08)' 
    : 'rgba(255, 191, 0, 0.05)',
  borderRadius: '16px',
  padding: '24px',
  marginTop: '32px',
  display: 'flex',
  gap: '20px',
  alignItems: 'flex-start',
  border: theme.palette.mode === 'dark' 
    ? '1px solid rgba(255, 191, 0, 0.1)' 
    : '1px solid rgba(0, 0, 0, 0.15)',
  '& svg': {
    color: theme.palette.mode === 'dark' ? '#ffb300' : '#ff9500',
    width: '40px',
    height: '40px',
    marginTop: '2px',
  },
}));

const ActionButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '24px',
  padding: '12px 24px',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '15px',
  transition: 'all 0.2s',
  ...(variant === 'cancel' && {
    color: theme.palette.mode === 'dark' ? '#ff453a' : '#ff3b30',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 69, 58, 0.1)' 
        : 'rgba(255, 59, 48, 0.1)',
    },
  }),
  ...(variant === 'contained' && {
    backgroundColor: '#0385FF',
    color: '#ffffff',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#0374e0',
    },
  }),
}));

const AllProjectsModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#ffffff',
  borderRadius: '24px',
  padding: '24px',
  width: '100%',
  maxWidth: '560px',
  maxHeight: '80vh',
  overflow: 'auto',
}));

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

/**
 * Nuevo StyledDialog para los chats
 */
const AllChatsModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#ffffff',
  borderRadius: '24px',
  padding: '24px',
  width: '100%',
  maxWidth: '560px',
  maxHeight: '80vh',
  overflow: 'auto',
}));

const ProjectsSection = forwardRef(({ 
  selectedCategory,
  setSelectedProject,
  selectedProject,
  user,
  onProjectChatSelect,
  onNewProject, 
}, ref) => {
  // Obtener contexto
  const { 
    projectChats, 
    updateProjectChats,
    addProjectChat,
    removeProjectChat
  } = useProjectContext();

  // Estados
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAllProjectsModalOpen, setIsAllProjectsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuProjectId, setMenuProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [expandedProjects, setExpandedProjects] = useState(new Set());
  
  // Estados del menú contextual de chats
  const [chatMenuAnchorEl, setChatMenuAnchorEl] = useState(null);
  const [menuChatId, setMenuChatId] = useState(null);

  // Estado para el modal de todos los chats de un proyecto
  const [openChatsModal, setOpenChatsModal] = useState(null); 
  // { projectId, chats: [...] }

  // [NUEVO] Estado para la confirmación de borrado de proyecto
  const [confirmDeleteProjectModalOpen, setConfirmDeleteProjectModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  // Hook para detectar si está en modo móvil
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // Ajustar el límite de proyectos a mostrar dependiendo del tamaño de pantalla
  const visibleProjectsLimit = isMobile ? 2 : 5;

  // Cargar proyectos iniciales
  useEffect(() => {
    const loadProjects = async () => {
      if (!user?.uid || !selectedCategory) return;
      
      setIsLoading(true);
      try {
        const loadedProjects = await getProjects(user.uid, selectedCategory);
        setProjects(loadedProjects);

        // Cargar chats de proyectos expandidos (si ya estaban expandidos)
        for (const projectId of expandedProjects) {
          if (!projectChats[projectId]) {
            const chatsResponse = await getProjectChats(user.uid, projectId);
            if (chatsResponse.chats) {
              // Ordenar de más reciente a más antiguo
              const sortedChats = [...chatsResponse.chats].sort((a, b) => {
                const dateA = new Date(a.updatedAt || a.createdAt);
                const dateB = new Date(b.updatedAt || b.createdAt);
                return dateB - dateA; // newest first
              });
              updateProjectChats(projectId, sortedChats);
            }
          }
        }
      } catch (err) {
        console.error('Error loading projects:', err);
        setSnackbarMessage('Failed to load projects');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    loadProjects();
  }, [user?.uid, selectedCategory, expandedProjects, projectChats, updateProjectChats]);

  // Filtrar y ordenar proyectos
  const filteredProjects = projects.filter(
    (project) => project.category === selectedCategory
  );
  const sortedProjects = [...filteredProjects].sort((a, b) => b.id - a.id);

  // Determinar proyectos visibles y proyectos ocultos
  const visibleProjects = sortedProjects.slice(0, visibleProjectsLimit);
  const hiddenProjects = sortedProjects.slice(visibleProjectsLimit);

  // Manejadores
  const handleNewProject = useCallback(() => {
    setIsModalOpen(true);
    setProjectName('');
    setError('');
    
    // Llamar al callback si existe
    if (onNewProject) {
      onNewProject();
    }
  }, [onNewProject]);

  useImperativeHandle(ref, () => ({
    handleNewProject
  }));

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setProjectName('');
    setError('');
  };

  const handleCreateProject = async () => {
    if (!projectName.trim() || !user?.uid) {
      setError('Give your project a name to get started');
      return;
    }
    
    // Verificar que no exista duplicado
    if (projects.some(p => 
      p.name.toLowerCase() === projectName.trim().toLowerCase() &&
      p.category === selectedCategory
    )) {
      setError('You already have a project with this name in this category. Try something unique.');
      return;
    }

    setIsLoading(true);
    try {
      const newProject = await createProject(
        user.uid,
        projectName.trim(),
        selectedCategory
      );
      
      setProjects(prev => [...prev, newProject]);
      setSelectedProject(newProject);
      setSelectedProjectId(newProject.id);
      
      setSnackbarMessage('Project created successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      handleCloseModal();
    } catch (err) {
      console.error('Error creating project:', err);
      setError('Failed to create project. Please try again.');
      setSnackbarMessage('Failed to create project');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleProjectExpand = async (projectId) => {
    setExpandedProjects((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(projectId)) {
        newSet.delete(projectId);
      } else {
        newSet.add(projectId);

        // Cargar chats si no están en el contexto
        if (!projectChats[projectId]) {
          getProjectChats(user.uid, projectId)
          .then((res) => {
              const sortedChats = (res.chats || []).sort((a, b) => {
                const dateA = new Date(a.updatedAt || a.createdAt);
                const dateB = new Date(b.updatedAt || b.createdAt);
                return dateB - dateA; // newest first
              });
              updateProjectChats(projectId, sortedChats);
            })
            .catch((err) => {
              console.error('Error fetching project chats:', err);
              setSnackbarMessage('Error loading project chats');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
            });
        }
      }
      return newSet;
    });
  };

  const handleProjectClick = (projectId) => {
    const foundProject = projects.find((p) => p.id === projectId);
    if (!foundProject) return;
    setSelectedProject(foundProject);
    setSelectedProjectId(foundProject.id);
  };

  const handleMenuOpen = (e, projectId) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
    setMenuProjectId(projectId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuProjectId(null);
  };

  const handleChatMenuOpen = (event, projectId, chatId) => {
    event.stopPropagation();
    setChatMenuAnchorEl(event.currentTarget);
    setMenuProjectId(projectId);
    setMenuChatId(chatId);
  };

  const handleChatMenuClose = () => {
    setChatMenuAnchorEl(null);
    setMenuProjectId(null);
    setMenuChatId(null);
  };

  const handleDeleteProjectChat = async (projectId, chatId) => {
    if (!chatId || !user?.uid) return;
    
    setIsLoading(true);
    try {
      await deleteChat(user.uid, chatId);
      
      // Usar la función del contexto para eliminar el chat
      removeProjectChat(projectId, chatId);
      
      setSnackbarMessage('Chat deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting chat:', err);
      setSnackbarMessage('Failed to delete chat');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
      handleChatMenuClose();
    }
  };

  // [NUEVO] Abrir el modal de confirmación para borrar proyecto
  const handleOpenDeleteProjectModal = useCallback(() => {
    handleMenuClose();
    // Obtenemos el project en base a menuProjectId
    const projectObj = projects.find(p => p.id === menuProjectId);
    if (!projectObj) return;

    setProjectToDelete(projectObj);
    setConfirmDeleteProjectModalOpen(true);
  }, [menuProjectId, projects]);

  // [NUEVO] Cuando el usuario confirma que sí desea borrar
  const handleConfirmDeleteProject = async () => {
    if (!projectToDelete || !user?.uid) {
      setConfirmDeleteProjectModalOpen(false);
      return;
    }
    
    setIsLoading(true);
    try {
      // Este llama a tu endpoint /api/projects/:projectId con la lógica de borrar chats + proyecto
      await deleteProject(user.uid, projectToDelete.id);

      // Filtramos en la lista local de proyectos
      setProjects((prev) => prev.filter((proj) => proj.id !== projectToDelete.id));

      // Si el proyecto que se borró era el seleccionado, lo limpiamos
      if (selectedProjectId === projectToDelete.id) {
        setSelectedProjectId(null);
        setSelectedProject(null);
      }

      setSnackbarMessage('Project deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting project:', err);
      setSnackbarMessage('Failed to delete project');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
      setConfirmDeleteProjectModalOpen(false);
      setProjectToDelete(null);
    }
  };

  const handleSeeMoreClick = () => {
    setIsAllProjectsModalOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleChatClick = useCallback(
    (projectId, chatId) => {
      const foundProject = projects.find((p) => p.id === projectId);
      if (!foundProject) return;
      
      setSelectedProjectId(projectId);
      setSelectedProject(foundProject);
    
      if (onProjectChatSelect) {
        onProjectChatSelect(chatId);
      }
    },
    [projects, setSelectedProject, onProjectChatSelect]
  );

  /**
   * Manejador para abrir el modal con todos los chats de un proyecto
   */
  const handleOpenAllChatsModal = (projectId) => {
    const chats = projectChats[projectId] || [];
    setOpenChatsModal({ projectId, chats });
  };

  /**
   * Manejador para cerrar el modal de todos los chats
   */
  const handleCloseAllChatsModal = () => {
    setOpenChatsModal(null);
  };

  const renderProjectChats = (project) => {
    const chats = projectChats[project.id] || [];

    // Asegurarnos de que estén ordenados del más reciente al más antiguo
    const sortedChats = [...chats].sort((a, b) => {
      const dateA = new Date(a.updatedAt || a.createdAt);
      const dateB = new Date(b.updatedAt || b.createdAt);
      return dateB - dateA; // newest first
    });

    // Mostramos solo los 3 primeros
    const visibleChats = sortedChats.slice(0, 3);
    const remainingCount = sortedChats.length - 3;

    return (
      <>
        <List sx={{ py: 0 }}>
          {visibleChats.map((chat) => (
            <ProjectItem
              key={chat.id}
              onClick={() => handleChatClick(project.id, chat.id)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Shapes size={20} />
              <Typography sx={{ flex: 1, ml: 1 }}>
                {chat.title || 'New chat'}
              </Typography>
              
              <MoreButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChatMenuOpen(e, project.id, chat.id);
                }}
              >
                <MoreVertIcon />
              </MoreButton>
            </ProjectItem>
          ))}
        </List>
        {remainingCount > 0 && (
          <SeeMoreButton onClick={() => handleOpenAllChatsModal(project.id)}>
            <MoreHorizontal />
            <Typography>See more ({remainingCount} chats)</Typography>
          </SeeMoreButton>
        )}
        {chats.length === 0 && (
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', ml: 1 }}
          >
            No chats in this project
          </Typography>
        )}
      </>
    );
  };

  return (
    <Box>
      <ProjectsSubheader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
            Projects
          </Typography>
          {selectedCategory && <CategoryChip>{selectedCategory}</CategoryChip>}
        </Box>
        {filteredProjects.length > 0 && (
          <AddButton onClick={handleNewProject}>
            <Plus />
          </AddButton>
        )}
      </ProjectsSubheader>
      
      {isLoading && projects.length === 0 ? (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      ) : filteredProjects.length === 0 ? (
        <ProjectItem onClick={handleNewProject}>
          <FolderPlus />
          <Typography>New project</Typography>
        </ProjectItem>
      ) : (
        <>
          {visibleProjects.map((project) => {
            const isExpanded = expandedProjects.has(project.id);

            return (
              <Box key={project.id}>
                <ProjectItem
                  $selected={selectedProjectId === project.id && selectedProject}
                  onClick={() => handleProjectClick(project.id)}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleProjectExpand(project.id);
                    }}
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    {isExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                  </IconButton>

                  <Folder />
                  <Typography sx={{ flex: 1, ml: 1 }}>{project.name}</Typography>

                  <MoreButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMenuOpen(e, project.id);
                    }}
                  >
                    <MoreVertIcon />
                  </MoreButton>
                </ProjectItem>

                {/* Si está expandido, mostramos los chats */}
                {isExpanded && (
                  <Box sx={{ ml: 3, mt: 1 }}>
                    {renderProjectChats(project)}
                  </Box>
                )}
              </Box>
            );
          })}

          {hiddenProjects.length > 0 && (
            <SeeMoreButton onClick={handleSeeMoreClick}>
              <MoreHorizontal />
              <Typography>See more ({hiddenProjects.length} more)</Typography>
            </SeeMoreButton>
          )}
        </>
      )}

      {/* Menú contextual para proyectos */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={handleOpenDeleteProjectModal}
          sx={{
            color: 'error.main',
            '&:hover': { backgroundColor: 'error.light' },
            gap: 1,
          }}
        >
          <DeleteIcon fontSize="small" />
          Delete Project
        </MenuItem>
      </Menu>

      {/* Menú contextual para chats */}
      <Menu
        anchorEl={chatMenuAnchorEl}
        open={Boolean(chatMenuAnchorEl)}
        onClose={handleChatMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => handleDeleteProjectChat(menuProjectId, menuChatId)}
          sx={{
            color: 'error.main',
            '&:hover': { backgroundColor: 'error.light' },
            gap: 1,
          }}
        >
          <DeleteIcon fontSize="small" />
          Delete Chat
        </MenuItem>
      </Menu>

      {/* Modal para crear nuevo proyecto */}
      <StyledDialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <ModalContent>
          <CloseButton onClick={handleCloseModal}>
            <X size={20} />
          </CloseButton>
          
          <Typography 
            variant="h6" 
            sx={{ mb: 2, fontWeight: 600, fontSize: '24px', letterSpacing: '-0.5px' }}
          >
            Project name
          </Typography>
          
          <StyledInput
            placeholder="Something like 'My Next Big Idea'"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            error={!!error}
            disabled={isLoading}
          />
          {error && (
            <Typography color="error" sx={{ mt: 1.5, fontSize: '13px' }}>
              {error}
            </Typography>
          )}

          <InfoBox>
            <Lightbulb />
            <Box>
              <Typography
                sx={{ 
                  fontSize: '16px',
                  fontWeight: 600,
                  color: (theme) => theme.palette.mode === 'dark' ? '#ffb300' : '#ff9500',
                  letterSpacing: '-0.3px',
                }}
              >
                A space for your ideas
              </Typography>
              <Typography
                sx={{ 
                  fontSize: '15px',
                  lineHeight: 1.5,
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.8)'
                      : 'rgba(0, 0, 0, 0.8)',
                  mt: 1,
                }}
              >
                Think of projects as your creative hubs. Each one is a dedicated
                space where your ideas, conversations, and files come together
                seamlessly. Perfect for bringing your next masterpiece to life.
              </Typography>
            </Box>
          </InfoBox>

          <Box
            sx={{
              mt: 'auto',
              pt: 4,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
            }}
          >
            <ActionButton onClick={handleCloseModal} variant="cancel" disabled={isLoading}>
              Cancel
            </ActionButton>
            <ActionButton onClick={handleCreateProject} variant="contained" disabled={isLoading}>
              {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Create project'}
            </ActionButton>
          </Box>
        </ModalContent>
      </StyledDialog>

      {/* Modal para ver todos los proyectos */}
      <StyledDialog
        open={isAllProjectsModalOpen}
        onClose={() => setIsAllProjectsModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <AllProjectsModalContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              All Projects
              <CategoryChip>{selectedCategory}</CategoryChip>
            </Typography>
            <CloseButton onClick={() => setIsAllProjectsModalOpen(false)}>
              <X size={20} />
            </CloseButton>
          </Box>

          {sortedProjects.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: 4,
              }}
            >
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '14px',
                  mb: 2,
                }}
              >
                No projects in this category yet
              </Typography>
              <ActionButton
                onClick={() => {
                  setIsAllProjectsModalOpen(false);
                  handleNewProject();
                }}
                variant="contained"
              >
                Create your first project
              </ActionButton>
            </Box>
          ) : (
            sortedProjects.map((project) => (
              <ProjectItem
                key={project.id}
                onClick={() => {
                  setSelectedProjectId(project.id);
                  setSelectedProject(project);
                  setIsAllProjectsModalOpen(false);
                }}
                $selected={selectedProjectId === project.id}
              >
                <Folder />
                <Typography>{project.name}</Typography>
                <MoreButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuOpen(e, project.id);
                  }}
                >
                  <MoreVertIcon />
                </MoreButton>
              </ProjectItem>
            ))
          )}
        </AllProjectsModalContent>
      </StyledDialog>

      {/* Modal para ver todos los chats de un proyecto */}
      <StyledDialog
        open={Boolean(openChatsModal)}
        onClose={handleCloseAllChatsModal}
        maxWidth="sm"
        fullWidth
      >
        {openChatsModal && (
          <AllChatsModalContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                All Chats
              </Typography>
              <CloseButton onClick={handleCloseAllChatsModal}>
                <X size={20} />
              </CloseButton>
            </Box>

            {openChatsModal.chats.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: 4,
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    mb: 2,
                  }}
                >
                  No chats in this project
                </Typography>
              </Box>
            ) : (
              openChatsModal.chats.map((chat) => (
                <ProjectItem
                  key={chat.id}
                  onClick={() => {
                    handleChatClick(openChatsModal.projectId, chat.id);
                    handleCloseAllChatsModal();
                  }}
                >
                  <Shapes size={20} />
                  <Typography>{chat.title || 'New chat'}</Typography>
                  <MoreButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChatMenuOpen(e, openChatsModal.projectId, chat.id);
                    }}
                  >
                    <MoreVertIcon />
                  </MoreButton>
                </ProjectItem>
              ))
            )}
          </AllChatsModalContent>
        )}
      </StyledDialog>

      {/* [NUEVO] Modal de confirmación para borrar proyecto */}
      <StyledDialog
        open={confirmDeleteProjectModalOpen}
        onClose={() => setConfirmDeleteProjectModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <ModalContent>
          <CloseButton onClick={() => setConfirmDeleteProjectModalOpen(false)}>
            <X size={20} />
          </CloseButton>

          <Typography
            variant="h6"
            sx={{
              mb: 2,
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: '-0.5px',
            }}
          >
            Delete Project
          </Typography>

          {projectToDelete ? (
            <Typography sx={{ fontSize: '15px' }}>
              Are you sure you want to delete the project <strong>{projectToDelete.name}</strong>? 
              This action will also remove all chats associated with the project and cannot be undone.
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '15px' }}>
              Are you sure you want to delete this project and all its chats?
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              mt: 'auto',
              pt: 4,
            }}
          >
            <ActionButton
              variant="cancel"
              onClick={() => setConfirmDeleteProjectModalOpen(false)}
            >
              Cancel
            </ActionButton>

            <ActionButton
              variant="contained"
              sx={{
                backgroundColor: '#ff3b30',
                '&:hover': {
                  backgroundColor: '#ff1e0f',
                },
              }}
              onClick={handleConfirmDeleteProject}
            >
              Delete
            </ActionButton>
          </Box>
        </ModalContent>
      </StyledDialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default ProjectsSection;
