import React, { useState, useCallback, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  IconButton, 
  Fade, 
  Tooltip, 
  CircularProgress,
  useMediaQuery
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { 
  BarChart3, 
  Receipt, 
  Users, 
  Brain, 
  TrendingUp, 
  Star, 
  Building2, 
  Plus, 
  Package, 
  DollarSign, 
  Calendar, 
  MessageCircle, 
  HelpCircle, 
  Lightbulb, 
  Upload, 
  Download, 
  PieChart, 
  ArrowUpDown, 
  UserPlus, 
  ListChecks, 
  MessageSquare,
  Home,
  ChevronRight,
  ChevronLeft
} from 'lucide-react';

import AGTProduct from './products/AGTProduct';
import AGTCashFlow from './products/AGTCashFlow';
import AGTSupplierFinder from './products/AGTSupplierFinder';
import AGTCouncil from './products/AGTCouncil';
import AGTGrowth from './products/AGTGrowth';
import AGTProfile from './products/AGTProfile';
import AGTFound from './extras/AGTFound';
import AGTFoundResults from './extras/AGTFoundResults';
import profileService from './services/AGTUps-product-services/profileService';
import AGTFoundService from './services/AGTUps-product-services/AGTFound/AGTFoundService';

const CACHE_DURATION = 24 * 60 * 60 * 1000;

const getAuthToken = () => sessionStorage.getItem('authToken');

const useProfile = (user) => {
  const [companyProfile, setCompanyProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);

  const STORAGE_KEY = user ? `agtups_profile_${user.uid}` : null;

  const getStoredProfile = useCallback(() => {
    if (!STORAGE_KEY) return null;
    
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      const { profile, timestamp } = JSON.parse(storedData);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;
      
      if (!isExpired) {
        return profile;
      }
      localStorage.removeItem(STORAGE_KEY);
    }
    return null;
  }, [STORAGE_KEY]);

  const storeProfile = useCallback((profile) => {
    if (!STORAGE_KEY) return;
    
    const dataToStore = {
      profile,
      timestamp: Date.now()
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataToStore));
  }, [STORAGE_KEY]);

  const fetchAndUpdateProfile = useCallback(async () => {
    if (!user) return;

    try {
      setIsProfileLoading(true);
      setProfileError(null);

      const token = getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await profileService.getProfile({
        uid: user.uid,
        token,
        email: user.email
      });
      
      if (response.success) {
        setCompanyProfile(response.data);
        storeProfile(response.data);
        return response.data;
      } else {
        throw new Error(response.error || 'Error al cargar el perfil');
      }
    } catch (error) {
      setProfileError(error.message);
      throw error;
    } finally {
      setIsProfileLoading(false);
    }
  }, [user, storeProfile]);

  useEffect(() => {
    const initializeProfile = async () => {
      if (!user) {
        setCompanyProfile(null);
        setIsProfileLoading(false);
        return;
      }

      try {
        setIsProfileLoading(true);
        const storedProfile = getStoredProfile();
        
        if (storedProfile) {
          setCompanyProfile(storedProfile);
          setIsProfileLoading(false);
          fetchAndUpdateProfile().catch(console.error);
        } else {
          await fetchAndUpdateProfile();
        }
      } catch (error) {
        console.error('Error initializing profile:', error);
        setProfileError(error.message);
        setIsProfileLoading(false);
      }
    };

    initializeProfile();
  }, [user, getStoredProfile, fetchAndUpdateProfile]);

  return {
    companyProfile,
    isProfileLoading,
    profileError,
    refreshProfile: fetchAndUpdateProfile
  };
};

// =============================================================================================
// Estilos adaptados a modo claro/oscuro
// =============================================================================================

const AppContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#000000',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%', // Añadimos esto
  overflowY: 'auto', // Permitimos scroll interno si es necesario
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(3),
  },
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  marginBottom: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(4),
  },
  '& .top-wrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  '& .navigation-row': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1400px',
    padding: '0 16px',
    height: '48px',
    opacity: 1,
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    position: 'relative',
  },
  '& .navigation-controls': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,
  },
  '& .home-button': {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: theme.spacing(2),
    zIndex: 2,
  },
  '& .component-title': {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '1.5rem',
    letterSpacing: '0.5px',
    lineHeight: '1.5',
    margin: '0 8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  '& .actions': {
    display: 'flex',
    gap: theme.spacing(1),
    position: 'absolute',
    right: theme.spacing(2),
    zIndex: 2,
  },
  '& .title-row': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .title-container': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  '& .search-container': {
    width: '100%',
    maxWidth: '800px',
    padding: '0 16px',
    marginTop: theme.spacing(2),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  maxWidth: '1400px',
  padding: theme.spacing(0, 2),
}));

const Separator = styled(Box)(({ theme }) => ({
  maxWidth: '1400px',
  margin: '40px auto',
  height: '1px',
  background: `linear-gradient(to right, 
    ${alpha(theme.palette.text.primary, 0.1)}, 
    ${alpha(theme.palette.text.primary, 0.15)}, 
    ${alpha(theme.palette.text.primary, 0.1)}
  )`,
}));

const ShortcutsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    overflowX: 'auto',
  },
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: alpha(theme.palette.text.primary, 0.1),
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.text.primary, 0.2),
    borderRadius: '4px',
  },
}));

const ShortcutGroup = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    minWidth: '300px',
  },
}));

const ShortcutTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const ShortcutsGrid = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#f2f2f2' : '#333333',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

const ActionButton = styled(Box)(({ theme, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  '& .icon-wrapper': {
    background: `linear-gradient(
      to top, 
      rgba(0, 0, 0, 0.4), 
      transparent
    ), ${color || theme.palette.primary.main}`,
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .action-text': {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    textAlign: 'center',
    lineHeight: 1.2,
    minHeight: '2.4em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  }
}));

const ModuleContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',  // Por defecto 1 columna para móvil
  gap: theme.spacing(2),
  padding: theme.spacing(1),  // Reducido el padding en móvil
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  },
}));

const ModuleCard = styled(Paper)(({ theme, color }) => ({
  position: 'relative',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: color || theme.palette.primary.main,
  border: 'none',
  padding: 0,
  height: '100px',  // Altura reducida para móvil
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.up('sm')]: {
    height: '120px',  // Altura original para tablets y desktop
  },
  '& .content-wrapper': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `linear-gradient(to top, rgba(0,0,0,0.4) 0%, transparent 100%)`,
    zIndex: 1
  },
  '& .title-wrapper': {
    position: 'absolute',
    top: theme.spacing(1.5),  // Reducido en móvil
    left: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    }
  },
  '& .title': {
    color: '#fff',
    fontWeight: 500,
    fontSize: '1rem',  // Tamaño reducido en móvil
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    }
  },
  '& .description': {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '0.75rem',  // Tamaño reducido en móvil
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 2,
    lineHeight: 1.2,
    maxWidth: '90%',  // Aumentado para móvil
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
      bottom: theme.spacing(3),
      left: theme.spacing(3),
      right: theme.spacing(3),
      maxWidth: '80%',
    }
  },
}));

const ContentSection = styled(Box)(({ theme }) => ({
  maxWidth: '1400px',
  margin: '0 auto',
  padding: '0 8px',  // Padding reducido en móvil
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: '0 16px',
  }
}));

const CircularIconWrapper = styled(Box)(({ theme, color }) => ({
  background: `linear-gradient(
    to top, 
    rgba(0, 0, 0, 0.4), 
    transparent
  ), ${color || theme.palette.primary.main}`,
  borderRadius: '50%',
  width: 36,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
  }
}));

// =============================================================================================
// Datos de ejemplo
// =============================================================================================
const shortcuts = [
  {
    id: 'product',
    title: 'AGT Product',
    color: '#415B7E',
    actions: [
      { icon: Plus, label: 'New Product' },
      { icon: Package, label: 'Check Inventory' },
      { icon: DollarSign, label: 'Review Prices' },
      { icon: TrendingUp, label: 'Competition' }
    ]
  },
  {
    id: 'council',
    title: 'AGT Council',
    color: '#4A4F81',
    actions: [
      { icon: Calendar, label: 'Schedule Meeting' },
      { icon: MessageCircle, label: 'Quick Question' },
      { icon: HelpCircle, label: 'Decision Help' },
      { icon: Lightbulb, label: 'Get Advice' }
    ]
  },
  {
    id: 'cashflow',
    title: 'AGT Cash Flow',
    color: '#DC9844',
    actions: [
      { icon: Upload, label: 'Quick Receipt' },
      { icon: Download, label: 'Current Flow' },
      { icon: PieChart, label: 'View Reports' },
      { icon: ArrowUpDown, label: 'Transactions' }
    ]
  },
  {
    id: 'supplier',
    title: 'AGT Supplier Finder',
    color: '#25505A',
    actions: [
      { icon: MessageSquare, label: 'Active Deals' },
      { icon: UserPlus, label: 'New Supplier' },
      { icon: ListChecks, label: 'View All' }
    ]
  }
];

const products = [
  {
    id: 'product',
    title: 'AGT Product',
    description: 'Optimize pricing, sales strategies, and market fit',
    color: '#415B7E',
    icon: BarChart3,
    component: AGTProduct,
  },
  {
    id: 'cashflow',
    title: 'AGT Cash Flow',
    description: 'Upload receipts and automate financial updates instantly',
    color: '#DC9844',
    icon: Receipt,
    component: AGTCashFlow,
  },
  {
    id: 'supplier',
    title: 'AGT Supplier Finder',
    description: 'Find and negotiate with suppliers through AI',
    color: '#25505A',
    icon: Users,
    component: AGTSupplierFinder,
  },
  {
    id: 'council',
    title: 'AGT Council',
    description: 'Get expert advice for complex business decisions',
    color: '#4A4F81',
    icon: Brain,
    component: AGTCouncil,
  },
  {
    id: 'growth',
    title: 'AGT Growth',
    description: "Strategies tailored to every stage of your startup's journey",
    color: '#FFB692',
    icon: TrendingUp,
    component: AGTGrowth,
  },
  {
    id: 'profile',
    title: 'AGT Profile',
    description: 'Gestiona el perfil de tu empresa y equipo fundador',
    color: '#BC4C4A',
    icon: Building2,
    component: AGTProfile,
  },
];

const getResponsiveTitle = (fullTitle, isMobile) => {
  if (!isMobile) return fullTitle;
  
  const titleMap = {
    'AGT Product': 'Product',
    'AGT Cash Flow': 'Cash',
    'AGT Supplier Finder': 'Supplier',
    'AGT Council': 'Council',
    'AGT Growth': 'Growth',
    'AGT Profile': 'Profile'
  };
  
  return titleMap[fullTitle] || fullTitle;
};

// =============================================================================================
// Componente Principal
// =============================================================================================
const AGTUps = ({ user }) => {
  const [activeSection, setActiveSection] = useState(() => {
    return localStorage.getItem('activeSection') || 'home';
  });
  
  const [companyProfile, setCompanyProfile] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  const handleProfileUpdate = (updatedProfile) => {
    setCompanyProfile(updatedProfile);
    
    if (!companyProfile && updatedProfile) {
      setActiveSection('home');
      localStorage.setItem('activeSection', 'home');
    }
  };

  useEffect(() => {
    // Si no hay perfil y no estamos en la sección 'profile', forzamos la sección 'profile'
    if (!companyProfile && activeSection !== 'profile') {
      setActiveSection('profile');
      localStorage.setItem('activeSection', 'profile');
    }
  }, [companyProfile, activeSection]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSearch = async (query) => {
    setIsSearching(true);
    try {
      const result = await AGTFoundService.search(query, {
        uid: user.uid,
        token: sessionStorage.getItem('authToken')
      });
      if (result.recommendations) {
        setSearchResults(result.recommendations);
        setActiveComponent('searchResults');
      }
    } catch (error) {
      console.error('Error en búsqueda:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  const handleNavigate = useCallback((direction) => {
    const currentIndex = products.findIndex(p => p.id === activeComponent);
    let nextIndex;
    
    if (direction === 'next') {
      nextIndex = currentIndex + 1 >= products.length ? 0 : currentIndex + 1;
    } else {
      nextIndex = currentIndex - 1 < 0 ? products.length - 1 : currentIndex - 1;
    }
    
    setActiveComponent(products[nextIndex].id);
  }, [activeComponent]);

  const handleHome = useCallback(() => {
    setActiveComponent(null);
    setSearchResults([]);
    setSearchQuery('');
  }, []);

  const handleProductClick = useCallback((productId) => {
    setActiveComponent(productId);
  }, []);

  const toggleFavorite = useCallback((productId) => {
    setFavorites(prev => 
      prev.includes(productId)
        ? prev.filter(id => id !== productId)
        : [...prev, productId]
    );
  }, []);

  const handleResultClick = (result) => {
    setActiveComponent(result.id);
  };

  const handleSearchResults = useCallback(async (result) => {
    if (result.error) {
      console.error(result.error);
      return;
    }

    setIsSearching(true);
    try {
      if (result.recommendations) {
        setSearchResults(result.recommendations);
        setActiveComponent('searchResults');
      }
    } catch (error) {
      console.error('Error processing search results:', error);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const renderContent = () => {
    // Si estamos en la sección 'profile', renderizar AGTProfile
    if (activeSection === 'profile') {
      return (
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          width: '100%' 
        }}>
          <AGTProfile 
            user={user}
            onProfileUpdate={handleProfileUpdate}
          />
        </Box>
      );
    }

    // Si el componente activo es 'searchResults', renderizar AGTFoundResults
    if (activeComponent === 'searchResults') {
      return (
        <AGTFoundResults 
          results={searchResults}
          isLoading={isSearching}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          onSearchResults={handleSearchResults}
          onResultClick={handleResultClick}
          setActiveComponent={setActiveComponent}
          user={user}
        />
      );
    }
    
    // Si hay un producto activo, renderizar su componente correspondiente
    const activeProduct = products.find(p => p.id === activeComponent);
    if (activeProduct) {
      const Component = activeProduct.component;
      return <Component user={user} />;
    }
    
    // Si no hay sección/componente activo, mostramos el menú inicial ("home")
    return (
      <ContentSection>
        <SectionTitle sx={{ mb: 2 }}>
          Shortcuts
        </SectionTitle>

        <ShortcutsContainer>
          {shortcuts.map((group) => (
            <ShortcutGroup key={group.id}>
              <ShortcutTitle>{group.title}</ShortcutTitle>
              <ShortcutsGrid>
                {group.actions.map((action, index) => (
                  <ActionButton key={index} color={group.color}>
                    <div className="icon-wrapper">
                      <action.icon size={20} color="white" />
                    </div>
                    <Typography className="action-text">
                      {action.label}
                    </Typography>
                  </ActionButton>
                ))}
              </ShortcutsGrid>
            </ShortcutGroup>
          ))}
        </ShortcutsContainer>

        <Separator />

        <SectionTitle sx={{ mb: 2 }}>
          AGT Suggestions
        </SectionTitle>
        <ModuleContainer>
          {products.map((product) => (
            <ModuleCard
              key={product.id}
              onClick={() => handleProductClick(product.id)}
              color={product.color}
            >
              <div className="title-wrapper">
                <Typography className="title" variant="h6">
                  {product.title}
                </Typography>
              </div>
              <Typography className="description">
                {product.description}
              </Typography>
              <div className="content-wrapper" />
            </ModuleCard>
          ))}
        </ModuleContainer>
      </ContentSection>
    );
  };

  if (isLoading) {
    return (
      <Box 
        sx={{
          height: '100vh', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppContainer>
      <ContentContainer>
        <TopBar>
          <div className="top-wrapper">
            {/* Si hay un componente activo, mostramos los controles de navegación */}
            {activeComponent && (
              <div className="navigation-row">
                <div className="home-button">
                  <Tooltip title="Home">
                    <CircularIconWrapper 
                      color="#A2A3A5"
                      onClick={handleHome}
                    >
                      <Home size={20} color="white" />
                    </CircularIconWrapper>
                  </Tooltip>
                </div>
                {activeComponent !== 'searchResults' ? (
                  <div className="navigation-controls">
                    <IconButton onClick={() => handleNavigate('prev')}>
                      <ChevronLeft />
                    </IconButton>
                    <Typography className="component-title">
                      {getResponsiveTitle(
                        products.find(p => p.id === activeComponent)?.title,
                        isMobile
                      )}
                    </Typography>
                    <IconButton onClick={() => handleNavigate('next')}>
                      <ChevronRight />
                    </IconButton>
                  </div>
                ) : (
                  <div style={{ 
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <Typography className="component-title">
                      Search
                    </Typography>
                  </div>
                )}
                <div className="actions">
                  <IconButton onClick={() => toggleFavorite(activeComponent)}>
                    <Star 
                      fill={favorites.includes(activeComponent) ? 'currentColor' : 'none'}
                    />
                  </IconButton>
                </div>
              </div>
            )}

            {/* Si NO hay componente activo y NO estamos en la sección 'profile', mostramos AGTFound */}
            {!activeComponent && activeSection !== 'profile' && (
              <Fade in={true}>
                <Box className="search-container">
                  <AGTFound
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onResults={handleSearchResults}
                    placeholder="¿Qué necesita tu negocio hoy?"
                    user={user}
                  />
                </Box>
              </Fade>
            )}
          </div>
        </TopBar>
        {renderContent()}
      </ContentContainer>
    </AppContainer>
  );
};

export default AGTUps;
