import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  LinearProgress,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import {
  TrendingUp,
  TrendingDown,
  Users,
  Target,
  Download,
  RefreshCcw,
  AlertCircle,
  Share2,
  Eye,
  UserCheck,
  ShoppingCart,
  Info,
  ArrowUpRight,
  ArrowDownRight,
  Package,
  Brain,
  PieChart as PieChartIcon,
  History // <-- Importamos el ícono de historial
} from 'lucide-react';

import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';

import { api } from '../../services/AGTUps-product-services/config';
import MarketAnalysisService from '../../services/AGTUps-product-services/marketAnalysisService';
import ProductSelector from './ProductSelector';
import MarketAnalysisHistory from './MarketAnalysisHistory'; // <-- Importamos nuestro componente de historial

/**
 * Sistema de Colores al estilo Apple (light/dark).
 */
const colors = {
  light: {
    label: {
      primary: '#000000'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      white: '#FFFFFF',
      black: '#000000',
      green: '#31C859' // Éxito
    },
    bg: {
      primaryElevated: '#FFFFFF'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#007BFE',
      backgroundBlur: '#FFFFFF',
      base: '#F2F2F7'
    },
    grouped: {
      bgPrimaryBase: '#FFFFFF'
    }
  },
  dark: {
    label: {
      primary: '#FFFFFF'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      white: '#FFFFFF',
      black: '#000000',
      green: '#2DD257' // Éxito en modo oscuro
    },
    bg: {
      primaryElevated: '#000000'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#0385FF',
      backgroundBlur: '#282828',
      base: '#272729'
    },
    grouped: {
      bgPrimaryBase: '#000000'
    }
  }
};

/**
 * Obtiene los colores de navegación al estilo Apple según el modo (light/dark).
 */
const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const currentColors = mode === 'light' ? colors.light : colors.dark;

  return {
    background: currentColors.bg.primaryElevated,
    textDefault: currentColors.label.primary,
    textSecondary: currentColors.system.grey,
    cardBackground: currentColors.ui.base,
    borderColor: currentColors.system.grey3,
    accent: currentColors.ui.accent,
    success: currentColors.system.green,
    error: '#FF3B30',   // rojo estilo iOS
    warning: '#FF9500', // naranja/amarillo estilo iOS
    secondary: '#AF52DE' // púrpura estilo iOS (opcional)
  };
};

/**
 * Paper con estilo Apple. Uso de bordes suaves y blur.
 */
const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    border: `1px solid ${navColors.borderColor}`,
    transition: 'all 0.3s ease',
    boxShadow: 'none',
    backdropFilter: 'blur(20px)',
    '&:hover': {
      borderColor: navColors.accent
    }
  };
});

/**
 * Card para métricas con estilo Apple (ligeramente translúcido).
 */
const MetricCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2.5),
    borderRadius: '12px',
    border: `1px solid ${navColors.borderColor}`,
    backdropFilter: 'blur(10px)',
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: navColors.accent,
      transform: 'translateY(-2px)'
    }
  };
});

/**
 * Select con estilo Apple (bordes suaves, opacidad).
 */
const StyledSelect = styled(Select)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    borderRadius: '12px',
    '& .MuiSelect-select': {
      padding: '12px 16px',
      fontSize: '0.95rem',
      fontWeight: 500
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: navColors.borderColor,
      borderWidth: '1px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: navColors.accent
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: navColors.accent,
      borderWidth: '1px'
    }
  };
});

/**
 * Barra de progreso estilo Apple (delgada, con rounded corners).
 */
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    height: 6,
    borderRadius: 3,
    backgroundColor: alpha(navColors.borderColor, 0.2),
    '& .MuiLinearProgress-bar': {
      borderRadius: 3,
      backgroundColor: navColors.accent
    }
  };
});

/**
 * Chip con estilo Apple (bordes suaves, transparencia).
 */
const StyledChip = styled(Chip)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    borderRadius: '8px',
    backgroundColor: alpha(navColors.accent, 0.1),
    color: navColors.accent,
    border: `1px solid ${alpha(navColors.accent, 0.2)}`,
    '&:hover': {
      backgroundColor: alpha(navColors.accent, 0.15)
    }
  };
});

/**
 * Overlay para indicar que se está actualizando algo.
 */
const LoadingOverlay = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 0.5)'
        : 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
    borderRadius: '16px'
  };
});

/**
 * Botón con estilo "Apple-like" similar al resto de componentes.
 */
const AppleStyledButton = styled(Button)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.accent, 0.1),
    color: navColors.accent,
    border: `1px solid ${alpha(navColors.accent, 0.2)}`,
    backdropFilter: 'blur(10px)',
    borderRadius: '8px',
    fontWeight: '600',
    textTransform: 'none',
    padding: theme.spacing(1, 2),
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: alpha(navColors.accent, 0.15)
    },
    '&:disabled': {
      opacity: 0.6,
      cursor: 'not-allowed'
    }
  };
});

/**
 * Componente para mostrar el botón centrado cuando no existe un análisis todavía.
 */
const CenteredAnalysisButton = ({ onClick, disabled, isGenerating }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: alpha(navColors.accent, 0.1),
        color: navColors.accent,
        border: `1px solid ${alpha(navColors.accent, 0.2)}`,
        backdropFilter: 'blur(10px)',
        borderRadius: '12px',
        fontWeight: '600',
        textTransform: 'none',
        padding: '12px 32px',
        fontSize: '1.1rem',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: alpha(navColors.accent, 0.15),
          transform: 'translateY(-2px)',
          boxShadow: `0 4px 12px ${alpha(navColors.accent, 0.2)}`
        },
        '&:disabled': {
          opacity: 0.6,
          cursor: 'not-allowed'
        }
      }}
      startIcon={
        isGenerating ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <Brain size={24} />
        )
      }
    >
      {isGenerating ? 'Generating Analysis...' : 'Generate Analysis with AGT'}
    </Button>
  );
};

const MarketAnalysis = ({ products }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);

  // Estado local
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [timeRange, setTimeRange] = useState('6m');
  const [analysisType, setAnalysisType] = useState('market');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [marketData, setMarketData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  // Nuevo estado para saber si se está generando análisis con IA
  const [generatingAI, setGeneratingAI] = useState(false);

  // Estado para mostrar/ocultar el diálogo de historial
  const [showHistory, setShowHistory] = useState(false);

  /**
   * useEffect que carga el análisis la primera vez
   * (cuando seleccionamos un producto y nunca antes se había cargado)
   */
  useEffect(() => {
    if (selectedProduct?.id && !initialLoadDone) {
      loadMarketData();
      setInitialLoadDone(true);
    }
  }, [selectedProduct, initialLoadDone]);

  /**
   * useEffect que actualiza cuando cambia el timeRange
   * (o si el producto cambia y ya habíamos cargado antes)
   */
  useEffect(() => {
    if (selectedProduct?.id && initialLoadDone) {
      loadMarketData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, timeRange, initialLoadDone]);

  /**
   * Carga un análisis existente o setea marketData = null si no existe
   */
  const loadMarketData = async () => {
    try {
      setLoading(true);
      setError(null);

      const existingAnalysis =
        await MarketAnalysisService.getMarketAnalysisByProduct(
          selectedProduct.id,
          { timeRange }
        );

      // existingAnalysis.data === null => significa "No existe análisis"
      if (!existingAnalysis.data) {
        setMarketData(null);
      } else {
        setMarketData(existingAnalysis.data);
      }

      setLastUpdated(new Date());
    } catch (err) {
      // Si no fue un 404, mostramos el error real
      // (asumimos que el service ya maneja el 404 retornando data=null)
      setError(`Error loading market data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Refresca (genera) un nuevo análisis (IA) pulsando el ícono de "refresh"
   */
  const handleRefreshData = async () => {
    if (!selectedProduct) return;
    try {
      setUpdating(true);
      const response = await MarketAnalysisService.createAnalysis(selectedProduct.id);
      setMarketData(response.data);
      setLastUpdated(new Date());
    } catch (err) {
      setError(`Error updating analysis: ${err.message}`);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Exporta a JSON el análisis actual
   */
  const handleExportData = async () => {
    try {
      if (!selectedProduct) return;

      const data = {
        marketAnalysis: marketData,
        product: selectedProduct,
        exportDate: new Date().toISOString(),
        timeRange,
        metadata: {
          version: '1.0',
          generatedBy: 'AGT Market Analysis',
          lastUpdated: lastUpdated?.toISOString()
        }
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `market-analysis-${selectedProduct.id}-${timeRange}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error exporting data: ${err.message}`);
    }
  };

  /**
   * Genera un análisis via IA (botón “Generate Analysis with AGT”)
   */
  const handleGenerateAIAnalysis = async () => {
    if (!selectedProduct?.id) return;
    try {
      setGeneratingAI(true);
      setError(null);

      // Llamada al endpoint que genera el análisis con IA
      const response = await api.post(`/market/generate/${selectedProduct.id}`);

      if (response.data?.success) {
        setMarketData(response.data.data);
        setLastUpdated(new Date());
        setInitialLoadDone(true); // Marcamos que sí tenemos datos
      }
    } catch (err) {
      setError(`Error generating AI analysis: ${err.message}`);
    } finally {
      setGeneratingAI(false);
    }
  };

  // Formatear data para el chart
  const formattedMarketData = useMemo(() => {
    if (!marketData) return null;
    return MarketAnalysisService.formatChartData(marketData);
  }, [marketData]);

  // Cálculo de métricas
  const metrics = useMemo(() => {
    if (!marketData) return null;
    return MarketAnalysisService.calculateMetrics(marketData);
  }, [marketData]);

  // Helper para asignar color en función de "confidence" (alto, medio, bajo)
  const getConfidenceColor = (confidence) => {
    if (confidence === 'high') return navColors.success;
    if (confidence === 'medium') return navColors.warning;
    return navColors.error;
  };

  // Colores para la gráfica Pie de competidores
  const competitorColors = [
    navColors.accent,
    navColors.success,
    navColors.warning,
    navColors.secondary,
    navColors.error
  ];

  return (
    <Box
      sx={{
        color: navColors.textDefault,
        backgroundColor: navColors.background,
        minHeight: '100vh',
        padding: theme.spacing(3),
        // Separación vertical entre contenedores principales
        '& > *:not(:last-child)': {
          mb: 4
        }
      }}
    >
      {/* Fila: Selector de producto + Botón "Generate Analysis with AGT" */}
      <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            <Box sx={{ flex: 1, minWidth: 200 }}>
              <ProductSelector
                products={products}
                selectedProduct={selectedProduct}
                onProductSelect={setSelectedProduct}
                compact
              />
            </Box>
            <AppleStyledButton
              startIcon={<Brain size={16} />}
              onClick={handleGenerateAIAnalysis}
              disabled={!selectedProduct || generatingAI}
            >
              {generatingAI ? 'Generating...' : 'Generate Analysis with AGT'}
            </AppleStyledButton>
          </Box>
        </Grid>
      </Grid>

      {/* Alerta de error (si aplica) */}
      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{
            borderRadius: '12px',
            backgroundColor: alpha(theme.palette.error.main, 0.1),
            color: theme.palette.error.main,
            border: `1px solid ${alpha(theme.palette.error.main, 0.2)}`,
            backdropFilter: 'blur(10px)',
            mb: 2
          }}
        >
          {error}
        </Alert>
      )}

      {/*
        Tres estados:
        1) No hay producto seleccionado
        2) Producto seleccionado pero marketData = null => "No Analysis Available"
        3) marketData != null => mostrar análisis
      */}
      {!selectedProduct ? (
        /* (1) NO hay producto seleccionado */
        <StyledPaper>
          <Box
            sx={{
              textAlign: 'center',
              py: 8,
              color: navColors.textDefault
            }}
          >
            <Package
              size={48}
              style={{
                marginBottom: '16px',
                color: navColors.textSecondary
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                letterSpacing: '-0.02em',
                marginBottom: 1
              }}
            >
              Select a Product
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: navColors.textSecondary
              }}
            >
              Choose a product to view its market analysis
            </Typography>
          </Box>
        </StyledPaper>
      ) : !marketData ? (
        /* (2) Hay producto, pero NO existe análisis => "No Analysis Available" */
        <>
          {loading ? (
            <Box sx={{ textAlign: 'center', py: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh',
                textAlign: 'center',
                px: 3
              }}
            >
              <Brain 
                size={64} 
                style={{ 
                  color: navColors.accent,
                  marginBottom: '24px',
                  opacity: 0.8
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  letterSpacing: '-0.02em',
                  marginBottom: 2,
                  color: navColors.textDefault
                }}
              >
                No Analysis Available
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: navColors.textSecondary,
                  maxWidth: 600,
                  marginBottom: 4
                }}
              >
                Generate a comprehensive market analysis for {selectedProduct.name} using our advanced AI technology.
              </Typography>
              <CenteredAnalysisButton
                onClick={handleGenerateAIAnalysis}
                disabled={generatingAI}
                isGenerating={generatingAI}
              />
            </Box>
          )}
        </>
      ) : (
        /* (3) Hay marketData => mostrar el análisis */
        <>
          {/* Controles: Time Range, Analysis Type, Refresh, Export, History */}
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: navColors.textSecondary,
                    '&.Mui-focused': {
                      color: navColors.accent
                    }
                  }}
                >
                  Time Range
                </InputLabel>
                <StyledSelect
                  value={timeRange}
                  label="Time Range"
                  onChange={(e) => setTimeRange(e.target.value)}
                  disabled={loading || updating}
                >
                  <MenuItem value="1m">Last Month</MenuItem>
                  <MenuItem value="3m">Last 3 Months</MenuItem>
                  <MenuItem value="6m">Last 6 Months</MenuItem>
                  <MenuItem value="1y">Last Year</MenuItem>
                </StyledSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    color: navColors.textSecondary,
                    '&.Mui-focused': {
                      color: navColors.accent
                    }
                  }}
                >
                  Analysis Type
                </InputLabel>
                <StyledSelect
                  value={analysisType}
                  label="Analysis Type"
                  onChange={(e) => setAnalysisType(e.target.value)}
                  disabled={loading || updating}
                >
                  <MenuItem value="market">Market Share</MenuItem>
                  <MenuItem value="competitors">Competitor Analysis</MenuItem>
                  <MenuItem value="trends">Market Trends</MenuItem>
                </StyledSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} display="flex" justifyContent="flex-end" gap={1}>
              {/* Botón de historial */}
              <Tooltip title="View Analysis History">
                <AppleStyledButton
                  onClick={() => setShowHistory(true)}
                  startIcon={<History size={16} />}
                  disabled={!selectedProduct}
                >
                  History
                </AppleStyledButton>
              </Tooltip>

              <Tooltip
                title={
                  lastUpdated
                    ? `Last updated: ${lastUpdated.toLocaleString()}`
                    : 'Never updated'
                }
              >
                <IconButton onClick={handleRefreshData} disabled={updating || loading}>
                  <RefreshCcw
                    size={20}
                    className={updating ? 'animate-spin' : ''}
                    style={{ color: navColors.accent }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export Analysis">
                <IconButton onClick={handleExportData} disabled={loading || !marketData}>
                  <Download size={20} style={{ color: navColors.accent }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share Analysis">
                <IconButton disabled={loading}>
                  <Share2 size={20} style={{ color: navColors.accent }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* Dialog para mostrar el historial */}
          <Dialog
            open={showHistory}
            onClose={() => setShowHistory(false)}
            maxWidth="lg"
            fullWidth
          >
            <DialogContent sx={{ p: 0 }}>
              {selectedProduct && <MarketAnalysisHistory productId={selectedProduct.id} />}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowHistory(false)}>Close</Button>
            </DialogActions>
          </Dialog>

          {loading && !marketData ? (
            <Box sx={{ textAlign: 'center', py: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            marketData && (
              <>
                {/* Sección principal de resumen */}
                <StyledPaper>
                  <Box sx={{ mb: 4 }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        letterSpacing: '-0.02em',
                        color: navColors.textDefault,
                        mb: 1
                      }}
                    >
                      {selectedProduct.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: navColors.textSecondary }}
                    >
                      Market Performance Overview
                    </Typography>
                  </Box>

                  {/* Métricas principales */}
                  {metrics && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <MetricCard>
                          {/* Market Share */}
                          <Tooltip 
                            title="El porcentaje del mercado total que tu producto captura en comparación con tus competidores. Por ejemplo, si hay 100 ventas totales en tu mercado y tu producto tiene 30, tu Market Share es del 30%." 
                            arrow 
                            placement="top"
                          >
                            <Typography
                              variant="body2"
                              sx={{ 
                                color: navColors.textSecondary, 
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                cursor: 'help'
                              }}
                            >
                              Market Share
                              <Info size={16} />
                            </Typography>
                          </Tooltip>

                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                              letterSpacing: '-0.02em',
                              color: navColors.textDefault
                            }}
                          >
                            {marketData?.marketShare}%
                          </Typography>
                          <StyledLinearProgress
                            variant="determinate"
                            value={marketData?.marketShare || 0}
                            sx={{ mt: 2 }}
                          />
                        </MetricCard>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <MetricCard>
                          {/* Market Fit */}
                          <Tooltip 
                            title="Indica qué tan bien tu producto satisface las necesidades reales del mercado. Un alto Market Fit significa que tu producto resuelve un problema importante para tus clientes y hay una fuerte demanda por él." 
                            arrow 
                            placement="top"
                          >
                            <Typography
                              variant="body2"
                              sx={{ 
                                color: navColors.textSecondary, 
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                cursor: 'help'
                              }}
                            >
                              Market Fit
                              <Info size={16} />
                            </Typography>
                          </Tooltip>

                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                              letterSpacing: '-0.02em',
                              color: navColors.textDefault
                            }}
                          >
                            {metrics?.marketFit || 0}%
                          </Typography>
                          <StyledLinearProgress
                            variant="determinate"
                            value={metrics?.marketFit || 0}
                            sx={{ mt: 2 }}
                          />
                        </MetricCard>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <MetricCard>
                          {/* Growth Rate */}
                          <Tooltip 
                            title="La velocidad a la que tu producto está creciendo en el mercado. Se mide como el porcentaje de incremento en ventas, usuarios o ingresos durante un período específico. Un Growth Rate del 20% significa que tu negocio creció un 20% respecto al período anterior." 
                            arrow 
                            placement="top"
                          >
                            <Typography
                              variant="body2"
                              sx={{ 
                                color: navColors.textSecondary, 
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                cursor: 'help'
                              }}
                            >
                              Growth Rate
                              <Info size={16} />
                            </Typography>
                          </Tooltip>

                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                              letterSpacing: '-0.02em',
                              color: navColors.textDefault
                            }}
                          >
                            {metrics?.growthPotential?.potential || 0}%
                          </Typography>
                          <StyledLinearProgress
                            variant="determinate"
                            value={metrics?.growthPotential?.potential || 0}
                            sx={{ mt: 2 }}
                          />
                        </MetricCard>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <MetricCard>
                          {/* Market Reach */}
                          <Tooltip 
                            title="El alcance geográfico de tu producto, medido en número de regiones o mercados donde está presente. Un mayor Market Reach indica que tu producto está disponible y es relevante en más lugares, lo que puede significar mayores oportunidades de crecimiento." 
                            arrow 
                            placement="top"
                          >
                            <Typography
                              variant="body2"
                              sx={{ 
                                color: navColors.textSecondary, 
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                cursor: 'help'
                              }}
                            >
                              Market Reach
                              <Info size={16} />
                            </Typography>
                          </Tooltip>

                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                              letterSpacing: '-0.02em',
                              color: navColors.textDefault
                            }}
                          >
                            {Object.keys(marketData?.geographicDistribution || {}).length} Regions
                          </Typography>
                          <StyledLinearProgress
                            variant="determinate"
                            value={
                              Object.keys(marketData?.geographicDistribution || {}).length * 10
                            }
                            sx={{ mt: 2 }}
                          />
                        </MetricCard>
                      </Grid>
                    </Grid>
                  )}
                </StyledPaper>

                {/* Gráfica (línea) + Panel de métricas a la derecha */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <StyledPaper style={{ position: 'relative' }}>
                      {updating && (
                        <LoadingOverlay>
                          <CircularProgress />
                        </LoadingOverlay>
                      )}
                      {/* Sección explicativa para el gráfico */}
                      <Box sx={{ mb: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Market Share Trends
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Tooltip title="Tu participación actual en el mercado a lo largo del tiempo" arrow>
                              <StyledChip
                                size="small"
                                icon={<Eye size={14} />}
                                label="Market Share"
                              />
                            </Tooltip>
                            <Tooltip title="Participación combinada de tus principales competidores" arrow>
                              <StyledChip
                                size="small"
                                icon={<Users size={14} />}
                                label="Competitors"
                              />
                            </Tooltip>
                            <Tooltip title="Tamaño total estimado del mercado disponible" arrow>
                              <StyledChip
                                size="small"
                                icon={<Target size={14} />}
                                label="Market Potential"
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                        
                        <Alert 
                          severity="info" 
                          sx={{ 
                            backgroundColor: alpha(navColors.accent, 0.1),
                            color: navColors.textDefault,
                            border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                            '& .MuiAlert-icon': {
                              color: navColors.accent
                            }
                          }}
                        >
                          <Typography variant="body2">
                            Este gráfico muestra la evolución de tu posición en el mercado a lo largo del tiempo. La línea azul representa tu Market Share, 
                            permitiéndote ver cómo ha crecido o disminuido tu participación. La línea morada muestra el comportamiento de tus competidores, 
                            y la línea verde indica el potencial total del mercado. El espacio entre tu línea y el potencial del mercado representa tu 
                            oportunidad de crecimiento.
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                              Cómo interpretarlo:
                            </Typography>
                            <Typography variant="body2" component="div">
                              • Si tu línea sube: Estás ganando participación en el mercado
                              <br />
                              • Si hay mucho espacio hasta la línea verde: Existe gran potencial de crecimiento
                              <br />
                              • Si la línea morada baja mientras la tuya sube: Estás ganando terreno frente a la competencia
                            </Typography>
                          </Box>
                        </Alert>
                      </Box>
                      {/* Fin sección explicativa */}

                      <Box sx={{ height: 320 }}>
                        {formattedMarketData?.marketShare?.data ? (
                          <ResponsiveContainer width="100%" height="100%">
                            <RechartsLineChart data={formattedMarketData.marketShare.data}>
                              <CartesianGrid
                                strokeDasharray="3 3"
                                stroke={alpha(navColors.textDefault, 0.2)}
                              />
                              <XAxis
                                dataKey="name"
                                tick={{ fill: navColors.textDefault }}
                              />
                              <YAxis tick={{ fill: navColors.textDefault }} />
                              <RechartsTooltip
                                contentStyle={{
                                  backgroundColor: alpha(navColors.cardBackground, 0.8),
                                  border: `1px solid ${navColors.borderColor}`,
                                  borderRadius: '8px',
                                  color: navColors.textDefault
                                }}
                              />
                              <Line
                                type="monotone"
                                dataKey="marketShare"
                                name="Market Share"
                                stroke={navColors.accent}
                                strokeWidth={2}
                                dot={{ r: 4 }}
                                activeDot={{ r: 8 }}
                              />
                              <Line
                                type="monotone"
                                dataKey="competitors"
                                name="Competitors"
                                stroke={navColors.secondary}
                                strokeWidth={2}
                                dot={{ r: 4 }}
                              />
                              <Line
                                type="monotone"
                                dataKey="potential"
                                name="Market Potential"
                                stroke={navColors.success}
                                strokeWidth={2}
                                dot={{ r: 4 }}
                              />
                              <Legend wrapperStyle={{ color: navColors.textDefault }} />
                            </RechartsLineChart>
                          </ResponsiveContainer>
                        ) : (
                          <Box
                            sx={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Typography sx={{ color: navColors.textSecondary }}>
                              No market share data available
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledPaper>
                          {/* Sección Market Position con introducción y tooltips */}
                          <Box sx={{ mb: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                              Market Position
                            </Typography>
                            <Alert 
                              severity="info" 
                              sx={{ 
                                backgroundColor: alpha(navColors.accent, 0.1),
                                color: navColors.textDefault,
                                border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                                '& .MuiAlert-icon': {
                                  color: navColors.accent
                                }
                              }}
                            >
                              <Typography variant="body2">
                                Esta sección muestra tu posicionamiento actual en el mercado desde tres perspectivas diferentes.
                                Cada métrica te ayuda a entender mejor cómo te comparas con la competencia y dónde hay oportunidades de mejora.
                              </Typography>
                            </Alert>
                          </Box>

                          {marketData ? (
                            <Box sx={{ display: 'grid', rowGap: 2 }}>
                              {/* Market Share */}
                              <Tooltip 
                                title={
                                  <Box>
                                    <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 1 }}>
                                      ¿Qué es Market Share?
                                    </Typography>
                                    <Typography variant="body2">
                                      Es el porcentaje del mercado que tu producto domina. Por ejemplo, si hay 1000 clientes en tu mercado 
                                      y 200 usan tu producto, tienes un 20% de Market Share.
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 'medium' }}>
                                      ¿Por qué es importante?
                                    </Typography>
                                    <Typography variant="body2">
                                      • Un Market Share alto indica liderazgo en el mercado
                                      <br />
                                      • Te ayuda a compararte directamente con la competencia
                                      <br />
                                      • Es un indicador clave de éxito comercial
                                    </Typography>
                                  </Box>
                                }
                                arrow
                                placement="top"
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 1,
                                    cursor: 'help'
                                  }}
                                >
                                  <Typography variant="body2">Market Share</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {metrics?.yearOverYear?.growth > 0 ? (
                                      <ArrowUpRight
                                        size={16}
                                        style={{ color: navColors.success }}
                                      />
                                    ) : (
                                      <ArrowDownRight
                                        size={16}
                                        style={{ color: navColors.error }}
                                      />
                                    )}
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color:
                                          metrics?.yearOverYear?.growth > 0
                                            ? navColors.success
                                            : navColors.error
                                      }}
                                    >
                                      {marketData.marketShare}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>

                              <StyledLinearProgress
                                variant="determinate"
                                value={marketData.marketShare}
                              />

                              {/* Competitive Position */}
                              <Tooltip 
                                title={
                                  <Box>
                                    <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 1 }}>
                                      ¿Qué es Competitive Position?
                                    </Typography>
                                    <Typography variant="body2">
                                      Mide qué tan fuerte es tu posición frente a los competidores directos, considerando factores como precio, 
                                      calidad, características únicas y satisfacción del cliente.
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 'medium' }}>
                                      Cómo interpretarlo:
                                    </Typography>
                                    <Typography variant="body2">
                                      • 0-30%: Posición débil, necesitas diferenciarte más
                                      <br />
                                      • 30-60%: Posición moderada, vas por buen camino
                                      <br />
                                      • 60-100%: Posición fuerte, eres líder en tu categoría
                                    </Typography>
                                  </Box>
                                }
                                arrow
                                placement="top"
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 1,
                                    cursor: 'help'
                                  }}
                                >
                                  <Typography variant="body2">Competitive Position</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ArrowUpRight
                                      size={16}
                                      style={{ color: navColors.success }}
                                    />
                                    <Typography
                                      variant="body2"
                                      sx={{ color: navColors.success }}
                                    >
                                      {metrics?.competitivePosition || 0}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>

                              <StyledLinearProgress
                                variant="determinate"
                                value={metrics?.competitivePosition || 0}
                              />

                              {/* Market Penetration */}
                              <Tooltip 
                                title={
                                  <Box>
                                    <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 1 }}>
                                      ¿Qué es Market Penetration?
                                    </Typography>
                                    <Typography variant="body2">
                                      Es el porcentaje del mercado potencial total que actualmente estás alcanzando. 
                                      Mide qué tanto has logrado entrar en el mercado disponible.
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 'medium' }}>
                                      Lo que nos dice:
                                    </Typography>
                                    <Typography variant="body2">
                                      • Bajo %: Gran oportunidad de crecimiento disponible
                                      <br />
                                      • % Medio: Buen avance, pero aún hay espacio
                                      <br />
                                      • Alto %: Dominas el mercado, considera expandirte a nuevos segmentos
                                    </Typography>
                                  </Box>
                                }
                                arrow
                                placement="top"
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 1,
                                    cursor: 'help'
                                  }}
                                >
                                  <Typography variant="body2">Market Penetration</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {metrics?.marketPenetration > 0 ? (
                                      <ArrowUpRight
                                        size={16}
                                        style={{ color: navColors.success }}
                                      />
                                    ) : (
                                      <ArrowDownRight
                                        size={16}
                                        style={{ color: navColors.error }}
                                      />
                                    )}
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color:
                                          metrics?.marketPenetration > 0
                                            ? navColors.success
                                            : navColors.error
                                      }}
                                    >
                                      {metrics?.marketPenetration || 0}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>

                              <StyledLinearProgress
                                variant="determinate"
                                value={metrics?.marketPenetration || 0}
                              />
                            </Box>
                          ) : (
                            <Box sx={{ textAlign: 'center', py: 3 }}>
                              <CircularProgress />
                            </Box>
                          )}
                        </StyledPaper>
                      </Grid>

                      {/* SECCIÓN Key Metrics */}
                      <Grid item xs={12}>
                        <StyledPaper>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 600,
                              marginBottom: 2
                            }}
                          >
                            Key Metrics
                          </Typography>
                          <Alert 
                            severity="info" 
                            sx={{ 
                              mb: 3,
                              backgroundColor: alpha(navColors.accent, 0.1),
                              color: navColors.textDefault,
                              border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                              '& .MuiAlert-icon': {
                                color: navColors.accent
                              }
                            }}
                          >
                            <Typography variant="body2">
                              Estos indicadores te muestran qué tan bien está funcionando tu producto en el mercado y su potencial de crecimiento futuro.
                            </Typography>
                          </Alert>
                          <Grid container spacing={2}>
                            {/* MARKET FIT con Tooltip detallado */}
                            <Grid item xs={6}>
                              <Tooltip
                                title={
                                  <Box>
                                    <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 1 }}>
                                      ¿Qué es Market Fit?
                                    </Typography>
                                    <Typography variant="body2">
                                      Es una medida de qué tan bien tu producto satisface las necesidades reales de tus clientes y resuelve sus problemas.
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 'medium' }}>
                                      Cómo interpretarlo:
                                    </Typography>
                                    <Typography variant="body2">
                                      • Bajo (0-40%): Tu producto necesita ajustes importantes
                                      <br />
                                      • Medio (40-70%): Vas bien pero hay espacio para mejorar
                                      <br />
                                      • Alto (70-100%): Excelente ajuste con el mercado
                                    </Typography>
                                  </Box>
                                }
                                arrow
                                placement="top"
                              >
                                <MetricCard sx={{ cursor: 'help' }}>
                                  <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                                    <UserCheck size={20} style={{ color: navColors.accent }} />
                                    <Typography variant="body2">Market Fit</Typography>
                                  </Box>
                                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                    {metrics?.marketFit || 'N/A'}%
                                  </Typography>
                                  {metrics?.yearOverYear?.growth && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                      {metrics.yearOverYear.growth > 0 ? (
                                        <TrendingUp
                                          size={14}
                                          style={{ color: navColors.success }}
                                        />
                                      ) : (
                                        <TrendingDown
                                          size={14}
                                          style={{ color: navColors.error }}
                                        />
                                      )}
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color:
                                            metrics.yearOverYear.growth > 0
                                              ? navColors.success
                                              : navColors.error
                                        }}
                                      >
                                        {Math.abs(metrics.yearOverYear.growth)}% vs last year
                                      </Typography>
                                    </Box>
                                  )}
                                </MetricCard>
                              </Tooltip>
                            </Grid>

                            {/* GROWTH POTENTIAL con Tooltip detallado */}
                            <Grid item xs={6}>
                              <Tooltip
                                title={
                                  <Box>
                                    <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 1 }}>
                                      ¿Qué es Growth Potential?
                                    </Typography>
                                    <Typography variant="body2">
                                      Indica el potencial de crecimiento futuro de tu producto, basado en tendencias del mercado, demanda no satisfecha y oportunidades identificadas.
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 'medium' }}>
                                      Nivel de confianza:
                                    </Typography>
                                    <Typography variant="body2">
                                      • Alto: Predicción basada en datos sólidos y tendencias claras
                                      <br />
                                      • Medio: Predicción basada en indicadores mixtos
                                      <br />
                                      • Bajo: Predicción con alta incertidumbre
                                    </Typography>
                                  </Box>
                                }
                                arrow
                                placement="top"
                              >
                                <MetricCard sx={{ cursor: 'help' }}>
                                  <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                                    <ShoppingCart size={20} style={{ color: navColors.secondary }} />
                                    <Typography variant="body2">Growth Potential</Typography>
                                  </Box>
                                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                    {metrics?.growthPotential?.potential || 'N/A'}%
                                  </Typography>
                                  {metrics?.growthPotential?.confidence && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                      <Brain size={14} style={{ color: navColors.accent }} />
                                      <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                        {metrics.growthPotential.confidence} confidence
                                      </Typography>
                                    </Box>
                                  )}
                                </MetricCard>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </StyledPaper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Distribución de competidores (Pie Chart) + Market Insights */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {/* Market Share Distribution */}
                    <StyledPaper>
                      <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                          Market Share Distribution
                        </Typography>
                        <Alert 
                          severity="info" 
                          sx={{ 
                            backgroundColor: alpha(navColors.accent, 0.1),
                            color: navColors.textDefault,
                            border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                            '& .MuiAlert-icon': {
                              color: navColors.accent
                            },
                            mb: 2
                          }}
                        >
                          <Typography variant="body2">
                            Este gráfico muestra cómo se distribuye la participación en el mercado entre tu producto y tus principales competidores. 
                            Te ayuda a visualizar tu posición relativa y la concentración del mercado.
                          </Typography>
                          
                          <Box sx={{ mt: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                              Cómo interpretarlo:
                            </Typography>
                            <Typography variant="body2" component="div">
                              • El tamaño de cada segmento representa el % del mercado que tiene cada competidor
                              <br />
                              • Mercado fragmentado: Muchos competidores con % similares
                              <br />
                              • Mercado concentrado: Pocos competidores con % grandes
                            </Typography>
                          </Box>
                        </Alert>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title="Al pasar el mouse sobre cada segmento, verás detalles adicionales como fortalezas y debilidades del competidor" arrow>
                            <StyledChip
                              size="small"
                              icon={<Info size={14} />}
                              label="Ver detalles"
                            />
                          </Tooltip>
                          
                          <Tooltip title="Los colores más oscuros indican mayor participación de mercado" arrow>
                            <StyledChip
                              size="small"
                              icon={<PieChartIcon size={14} />}
                              label="Guía de colores"
                            />
                          </Tooltip>
                        </Box>
                      </Box>

                      <Box sx={{ height: 320 }}>
                        {formattedMarketData?.competitors ? (
                          <ResponsiveContainer width="100%" height="100%">
                            <RechartsPieChart>
                              <Pie
                                data={formattedMarketData.competitors}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={100}
                                dataKey="value"
                                labelLine={false}
                                label={({ name, percent }) =>
                                  `${name} (${(percent * 100).toFixed(1)}%)`
                                }
                              >
                                {formattedMarketData.competitors.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={competitorColors[index % competitorColors.length]}
                                    className="transition-all duration-300 hover:opacity-80"
                                  />
                                ))}
                              </Pie>
                              <RechartsTooltip
                                content={({ active, payload }) => {
                                  if (!active || !payload?.[0]) return null;
                                  const data = payload[0].payload;
                                  return (
                                    <Box
                                      sx={{
                                        backgroundColor: alpha(navColors.cardBackground, 0.9),
                                        p: 2,
                                        borderRadius: '8px',
                                        color: navColors.textDefault,
                                        border: `1px solid ${navColors.borderColor}`
                                      }}
                                    >
                                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                        {data.name}
                                      </Typography>
                                      <Typography variant="body2">
                                        Market Share: {data.value.toFixed(1)}%
                                      </Typography>
                                      {data.strengths?.length > 0 && (
                                        <Box sx={{ mt: 1 }}>
                                          <Typography
                                            variant="caption"
                                            sx={{ color: navColors.success }}
                                          >
                                            Strengths: {data.strengths.join(', ')}
                                          </Typography>
                                        </Box>
                                      )}
                                      {data.weaknesses?.length > 0 && (
                                        <Box sx={{ mt: 1 }}>
                                          <Typography
                                            variant="caption"
                                            sx={{ color: navColors.error }}
                                          >
                                            Weaknesses: {data.weaknesses.join(', ')}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                              <Legend
                                verticalAlign="bottom"
                                height={36}
                                wrapperStyle={{ color: navColors.textDefault }}
                              />
                            </RechartsPieChart>
                          </ResponsiveContainer>
                        ) : (
                          <Box
                            sx={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Typography sx={{ color: navColors.textSecondary }}>
                              No competitor data available
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </StyledPaper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledPaper>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          Market Insights
                        </Typography>
                        <StyledChip
                          icon={<Brain size={16} style={{ color: navColors.accent }} />}
                          label="AI Generated"
                          size="small"
                        />
                      </Box>

                      {marketData?.insights ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <Alert 
                            severity="info" 
                            sx={{ 
                              backgroundColor: alpha(navColors.accent, 0.1),
                              color: navColors.textDefault,
                              border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                              mb: 3,
                              '& .MuiAlert-icon': {
                                color: navColors.accent
                              }
                            }}
                          >
                            <Typography variant="body2" sx={{ mb: 2 }}>
                              En esta sección encontrarás un resumen inteligente de tu posición en el mercado, incluyendo:
                            </Typography>
                            <Typography variant="body2" component="div">
                              • Oportunidades de crecimiento identificadas
                              <br />
                              • Amenazas potenciales del mercado
                              <br />
                              • Recomendaciones estratégicas personalizadas
                              <br />
                              • Tendencias relevantes del mercado
                            </Typography>
                          </Alert>

                          {/* Oportunidades */}
                          {marketData.insights.opportunities?.map((opportunity, index) => (
                            <Alert
                              key={index}
                              severity="info"
                              icon={<TrendingUp />}
                              sx={{
                                backgroundColor: alpha(navColors.accent, 0.1),
                                color: navColors.textDefault,
                                border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                                backdropFilter: 'blur(8px)'
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                Growth Opportunity
                              </Typography>
                              <Typography variant="body2">{opportunity.description}</Typography>
                              <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                <StyledChip
                                  label={`Impact: ${opportunity.impact}`}
                                  size="small"
                                />
                                {opportunity.timeframe && (
                                  <Chip
                                    label={opportunity.timeframe}
                                    size="small"
                                    sx={{
                                      borderRadius: '8px'
                                    }}
                                  />
                                )}
                              </Box>
                            </Alert>
                          ))}

                          {/* Amenazas */}
                          {marketData.insights.threats?.map((threat, index) => (
                            <Alert
                              key={index}
                              severity="warning"
                              icon={<AlertCircle />}
                              sx={{
                                backgroundColor: alpha(navColors.warning, 0.1),
                                color: navColors.textDefault,
                                border: `1px solid ${alpha(navColors.warning, 0.2)}`,
                                backdropFilter: 'blur(8px)'
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                Market Threat
                              </Typography>
                              <Typography variant="body2">{threat.description}</Typography>
                              <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                <StyledChip
                                  label={`Severity: ${threat.severity}`}
                                  size="small"
                                  sx={{
                                    backgroundColor: alpha(navColors.warning, 0.15),
                                    color: navColors.warning,
                                    borderColor: alpha(navColors.warning, 0.2)
                                  }}
                                />
                                <StyledChip
                                  label={`Probability: ${threat.probability}`}
                                  size="small"
                                  sx={{
                                    backgroundColor: alpha(navColors.warning, 0.15),
                                    color: navColors.warning,
                                    borderColor: alpha(navColors.warning, 0.2)
                                  }}
                                />
                              </Box>
                            </Alert>
                          ))}

                          {/* Recomendaciones */}
                          {marketData.insights.recommendations?.map(
                            (recommendation, index) => (
                              <Alert
                                key={index}
                                severity="success"
                                icon={<Info />}
                                sx={{
                                  backgroundColor: alpha(navColors.success, 0.1),
                                  color: navColors.textDefault,
                                  border: `1px solid ${alpha(navColors.success, 0.2)}`,
                                  backdropFilter: 'blur(8px)'
                                }}
                              >
                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                  Strategic Recommendation
                                </Typography>
                                <Typography variant="body2">
                                  {recommendation.strategy}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                  <StyledChip
                                    label={`Priority: ${recommendation.priority}`}
                                    size="small"
                                    sx={{
                                      backgroundColor: alpha(navColors.success, 0.15),
                                      color: navColors.success
                                    }}
                                  />
                                  <Chip
                                    label={recommendation.expectedImpact}
                                    size="small"
                                    sx={{
                                      borderRadius: '8px'
                                    }}
                                  />
                                </Box>
                              </Alert>
                            )
                          )}
                        </Box>
                      ) : (
                        // No hay insights en el marketData
                        <Box>
                          <Alert 
                            severity="info" 
                            sx={{ 
                              backgroundColor: alpha(navColors.accent, 0.1),
                              color: navColors.textDefault,
                              border: `1px solid ${alpha(navColors.accent, 0.2)}`,
                              mb: 3,
                              '& .MuiAlert-icon': {
                                color: navColors.accent
                              }
                            }}
                          >
                            <Typography variant="body2" sx={{ mb: 2 }}>
                              En esta sección encontrarás un resumen inteligente de tu posición en el mercado, incluyendo:
                            </Typography>
                            <Typography variant="body2" component="div">
                              • Oportunidades de crecimiento identificadas
                              <br />
                              • Amenazas potenciales del mercado
                              <br />
                              • Recomendaciones estratégicas personalizadas
                              <br />
                              • Tendencias relevantes del mercado
                            </Typography>
                          </Alert>
                          
                          <Box 
                            sx={{ 
                              textAlign: 'center', 
                              py: 4,
                              backgroundColor: alpha(navColors.background, 0.5),
                              borderRadius: 2,
                              border: `1px dashed ${navColors.borderColor}`
                            }}
                          >
                            <Brain 
                              size={32} 
                              style={{ 
                                color: navColors.textSecondary,
                                marginBottom: '16px',
                                opacity: 0.7
                              }}
                            />
                            <Typography 
                              sx={{ 
                                color: navColors.textSecondary,
                                mb: 1
                              }}
                            >
                              No hay insights disponibles en este momento
                            </Typography>
                            <Typography 
                              variant="body2"
                              sx={{ 
                                color: navColors.textSecondary,
                                opacity: 0.8
                              }}
                            >
                              Los insights se generarán automáticamente cuando haya suficientes datos de mercado
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </StyledPaper>
                  </Grid>
                </Grid>
              </>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default MarketAnalysis;
