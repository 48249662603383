import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_API_URL}/api/agtups/sales`;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Sales Strategy API Error:', error);
    const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
    return Promise.reject(new Error(errorMessage));
  }
);

class SalesStrategyService {
  // Crear nueva estrategia de ventas
  static async createSalesStrategy(strategyData) {
    try {
      const validatedData = this.formatStrategyData(strategyData);
      const validation = this.validateStrategyData(validatedData);
      
      if (!validation.isValid) {
        throw new Error(`Validation error: ${validation.errors.join(', ')}`);
      }

      const response = await api.post('/', validatedData);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating sales strategy: ${error.message}`);
    }
  }

  // Obtener estrategia por ID
  static async getSalesStrategyById(strategyId, productId) {
    try {
      const response = await api.get(`/${strategyId}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching sales strategy: ${error.message}`);
    }
  }

  // Obtener estrategias por producto
  static async getSalesStrategiesByProduct(productId, params = {}) {
    try {
      const response = await api.get(`/product/${productId}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching sales strategies: ${error.message}`);
    }
  }

  // Analizar rendimiento de ventas
  static async analyzeSalesPerformance(productId, params = {}) {
    try {
      const response = await api.get(`/performance/${productId}`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error analyzing sales performance: ${error.message}`);
    }
  }

  // Actualizar estrategia de ventas
  static async updateSalesStrategy(strategyId, productId, updateData) {
    try {
      const validatedData = this.formatStrategyData(updateData);
      const validation = this.validateStrategyData(validatedData);
      
      if (!validation.isValid) {
        throw new Error(`Validation error: ${validation.errors.join(', ')}`);
      }

      const response = await api.put(`/${strategyId}/${productId}`, validatedData);
      return response.data;
    } catch (error) {
      throw new Error(`Error updating sales strategy: ${error.message}`);
    }
  }

  // Eliminar estrategia de ventas
  static async deleteSalesStrategy(strategyId, productId) {
    try {
      const response = await api.delete(`/${strategyId}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error deleting sales strategy: ${error.message}`);
    }
  }

  // Formatear datos para gráficos
  static formatChartData(salesData) {
    return {
      salesTrend: {
        labels: salesData.map(data => new Date(data.date).toLocaleDateString()),
        datasets: [{
          label: 'Sales Volume',
          data: salesData.map(data => data.volume),
          borderColor: '#4f46e5',
          fill: false
        }]
      },
      channelDistribution: {
        labels: Object.keys(salesData.byChannel),
        datasets: [{
          data: Object.values(salesData.byChannel).map(channel => channel.sales),
          backgroundColor: [
            '#4f46e5',
            '#0891b2',
            '#059669',
            '#7c3aed',
            '#db2777'
          ]
        }]
      },
      performance: {
        labels: salesData.performance.map(data => data.period),
        datasets: [{
          label: 'Target Achievement %',
          data: salesData.performance.map(data => data.achievement),
          borderColor: '#059669',
          fill: false
        }]
      }
    };
  }

  // Validar datos de estrategia
  static validateStrategyData(strategyData) {
    const errors = [];

    if (!strategyData.productId) {
      errors.push('Product ID is required');
    }

    if (!strategyData.salesTargets || Object.keys(strategyData.salesTargets).length === 0) {
      errors.push('Sales targets are required');
    }

    if (!Array.isArray(strategyData.channelDistribution)) {
      errors.push('Channel distribution must be an array');
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  // Formatear datos de estrategia
  static formatStrategyData(strategyData) {
    return {
      productId: strategyData.productId,
      salesTargets: strategyData.salesTargets || {},
      channelDistribution: Array.isArray(strategyData.channelDistribution) 
        ? strategyData.channelDistribution 
        : [],
      salesFunnel: strategyData.salesFunnel || {},
      performanceMetrics: strategyData.performanceMetrics || {},
      teamPerformance: Array.isArray(strategyData.teamPerformance) 
        ? strategyData.teamPerformance 
        : [],
      geographicPerformance: strategyData.geographicPerformance || {},
      seasonalTrends: Array.isArray(strategyData.seasonalTrends) 
        ? strategyData.seasonalTrends 
        : [],
      customerSegments: Array.isArray(strategyData.customerSegments) 
        ? strategyData.customerSegments 
        : []
    };
  }

  // Calcular métricas de rendimiento
  static calculatePerformanceMetrics(salesData, targets) {
    return {
      achievement: (salesData.total / targets.total) * 100,
      channelEfficiency: Object.keys(salesData.byChannel).map(channel => ({
        channel,
        efficiency: (salesData.byChannel[channel].sales / targets.byChannel[channel]) * 100
      })),
      growth: ((salesData.total - salesData.previousPeriod) / salesData.previousPeriod) * 100,
      conversionRate: (salesData.conversions / salesData.leads) * 100
    };
  }
}

export default SalesStrategyService;
