// marketAnalysisService.js
import { api } from './config';

class MarketAnalysisService {
  async getMarketAnalysisByProduct(productId, params = {}) {
    try {
      const response = await api.get(`/market/${productId}`, { params });
      return response.data; 
      // Por ejemplo: { success: true, data: { ...análisis... } }
    } catch (error) {
      // Si el backend retorna 404, interpretamos que "no existe un análisis todavía"
      if (error.response && error.response.status === 404) {
        return { data: null };  // Devolvemos data: null en lugar de lanzar error
      }
      // Otros errores sí se lanzan
      throw new Error(`Error fetching market analysis: ${error.message}`);
    }
  }

  async createAnalysis(productId) {
    try {
      const response = await api.post(`/market/generate/${productId}`);
      return response.data; // { success: true, data: { ... } }
    } catch (error) {
      throw new Error(`Error creating analysis: ${error.message}`);
    }
  }

  formatChartData(marketData) {
    if (!marketData) return null;

    // Formato para el gráfico de líneas
    const marketShareData = marketData.historicalData?.map(point => ({
      name: new Date(point.date).toLocaleDateString(),
      marketShare: point.share,
      competitors: point.competitorShare,
      potential: point.marketPotential
    }));

    // Formato para el gráfico de pie
    const competitorData = marketData.competitors?.map(competitor => ({
      name: competitor.name,
      value: competitor.marketShare,
      strengths: competitor.strengths,
      weaknesses: competitor.weaknesses
    }));

    return {
      marketShare: {
        data: marketShareData || []
      },
      competitors: competitorData || []
    };
  }

  calculateMetrics(marketData) {
    if (!marketData) return null;

    return {
      marketFit: this._calculateMarketFit(marketData),
      yearOverYear: this._calculateYearOverYear(marketData),
      competitivePosition: this._calculateCompetitivePosition(marketData),
      marketPenetration: this._calculateMarketPenetration(marketData),
      growthPotential: this._calculateGrowthPotential(marketData)
    };
  }

  _calculateMarketFit(marketData) {
    // Calcula el market fit basado en varios factores
    const factors = [
      marketData.customerSatisfaction || 0,
      marketData.productMarketFit || 0,
      marketData.marketShare || 0
    ];
    
    return Math.round(factors.reduce((acc, val) => acc + val, 0) / factors.length);
  }

  _calculateYearOverYear(marketData) {
    if (!marketData.historicalData?.length) return { growth: 0 };
    
    const sortedData = [...marketData.historicalData].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    
    const currentShare = sortedData[0]?.share || 0;
    const lastYearShare = sortedData[sortedData.length - 1]?.share || 0;
    
    return {
      growth: lastYearShare ? ((currentShare - lastYearShare) / lastYearShare) * 100 : 0
    };
  }

  _calculateCompetitivePosition(marketData) {
    const totalMarket = marketData.competitors?.reduce(
      (sum, comp) => sum + (comp.marketShare || 0),
      marketData.marketShare || 0
    );
    
    return totalMarket ? Math.round((marketData.marketShare / totalMarket) * 100) : 0;
  }

  _calculateMarketPenetration(marketData) {
    return Math.round(
      ((marketData.activeUsers || 0) / (marketData.totalAddressableMarket || 1)) * 100
    );
  }

  _calculateGrowthPotential(marketData) {
    const potential = Math.min(
      100,
      Math.round(
        ((marketData.marketPotential || 0) - (marketData.marketShare || 0)) *
          (marketData.growthRate || 1)
      )
    );

    let confidence = 'medium';
    if (marketData.marketConfidence > 0.7) confidence = 'high';
    if (marketData.marketConfidence < 0.3) confidence = 'low';

    return { potential, confidence };
  }

  async getAnalysesHistory(productId, params = {}) {
    try {
      const response = await api.get(`/market/${productId}/history`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching analyses history: ${error.message}`);
    }
  }

  async compareAnalyses(productId, analysisIds) {
    try {
      const response = await api.post(`/market/${productId}/compare`, { analysisIds });
      return response.data;
    } catch (error) {
      throw new Error(`Error comparing analyses: ${error.message}`);
    }
  }

  async exportAnalysis(analysis) {
    try {
      const data = {
        analysis,
        exportDate: new Date().toISOString(),
        metadata: {
          version: '1.0',
          type: 'single_analysis'
        }
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `market-analysis-${analysis.analysisId}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw new Error(`Error exporting analysis: ${error.message}`);
    }
  }
}

export default new MarketAnalysisService();
