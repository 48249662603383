import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Link,
  ClickAwayListener
} from '@mui/material';
import {
  ArrowUp,
  MapPin,
  Sun,
  Search,
  Video,
  GraduationCap,
  DollarSign,
  X,
  Clock,
  Coffee,
  Book,
  Music,
  ShoppingCart,
  Briefcase,
  Utensils,
  Camera,
  Plane,
  Car,
  Smartphone,
  Heart,
  Zap,
  Umbrella,
  Calendar,
  Users,
  Scissors,
  Palette,
  Gift,
  HelpCircle,
} from 'lucide-react';
import debounce from 'lodash/debounce';
import { getSuggestions, TrendingSuggestions } from '../../data/suggestions';
import {
  CenteredContainer,
  ContentWrapper,
  TypedText,
  StyledForm,
  StyledTextField,
  StyledAutocomplete,
  SendButton,
  ClearButton,
  SectionsContainer,
  Section,
  CardsRow,
  CustomCard,
  CustomCardContent,
  StyledFooter,
  LinksContainer,
  FooterLink,
  HelpButton
} from './HomeLimited.styles';

const AGT_CARDS = [
  { Icon: GraduationCap, text: "AGT Edu", color: "system.grey" },
  { Icon: DollarSign, text: "AGT Ups", color: "ui.accent" }
];

const PROMPT_CARDS = [
  { Icon: Video, text: 'Top 10 viral videos today', color: "ui.accent" },
  { Icon: Music, text: 'Global music hit charts', color: "ui.accent" },
  { Icon: Search, text: 'Top headlines right now', color: "ui.accent" },
  { Icon: Calendar, text: 'Featured events this week', color: "ui.accent" },
  { Icon: Zap, text: 'Work productivity rankings', color: "ui.accent" },
  { Icon: Book, text: 'Best-selling books of the month', color: "ui.accent" },
  { Icon: ShoppingCart, text: 'E-commerce trends', color: "ui.accent" },
  { Icon: Users, text: 'Most active online communities', color: "ui.accent" },
  { Icon: Briefcase, text: 'Sectors with highest job demand', color: "ui.accent" },
  { Icon: Palette, text: 'Current design trends', color: "ui.accent" },
  { Icon: Heart, text: 'Popular conversation topics', color: "ui.accent" },
  { Icon: Gift, text: 'Most searched products online', color: "ui.accent" },
  { Icon: Smartphone, text: 'Trending apps right now', color: "ui.accent" },
];

const PromptCard = React.memo(({ Icon, text, color, onClick }) => {
  const theme = useTheme();
  
  return (
    <CustomCard onClick={onClick}>
      <CustomCardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 36,
            height: 36,
            borderRadius: '50%',
            background: `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), ${theme.palette.colors[color.split('.')[0]][color.split('.')[1]]}`,
          }}
        >
          <Icon size={24} color="white" />
        </Box>
        <Typography 
          variant="body2"
          sx={{
            color: theme.palette.colors.label.primary
          }}
        >
          {text}
        </Typography>
      </CustomCardContent>
    </CustomCard>
  );
});

const CardRow = React.memo(({ cardData, onCardClick }) => (
  <CardsRow>
    {cardData.map((card, index) => (
      <PromptCard
        key={`${card.text}-${index}`}
        {...card}
        onClick={() => onCardClick(card.text)}
      />
    ))}
  </CardsRow>
));

const HelpMenu = React.memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (isMobile) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const helpLinks = [
    { text: 'Help & FAQ', url: 'https://directiveai.org/knowledge-base' },
    { text: 'Integration Guide', url: 'https://directiveai.org/docs' },
    { text: 'Support', url: 'https://directiveai.org/support' },
  ];

  return (
    <>
      <HelpButton
        aria-label="help"
        onClick={handleClick}
      >
        <HelpCircle />
      </HelpButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.colors.bg.primaryElevated,
            border: `1px solid ${theme.palette.colors.system.bgPrimaryBase}`,
            '& .MuiMenuItem-root': {
              color: theme.palette.colors.label.primary,
              '&:hover': {
                backgroundColor: theme.palette.colors.grouped.bgPrimaryBase,
              },
            },
          },
        }}
      >
        {helpLinks.map((link) => (
          <MenuItem
            key={link.text}
            onClick={() => {
              window.open(link.url, '_blank');
              handleClose();
            }}
          >
            {link.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

const FooterLinks = React.memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) return null;

  return (
    <StyledFooter>
      <LinksContainer>
        <FooterLink href="https://directiveai.org/agt-pro" target="_blank" rel="noopener">
          Pro
        </FooterLink>
        <FooterLink href="https://directiveai.org/edu" target="_blank" rel="noopener">
          Edu
        </FooterLink>
        <FooterLink href="https://directiveai.org/ups-overview" target="_blank" rel="noopener">
          Ups
        </FooterLink>
        <FooterLink href="https://directiveai.org" target="_blank" rel="noopener">
          DirectiveAI
        </FooterLink>
        <FooterLink href="https://directiveai.org/integration-guide" target="_blank" rel="noopener">
          Simplicity
        </FooterLink>
        <FooterLink href="https://omverse.ai/" target="_blank" rel="noopener">
          Omverse
        </FooterLink>
      </LinksContainer>
      <Box>
        <HelpMenu />
      </Box>
    </StyledFooter>
  );
});

const InputField = React.memo(({ 
  state,
  onStateUpdate,
  onSubmit,
  inputRef
}) => {
  const handleInputChange = useCallback((event, newValue) => {
    onStateUpdate({
      input: newValue || '',
      showTrending: !newValue,
    });
    debouncedUpdateSuggestions(newValue);
  }, [onStateUpdate]);

  const debouncedUpdateSuggestions = useMemo(
    () => debounce((newValue) => {
      if (newValue) {
        const suggestions = getSuggestions(newValue);
        onStateUpdate({
          filteredSuggestions: suggestions,
          showSuggestions: true
        });
      } else {
        onStateUpdate({
          filteredSuggestions: [],
          showSuggestions: false
        });
      }
    }, 50),
    [onStateUpdate]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateSuggestions.cancel();
    };
  }, [debouncedUpdateSuggestions]);

  const handleClear = useCallback(() => {
    onStateUpdate({
      input: '',
      showTrending: true,
      showSuggestions: false,
      filteredSuggestions: []
    });
  }, [onStateUpdate]);

  return (
    <StyledAutocomplete
      freeSolo
      options={state.filteredSuggestions}
      inputValue={state.input}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          inputRef={inputRef}
          placeholder="Ask AGT"
          variant="outlined"
          fullWidth
          onFocus={() => {
            if (state.isInitialFocus) {
              onStateUpdate({ isInitialFocus: false });
              return;
            }
            if (!state.input) {
              onStateUpdate({ showTrending: true });
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state.input && (
                  <ClearButton onClick={handleClear}>
                    <X size={20} />
                  </ClearButton>
                )}
                <SendButton onClick={onSubmit}>
                  <ArrowUp size={24} />
                </SendButton>
              </>
            ),
          }}
        />
      )}
      open={state.showSuggestions}
      onOpen={() => onStateUpdate({ showSuggestions: true })}
      onClose={() => onStateUpdate({ showSuggestions: false })}
      autoComplete={false}
      autoHighlight={false}
      disableListWrap
      clearOnBlur={false}
      selectOnFocus={false}
      blurOnSelect
      filterOptions={(x) => x}
    />
  );
});

const HomeLimited = ({ onSendMessage, onAgtEduClick, onAgtUpsClick, onAuthRequired }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = useState({
    input: '',
    showTrending: false,
    showSuggestions: false,
    filteredSuggestions: [],
    isInitialFocus: true,
    randomPromptCards: [],
  });

  const inputRef = useRef(null);

  const updateState = useCallback((updates) => {
    setState(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e?.preventDefault();
    if (state.input.trim()) {
      onAuthRequired(state.input);
      updateState({
        input: '',
        showTrending: false,
        showSuggestions: false
      });
    }
  }, [state.input, onAuthRequired, updateState]);

  const cardActions = useMemo(() => ({
    "AGT Edu": onAgtEduClick,
    "AGT Ups": onAgtUpsClick,
    default: (text) => {
      updateState({
        input: text,
        showTrending: false,
        showSuggestions: false
      });
    }
  }), [onAgtEduClick, onAgtUpsClick, updateState]);

  const handleCardClick = useCallback((text) => {
    (cardActions[text] || cardActions.default)(text);
  }, [cardActions]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const shuffled = [...PROMPT_CARDS].sort(() => 0.5 - Math.random());
    updateState({ randomPromptCards: shuffled.slice(0, 2) });
  }, [updateState]);

  const handleClickAway = useCallback(() => {
    updateState({
      showTrending: false,
      showSuggestions: false
    });
  }, [updateState]);

  const MemoizedTrendingSuggestions = useMemo(() => React.memo(TrendingSuggestions), []);

  return (
    <CenteredContainer>
      <ContentWrapper>
        <TypedText variant="h1" align="center">
          Simplicity meets power.
        </TypedText>
        
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box width="100%" position="relative">
            <StyledForm onSubmit={handleSubmit}>
              <InputField
                state={state}
                onStateUpdate={updateState}
                onSubmit={handleSubmit}
                inputRef={inputRef}
              />
            </StyledForm>
            
            {state.showTrending && !state.input && (
              <Box 
                position="absolute" 
                width="100%" 
                zIndex={1} 
                className="trending-suggestions"
              >
                <MemoizedTrendingSuggestions onSuggestionClick={handleCardClick} />
              </Box>
            )}
          </Box>
        </ClickAwayListener>

        <SectionsContainer>
        <Section>
            <Typography variant="h5" sx={{ 
              width: '100%',
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: 1,
              fontSize: '21px',
              lineHeight: 1.19048,
              letterSpacing: '0.011em',
              color: theme.palette.colors.label.primary
            }}>
              Explore
            </Typography>
            <CardRow 
              cardData={AGT_CARDS}
              onCardClick={handleCardClick}
            />
          </Section>

          <Section>
            <Typography variant="h5" sx={{ 
              width: '100%',
              textAlign: 'left',
              fontWeight: 600,
              marginBottom: 1,
              fontSize: '21px',
              lineHeight: 1.19048,
              letterSpacing: '0.011em',
              color: theme.palette.colors.label.primary
            }}>
              Ask
            </Typography>
            <CardRow 
              cardData={state.randomPromptCards}
              onCardClick={handleCardClick}
            />
          </Section>
        </SectionsContainer>
      </ContentWrapper>
      
      {!isMobile && <FooterLinks />}
    </CenteredContainer>
  );
};

HomeLimited.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  onAgtEduClick: PropTypes.func.isRequired,
  onAgtUpsClick: PropTypes.func.isRequired,
  onAuthRequired: PropTypes.func.isRequired,
};

PromptCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

CardRow.propTypes = {
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.elementType.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  onCardClick: PropTypes.func.isRequired,
};

InputField.propTypes = {
  state: PropTypes.shape({
    input: PropTypes.string.isRequired,
    showTrending: PropTypes.bool.isRequired,
    showSuggestions: PropTypes.bool.isRequired,
    filteredSuggestions: PropTypes.array.isRequired,
    isInitialFocus: PropTypes.bool.isRequired,
  }).isRequired,
  onStateUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
};

FooterLinks.propTypes = {};
HelpMenu.propTypes = {};

export default React.memo(HomeLimited);