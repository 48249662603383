// VoiceInterface.js
import React, { useCallback, useMemo, memo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { voiceColors } from './voiceConfig';
import DynamicStormWave from './DynamicStormWave';
import VoicePreviewPlayer from './VoicePreviewPlayer';
import useMediaQuery from '@mui/material/useMediaQuery';

const Container = memo(styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  padding: '1rem',
  '@media (max-width: 600px)': {
    gap: '0.5rem',
    padding: '1rem',
  }
})));

const Circle = memo(styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'isMobile',
})(({ color, isMobile }) => ({
  position: 'relative',
  width: isMobile ? '12rem' : '16rem',
  height: isMobile ? '12rem' : '16rem',
  borderRadius: '50%',
  background: `linear-gradient(180deg, rgba(255,255,255,0.8) 0%, ${color} 100%)`,
  boxShadow: `0 0 30px ${color}33`,
  willChange: 'background, box-shadow',
  overflow: 'hidden',
})));

const VoiceCarousel = memo(styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
  marginTop: '2rem'
})));

const MobileVoiceSelector = memo(styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  marginTop: '1rem',
  padding: '0 1rem',
})));

const VoiceOption = memo(styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
  textAlign: 'center',
  opacity: active ? 1 : 0.4,
  transition: 'opacity 0.3s ease',
  willChange: 'opacity',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8
  }
})));

const MobileVoiceOption = memo(styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem'
})));

const ActionButton = memo(styled('button', {
  shouldForwardProp: (prop) => prop !== 'primary' && prop !== 'isMobile',
})(({ primary, isMobile, theme }) => ({
  width: '100%',
  padding: isMobile ? '0.5rem' : '0.75rem',
  borderRadius: '9999px',
  border: 'none',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  backgroundColor: primary ? '#0385FF' : 'transparent',
  color: primary ? '#FFFFFF' : theme.palette.text.primary,
  fontSize: isMobile ? '0.9rem' : '1rem',
  '&:hover': {
    backgroundColor: primary ? '#026fcc' : theme.palette.action.hover,
  }
})));

const VoiceOptionComponent = memo(({ voice, isSelected, onSelect }) => (
  <VoiceOption 
    active={isSelected} 
    onClick={() => onSelect(voice.id)}
  >
    <Typography
      variant="h6"
      sx={{ 
        opacity: isSelected ? 1 : 0.4,
        color: theme => theme.palette.text.primary
      }}
    >
      {voice.name}
    </Typography>
    <Typography
      variant="body2"
      sx={{ 
        opacity: isSelected ? 1 : 0.4,
        color: theme => theme.palette.text.secondary
      }}
    >
      {voice.description}
    </Typography>
  </VoiceOption>
));

const MobileVoiceInfo = memo(({ voice }) => (
  <Box sx={{ textAlign: 'center' }}>
    <Typography
      variant="h6"
      sx={{ 
        fontSize: '1rem',
        color: theme => theme.palette.text.primary
      }}
    >
      {voice.name}
    </Typography>
    <Typography
      variant="body2"
      sx={{ 
        fontSize: '0.8rem',
        color: theme => theme.palette.text.secondary
      }}
    >
      {voice.description}
    </Typography>
  </Box>
));

const VoiceInterface = ({
  voices,
  selectedVoice: initialVoice,
  onVoiceChange,
  disabled,
  onDone,
  onCancel
}) => {
  const [selectedVoice, setSelectedVoice] = React.useState(initialVoice || 'alloy');
  const [isPreviewPlaying, setIsPreviewPlaying] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const currentVoiceIndex = useMemo(() => 
    voices.findIndex(v => v.id === selectedVoice),
    [voices, selectedVoice]
  );

  const handleVoiceSelect = useCallback((voiceId) => {
    if (!disabled) {
      setSelectedVoice(voiceId);
      onVoiceChange?.(voiceId);
    }
  }, [disabled, onVoiceChange]);

  const handleNavigate = useCallback((direction) => {
    if (disabled) return;

    const newIndex = direction === 'next'
      ? (currentVoiceIndex + 1) % voices.length
      : (currentVoiceIndex - 1 + voices.length) % voices.length;
    
    const newVoice = voices[newIndex].id;
    setSelectedVoice(newVoice);
    onVoiceChange?.(newVoice);
  }, [currentVoiceIndex, voices, disabled, onVoiceChange]);

  const handleDone = useCallback(() => {
    onDone?.(selectedVoice);
  }, [onDone, selectedVoice]);

  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const currentColor = useMemo(() => 
    voiceColors[selectedVoice], 
    [selectedVoice]
  );

  const renderVoiceSelector = () => {
    if (!isMobile) {
      return (
        <VoiceCarousel>
          <IconButton 
            onClick={() => handleNavigate('prev')} 
            disabled={disabled}
            sx={{ color: theme => theme.palette.text.primary }}
          >
            <ChevronLeftIcon />
          </IconButton>

          {voices.map((voice) => (
            <VoiceOptionComponent
              key={voice.id}
              voice={voice}
              isSelected={selectedVoice === voice.id}
              onSelect={handleVoiceSelect}
            />
          ))}

          <IconButton 
            onClick={() => handleNavigate('next')} 
            disabled={disabled}
            sx={{ color: theme => theme.palette.text.primary }}
          >
            <ChevronRightIcon />
          </IconButton>
        </VoiceCarousel>
      );
    }

    const isFirst = currentVoiceIndex === 0;
    const isLast = currentVoiceIndex === voices.length - 1;
    
    return (
      <MobileVoiceSelector>
        <MobileVoiceOption>
          <IconButton 
            onClick={() => !isFirst && handleNavigate('prev')} 
            sx={{ 
              color: theme => theme.palette.text.primary,
              visibility: isFirst ? 'hidden' : 'visible',
              cursor: isFirst ? 'default' : 'pointer'
            }}
            disabled={isFirst}
          >
            <ChevronLeftIcon />
          </IconButton>
          
          <MobileVoiceInfo voice={voices[currentVoiceIndex]} />
          
          <IconButton 
            onClick={() => !isLast && handleNavigate('next')} 
            sx={{ 
              color: theme => theme.palette.text.primary,
              visibility: isLast ? 'hidden' : 'visible',
              cursor: isLast ? 'default' : 'pointer'
            }}
            disabled={isLast}
          >
            <ChevronRightIcon />
          </IconButton>
        </MobileVoiceOption>
      </MobileVoiceSelector>
    );
  };

  return (
    <Container>
      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        sx={{ 
          mb: isMobile ? 2 : 4,
          color: theme => theme.palette.text.primary
        }}
      >
        Choose a voice
      </Typography>

      <Circle 
        color={currentColor} 
        isMobile={isMobile}
        sx={{
          animation: isPreviewPlaying 
            ? 'pulse 2s infinite ease-in-out' 
            : 'none'
        }}
      >
        <DynamicStormWave 
          primaryColor={currentColor}
          secondaryColor={`${currentColor}88`}
          voiceType={selectedVoice}
          cloudColor="rgba(255, 255, 255, 0.85)"
        />
      </Circle>

      {renderVoiceSelector()}

      <Box sx={{ 
        width: isMobile ? '100%' : '250px', 
        mt: isMobile ? 2 : 4, 
        display: 'flex', 
        flexDirection: 'column', 
        gap: isMobile ? 1 : 2,
        padding: isMobile ? '0 1rem' : 0
      }}>
        <ActionButton primary onClick={handleDone} isMobile={isMobile}>
          Done
        </ActionButton>
        <ActionButton onClick={handleCancel} isMobile={isMobile}>
          Cancel
        </ActionButton>
      </Box>

      <VoicePreviewPlayer
        voiceId={selectedVoice}
        onPlayingChange={setIsPreviewPlaying}
      />

      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1) }
            50% { transform: scale(1.02) }
            100% { transform: scale(1) }
          }
        `}
      </style>
    </Container>
  );
};

export default memo(VoiceInterface);
