import React, { useState, useCallback, useRef, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Popover, 
  IconButton, 
  Typography,
  List,
  ListItem,
  ListItemText,
  Badge
} from '@mui/material';
import { Search, X, ArrowDown, ArrowUp } from 'lucide-react';

const AGTMakerSearch = ({ content, contentRef, onHighlight }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(-1);
  const searchButtonRef = useRef(null);

  const findSearchMatches = useCallback((text) => {
    if (!text.trim()) return [];
    
    const searchableElements = contentRef.current?.querySelectorAll('.searchable-content');
    const results = [];
    const searchTerm = text.toLowerCase();

    searchableElements?.forEach((element, elementIndex) => {
      const content = element.textContent;
      let lastIndex = 0;
      let lineIndex = 0;

      content.split('\n').forEach((line, index) => {
        let position = line.toLowerCase().indexOf(searchTerm);
        while (position !== -1) {
          results.push({
            element,
            elementIndex,
            line: line.trim(),
            lineIndex: lineIndex,
            startIndex: lastIndex + position,
            endIndex: lastIndex + position + searchTerm.length,
            context: line.slice(Math.max(0, position - 30), position + searchTerm.length + 30).trim()
          });
          position = line.toLowerCase().indexOf(searchTerm, position + 1);
        }
        lastIndex += line.length + 1;
        lineIndex++;
      });
    });
    
    return results;
  }, [contentRef]);

  const clearHighlights = useCallback(() => {
    if (!contentRef.current) return;
    
    const highlights = contentRef.current.querySelectorAll('.search-highlight-overlay');
    
    highlights.forEach((el) => {
      try {
        const parent = el.parentNode;
        const text = el.textContent;
        const textNode = document.createTextNode(text);
        parent.replaceChild(textNode, el);
        parent.normalize();
      } catch (error) {
        // Silent error handling
      }
    });
  }, []);

  const createHighlightOverlay = useCallback((result) => {
    if (!result?.element) return null;

    try {
      const walker = document.createTreeWalker(
        result.element,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      let node;
      let currentOffset = 0;
      let foundNode = null;

      while ((node = walker.nextNode()) && !foundNode) {
        const nodeLength = node.textContent.length;
        if (currentOffset + nodeLength > result.startIndex) {
          foundNode = node;
          break;
        }
        currentOffset += nodeLength;
      }

      if (!foundNode) return null;

      const range = document.createRange();
      const relativeStart = result.startIndex - currentOffset;
      const relativeEnd = result.endIndex - currentOffset;
      
      range.setStart(foundNode, relativeStart);
      range.setEnd(foundNode, relativeEnd);

      const highlight = document.createElement('span');
      highlight.className = 'search-highlight-overlay';
      
      const isCodeElement = result.element.closest('pre, code');
      if (isCodeElement) {
        highlight.style.backgroundColor = 'rgba(255, 255, 0, 0.2)';
        highlight.style.color = 'inherit';
      } else {
        highlight.style.backgroundColor = 'rgba(255, 255, 0, 0.3)';
      }
      highlight.style.borderRadius = '2px';
      
      range.surroundContents(highlight);
      
      return highlight;
    } catch (error) {
      return null;
    }
  }, []);

  const scrollToMatch = useCallback((resultIndex) => {
    if (!contentRef.current || resultIndex < 0 || resultIndex >= searchResults.length) {
      return;
    }
    
    const result = searchResults[resultIndex];
    clearHighlights();

    const highlight = createHighlightOverlay(result);
    
    if (highlight) {
      highlight.classList.add('search-highlight-active');
      highlight.style.backgroundColor = 'rgba(255, 165, 0, 0.5)';
      
      highlight.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [searchResults, searchQuery, createHighlightOverlay, clearHighlights]);

  const handleSearchChange = useCallback((event) => {
    const value = event.target.value;
    setSearchQuery(value);
    const results = findSearchMatches(value);
    setSearchResults(results);
    setCurrentResultIndex(-1);
    
    clearHighlights();
    
    if (onHighlight) {
      onHighlight(value ? { term: value, results } : null);
    }
  }, [findSearchMatches, onHighlight, clearHighlights]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setSearchResults([]);
    setCurrentResultIndex(-1);
    clearHighlights();
    
    if (onHighlight) {
      onHighlight(null);
    }
  }, [onHighlight, clearHighlights]);

  const handleNextResult = useCallback(() => {
    if (searchResults.length === 0) return;
    
    const nextIndex = currentResultIndex + 1 >= searchResults.length ? 0 : currentResultIndex + 1;
    setCurrentResultIndex(nextIndex);
    scrollToMatch(nextIndex);
  }, [currentResultIndex, searchResults.length, scrollToMatch]);

  const handlePreviousResult = useCallback(() => {
    if (searchResults.length === 0) return;

    const prevIndex = currentResultIndex - 1 < 0 ? searchResults.length - 1 : currentResultIndex - 1;
    setCurrentResultIndex(prevIndex);
    scrollToMatch(prevIndex);
  }, [currentResultIndex, searchResults.length, scrollToMatch]);

  const handleSearchClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setSearchQuery('');
    setSearchResults([]);
    setCurrentResultIndex(-1);
    clearHighlights();
    
    if (onHighlight) {
      onHighlight(null);
    }
  }, [onHighlight, clearHighlights]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!anchorEl) return;

      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();
        handlePreviousResult();
      } else if (event.key === 'Enter') {
        event.preventDefault();
        handleNextResult();
      } else if (event.key === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [anchorEl, handleNextResult, handlePreviousResult, handleClose]);

  useEffect(() => {
    return () => {
      clearHighlights();
    };
  }, [clearHighlights]);

  return (
    <>
      <Badge 
        badgeContent={searchResults.length} 
        color="primary"
        invisible={searchResults.length === 0}
        sx={{
          '& .MuiBadge-badge': {
            right: 5,
            top: 5
          }
        }}
      >
        <IconButton
          ref={searchButtonRef}
          onClick={handleSearchClick}
          color="inherit"
          aria-label="search content"
          sx={{
            height: '36px',
            width: '36px',
            borderRadius: '12px',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <Search size={20} />
        </IconButton>
      </Badge>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            width: '400px',
            maxHeight: '500px',
            borderRadius: '28px',
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
            border: (theme) => theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.05)' : 'none',
            marginBottom: '8px',
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <TextField
              fullWidth
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search in content..."
              autoFocus
              InputProps={{
                sx: {
                  borderRadius: '12px',
                  height: '36px',
                  '& input': {
                    padding: '8px 0',
                    color: (theme) => theme.palette.mode === 'dark' ? '#fff' : 'inherit',
                    '&::placeholder': {
                      color: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'inherit',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#2c2e33' : 'rgba(0, 0, 0, 0.06)',
                  transition: 'all 0.2s ease',
                },
                startAdornment: (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.5, mr: 1 }}>
                    <Search size={18} style={{ color: '#0385FF' }} />
                  </Box>
                ),
                endAdornment: searchQuery && (
                  <IconButton 
                    size="small" 
                    onClick={handleClearSearch}
                    sx={{ 
                      p: 0.5,
                      mr: 0.5,
                      color: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                      }
                    }}
                  >
                    <X size={14} />
                  </IconButton>
                )
              }}
            />
          </Box>

          {searchResults.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 1,
              color: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary'
            }}>
              <Typography variant="body2">
                {searchResults.length} matches found
              </Typography>
              <Box>
                <IconButton 
                  size="small" 
                  onClick={handlePreviousResult}
                  title="Previous match (Shift + Enter)"
                  sx={{ 
                    color: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'inherit',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <ArrowUp size={16} />
                </IconButton>
                <IconButton 
                  size="small" 
                  onClick={handleNextResult}
                  title="Next match (Enter)"
                  sx={{ 
                    color: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'inherit',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <ArrowDown size={16} />
                </IconButton>
              </Box>
            </Box>
          )}

          <List dense>
            {searchResults.map((result, index) => (
              <ListItem
                key={`${result.elementIndex}-${result.lineIndex}-${result.startIndex}`}
                component="button"
                selected={index === currentResultIndex}
                onClick={() => {
                  setCurrentResultIndex(index);
                  scrollToMatch(index);
                }}
                sx={{
                  borderRadius: '8px',
                  mb: 0.5,
                  color: (theme) => theme.palette.mode === 'dark' ? '#fff' : 'inherit',
                  '&:hover': {
                    backgroundColor: (theme) => 
                      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#0385FF',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#0374e4',
                    },
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography 
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.mode === 'dark' && !index === currentResultIndex ? 
                          'rgba(255, 255, 255, 0.9)' : 'inherit'
                      }}
                    >
                      {result.context.slice(0, result.startIndex - result.context.indexOf(result.context.trim()))}
                      <span style={{ 
                        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 0, 0.15)' : 'rgba(255, 255, 0, 0.3)',
                        borderRadius: '2px',
                        padding: '0 2px'
                      }}>
                        {result.context.slice(
                          result.startIndex - result.context.indexOf(result.context.trim()),
                          result.endIndex - result.context.indexOf(result.context.trim())
                        )}
                      </span>
                      {result.context.slice(result.endIndex - result.context.indexOf(result.context.trim()))}
                    </Typography>
                  }
                  secondary={
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: (theme) => theme.palette.mode === 'dark' ? 
                          'rgba(255, 255, 255, 0.5)' : 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }}
                    >
                      <span>Line {result.lineIndex + 1}</span>
                      {result.element.closest('pre, code') && (
                        <span style={{ 
                          backgroundColor: 'rgba(0, 0, 0, 0.1)', 
                          padding: '2px 6px', 
                          borderRadius: '4px',
                          fontSize: '0.65rem'
                        }}>
                          code
                        </span>
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default React.memo(AGTMakerSearch);