import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  LinearProgress, 
  Chip, 
  Avatar,
  AvatarGroup,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  TrendingUp, 
  Target,
  Book,
  Brain,
  Award,
  Clock,
  Calendar,
  CheckCircle,
  Star,
  BarChart2,
  Zap,
  BookOpen,
  Lightbulb,
  Users,
  MessageSquare
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const LearningPathCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
}));

const SkillCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
}));

const AchievementCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const AGTGrowth = () => {
  const [activePath] = useState('Advanced Mathematics');

  return (
    <Box className="space-y-6">
      {/* Learning Path Overview */}
      <StyledPaper>
        <Box className="flex justify-between items-start mb-6">
          <Box>
            <Typography variant="h6" gutterBottom>Current Learning Path</Typography>
            <Typography variant="h4" className="mb-2">{activePath}</Typography>
            <Box className="flex gap-2">
              <Chip 
                icon={<Brain size={14} />} 
                label="Advanced Level" 
                color="primary" 
              />
              <Chip 
                icon={<Clock size={14} />} 
                label="Est. 6 months" 
                variant="outlined" 
              />
            </Box>
          </Box>
          <ActionButton>
            <BarChart2 size={16} />
            View Detailed Progress
          </ActionButton>
        </Box>

        <Box className="mt-6">
          <Box className="flex justify-between mb-2">
            <Typography variant="body2" color="text.secondary">
              Overall Progress
            </Typography>
            <Typography variant="body2" color="text.secondary">
              65%
            </Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={65}
            sx={{ height: 8, borderRadius: 4 }}
          />
        </Box>
      </StyledPaper>

      {/* Learning Stats and Next Steps */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Current Progress</Typography>
            
            <Grid container spacing={3}>
              {/* Current Module Progress */}
              <Grid item xs={12}>
                <LearningPathCard>
                  <Box className="flex justify-between items-center mb-3">
                    <Box>
                      <Typography variant="subtitle1">Multivariable Calculus</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Current Module - Week 3 of 8
                      </Typography>
                    </Box>
                    <Chip 
                      label="In Progress"
                      color="primary"
                      size="small"
                    />
                  </Box>
                  <Box className="space-y-3">
                    <Box>
                      <Box className="flex justify-between mb-1">
                        <Typography variant="body2">Module Progress</Typography>
                        <Typography variant="body2">75%</Typography>
                      </Box>
                      <LinearProgress 
                        variant="determinate" 
                        value={75}
                        sx={{ height: 6, borderRadius: 3 }}
                      />
                    </Box>
                    <Box className="flex gap-2">
                      <Chip 
                        size="small" 
                        icon={<CheckCircle size={14} />}
                        label="6/8 Topics Complete" 
                      />
                      <Chip 
                        size="small" 
                        icon={<Star size={14} />}
                        label="85% Quiz Average" 
                      />
                    </Box>
                  </Box>
                </LearningPathCard>
              </Grid>

              {/* Key Skills Progress */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>Key Skills Progress</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SkillCard>
                      <Box className="flex justify-between items-start mb-2">
                        <Typography variant="subtitle2">Partial Derivatives</Typography>
                        <Chip label="Advanced" size="small" color="success" />
                      </Box>
                      <LinearProgress 
                        variant="determinate" 
                        value={90}
                        sx={{ height: 4, borderRadius: 2 }}
                      />
                    </SkillCard>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SkillCard>
                      <Box className="flex justify-between items-start mb-2">
                        <Typography variant="subtitle2">Triple Integrals</Typography>
                        <Chip label="Intermediate" size="small" color="primary" />
                      </Box>
                      <LinearProgress 
                        variant="determinate" 
                        value={75}
                        sx={{ height: 4, borderRadius: 2 }}
                      />
                    </SkillCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            {/* Learning Stats */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Learning Stats</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-blue-50 rounded-lg text-center">
                      <BookOpen size={24} className="mx-auto mb-1 text-blue-500" />
                      <Typography variant="h6">32</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Study Hours
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-green-50 rounded-lg text-center">
                      <Lightbulb size={24} className="mx-auto mb-1 text-green-500" />
                      <Typography variant="h6">28</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Topics Mastered
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-purple-50 rounded-lg text-center">
                      <Target size={24} className="mx-auto mb-1 text-purple-500" />
                      <Typography variant="h6">92%</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Goals Met
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="p-3 bg-yellow-50 rounded-lg text-center">
                      <Brain size={24} className="mx-auto mb-1 text-yellow-500" />
                      <Typography variant="h6">4.8</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Learning Score
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>

            {/* Learning Achievements */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Recent Achievements</Typography>
                <Box className="space-y-3">
                  <AchievementCard>
                    <Award size={24} className="text-yellow-500" />
                    <Box>
                      <Typography variant="subtitle2">Perfect Quiz Score</Typography>
                      <Typography variant="caption" color="text.secondary">
                        Multivariable Calculus - Week 2
                      </Typography>
                    </Box>
                  </AchievementCard>
                  <AchievementCard>
                    <Zap size={24} className="text-blue-500" />
                    <Box>
                      <Typography variant="subtitle2">Study Streak</Typography>
                      <Typography variant="caption" color="text.secondary">
                        7 days consecutive learning
                      </Typography>
                    </Box>
                  </AchievementCard>
                </Box>
              </StyledPaper>
            </Grid>

            {/* Study Group */}
            <Grid item xs={12}>
              <StyledPaper>
                <Box className="flex justify-between items-center mb-3">
                  <Typography variant="h6">Study Group</Typography>
                  <AvatarGroup max={3}>
                    <Avatar>JD</Avatar>
                    <Avatar>MS</Avatar>
                    <Avatar>RJ</Avatar>
                  </AvatarGroup>
                </Box>
                <Box className="space-y-2">
                  <Typography variant="body2" color="text.secondary">
                    Next session in 2 hours
                  </Typography>
                  <Box className="flex gap-2">
                    <ActionButton className="flex-1">
                      <Users size={16} />
                      Join Group
                    </ActionButton>
                    <ActionButton className="flex-1">
                      <MessageSquare size={16} />
                      Chat
                    </ActionButton>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Recommended Next Steps */}
      <StyledPaper>
        <Typography variant="h6" gutterBottom>Recommended Next Steps</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box className="p-4 border rounded-lg">
              <Box className="flex items-center gap-2 mb-2">
                <Book size={20} className="text-blue-500" />
                <Typography variant="subtitle1">Practice Problems</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Reinforce your understanding of partial derivatives
              </Typography>
              <ActionButton className="mt-2">
                Start Practice
              </ActionButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="p-4 border rounded-lg">
              <Box className="flex items-center gap-2 mb-2">
                <Users size={20} className="text-green-500" />
                <Typography variant="subtitle1">Group Session</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Join upcoming study group on surface integrals
              </Typography>
              <ActionButton className="mt-2">
                Schedule Session
              </ActionButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="p-4 border rounded-lg">
              <Box className="flex items-center gap-2 mb-2">
                <Brain size={20} className="text-purple-500" />
                <Typography variant="subtitle1">Assessment</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Take the module review quiz to test your knowledge
              </Typography>
              <ActionButton className="mt-2">
                Start Quiz
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
};

export default AGTGrowth;