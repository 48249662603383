// VoiceIndicator.js
import React, { memo, useEffect, useState } from 'react';
import { Box, Typography, Tooltip, useTheme } from '@mui/material';
import { keyframes } from '@emotion/react';
import DynamicStormWave from './DynamicStormWave';

const subtle3DFloat = keyframes`
  0% {
    transform: translateY(0px) translateX(0px);
  }
  25% {
    transform: translateY(-2px) translateX(1px);
  }
  75% {
    transform: translateY(2px) translateX(-1px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
`;

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const pulseGlow = (color) => keyframes`
  0% {
    box-shadow: 0 0 10px ${color}33;
  }
  50% {
    box-shadow: 0 0 20px ${color}66;
  }
  100% {
    box-shadow: 0 0 10px ${color}33;
  }
`;

const VoiceIndicator = memo(({ 
  color, 
  name, 
  voiceType = 'alloy',
  onClick, 
  tooltip = "Change voice",
  isSpeaking = false,
  isThinking = false
}) => {
  const [waveOpacity, setWaveOpacity] = useState(0.85);
  const isActive = isSpeaking || isThinking;
  const theme = useTheme();
  
  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setWaveOpacity(prev => 0.7 + Math.random() * 0.3);
      }, 100);
    }
    return () => clearInterval(interval);
  }, [isActive]);

  const glowColor = theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255'
    : color;

  const cloudColor = `rgba(255, 255, 255, ${waveOpacity})`;

  return (
    <Tooltip title={tooltip}>
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '4px',
          borderRadius: '12px',
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.1)'
              : `${color}22`
          }
        }}
        role="button"
        aria-label={`Selected voice: ${name}. Click to change.`}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}
      >
        <Box
          sx={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            background: `linear-gradient(180deg, rgba(255,255,255,0.8) 0%, ${color} 100%)`,
            position: 'relative',
            overflow: 'hidden',
            animation: isActive 
              ? `${subtle3DFloat} 3s ease-in-out infinite, ${pulseGlow(glowColor)} 2s ease-in-out infinite` 
              : 'none',
            transition: 'all 0.3s ease',
            transform: 'translateZ(0)',
          }}
        >
          <DynamicStormWave 
            primaryColor={color}
            secondaryColor={`${color}88`}
            voiceType={voiceType}
            cloudColor={cloudColor}
          />
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0px'
          }}
        >
          <Typography 
            variant="subtitle1" 
            sx={{
              fontSize: '0.875rem',
              fontWeight: 500,
              color: theme.palette.text.primary,
              opacity: isActive ? 1 : 0.9,
              transition: 'opacity 0.3s ease'
            }}
          >
            {name}
          </Typography>
          
          {isActive && (
            <Typography
              variant="caption"
              sx={{
                fontSize: '0.75rem',
                fontWeight: 400,
                marginTop: '-2px',
                background: theme.palette.mode === 'dark'
                  ? 'linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.7) 100%)'
                  : 'linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.7) 100%)',
                backgroundSize: '200% auto',
                color: 'transparent',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                animation: `${shimmerAnimation} 2s linear infinite`,
                transition: 'all 0.3s ease'
              }}
            >
              {isThinking ? 'Thinking' : 'Speaking'}
            </Typography>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
});

VoiceIndicator.displayName = 'VoiceIndicator';

export default VoiceIndicator;
