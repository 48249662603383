import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Avatar, 
  AvatarGroup, 
  TextField, 
  Chip, 
  Divider 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Users, 
  MessageSquare, 
  Video, 
  FileText, 
  Share2, 
  PlusCircle,
  Clock,
  Calendar,
  Brain,
  Book,
  Link as LinkIcon
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const ChatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
}));

const DirectiveAI = () => {
  const [message, setMessage] = useState('');

  return (
    <Box className="space-y-6">
      {/* Active Session Area */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Typography variant="h6">Advanced Mathematics Study Group</Typography>
                <Typography variant="body2" color="text.secondary">
                  Session in progress - 1h 30m remaining
                </Typography>
              </Box>
              <Box className="flex gap-2">
                <ActionButton>
                  <Video size={16} />
                  Join Video
                </ActionButton>
                <ActionButton>
                  <Share2 size={16} />
                  Share Screen
                </ActionButton>
              </Box>
            </Box>

            {/* Chat Area */}
            <Box className="space-y-4">
              <ChatBox>
                <Box className="flex gap-3">
                  <Avatar>JD</Avatar>
                  <Box>
                    <Box className="flex items-center gap-2 mb-1">
                      <Typography variant="subtitle2">John Doe</Typography>
                      <Typography variant="caption" color="text.secondary">
                        10:30 AM
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      Could someone explain the concept of partial derivatives in multivariable calculus?
                    </Typography>
                  </Box>
                </Box>
              </ChatBox>

              <ChatBox>
                <Box className="flex gap-3">
                  <Avatar sx={{ bgcolor: 'primary.main' }}>AI</Avatar>
                  <Box>
                    <Box className="flex items-center gap-2 mb-1">
                      <Typography variant="subtitle2">DirectiveAI</Typography>
                      <Typography variant="caption" color="text.secondary">
                        10:31 AM
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      Let me help explain partial derivatives. A partial derivative represents how a 
                      multivariable function changes when only one of its variables is allowed to vary...
                    </Typography>
                    <Box className="mt-2 flex gap-2">
                      <Chip 
                        size="small" 
                        label="View Example" 
                        color="primary"
                        onClick={() => {}}
                      />
                      <Chip 
                        size="small" 
                        label="Practice Problems" 
                        variant="outlined"
                        onClick={() => {}}
                      />
                    </Box>
                  </Box>
                </Box>
              </ChatBox>
            </Box>

            {/* Message Input */}
            <Box className="mt-4">
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="Type your message or question..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <Box className="flex gap-2">
                      <ActionButton>
                        <FileText size={16} />
                        Attach
                      </ActionButton>
                      <ActionButton>
                        <MessageSquare size={16} />
                        Send
                      </ActionButton>
                    </Box>
                  ),
                }}
              />
            </Box>
          </StyledPaper>
        </Grid>

        {/* Sidebar */}
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            {/* Participants */}
            <Grid item xs={12}>
              <StyledPaper>
                <Box className="flex justify-between items-center mb-3">
                  <Typography variant="h6">Participants</Typography>
                  <AvatarGroup max={4}>
                    <Avatar>JD</Avatar>
                    <Avatar>MS</Avatar>
                    <Avatar>RJ</Avatar>
                    <Avatar>AB</Avatar>
                  </AvatarGroup>
                </Box>
                <Box className="space-y-2">
                  <Box className="flex justify-between items-center">
                    <Typography variant="body2">Active Members</Typography>
                    <Typography variant="body2" color="primary">4/6</Typography>
                  </Box>
                  <ActionButton className="w-full">
                    <PlusCircle size={16} />
                    Invite Members
                  </ActionButton>
                </Box>
              </StyledPaper>
            </Grid>

            {/* Session Resources */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Session Resources</Typography>
                <Box className="space-y-3">
                  <Box className="flex items-center gap-2 p-2 bg-gray-50 rounded">
                    <Book size={16} className="text-blue-500" />
                    <Typography variant="body2">Multivariable Calculus Notes</Typography>
                  </Box>
                  <Box className="flex items-center gap-2 p-2 bg-gray-50 rounded">
                    <LinkIcon size={16} className="text-green-500" />
                    <Typography variant="body2">Khan Academy Reference</Typography>
                  </Box>
                  <Box className="flex items-center gap-2 p-2 bg-gray-50 rounded">
                    <Brain size={16} className="text-purple-500" />
                    <Typography variant="body2">Practice Problems Set</Typography>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>

            {/* Upcoming Sessions */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" gutterBottom>Upcoming Sessions</Typography>
                <Box className="space-y-3">
                  <Box className="p-3 border rounded-lg">
                    <Box className="flex items-center gap-2 mb-1">
                      <Calendar size={16} className="text-blue-500" />
                      <Typography variant="subtitle2">Linear Algebra Review</Typography>
                    </Box>
                    <Typography variant="caption" color="text.secondary" display="block">
                      Tomorrow, 2:00 PM
                    </Typography>
                  </Box>
                  <Box className="p-3 border rounded-lg">
                    <Box className="flex items-center gap-2 mb-1">
                      <Clock size={16} className="text-green-500" />
                      <Typography variant="subtitle2">Statistics Workshop</Typography>
                    </Box>
                    <Typography variant="caption" color="text.secondary" display="block">
                      Friday, 3:30 PM
                    </Typography>
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DirectiveAI;