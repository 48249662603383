import React, {
  useState,
  useCallback,
  memo,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import {
  TextareaAutosize,
  IconButton,
  Box,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import {
  ArrowUp,
  X,
  AlertCircle,
  AudioLines
} from 'lucide-react';
import { styled, useTheme } from '@mui/material/styles';
import { getThemeStyles } from '../../themes/themeConfig';
import ModelSelector from './ModelSelector';
import PersonalitySelector from './PersonalitySelector';
import TranscriptionButton from './TranscriptionButton';
import FileAttachment from './FileAttachment';
import IncompatibilityModal from './IncompatibilityModal';
import ThumbnailWrapper from './ThumbnailWrapper';
import { useAudioSupport } from '../../hooks/useAudioSupport';
import AGTVision from './vision/AGTVision';
import AGTSearch from './AGTSearch';

// Constants for file limitations
const MAX_FILE_SIZE_BYTES = 4 * 1024 * 1024;
const MAX_FILES = 5;

// Carácter invisible (Zero Width Space)
const INVISIBLE_CHAR = '\u200B';

const BlueIconButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  width: '32px',
  height: '32px',
  minWidth: '32px',
  minHeight: '32px',
  backgroundColor: '#0385FF',
  color: '#ffffff',
  transition: 'background-color 0.3s',
  margin: '0',
  '&:hover': {
    backgroundColor: '#026fcc',
  },
  '&:disabled': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.12)'
        : 'rgba(255, 255, 255, 0.12)',
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.26)'
        : 'rgba(255, 255, 255, 0.26)',
  },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '12px',
  padding: '4px',
  border: `1px solid ${
    theme.palette.mode === 'light'
      ? 'transparent'
      : 'transparent'
  }`,
  // Definimos los estilos directamente en lugar de usar getThemeStyles
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'light'
      ? 'transparent'
      : 'transparent',
  }
}));

const TopControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '8px',
  width: '100%',
  overflow: 'visible',
  paddingTop: '8px',
  paddingLeft: '8px',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 8px',
  marginTop: '0px',
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '52px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s ease',
  overflow: 'visible',
  ...getThemeStyles('InputRow', theme.palette.mode),
}));

const InputContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '8px',
  gap: '8px',
  minHeight: '52px',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'visible',
  '& > *': {
    overflow: 'visible',
  },
});

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: 'transparent',
  border: 'none',
  resize: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  padding: '8px',
  minHeight: '36px',
  lineHeight: '20px',
  margin: '0',
  position: 'relative',
  top: '8px',
  '&:focus': {
    outline: 'none'
  },
  boxSizing: 'border-box',
  paddingTop: '0px',
  paddingBottom: '0px',
  color: theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.87)'
    : 'rgba(255, 255, 255, 0.87)',
  '&::placeholder': {
    color: theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.42)'
      : 'rgba(255, 255, 255, 0.5)'
  }
}));

const AttachedFilesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginBottom: '8px',
  padding: '4px',
  maxHeight: '200px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.2)'
        : 'rgba(255, 255, 255, 0.2)',
    borderRadius: '3px',
  },
}));

const AudioRecordingChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.mode === 'light' 
    ? 'rgba(0, 0, 0, 0.1)' 
    : 'rgba(255, 255, 255, 0.2)',
  borderRadius: '20px',
  padding: '4px 12px',
  fontSize: '0.875rem',
  color: theme.palette.mode === 'light'
    ? 'rgba(0, 0, 0, 0.7)'
    : 'rgba(255, 255, 255, 0.7)',
  marginBottom: '8px',
  '& .MuiIconButton-root': {
    padding: '4px',
    color: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light'
        ? 'transparent'
        : 'transparent'
    }
  }
}));

// Agregamos estilos para que <u> herede el mismo font-size y
// solo muestre el subrayado.
const AlertContainer = ({ message, iconColor, onClose, onProjectClick }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleClick = (e) => {
    // Si el click fue en un elemento <u>, llamar a onProjectClick
    if (
      e.target.tagName.toLowerCase() === 'u' &&
      e.target.textContent === 'Projects'
    ) {
      e.preventDefault();
      if (onProjectClick) {
        onProjectClick();
      }
    }
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: isDarkMode
          ? 'rgba(254, 243, 199, 0.1)'
          : 'rgb(254, 243, 199)',
        border: `1px solid ${
          isDarkMode ? 'rgb(180, 150, 50)' : 'rgb(252, 211, 77)'
        }`,
        borderRadius: '28px',
        padding: '12px 16px',
        marginBottom: '8px',
        width: '100%',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease-in-out',
        '& u': {
          fontSize: 'inherit',
          textDecoration: 'underline',
          cursor: 'pointer',
          color: '#0385FF', // Agregar color azul al texto subrayado
          '&:hover': {
            textDecoration: 'none', // Quitar subrayado al hover
          },
        },
      }}
    >
      <AlertCircle size={20} style={{ color: iconColor }} />
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body2"
          sx={{
            color: isDarkMode ? 'rgb(252, 211, 77)' : 'rgb(120, 53, 15)',
            fontWeight: 500,
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Box>
      {onClose && (
        <IconButton
          size="small"
          onClick={onClose}
          sx={{
            padding: '4px',
            color: isDarkMode ? 'rgb(252, 211, 77)' : 'rgb(120, 53, 15)',
            '&:hover': {
              backgroundColor: isDarkMode
                ? 'rgba(252, 211, 77, 0.1)'
                : 'rgba(120, 53, 15, 0.1)',
            },
          }}
        >
          <X size={16} />
        </IconButton>
      )}
    </Box>
  );
};

const OptimizedInput = forwardRef(({
  onSendMessage,
  isWaitingForResponse,
  inputRef,
  rateLimitInfo = null,     // <---- Asegúrate de recibir el objeto con { message, resetIn, isPro, ... }
  onOpenAGTPromo,
  selectedModel = 'claude-3-haiku-20240307',
  onModelChange,
  selectedPersonality = 'normal',
  onPersonalityChange,
  isPro = false,
  error = null,
  onErrorClose,
  lastAssistantMessage,
  onPromptAction = () => {},
  onProjectClick, // <-- Nuevo prop para el <u>Projects</u> link
}, ref) => {
  // Texto y archivos adjuntos
  const [inputMessage, setInputMessage] = useState('');
  const [audioMessage, setAudioMessage] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [showIncompatibilityModal, setShowIncompatibilityModal] = useState(false);
  const [errors, setErrors] = useState([]);

  // Nuevo: estado para saber si la búsqueda web está activa
  const [isAGTSearchActive, setIsAGTSearchActive] = useState(false);

  const textareaRef = useRef(null);

  // REFERENCIAS A LOS COMPONENTES
  const visionRef = useRef(null);
  const searchRef = useRef(null);
  const transcriptionRef = useRef(null);
  const fileAttachmentRef = useRef(null);

  const isAudioSupported = useAudioSupport();

  // Manejador de acciones (Prompts)
  const handlePromptAction = useCallback((action) => {
    switch(action) {
      case 'vision':
        visionRef.current?.click();
        break;
      case 'search':
        searchRef.current?.click();
        break;
      case 'voice':
        transcriptionRef.current?.click();
        break;
      case 'files':
        fileAttachmentRef.current?.click();
        break;
      default:
        console.warn('Unhandled prompt action:', action);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handlePromptAction: handlePromptAction
  }));

  const handleFileSizeErrorClose = useCallback((indexToRemove) => {
    setErrors((prev) => prev.filter((_, index) => index !== indexToRemove));
  }, []);

  const blobToBase64 = useCallback((blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }, []);

  // Manejo de pegar imágenes
  useEffect(() => {
    const handlePaste = async (e) => {
      if (isWaitingForResponse) return;

      const items = Array.from(e.clipboardData.items);
      const imageItems = items.filter((item) => item.type.startsWith('image/'));

      if (imageItems.length > 0) {
        e.preventDefault();

        if (selectedFiles.length + imageItems.length > MAX_FILES) {
          setErrors((prev) => [
            ...prev,
            `Need more space for images? Try <u>Projects</u> for unlimited images`
          ]);
          return;
        }

        const processedFiles = [];
        let totalSize = selectedFiles.reduce((acc, file) => acc + (file.size || 0), 0);

        for (const item of imageItems) {
          const blob = item.getAsFile();
          if (!blob) continue;

          if (blob.size > MAX_FILE_SIZE_BYTES) {
            setErrors((prev) => [
              ...prev,
              `Image size exceeds limit. Try <u>Projects</u> for larger files`
            ]);
            continue;
          }

          if (totalSize + blob.size > MAX_FILE_SIZE_BYTES) {
            setErrors((prev) => [
              ...prev,
              `Storage limit reached. Try <u>Projects</u> for expanded capacity`
            ]);
            continue;
          }

          try {
            const base64Data = await blobToBase64(blob);
            processedFiles.push({
              name: `pasted-image-${new Date().getTime()}.png`,
              type: blob.type,
              size: blob.size,
              data: base64Data,
            });
            totalSize += blob.size;
          } catch (error) {
            console.error('Error processing pasted image:', error);
            setErrors((prev) => [
              ...prev,
              'Error processing pasted image. Please try again.'
            ]);
          }
        }

        if (processedFiles.length > 0) {
          setSelectedFiles((prev) => [...prev, ...processedFiles]);
        }
      }
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('paste', handlePaste);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('paste', handlePaste);
      }
    };
  }, [isWaitingForResponse, selectedFiles, blobToBase64]);

  // Cuando cambia el input de texto
  const handleInputChange = useCallback((e) => {
    setInputMessage(e.target.value);
    setAudioMessage(null);
  }, []);

  // Presionar ENTER
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey && !isWaitingForResponse) {
        event.preventDefault();
        const hasText = typeof inputMessage === 'string' && inputMessage.trim().length > 0;
        const hasFiles = selectedFiles.length > 0;

        if (hasText || audioMessage || hasFiles) {
          const textToSend = audioMessage
            ? audioMessage
            : hasText
              ? inputMessage
              : INVISIBLE_CHAR;

          const messageToSend = {
            text: textToSend,
            files: selectedFiles,
            fileContents,
            source: isAGTSearchActive ? 'AGTSearch' : undefined,
          };

          onSendMessage(messageToSend);
          setInputMessage('');
          setAudioMessage(null);
          setSelectedFiles([]);
          setFileContents([]);
        }
      }
    },
    [
      onSendMessage,
      isWaitingForResponse,
      inputMessage,
      audioMessage,
      selectedFiles,
      fileContents,
      isAGTSearchActive,
    ]
  );

  // Clic en "enviar"
  const handleSendClick = useCallback(() => {
    if (isWaitingForResponse) return;

    const hasText = typeof inputMessage === 'string' && inputMessage.trim().length > 0;
    const hasFiles = selectedFiles.length > 0;

    if (!hasText && !audioMessage && !hasFiles) return;

    const textToSend = audioMessage
      ? audioMessage
      : hasText
        ? inputMessage
        : INVISIBLE_CHAR;

    const messageToSend = {
      text: textToSend,
      files: selectedFiles,
      fileContents,
      source: isAGTSearchActive ? 'AGTSearch' : undefined,
    };

    onSendMessage(messageToSend);
    setInputMessage('');
    setAudioMessage(null);
    setSelectedFiles([]);
    setFileContents([]);
  }, [
    onSendMessage,
    inputMessage,
    isWaitingForResponse,
    audioMessage,
    selectedFiles,
    fileContents,
    isAGTSearchActive,
  ]);

  // Botón de audio (cuando no hay contenido)
  const handleTranscriptionClick = useCallback(() => {
    if (!isAudioSupported) {
      setShowIncompatibilityModal(true);
    }
  }, [isAudioSupported]);

  // Manejo de archivos
  const handleFileSelect = useCallback(
    (newFiles) => {
      const totalCurrentSize = selectedFiles.reduce(
        (acc, file) => acc + (file.size || 0),
        0
      );
      const validFiles = [];
      const newErrors = [];

      if (selectedFiles.length + newFiles.length > MAX_FILES) {
        newErrors.push(
          `Ready for more files? Try <u>Projects</u> for batch uploads`
        );
        newFiles = newFiles.slice(0, MAX_FILES - selectedFiles.length);
      }

      let runningSize = totalCurrentSize;

      for (const file of newFiles) {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          newErrors.push(
            `${file.name} is too large. Try <u>Projects</u> for larger files`
          );
          continue;
        }

        if (runningSize + file.size > MAX_FILE_SIZE_BYTES) {
          newErrors.push(
            `Storage full. Try <u>Projects</u> for unlimited storage`
          );
          continue;
        }

        validFiles.push(file);
        runningSize += file.size;
      }

      if (newErrors.length > 0) {
        setErrors((prev) => [...prev, ...newErrors]);
      }

      if (validFiles.length > 0) {
        setSelectedFiles((prev) => [...prev, ...validFiles]);
      }
    },
    [selectedFiles]
  );

  const handleRemoveFile = useCallback((fileToRemove) => {
    setSelectedFiles((prev) => prev.filter((file) => file !== fileToRemove));
    setFileContents((prevContents) => {
      if (Array.isArray(prevContents)) {
        return prevContents.filter((content) => content.name !== fileToRemove.name);
      }
      return prevContents;
    });
  }, []);

  // Manejo de transcripción
  const handleTranscriptionComplete = useCallback((result) => {
    setIsRecording(false);
    const newAudioMsg = {
      type: 'audio_message',
      text: result.transcription,
      audioUrl: result.audioUrl,
      audioBlob: result.audioBlob,
      duration: result.duration,
    };
    setAudioMessage(newAudioMsg);
    setInputMessage('');
  }, []);

  const handleTranscriptionCancel = useCallback(() => {
    setIsRecording(false);
  }, []);

  const handleRecordingStateChange = useCallback((recording) => {
    setIsRecording(recording);
  }, []);

  const handleCancelAudio = useCallback(() => {
    setAudioMessage(null);
    if (audioMessage?.audioUrl) {
      URL.revokeObjectURL(audioMessage.audioUrl);
    }
  }, [audioMessage]);

  // Render del botón derecho (flecha o micrófono)
  const renderRightButton = useCallback(() => {
    const hasContent =
      inputMessage.trim().length > 0 ||
      audioMessage ||
      selectedFiles.length > 0;

    return (
      <Box
        sx={{
          position: 'relative',
          transition: 'all 0.3s ease',
          width: isRecording ? '100%' : 'auto',
          minWidth: '36px',
        }}
      >
        {hasContent ? (
          <BlueIconButton onClick={handleSendClick} disabled={isWaitingForResponse}>
            <ArrowUp size={24} />
          </BlueIconButton>
        ) : isAudioSupported ? (
          <TranscriptionButton
            ref={transcriptionRef}
            onTranscriptionComplete={handleTranscriptionComplete}
            onTranscriptionCancel={handleTranscriptionCancel}
            onRecordingStateChange={handleRecordingStateChange}
            disabled={isWaitingForResponse}
            isPro={isPro}
            isFullWidth={isRecording}
          />
        ) : (
          <BlueIconButton onClick={handleTranscriptionClick}>
            <AudioLines size={24} />
          </BlueIconButton>
        )}
      </Box>
    );
  }, [
    inputMessage,
    audioMessage,
    selectedFiles,
    isRecording,
    isWaitingForResponse,
    isAudioSupported,
    isPro,
    handleSendClick,
    handleTranscriptionClick,
    handleTranscriptionComplete,
    handleTranscriptionCancel,
    handleRecordingStateChange,
  ]);

  return (
    <InputWrapper>
      {/* Contenedor principal, se oculta si estamos grabando audio */}
      <Box
        sx={{
          opacity: isRecording ? 0 : 1,
          visibility: isRecording ? 'hidden' : 'visible',
          height: isRecording ? 0 : 'auto',
          overflow: 'hidden',
          transition: 'opacity 0.3s ease, height 0.3s ease',
        }}
      >
        {/* Rate limit si se excede */}
        {rateLimitInfo && (
          <AlertContainer
            // Por ejemplo: "Has alcanzado el límite (Try again in 5 mins)"
            message={`${rateLimitInfo.message} (Try again in ${Math.ceil(
              rateLimitInfo.resetIn / 60
            )} mins)`}
            iconColor="#d97706"
            onProjectClick={onProjectClick}
          />
        )}

        {/* Errores de archivo u otros */}
        {errors.map((error, index) => (
          <AlertContainer
            key={index}
            message={error}
            iconColor="#d97706"
            onClose={() => handleFileSizeErrorClose(index)}
            onProjectClick={onProjectClick}
          />
        ))}

        {/* Model & Personality */}
        <TopControls>
          <ModelSelector
            selectedModel={selectedModel}
            onModelChange={onModelChange}
            disabled={isWaitingForResponse}
            isPro={isPro}
          />
          <PersonalitySelector
            selectedPersonality={selectedPersonality}
            onPersonalityChange={onPersonalityChange}
            disabled={isWaitingForResponse || selectedModel === 'agt-ultra-20241227'}
            isPro={isPro}
          />
        </TopControls>

        {/* Previsualización de archivos adjuntos */}
        {selectedFiles.length > 0 && (
          <AttachedFilesContainer>
            {selectedFiles.map((file, index) => (
              <ThumbnailWrapper
                key={index}
                file={file}
                onRemove={handleRemoveFile}
              />
            ))}
          </AttachedFilesContainer>
        )}

        {/* Mensaje de audio grabado */}
        {audioMessage && (
          <AudioRecordingChip>
            <Typography variant="body2" sx={{ marginRight: '8px' }}>
              Audio message recorded ({audioMessage.duration.toFixed(1)}s)
            </Typography>
            <IconButton size="small" onClick={handleCancelAudio} sx={{ padding: 0 }}>
              <X size={16} />
            </IconButton>
          </AudioRecordingChip>
        )}
      </Box>

      {/* Contenedor del input principal (texto + botón) */}
      <InputContainer>
        <InputContent
          sx={{
            transform: isRecording ? 'translateX(0)' : 'translateX(0)',
            '& > *:not(:last-child)': {
              opacity: isRecording ? 0 : 1,
              width: isRecording ? 0 : 'auto',
              padding: isRecording ? 0 : undefined,
              margin: isRecording ? 0 : undefined,
              transition: 'all 0.3s ease',
              overflow: 'hidden',
            },
          }}
        >
          <StyledTextarea
            ref={(el) => {
              textareaRef.current = el;
              if (inputRef) inputRef.current = el;
            }}
            placeholder={
              isWaitingForResponse
                ? 'Waiting response...'
                : audioMessage
                ? 'Audio message ready to send'
                : 'Ask AGT'
            }
            value={inputMessage}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            minRows={1}
            maxRows={4}
            disabled={isWaitingForResponse || audioMessage !== null}
          />
          {renderRightButton()}
        </InputContent>

        {/* Botones inferiores (Adjuntar archivo, AGTVision, AGTSearch) */}
        {!isRecording && !audioMessage && (
          <ButtonsContainer>
            <FileAttachment
              ref={fileAttachmentRef}
              onFileSelect={handleFileSelect}
              onFileError={(errorMsg) => setErrors((prev) => [...prev, errorMsg])}
              disabled={isWaitingForResponse}
            />
            <AGTVision
              ref={visionRef}
              isPro={isPro}
              onSendMessage={onSendMessage}
              lastAssistantMessage={lastAssistantMessage}
              isWaitingForResponse={isWaitingForResponse}
              onClose={() => {
                // Handle close if needed
              }}
            />
            <AGTSearch
              ref={searchRef}
              disabled={isWaitingForResponse}
              onToggleSearch={(active) => setIsAGTSearchActive(active)}
            />
          </ButtonsContainer>
        )}
      </InputContainer>

      {/* Snackbar de error global */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={onErrorClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={onErrorClose} severity="error" variant="filled" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      {/* Modal para navegadores sin compatibilidad de audio */}
      <IncompatibilityModal
        open={showIncompatibilityModal}
        onClose={() => setShowIncompatibilityModal(false)}
      />
    </InputWrapper>
  );
});

OptimizedInput.displayName = 'OptimizedInput';
export default memo(OptimizedInput);
