import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Archive, MessageSquare, Stars, Briefcase, Users } from 'lucide-react';
import { styled } from '@mui/material/styles';

const FooterBox = styled(Box)(({ theme }) => ({
  padding: '12px',
  marginTop: '8px',
  marginLeft: '8px',
  marginRight: '8px',
  marginBottom: '16px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.2)'
      : 'rgba(255, 255, 255, 0.8)',
  borderRadius: '8px',
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)'
  }`,
  maxWidth: '244px',
}));

const SectionHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '6px',
});

const IconStyle = {
  width: '18px',
  height: '18px',
};

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 500,
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(0, 0, 0, 0.7)',
  lineHeight: 1.4,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  // Cambiar de kebab-case a camelCase
  WebkitLineClamp: 2, // antes: '-webkit-line-clamp'
  WebkitBoxOrient: 'vertical', // antes: '-webkit-box-orient'
  maxHeight: '2.8em',
  letterSpacing: '0.02em',
}));

const LoadMoreButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.primary.main,
  textTransform: 'none',
  padding: '4px 8px',
  minWidth: 'auto',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  '&:disabled': {
    color: theme.palette.text.disabled,
  }
}));

/**
 * ChatListFooter
 *
 * Muestra un texto diferente según la categoría seleccionada.
 *
 * @param {string}  category          -> Categoría actual (personal, work, team, custom, etc.)
 * @param {number}  chatsCount        -> Cantidad de chats ya listados
 * @param {number}  totalChatsCount   -> Cantidad total de chats disponibles (opcional)
 * @param {boolean} hasNextPage       -> Indica si hay más chats para cargar
 * @param {boolean} isNextPageLoading -> Indica si se está cargando la siguiente página
 * @param {function} onLoadMore       -> Acción para cargar más chats
 */
const ChatListFooter = ({
  category,
  chatsCount,
  totalChatsCount,
  hasNextPage,
  isNextPageLoading,
  onLoadMore,
}) => {
  // 1) Si la categoría es "personal", mantenemos la lógica por número de chats
  if (category === 'personal') {
    // (A) Sin chats
    if (chatsCount === 0) {
      return (
        <FooterBox>
          <SectionHeader>
            <MessageSquare style={IconStyle} color="#3B82F6" />
            <TitleTypography>Start Chatting</TitleTypography>
          </SectionHeader>
          <DescriptionTypography>
            Start your journey here. Chat history at a glance.
          </DescriptionTypography>
        </FooterBox>
      );
    }
    // (B) Entre 1 y 5 chats
    if (chatsCount <= 5) {
      return (
        <FooterBox>
          <SectionHeader>
            <Stars style={IconStyle} color="#FBBF24" />
            <TitleTypography>Getting Started</TitleTypography>
          </SectionHeader>
          <DescriptionTypography>
            Building your chat collection. Keep going.
          </DescriptionTypography>
        </FooterBox>
      );
    }
    // (C) Más de 5 chats
    return (
      <FooterBox>
        <SectionHeader>
          <Archive style={IconStyle} color="#6B7280" />
          <TitleTypography>Older Chats</TitleTypography>
        </SectionHeader>
        <DescriptionTypography sx={{ marginBottom: '12px' }}>
          {`${chatsCount} recent chats available${
            totalChatsCount ? ` of ${totalChatsCount}` : ''
          }.`}
        </DescriptionTypography>
        {hasNextPage && (
          <LoadMoreButton
            variant="text"
            size="small"
            onClick={() => {
              if (!isNextPageLoading) onLoadMore();
            }}
            disabled={isNextPageLoading}
          >
            {isNextPageLoading ? 'Loading...' : 'Load more chats'}
          </LoadMoreButton>
        )}
      </FooterBox>
    );
  }

  // 2) Si la categoría es "work"
  if (category === 'work') {
    return (
      <FooterBox>
        <SectionHeader>
          <Briefcase style={IconStyle} color="#3B82F6" />
          <TitleTypography>Work Category</TitleTypography>
        </SectionHeader>
        <DescriptionTypography sx={{ marginBottom: '12px' }}>
          {chatsCount === 0
            ? `Create your first work chat.`
            : `Professional chats in one place.`}
        </DescriptionTypography>
        {hasNextPage && (
          <LoadMoreButton
            variant="text"
            size="small"
            onClick={() => {
              if (!isNextPageLoading) onLoadMore();
            }}
            disabled={isNextPageLoading}
          >
            {isNextPageLoading ? 'Loading...' : 'Load more work chats'}
          </LoadMoreButton>
        )}
      </FooterBox>
    );
  }

  // 3) Si la categoría es "team"
  if (category === 'team') {
    return (
      <FooterBox>
        <SectionHeader>
          <Users style={IconStyle} color="#FBBF24" />
          <TitleTypography>Team Category</TitleTypography>
        </SectionHeader>
        <DescriptionTypography sx={{ marginBottom: '12px' }}>
          {chatsCount === 0
            ? `Start team collaboration now.`
            : `Team chats at your fingertips.`}
        </DescriptionTypography>
        {hasNextPage && (
          <LoadMoreButton
            variant="text"
            size="small"
            onClick={() => {
              if (!isNextPageLoading) onLoadMore();
            }}
            disabled={isNextPageLoading}
          >
            {isNextPageLoading ? 'Loading...' : 'Load more team chats'}
          </LoadMoreButton>
        )}
      </FooterBox>
    );
  }

  // 4) Para categorías personalizadas o desconocidas
  //    (puedes personalizar si deseas categorías "cat1", "cat2", etc.)
  return (
    <FooterBox>
      <SectionHeader>
        <Archive style={IconStyle} color="#6B7280" />
        <TitleTypography>Custom Category</TitleTypography>
      </SectionHeader>
      <DescriptionTypography sx={{ marginBottom: '12px' }}>
        {chatsCount === 0
          ? `Create your custom chat.`
          : `${category} chats collected here.`}
      </DescriptionTypography>
      {hasNextPage && (
        <LoadMoreButton
          variant="text"
          size="small"
          onClick={() => {
            if (!isNextPageLoading) onLoadMore();
          }}
          disabled={isNextPageLoading}
        >
          {isNextPageLoading ? 'Loading...' : 'Load more chats'}
        </LoadMoreButton>
      )}
    </FooterBox>
  );
};

export default ChatListFooter;