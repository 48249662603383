// themeConfig.js

export const themeConfig = {
  // ChatViewNest.js
  ChatContainer: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      backdropFilter: 'none',
      transform: 'translateZ(0)',
    },
    dark: {
      backgroundColor: 'transparent',
      backdropFilter: 'none',
      transform: 'translateZ(0)',
    },
  },
  Sidebar: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRight: '0px solid rgba(0, 0, 0, 0)',
    },
    dark: {
      backgroundColor: 'rgba(15, 15, 15, 1)',
      borderRight: '0px solid rgba(255, 255, 255, 0)',
    },
  },
  SidebarHeader: {
    light: {
      borderBottom: 'transparent',
    },
    dark: {
      borderBottom: 'transparent',
    },
  },
  ChatList: {
    light: {
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
      },
    },
    dark: {
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.2)',
      },
    },
  },
  ChatArea: {
    light: {
      backgroundColor: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
    },
  },
  MessagesContainer: {
    light: {
      backgroundColor: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
    },
  },
  MessageBarContainer: {
    light: {
      backgroundColor: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
    },
  },
  InputContainer: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  InputRow: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  InfoText: {
    light: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  MobileHeader: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderBottom: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
      borderBottom: 'transparent',
    },
  },
  DesktopHeader: {
    light: {
      backgroundColor: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
    },
  },
  ErrorMessage: {
    light: {
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
      color: 'rgba(255, 0, 0, 0.7)',
    },
    dark: {
      backgroundColor: 'rgba(255, 0, 0, 0.2)',
      color: 'rgba(255, 0, 0, 0.8)',
    },
  },
  RetryButton: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
  },
  ViewAllButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  },
  FilterChip: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
      '&.selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
      '&.selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
  },
  SearchInputContainer: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
  },
  StyledInput: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
      '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
  ClearAllButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  },

  // FullDashboard.js
  FixedContainer: {
    light: {
      backgroundColor: '#FFFFFF',
    },
    dark: {
      backgroundColor: '#171717',
    },
  },
  StyledWindowBar: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    dark: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  TopBar: {
    light: {
      borderBottom: 'transparent',
    },
    dark: {
      borderBottom: 'transparent',
    },
  },
  ContentArea: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    dark: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  NavigationContainer: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
    dark: {
      backgroundColor: 'rgba(15, 15, 15, 0.1)',
    },
  },
  SlidingBackground: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  NavButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },

  // MagicalMessage.js
  MessageContainer: {
    light: {
      // No necesita estilos específicos por modo, pero lo incluimos por consistencia
    },
    dark: {
      // No necesita estilos específicos por modo, pero lo incluimos por consistencia
    },
  },
  MessageContent: {
    light: {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.87)',
      backdropFilter: 'blur(10px)',
      border: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 255, 0.87)',
      backdropFilter: 'blur(10px)',
      border: 'transparent',
    },
  },
  UserMessageContent: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      color: 'rgba(0, 0, 0, 0.87)',
      backdropFilter: 'blur(10px)',
      border: 'transparent',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: 'rgba(255, 255, 255, 0.87)',
      backdropFilter: 'blur(10px)',
      border: 'transparent',
    },
  },
  StyledReactMarkdown: {
    light: {
      '& strong': { color: '#000000' },
      '& em': { color: '#000000' },
      '& h1, & h2, & h3, & h4, & h5, & h6': { color: '#000000' },
      '& code': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
      '& .enumeration-keyword': { color: '#000000' },
    },
    dark: {
      '& strong': { color: '#FFFFFF' },
      '& em': { color: '#FFFFFF' },
      '& h1, & h2, & h3, & h4, & h5, & h6': { color: '#FFFFFF' },
      '& code': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
      '& .enumeration-keyword': { color: '#FFFFFF' },
    },
  },
  AssistantIconContainer: {
    light: {
      backgroundColor: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
    },
  },
  FeedbackButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.7)',
      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
    },
  },
  ModalContent: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
    dark: {
      backgroundColor: 'rgba(128, 128, 128, 0.3)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
    },
  },
  StyledTextField: {
    light: {
      '& .MuiInputBase-input': { color: 'rgba(0, 0, 0, 0.87)' },
      '& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.7)' },
      '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'rgba(0, 0, 0, 0.3)' },
        '&:hover fieldset': { borderColor: 'rgba(0, 0, 0, 0.5)' },
        '&.Mui-focused fieldset': { borderColor: 'rgba(0, 0, 0, 0.7)' },
      },
    },
    dark: {
      '& .MuiInputBase-input': { color: 'white' },
      '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
      '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
        '&.Mui-focused fieldset': { borderColor: 'rgba(255, 255, 255, 0.7)' },
      },
    },
  },
  StyledSelect: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '& .MuiSelect-icon': { color: 'rgba(0, 0, 0, 0.87)' },
      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.3)' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.5)' },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.7)' },
    },
    dark: {
      color: 'white',
      '& .MuiSelect-icon': { color: 'white' },
      '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.3)' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.5)' },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(255, 255, 255, 0.7)' },
    },
  },
  StyledButton: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
      '& .MuiSvgIcon-root': { color: 'rgba(0, 0, 0, 0.87)' },
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
      '& .MuiSvgIcon-root': { color: 'white' },
    },
  },
  FileAttachmentContainer: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },

  // OptimizedInput.js
  InputWrapper: {
    light: {
      // Añade estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Añade estilos específicos para el modo oscuro si es necesario
    },
  },
  StyledTextarea: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.5)',
      },
    },
    dark: {
      color: 'white',
      '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
  AttachedFilesContainer: {
    light: {
      // Añade estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Añade estilos específicos para el modo oscuro si es necesario
    },
  },
  AttachedFileChip: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'white',
    },
  },
  InputIconButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    dark: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
  },

  // FileAttachment.js
  FileAttachmentButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },

  // SidebarNavigation.js (dentro de FullDashboard.js)
  NavigationContainer: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(10px)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
    },
  },
  SlidingBackground: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  NavButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },

  // BackgroundSelector.js
  BackgroundButton: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },

  // CategorizedChatList.js
  AllChatsText: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  ChatListItem: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  // New configurations for PromptCards
  CustomCard: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.2)',
      },
    },
    dark: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      '&:hover': {
        border: '1px solid rgba(255, 255, 255, 0.2)',
      },
    },
  },
  CustomCardContent: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  // New configurations for ProfileDashboard
  ProfileContainer: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      backgroundColor: 'rgba(128, 128, 128, 0.3)',
      color: 'white',
    },
  },
  ProfileImage: {
    light: {
      border: '3px solid rgba(0, 0, 0, 0.1)',
    },
    dark: {
      border: '3px solid rgba(255, 255, 255, 0.1)',
    },
  },
  NameDisplay: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'white',
    },
  },
  CustomButton: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    dark: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
  // AGTMaker.js
  WhiteboardContainer: {
    light: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    dark: {
      backgroundColor: '#1E1E1E',
      boxShadow: '0 0 10px rgba(255,255,255,0.1)',
    },
  },
  WhiteboardHeader: {
    light: {
      backgroundColor: '#F5F5F5',
    },
    dark: {
      backgroundColor: '#2D2D2D',
    },
  },
  WhiteboardTitle: {
    light: {
      color: '#000000',
    },
    dark: {
      color: '#FFFFFF',
    },
  },
  ContentArea: {
    light: {
      backgroundColor: 'transparent',
      color: '#000000',
    },
    dark: {
      backgroundColor: 'transparent',
      color: '#FFFFFF',
    },
  },
  WhiteboardFooter: {
    light: {
      backgroundColor: '#F5F5F5',
    },
    dark: {
      backgroundColor: '#2D2D2D',
    },
  },
  ActionButton: {
    light: {
      color: '#000000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    dark: {
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  ZoomControls: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    dark: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  // Nuevo componente NavContainer
  NavContainer: {
    light: {
      backgroundColor: 'transparent',
      borderRight: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
      borderRight: 'transparent',
    },
  },
  // HomeInput
  CenteredContainer: {
    light: {
      backgroundColor: 'transparent',
    },
    dark: {
      backgroundColor: 'transparent',
    },
  },
  ContentWrapper: {
    light: {
      // Estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Estilos específicos para el modo oscuro si es necesario
    },
  },
  StyledForm: {
    light: {
      // Estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Estilos específicos para el modo oscuro si es necesario
    },
  },
  StyledTextField: {
    light: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '30px',
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiOutlinedInput-input': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    dark: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '30px',
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiOutlinedInput-input': {
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
  },
  StyledAutocomplete: {
    light: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '& .MuiAutocomplete-listbox': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    dark: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
      },
      '& .MuiAutocomplete-listbox': {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
  },
  SendButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  ClearButton: {
    light: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  SectionsContainer: {
    light: {
      // Estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Estilos específicos para el modo oscuro si es necesario
    },
  },
  Section: {
    light: {
      // Estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Estilos específicos para el modo oscuro si es necesario
    },
  },
  SectionTitle: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  CardsRow: {
    light: {
      // Estilos específicos para el modo claro si es necesario
    },
    dark: {
      // Estilos específicos para el modo oscuro si es necesario
    },
  },
  CustomCard: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      },
    },
    dark: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      '&:hover': {
        border: '1px solid rgba(255, 255, 255, 0.2)',
        boxShadow: '0 2px 4px rgba(255, 255, 255, 0.1)',
      },
    },
  },
  CustomCardContent: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  TrendingSection: {
    light: {
      backgroundColor: '#FFFFFF',
    },
    dark: {
      backgroundColor: '#1E1E1E',
    },
  },
  TrendingSuggestions: {
    light: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    },
    dark: {
      backgroundColor: '#1E1E1E',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    },
  },
  TrendingSuggestionItem: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  // New configurations for TrendingSuggestions
  TrendingContainer: {
    light: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      backdropFilter: 'blur(0px)',
    },
    dark: {
      backgroundColor: 'rgba(30, 30, 30, 1)',
      backdropFilter: 'blur(0px)',
    },
  },
  TrendingItem: {
    light: {
      '&:hover': {
        color: '#1976d2', // MUI's default primary color for light theme
      },
    },
    dark: {
      '&:hover': {
        color: '#90caf9', // MUI's default primary color for dark theme
      },
    },
  },
  TrendingIcon: {
    light: {
      color: '#10B981',
    },
    dark: {
      color: '#10B981',
    },
  },
  TrendingText: {
    light: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.87)',
    },
  },
  TrendingValue: {
    light: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    dark: {
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
  TrendingGraph: {
    light: {
      stroke: '#10B981',
    },
    dark: {
      stroke: '#10B981',
    },
  },
  SidebarOverlay: {
    light: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000
    },
    dark: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000
    }
  },
  FooterContainer: {
    light: {
      backgroundColor: 'transparent',
      borderTop: 'transparent'
    },
    dark: {
      backgroundColor: 'transparent', 
      borderTop: 'transparent'
    }
  }
};

export const getThemeStyles = (component, mode) => {
  if (!themeConfig[component]) {
    console.warn(`No theme configuration found for component: ${component}`);
    return {};
  }
  
  if (mode === 'light' || mode === 'dark') {
    return themeConfig[component][mode] || {};
  }
  
  return {};
};