import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ArrowLeft,
  Upload,
  File as LucideFile,
  X,
  FileText,
  Image,
  FileCode,
  Trash2,
  AlertCircle
} from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import {
  uploadImageToS3,
  uploadPdfToS3,
  getProjectFiles,
  deleteFile
} from '../../../services/AGTService';

// ----------------------------------------------------------------
// Styled Components
// ----------------------------------------------------------------
const StyledButton = styled(Button)(() => ({
  backgroundColor: '#0385FF',
  color: '#FFFFFF',
  borderRadius: '20px',
  textTransform: 'none',
  padding: '6px 16px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#0371db',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: 6,
  },
}));

// ----------------------------------------------------------------
// AlertContainer
// ----------------------------------------------------------------
const AlertContainer = ({ message, iconColor, onClose }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        // Ajustamos para que no fuerce el 100% de la pantalla
        // y se adapte al ancho del contenedor padre.
        maxWidth: '100%',
        backgroundColor: isDarkMode
          ? 'rgba(254, 243, 199, 0.1)'
          : 'rgb(254, 243, 199)',
        border: `1px solid ${
          isDarkMode ? 'rgb(180, 150, 50)' : 'rgb(252, 211, 77)'
        }`,
        borderRadius: '28px',
        padding: '12px 16px',
        marginBottom: '8px',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <AlertCircle size={20} style={{ color: iconColor }} />
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body2"
          sx={{
            color: isDarkMode ? 'rgb(252, 211, 77)' : 'rgb(120, 53, 15)',
            fontWeight: 500,
          }}
        >
          {message}
        </Typography>
      </Box>
      {onClose && (
        <IconButton
          size="small"
          onClick={onClose}
          sx={{
            padding: '4px',
            color: isDarkMode ? 'rgb(252, 211, 77)' : 'rgb(120, 53, 15)',
            '&:hover': {
              backgroundColor: isDarkMode
                ? 'rgba(252, 211, 77, 0.1)'
                : 'rgba(120, 53, 15, 0.1)',
            },
          }}
        >
          <X size={16} />
        </IconButton>
      )}
    </Box>
  );
};

// ----------------------------------------------------------------
// FileIcon helper
// ----------------------------------------------------------------
const FileIcon = ({ fileType, size = 20 }) => {
  const theme = useTheme();
  const color = theme.palette.mode === 'dark' ? '#fff' : '#000';

  switch (fileType) {
    case 'image':
      return <Image size={size} color={color} />;
    case 'code':
      return <FileCode size={size} color={color} />;
    case 'document':
      return <FileText size={size} color={color} />;
    default:
      return <LucideFile size={size} color={color} />;
  }
};

const getFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension)) return 'image';
  if (['js', 'jsx', 'ts', 'tsx', 'py', 'java', 'cpp', 'c'].includes(extension)) return 'code';
  if (['doc', 'docx', 'pdf', 'txt', 'csv', 'xls', 'xlsx'].includes(extension)) return 'document';
  return 'file';
};

// ----------------------------------------------------------------
// Main Component
// ----------------------------------------------------------------
const FilesView = ({ project, onBack, user, isModal = false, open = false, onClose }) => {
  const theme = useTheme();
  const [filesPending, setFilesPending] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [deletingFile, setDeletingFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [showFileLimit, setShowFileLimit] = useState(false);

  // Puedes ajustar aquí la cantidad de archivos permitidos
  const FILE_LIMIT = 20;

  // ----------------------------------------------------------------
  // Efectos
  // ----------------------------------------------------------------
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    const fetchProjectFiles = async () => {
      if (project?.id && user?.uid) {
        try {
          const existingFiles = await getProjectFiles(user.uid, project.id);
          setProjectFiles(existingFiles);
        } catch (error) {
          console.error('Error fetching project files:', error);
        }
      }
    };
    fetchProjectFiles();
  }, [project?.id, user?.uid]);

  // ----------------------------------------------------------------
  // Manejo de DropZone
  // ----------------------------------------------------------------
  const onDrop = useCallback(
    (acceptedFiles) => {
      const totalFiles = filesPending.length + acceptedFiles.length;

      if (totalFiles > FILE_LIMIT) {
        setShowFileLimit(true);
        // Solo tomamos los archivos que caben dentro del límite
        const remainingSlots = FILE_LIMIT - filesPending.length;
        acceptedFiles = acceptedFiles.slice(0, remainingSlots);
      }

      const newFiles = acceptedFiles.map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        file,
        type: getFileType(file.name),
      }));
      setFilesPending((prev) => [...prev, ...newFiles]);
    },
    [filesPending]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    noClick: false,
  });

  // ----------------------------------------------------------------
  // Manejo de archivos pendientes
  // ----------------------------------------------------------------
  const removeFile = (fileId) => {
    setFilesPending((prev) => prev.filter((f) => f.id !== fileId));
  };

  // ----------------------------------------------------------------
  // Manejo de eliminación de archivos existentes
  // ----------------------------------------------------------------
  const handleDeleteClick = (file) => {
    setDeletingFile(file);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeletingFile(null);
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = async () => {
    if (!deletingFile) return;

    setIsDeleting(true);
    try {
      const fileType = deletingFile.key.split('/')[0];

      await deleteFile(fileType, deletingFile.key, user.uid, project.id);

      const updatedFiles = await getProjectFiles(user.uid, project.id);
      setProjectFiles(updatedFiles);

      handleDeleteCancel();
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Error deleting file. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  // ----------------------------------------------------------------
  // Manejo de subida
  // ----------------------------------------------------------------
  const formatFileSize = (bytes) => {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleSave = async () => {
    if (filesPending.length === 0) {
      if (onClose) onClose();
      return;
    }
    setIsSaving(true);

    try {
      const fileEntriesToSave = [];

      for (const item of filesPending) {
        const { file } = item;
        const extension = file.name.split('.').pop().toLowerCase();

        // Se define qué método usar para subir y extraer contenido
        if (['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt'].includes(extension)) {
          const pdfUploadResult = await uploadPdfToS3(
            {
              name: file.name,
              type: file.type,
              data: file,
              projectId: project?.id,
            },
            user.uid
          );

          if (pdfUploadResult.extractedText) {
            fileEntriesToSave.push({
              fileName: file.name,
              content: pdfUploadResult.extractedText
            });
          }
        } else if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension)) {
          await uploadImageToS3(
            {
              name: file.name,
              type: file.type,
              data: file,
              projectId: project?.id,
            },
            user.uid
          );
        } else {
          const genericUploadResult = await uploadPdfToS3(
            {
              name: file.name,
              type: file.type,
              data: file,
              projectId: project?.id,
            },
            user.uid
          );

          if (genericUploadResult.extractedText) {
            fileEntriesToSave.push({
              fileName: file.name,
              content: genericUploadResult.extractedText
            });
          }
        }
      }

      // Si hubo contenido de texto extraído, se envía al endpoint
      if (fileEntriesToSave.length > 0 && project?.id && user?.uid) {
        try {
          await axios.put(`/api/projects/${project.id}/files-content`, {
            userId: user.uid,
            fileEntries: fileEntriesToSave
          });
          console.log('Project files updated with extracted text.');
        } catch (err) {
          console.error('Error updating project files content:', err);
        }
      }

      alert('All files uploaded successfully');
      setFilesPending([]);

      // Actualiza la lista de archivos existentes
      if (project?.id && user?.uid) {
        const existingFiles = await getProjectFiles(user.uid, project.id);
        setProjectFiles(existingFiles);
      }

      if (onClose) onClose();
    } catch (err) {
      console.error('Error uploading files:', err);
      alert(`Error uploading files: ${err.message || 'Unknown'}`);
    } finally {
      setIsSaving(false);
    }
  };

  // ----------------------------------------------------------------
  // Contenido principal (no modal)
  // ----------------------------------------------------------------
  // NOTA: en esta sección se ha movido el AlertContainer
  // dentro del contenedor con maxWidth para que no ocupe
  // todo el ancho de la pantalla.
  const content = (
    <Box
      sx={{
        // Este Box es el contenedor más externo.
        // Ponemos "display: flex" y "flexDirection: column"
        // para luego poder hacer sticky con la sección del botón.
        width: '100%',
        px: isModal ? 0 : 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: isModal
            ? '100%'
            : {
                xs: '100%',
                sm: '100%',
                md: '800px',
                lg: '900px',
              },
        }}
      >
        {showFileLimit && (
          <AlertContainer
            message="Maximum file limit reached (20 files)"
            iconColor="#f59e0b"
            onClose={() => setShowFileLimit(false)}
          />
        )}

        {/* Encabezado (cuando no es modal) */}
        {!isModal && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 2,
              borderBottom: 1,
              borderColor: 'divider',
              mb: 3,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={onBack}
                size="small"
                sx={{
                  mr: 1,
                  color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700',
                }}
              >
                <ArrowLeft />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 500,
                }}
              >
                Files
              </Typography>
            </Box>
          </Box>
        )}

        {/* Sección de Dropzone */}
        <Box
          {...getRootProps()}
          sx={{
            p: 4,
            textAlign: 'center',
            borderRadius: '16px',
            backgroundColor:
              isDragging || isDragActive
                ? theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.08)'
                  : 'rgba(0, 0, 0, 0.04)'
                : theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.03)'
                : 'rgba(0, 0, 0, 0.02)',
            border: '2px dashed',
            borderColor:
              isDragging || isDragActive
                ? '#0385FF'
                : theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)',
            transition: 'all 0.2s ease',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.03)',
            },
          }}
        >
          <input {...getInputProps()} />
          <Upload
            size={48}
            style={{
              marginBottom: theme.spacing(2),
              color:
                isDragging || isDragActive
                  ? '#0385FF'
                  : theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.5)'
                  : 'rgba(0, 0, 0, 0.5)',
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.5)'
                  : 'rgba(0, 0, 0, 0.5)',
            }}
          >
            {isMobile ? 'Touch to upload' : 'Drop files here or click to upload'}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mt: 1,
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
            }}
          >
            Maximum 20 files allowed
          </Typography>
        </Box>

        {/* Listado de archivos pendientes */}
        {filesPending.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="subtitle2"
              sx={{
                mb: 2,
                fontSize: '0.9rem',
                fontWeight: 500,
              }}
            >
              Files to be uploaded
            </Typography>
            <List>
              {filesPending.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    mb: 1.5,
                    p: 1.5,
                    borderRadius: '12px',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.03)'
                        : 'rgba(0, 0, 0, 0.02)',
                    '&:hover': {
                      backgroundColor:
                        theme.palette.mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.08)'
                          : 'rgba(0, 0, 0, 0.05)',
                    },
                    transition: 'background-color 0.2s ease',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <FileIcon fileType={item.type} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.file.name}
                    secondary={formatFileSize(item.file.size)}
                  />
                  <IconButton
                    onClick={() => removeFile(item.id)}
                    size="small"
                    sx={{
                      color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700',
                      '&:hover': {
                        color: theme.palette.error.main,
                      },
                    }}
                  >
                    <X size={18} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* Listado de archivos existentes */}
        {projectFiles.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="subtitle2"
              sx={{
                mb: 2,
                fontSize: '0.9rem',
                fontWeight: 500,
              }}
            >
              Existing Files
            </Typography>
            <List>
              {projectFiles.map((item) => (
                <ListItem
                  key={item.key}
                  sx={{
                    mb: 1.5,
                    p: 1.5,
                    borderRadius: '12px',
                    backgroundColor:
                      theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.03)'
                        : 'rgba(0, 0, 0, 0.02)',
                    '&:hover': {
                      backgroundColor:
                        theme.palette.mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.08)'
                          : 'rgba(0, 0, 0, 0.05)',
                    },
                    transition: 'background-color 0.2s ease',
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <FileIcon
                      fileType={item.fileType === 'image' ? 'image' : 'document'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.key.split('/').pop()}
                    secondary={`Size: ${formatFileSize(item.size || 0)}`}
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      size="small"
                      component="a"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      <ArrowLeft
                        size={18}
                        style={{ transform: 'rotate(180deg)' }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteClick(item)}
                      sx={{
                        color: theme.palette.error.main,
                        '&:hover': {
                          backgroundColor: theme.palette.error.main + '1A',
                        },
                      }}
                    >
                      <Trash2 size={18} />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>

      {/* Para que el botón se mantenga siempre visible (no-modal):
          Usamos "position: sticky; bottom: 0;" dentro del mismo contenedor
          donde sucede el scroll, o en uno superior (depende de tu layout).
          Aquí asumimos que el scroll es global y no necesitamos altura fija. 
          Si tu layout hace scroll en otro contenedor, ajusta en consecuencia. */}
      {!isModal && filesPending.length > 0 && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            borderColor: 'divider',
            py: 2,
            mt: 2,
            zIndex: 10,
          }}
        >
          <StyledButton onClick={handleSave} disabled={isSaving}>
            {isSaving ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </StyledButton>
        </Box>
      )}
    </Box>
  );

  // ----------------------------------------------------------------
  // Diálogo de confirmación de borrado
  // ----------------------------------------------------------------
  const deleteConfirmDialog = (
    <Dialog
      open={deleteConfirmOpen}
      onClose={handleDeleteCancel}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          bgcolor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
          backgroundImage: 'none',
        },
      }}
    >
      <DialogTitle
        sx={{
          pt: 3,
          px: 3,
          fontSize: '1.1rem',
          fontWeight: 500
        }}
      >
        Delete File
      </DialogTitle>
      <DialogContent sx={{ px: 3, pb: 1 }}>
        <Typography>
          Are you sure you want to delete{' '}
          <strong>{deletingFile?.key.split('/').pop()}</strong>?
          This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3, display: 'flex', gap: 1 }}>
        <Button
          onClick={handleDeleteCancel}
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          }}
          disabled={isDeleting}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteConfirm}
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            backgroundColor: theme.palette.error.main,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.error.dark,
            },
          }}
          disabled={isDeleting}
        >
          {isDeleting ? <CircularProgress size={20} color="inherit" /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // ----------------------------------------------------------------
  // Render final, diferenciando el modo modal o normal
  // ----------------------------------------------------------------
  if (isModal) {
    return (
      <>
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: '20px',
              bgcolor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#ffffff',
              backgroundImage: 'none',
            },
          }}
        >
          <DialogTitle
            sx={{
              pt: 3,
              pb: 2,
              px: 3,
              fontSize: '1.1rem',
              fontWeight: 500,
              borderBottom: 1,
              borderColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)',
            }}
          >
            Add Files
          </DialogTitle>

          <DialogContent
            // Si quieres que las acciones queden fijas
            // con scroll interno, podrías hacer algo como
            // sx={{ maxHeight: '60vh', overflowY: 'auto' }}
            sx={{ mt: 2, p: 3 }}
          >
            {content}
          </DialogContent>

          <DialogActions
            sx={{
              // Para mantener fijo el footer en el dialog:
              // position: 'sticky',
              // bottom: 0,
              // zIndex: 10,
              // bgcolor: theme.palette.background.paper,
              px: 3,
              pb: 3,
              display: 'flex',
              gap: 1,
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                px: 3,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.1)'
                      : 'rgba(0, 0, 0, 0.05)',
                },
              }}
              disabled={isSaving}
            >
              Cancel
            </Button>

            <StyledButton
              onClick={handleSave}
              disabled={filesPending.length === 0 || isSaving}
            >
              {isSaving ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </StyledButton>
          </DialogActions>
        </Dialog>
        {deleteConfirmDialog}
      </>
    );
  }

  // Modo normal (no modal):
  return (
    <>
      <Box>
        {content}
        {/* En modo normal, el botón "Save" ya está sticky dentro de "content"
            solo si hay archivos pendientes. 
            Si quieres mostrar siempre el botón, muévelo de allí. */}
      </Box>
      {deleteConfirmDialog}
    </>
  );
};

export default FilesView;
