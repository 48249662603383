// AGTMaker.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Popover,
  MenuItem,
  Modal,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { X, Copy, Download } from 'lucide-react';
import mermaid from 'mermaid';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

import AGTMakerCode from './AGTMakerCode';
import AGTMakerExport from './AGTMakerExport';        // Exportador a PDF / TXT
import AGTMakerExportDocx from './AGTMakerExportDocx';
import AGTMakerExportTxt from './AGTMakerExportTxt';
import AGTMakerSearch from './AGTMakerSearch';

import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import html2canvas from 'html2canvas';
// --- ELIMINADO: import { saveSvgAsPng } from 'save-svg-as-png';  // <--- Se elimina la librería que causaba el error

// -----------------------------------------------------------------------------
//       Nueva implementación para convertir y descargar SVG como PNG
// -----------------------------------------------------------------------------
const downloadSvgAsPng = async (svgElement, filename, options = {}) => {
  if (!svgElement) {
    console.error('SVG element is null');
    return;
  }

  // Función auxiliar para convertir SVG a canvas
  const svgToCanvas = async (svg, width, height, scale = 2) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio || 1;

      canvas.width = width * scale * pixelRatio;
      canvas.height = height * scale * pixelRatio;

      // Escalar el contexto para mayor calidad
      ctx.scale(scale * pixelRatio, scale * pixelRatio);

      const svgData = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(svgUrl);
        resolve(canvas);
      };
      img.src = svgUrl;
    });
  };

  // Función auxiliar para descargar canvas como PNG
  const downloadCanvas = (canvas, filename) => {
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 'image/png');
  };

  try {
    // Obtener dimensiones del SVG real en la página
    const svgRect = svgElement.getBoundingClientRect();
    const { width, height } = svgRect;

    // Convertir SVG a canvas
    const canvas = await svgToCanvas(svgElement, width, height, options.scale || 2);

    // Descargar el canvas como PNG
    downloadCanvas(canvas, filename);
  } catch (error) {
    console.error('Error al descargar SVG como PNG:', error);
  }
};

// -----------------------------------------------------------------------------
//              Funciones auxiliares (normalización, parse, etc.)
// -----------------------------------------------------------------------------

// Normaliza fechas (dd/mm/yyyy) a (yyyy-mm-dd)
const normalizeDate = (dateStr) => {
  const regexDDMMYYYY = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const regexYYYYMMDD = /^(\d{4})-(\d{2})-(\d{2})$/;

  let match = dateStr.match(regexDDMMYYYY);
  if (match) {
    const [_, dd, mm, yyyy] = match;
    return `${yyyy}-${mm}-${dd}`;
  }

  match = dateStr.match(regexYYYYMMDD);
  if (match) {
    return dateStr;
  }

  throw new Error(`Formato de fecha no reconocido: ${dateStr}`);
};

// Ajusta las fechas en un diagrama Gantt de Mermaid
const normalizeGanttDates = (mermaidCode) => {
  const lines = mermaidCode.split('\n');
  const normalizedLines = lines.map(line => {
    if (!line.includes(',')) return line;

    const parts = line.split(',');
    if (parts.length < 3) return line;

    const datePart = parts[1].trim();
    let normalizedDate;
    try {
      normalizedDate = normalizeDate(datePart);
    } catch (error) {
      console.warn(error.message);
      return line;
    }

    parts[1] = ` ${normalizedDate}`;
    return parts.join(',');
  });

  let hasDateFormat = false;
  const finalLines = normalizedLines.map(line => {
    if (line.toLowerCase().startsWith('dateformat')) {
      hasDateFormat = true;
      return '    dateFormat YYYY-MM-DD';
    }
    return line;
  });

  if (!hasDateFormat) {
    const titleIndex = finalLines.findIndex(line => line.toLowerCase().startsWith('title'));
    if (titleIndex !== -1) {
      finalLines.splice(titleIndex + 1, 0, '    dateFormat YYYY-MM-DD');
    }
  }

  return finalLines.join('\n');
};

// Preprocesa (formatea) Mermaid
const preprocessMermaidCode = (code) => {
  // Elimina puntos y comas al final de líneas
  let formattedCode = code.replace(/;+/g, '');

  // Añade saltos de línea tras cada classDef
  formattedCode = formattedCode.replace(/(classDef\s+\w+\s+[^;]+)/g, '$1\n');

  // Asegura que "graph LR" o "graph TD" esté en su propia línea
  formattedCode = formattedCode.replace(/graph\s+\w+;?/i, (match) => match.replace(/;$/, '') + '\n');

  // Reemplaza ";" con saltos de línea
  formattedCode = formattedCode.split(';').join('\n');

  // Trim final
  formattedCode = formattedCode.trim();

  return formattedCode;
};

// Intenta arreglar el código mermaid con reglas básicas
const autoFixMermaidCode = (code) => {
  let fixedCode = code.replace(/;+/g, '\n');
  fixedCode = fixedCode.replace(/(\w+)\["(\w+);"\]/g, '$1["$2"]');
  return fixedCode.trim();
};

// Convierte [Expresión LaTeX] a $$Expresión$$ en markdown
const convertBracketedLaTeX = (text) => {
  const withLaTeXDelimiters = text.replace(/LaTeX:\s*\[([^\]]+)\]/g, 'LaTeX: $$ $1 $$');
  const finalText = withLaTeXDelimiters.replace(/\[([^\]]*\\[a-zA-Z]+\s*[^\]]*)\]/g, '$$ $1 $$');
  return finalText;
};

// -----------------------------------------------------------------------------
//                              Componente principal
// -----------------------------------------------------------------------------

const AGTMaker = ({ content, onDelete, isOpen, onClose, onContentReady = () => {} }) => {
  // -------------------------------------------------
  // States y Refs principales
  // -------------------------------------------------
  const [displayedContent, setDisplayedContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [isContentReady, setIsContentReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [diagrams, setDiagrams] = useState({});
  const [errors, setErrors] = useState({});
  const [hasContent, setHasContent] = useState(false);
  const searchContentRef = useRef(null);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiagram, setSelectedDiagram] = useState(null);
  // Ref para capturar SOLO el diagrama (para el preview en el modal)
  const diagramRef = useRef(null);

  // Paleta de colores para Mermaid
  const colorPalette = {
    nodes: [
      { fill: '#BBDEFB', stroke: '#1976D2', text: '#000000' },
      { fill: '#C8E6C9', stroke: '#388E3C', text: '#000000' },
      { fill: '#FFECB3', stroke: '#FFA000', text: '#000000' },
      { fill: '#F8BBD0', stroke: '#C2185B', text: '#000000' },
      { fill: '#D1C4E9', stroke: '#512DA8', text: '#000000' },
      { fill: '#B2EBF2', stroke: '#0097A7', text: '#000000' },
      { fill: '#FFCCBC', stroke: '#E64A19', text: '#000000' },
      { fill: '#F0F4C3', stroke: '#AFB42B', text: '#000000' }
    ],
    relationships: [
      '#1976D2',
      '#388E3C',
      '#FFA000',
      '#C2185B',
      '#512DA8',
      '#0097A7',
      '#E64A19',
      '#AFB42B'
    ]
  };

  // Inicializa mermaid y Prism
  useEffect(() => {
    mermaid.initialize({
      startOnLoad: false,
      theme: 'default',
      securityLevel: 'loose',
    });
    Prism.highlightAll();
  }, []);

  // Cuando se abre, extrae y renderiza
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const agtContent = extractAGTContent(content);
      setOriginalContent(agtContent);
      setDisplayedContent(agtContent);
      setHasContent(agtContent.trim().length > 0);

      renderDiagrams(agtContent).then(() => {
        setIsLoading(false);
        setIsContentReady(true);
        onContentReady();
        Prism.highlightAll();
      });
    }
  }, [isOpen, content, onContentReady]);

  // Extraer contenido <agt>...</agt>
  const extractAGTContent = useCallback((text) => {
    const agtRegex = /<agt>([\s\S]*?)<\/agt>/g;
    const matches = text.match(agtRegex);
    if (matches) {
      return matches.map(match => match.replace(/<\/?agt>/g, '')).join('\n\n');
    }
    return '';
  }, []);

  // Sanitiza mermaid
  const sanitizeMermaidCode = useCallback((code) => {
    // Updated regex to include all necessary characters for Mermaid diagrams
    const allowedCharacters = /^[a-zA-Z0-9\s#:\-\[\]"'(),._><\\|{}!/%=;…""''`$+*&@~^?]*$/;
    
    if (!allowedCharacters.test(code)) {
      throw new Error('El código Mermaid contiene caracteres no permitidos.');
    }
    
    return code;
}, []);  

  // Añade color a mermaid
  const addColorToMermaidCode = useCallback((code) => {
    const lines = code.split('\n').map(line => line.trim());
    if (lines.length === 0) return code;

    const firstNonEmptyLine = lines.find(line => line !== '');
    if (!firstNonEmptyLine) return code;

    let chartType = '';
    if (firstNonEmptyLine.toLowerCase().startsWith('graph')) {
      chartType = 'graph';
    } else if (firstNonEmptyLine.toLowerCase().startsWith('pie')) {
      chartType = 'pie';
    } else if (firstNonEmptyLine.toLowerCase().startsWith('line')) {
      chartType = 'line';
    } else if (firstNonEmptyLine.toLowerCase().startsWith('bar')) {
      chartType = 'bar';
    } else if (firstNonEmptyLine.toLowerCase().startsWith('gantt')) {
      chartType = 'gantt';
    } else {
      chartType = 'unknown';
    }

    if (chartType === 'graph') {
      let colorIndex = 0;
      const nodeColors = new Map();
      let coloredCode = '';

      const specialPatterns = [
        /^subgraph\s+/,
        /^end\s+/,
        /^classDef\s+/,
        /^linkStyle\s+/,
        /^%%\s+/,
        /^note\s+/,
        /^style\s+/
      ];

      lines.forEach(line => {
        if (chartType === 'graph' && /\btitle\b/.test(line)) {
          // Omite líneas con 'title'
          coloredCode += '';
          return;
        }

        if (specialPatterns.some(pattern => pattern.test(line))) {
          coloredCode += line + '\n';
          return;
        }

        if (line.length === 0) {
          coloredCode += '\n';
          return;
        }

        // Relación --> en la línea
        const relationshipMatch = line.match(/^"?([^"\s]+)"?\s*-->\s*"?([^"\s]+)"?/);
        if (relationshipMatch) {
          const [_, fromNode, toNode] = relationshipMatch;

          if (!nodeColors.has(fromNode)) {
            nodeColors.set(fromNode, `style${colorIndex}`);
            colorIndex++;
          }

          if (!nodeColors.has(toNode)) {
            nodeColors.set(toNode, `style${colorIndex}`);
            colorIndex++;
          }

          const relationshipColor = colorPalette.relationships[colorIndex % colorPalette.relationships.length];
          coloredCode += `${fromNode} -->|${relationshipColor}| ${toNode}\n`;

        } else if (/^\w+$/.test(line)) {
          // Nodo suelto
          const node = line;
          if (!nodeColors.has(node)) {
            nodeColors.set(node, `style${colorIndex}`);
            colorIndex++;
          }
          coloredCode += node + '\n';
        } else {
          coloredCode += line + '\n';
        }
      });

      // Definiciones de estilo
      let styleDefinitions = 'classDef default fill:#f9f9f9,stroke:#999,stroke-width:1px\n';
      colorPalette.nodes.forEach((scheme, index) => {
        styleDefinitions += `classDef style${index} fill:${scheme.fill},stroke:${scheme.stroke},stroke-width:2px,color:${scheme.text}\n`;
      });

      // Insertamos "class" para los nodos detectados
      let finalCode = styleDefinitions + '\n';
      nodeColors.forEach((styleClass, node) => {
        finalCode += `${node}:::${styleClass}\n`;
      });

      // Retornamos definiciones + cuerpo
      return `${finalCode}\n${coloredCode}`;
    } else {
      // Pie, gantt, etc.
      let cleanedCode = '';
      lines.forEach(line => {
        if (chartType !== 'graph' && /\btitle\b/.test(line)) {
          cleanedCode += line + '\n';
          return;
        }
        cleanedCode += line + '\n';
      });
      return cleanedCode;
    }
  }, [colorPalette]);

  // Parse line diagram
  const parseLineDiagram = useCallback((code) => {
    const lines = code.split('\n').map(line => line.trim()).filter(line => line !== '');
    const data = [];
    let title = '';
    let xAxis = '';
    let yAxis = '';

    lines.forEach(line => {
      if (line.toLowerCase().startsWith('title')) {
        title = line.replace(/title\s+/i, '').trim();
      } else if (line.toLowerCase().startsWith('xaxis')) {
        xAxis = line.replace(/xaxis\s*:/i, '').trim();
      } else if (line.toLowerCase().startsWith('yaxis')) {
        yAxis = line.replace(/yaxis\s*:/i, '').trim();
      } else {
        // Ejemplo: "serie1: label, 100"
        const match = line.match(/^"?([^":]+)"?\s*:\s*([^,]+),\s*(\d+)$/);
        if (match) {
          const label = match[2].trim();
          const value = parseInt(match[3], 10);
          data.push({ label, value });
        }
      }
    });

    return { title, xAxis, yAxis, data };
  }, []);

  // Parse bar diagram
  const parseBarDiagram = useCallback((code) => {
    const lines = code.split('\n').map(line => line.trim()).filter(line => line !== '');
    const data = [];
    let title = '';
    let xAxis = '';
    let yAxis = '';

    lines.forEach(line => {
      if (line.toLowerCase().startsWith('title')) {
        title = line.replace(/title\s+/i, '').trim();
      } else if (line.toLowerCase().startsWith('x-axis') || line.toLowerCase().startsWith('xaxis')) {
        xAxis = line.replace(/x-?axis\s*:/i, '').trim();
      } else if (line.toLowerCase().startsWith('y-axis') || line.toLowerCase().startsWith('yaxis')) {
        yAxis = line.replace(/y-?axis\s*:/i, '').trim();
      } else {
        // Ejemplo: "Productos: 250"
        const match = line.match(/^"?([^":]+)"?\s*:\s*(\d+)$/);
        if (match) {
          const label = match[1].trim();
          const value = parseInt(match[2], 10);
          data.push({ label, value });
        }
      }
    });

    return { title, xAxis, yAxis, data };
  }, []);

  // Render diagrams
  const renderDiagrams = useCallback(async (text) => {
    const processedText = convertBracketedLaTeX(text);
    const mermaidRegex = /```mermaid([\s\S]*?)```/g;
    const matches = processedText.matchAll(mermaidRegex);

    const newDiagrams = {};
    const newErrors = {};
    let index = 0;

    for (const match of matches) {
      let codeBlock = match[1].trim();
      codeBlock = preprocessMermaidCode(codeBlock);

      const firstLine = codeBlock.split('\n')[0].trim().toLowerCase();

      if (firstLine.startsWith('line')) {
        const { title, xAxis, yAxis, data } = parseLineDiagram(codeBlock);
        newDiagrams[index] = { type: 'line', title, xAxis, yAxis, data };
      } else if (firstLine.startsWith('bar')) {
        const { title, xAxis, yAxis, data } = parseBarDiagram(codeBlock);
        newDiagrams[index] = { type: 'bar', title, xAxis, yAxis, data };
      } else {
        // Render mermaid normal
        try {
          let sanitizedCode = sanitizeMermaidCode(codeBlock);
          if (sanitizedCode.toLowerCase().startsWith('gantt')) {
            sanitizedCode = normalizeGanttDates(sanitizedCode);
          }
          const coloredCode = addColorToMermaidCode(sanitizedCode);

          const { svg } = await mermaid.render(`mermaid-${index}`, coloredCode);
          newDiagrams[index] = { type: 'mermaid', svg };
        } catch (error) {
          console.error('Error rendering Mermaid diagram:', error);

          // Intentar segunda pasada autoFix
          try {
            const autoFixedCode = autoFixMermaidCode(codeBlock);
            const { svg } = await mermaid.render(`mermaid-${index}`, autoFixedCode);
            newDiagrams[index] = { type: 'mermaid', svg };
          } catch (autoError) {
            console.error('Auto-fix failed:', autoError);
            newErrors[index] = autoError.message;
            newDiagrams[index] = {
              type: 'error',
              content: `
                <div style="padding: 16px; border: 1px solid #ff6b6b; border-radius: 4px; background-color: #fff5f5; color: #c92a2a;">
                  <p style="margin: 0; font-weight: bold;">Error rendering diagram:</p>
                  <p style="margin: 8px 0 0 0; font-family: monospace;">${autoError.message}</p>
                </div>
              `
            };
          }
        }
      }
      index++;
    }

    setDiagrams(newDiagrams);
    setErrors(newErrors);
  }, [
    sanitizeMermaidCode,
    addColorToMermaidCode,
    parseLineDiagram,
    parseBarDiagram
  ]);

  // Copiar contenido
  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(displayedContent);
    } catch (error) {
      console.error('Error copiando al portapapeles:', error);
    }
  }, [displayedContent]);

  // Menu (popover) de descargas
  const handleDownloadClick = useCallback((event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setAnchorEl(event.currentTarget);
  }, []);

  const handleDownloadClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // --------------------------------------------------------------------------
  // Generamos imágenes de los diagramas y luego exportamos (TXT, PDF, DOCX)
  // --------------------------------------------------------------------------
  const handleDownloadTxt = useCallback(async () => {
    try {
      await AGTMakerExportTxt.exportToTxt(displayedContent, 'myPlainText.txt');
      handleDownloadClose();
    } catch (error) {
      console.error('Error exportando a TXT:', error);
    }
  }, [displayedContent, handleDownloadClose]);

  const handleDownloadPdf = useCallback(async () => {
    try {
      // 1. Generar imágenes base64 con html2canvas (para PDF).
      const diagramImages = {};
      for (const [index, diag] of Object.entries(diagrams)) {
        if (diag.type === 'mermaid' || diag.type === 'line' || diag.type === 'bar') {
          const elementId = `diagram-${index}`;
          const element = document.getElementById(elementId);
          if (element) {
            const canvas = await html2canvas(element, { scale: 2 });
            const dataUrl = canvas.toDataURL('image/png');
            diagramImages[index] = {
              type: diag.type,
              title: diag.title || '',
              dataUrl,
            };
          }
        }
      }

      // 2. Llamamos a la función de exportación a PDF
      await AGTMakerExport.exportToPdf(displayedContent, {}, diagramImages);
      handleDownloadClose();
    } catch (error) {
      console.error('Error exportando a PDF:', error);
    }
  }, [displayedContent, diagrams, handleDownloadClose]);

  const handleDownloadDocx = useCallback(async () => {
    try {
      // 1. Generar imágenes base64 (para DOCX).
      const diagramImages = {};
      for (const [index, diag] of Object.entries(diagrams)) {
        if (diag.type === 'mermaid' || diag.type === 'line' || diag.type === 'bar') {
          const elementId = `diagram-${index}`;
          const element = document.getElementById(elementId);
          if (element) {
            const canvas = await html2canvas(element, { scale: 2 });
            const dataUrl = canvas.toDataURL('image/png');
            diagramImages[index] = {
              type: diag.type,
              title: diag.title || '',
              dataUrl,
            };
          }
        }
      }

      // 2. Exportar a DOCX usando nuestro nuevo exportador
      await AGTMakerExportDocx.exportToDocx(displayedContent, {}, diagramImages);
      handleDownloadClose();
    } catch (error) {
      console.error('Error exportando a DOCX:', error);
    }
  }, [displayedContent, diagrams, handleDownloadClose]);

  // Al hacer clic en un diagrama => abrir modal
  const handleDiagramClick = useCallback((diagramData) => {
    setSelectedDiagram(diagramData);
    setIsModalOpen(true);
  }, []);

  // --------------------------------------------------------------------------
  // NUEVO handleDownloadImage SIN save-svg-as-png
  // --------------------------------------------------------------------------
  const handleDownloadImage = useCallback(() => {
    if (typeof window === 'undefined') {
      console.error('No hay "window" (posible SSR).');
      return;
    }

    if (!diagramRef.current) {
      console.error('diagramRef.current es null');
      return;
    }

    const svgElement = diagramRef.current.querySelector('svg');
    if (!svgElement) {
      console.error('No se encontró un elemento <svg> en el diagrama');
      return;
    }

    // Llamamos a nuestra nueva función para descargar
    downloadSvgAsPng(svgElement, 'diagram.png', { scale: 2 })
      .catch((error) => {
        console.error('Error al descargar imagen PNG:', error);
      });
  }, []);

  // Render del contenido principal (markdown, code, diagramas)
  const renderContent = useCallback(() => {
    if (isLoading || !isContentReady) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography>Cargando...</Typography>
        </Box>
      );
    }

    const blocks = displayedContent.split(/(```[\s\S]*?```)/);

    return (
      <Box>
        {blocks.map((block, index) => {
          // Bloques de código triple-backtick
          if (block.startsWith('```') && block.endsWith('```')) {
            const [firstLine, ...codeLines] = block.slice(3, -3).split('\n');
            const language = firstLine.trim();
            const code = codeLines.join('\n');

            if (language === 'mermaid') {
              const firstCodeLine = code.split('\n')[0].trim().toLowerCase();
              // Evita re-render de line, bar, gantt
              if (
                firstCodeLine.startsWith('line') ||
                firstCodeLine.startsWith('bar') ||
                firstCodeLine.startsWith('gantt')
              ) {
                return null;
              }
              return (
                <Box key={`code-block-${index}`} sx={{ position: 'relative', mb: 4 }}>
                  <Box className="code-container searchable-content">
                    <AGTMakerCode
                      code={code}
                      language={language}
                      searchable={true}
                    />
                  </Box>
                </Box>
              );
            }

            // Código normal
            return (
              <Box key={`code-block-${index}`} sx={{ position: 'relative', mb: 4 }}>
                <Box className="code-container searchable-content">
                  <AGTMakerCode
                    code={code}
                    language={language}
                    searchable={true}
                  />
                </Box>
              </Box>
            );
          }

          // Bloque normal de Markdown
          return (
            <div key={`content-${index}`} className="content-block content-line searchable-content">
              <ReactMarkdown
                remarkPlugins={[remarkMath, remarkGfm]}
                rehypePlugins={[rehypeKatex]}
                components={{
                  table: ({ node, ...props }) => (
                    <Box
                      component="table"
                      sx={{ width: '100%', borderCollapse: 'collapse', mb: 2 }}
                      {...props}
                    />
                  ),
                  th: ({ node, ...props }) => (
                    <Box
                      component="th"
                      sx={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}
                      {...props}
                    />
                  ),
                  td: ({ node, ...props }) => (
                    <Box
                      component="td"
                      sx={{ border: '1px solid #ddd', padding: '8px' }}
                      {...props}
                    />
                  ),
                }}
                className="markdown-content"
              >
                {block}
              </ReactMarkdown>
            </div>
          );
        })}

        {/* Render de los diagramas (mermaid, line, bar, error) */}
        {Object.entries(diagrams).map(([index, diagram]) => {
          if (diagram.type === 'mermaid') {
            return (
              <Box
                key={`mermaid-${index}`}
                id={`diagram-${index}`}
                className="mermaid-diagram searchable-content"
                sx={{ marginTop: '16px', marginBottom: '16px', cursor: 'pointer' }}
                onClick={() => handleDiagramClick(diagram)}
                dangerouslySetInnerHTML={{ __html: diagram.svg }}
              />
            );
          } else if (diagram.type === 'line') {
            return (
              <Box
                key={`line-${index}`}
                id={`diagram-${index}`}
                className="line-diagram searchable-content"
                sx={{ marginTop: '16px', marginBottom: '16px', cursor: 'pointer' }}
                onClick={() => handleDiagramClick(diagram)}
              >
                <Typography variant="h6" align="center" gutterBottom>
                  {diagram.title}
                </Typography>
                <ResponsiveContainer width="100%" height={600}>
                  <LineChart data={diagram.data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            );
          } else if (diagram.type === 'bar') {
            return (
              <Box
                key={`bar-${index}`}
                id={`diagram-${index}`}
                className="bar-diagram searchable-content"
                sx={{ marginTop: '16px', marginBottom: '16px', cursor: 'pointer' }}
                onClick={() => handleDiagramClick(diagram)}
              >
                <Typography variant="h6" align="center" gutterBottom>
                  {diagram.title}
                </Typography>
                <ResponsiveContainer width="100%" height={600}>
                  <BarChart data={diagram.data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            );
          } else if (diagram.type === 'error') {
            return (
              <Box
                key={`error-${index}`}
                className="mermaid-error searchable-content"
                sx={{ marginTop: '16px', marginBottom: '16px' }}
                dangerouslySetInnerHTML={{ __html: diagram.content }}
              />
            );
          } else {
            return null;
          }
        })}
      </Box>
    );
  }, [
    displayedContent,
    diagrams,
    isLoading,
    isContentReady,
    handleDiagramClick
  ]);

  // --------------------------------------------------------------------------
  //       Estilado del Whiteboard, Header, Footer, etc. con styled()
  // --------------------------------------------------------------------------
  const WhiteboardContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '10px',
    left: '10px',
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    border: 'none',
    color: theme.palette.text.primary,
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease',
    transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
    opacity: isOpen ? 1 : 0,
    zIndex: 1300,
    borderRadius: '12px',
    ...(theme.palette.mode === 'dark' && {
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
      border: '1px solid rgba(255, 255, 255, 0.05)',
    }),
  }));

  const WhiteboardHeader = styled(Box)(({ theme }) => ({
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : theme.palette.divider
    }`,
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
    position: 'relative',
  }));

  const WhiteboardTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: '1.25rem',
  }));

  const ContentArea = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    padding: '24px',
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
    position: 'relative',
    height: 'calc(100vh - 200px)',
    '& .katex': {
      fontSize: '1.1em',
      fontFamily: 'KaTeX_Math',
    },
    '& .katex-display': {
      margin: '1em 0',
      overflow: 'auto hidden',
      '& > .katex': {
        maxWidth: '100%',
      }
    },
    '& .math-inline': {
      padding: '0 0.2em',
    },
    '& .search-highlight-overlay': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 0, 0.15)'
          : 'rgba(255, 255, 0, 0.3)',
      borderRadius: '2px',
    },
    '& .search-highlight-active': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 165, 0, 0.3)'
          : 'rgba(255, 165, 0, 0.5) !important',
    },
    '& .content-line': {
      padding: '2px 0',
      transition: 'background-color 0.3s ease',
    },
    '& .code-container': {
      borderRadius: '8px',
      overflow: 'hidden',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0, 0, 0, 0.05)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.15)'
            : 'rgba(0, 0, 0, 0.15)',
      },
    },
    '& .searchable-content': {
      position: 'relative',
    },
    '& .mermaid-diagram': {
      marginTop: '16px',
      marginBottom: '16px',
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.05)'
            : 'rgba(0, 0, 0, 0.02)',
        padding: '16px',
      }
    },
    '& .line-diagram': {
      marginTop: '16px',
      marginBottom: '16px',
    },
    '& .bar-diagram': {
      marginTop: '16px',
      marginBottom: '16px',
    },
    '& .mermaid-error': {
      marginTop: '16px',
      marginBottom: '16px',
      color: '#c92a2a',
    }
  }));

  const WhiteboardFooter = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderTop: `1px solid ${
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : theme.palette.divider
    }`,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1b1e' : theme.palette.background.paper,
  }));

  // --------------------------------------------------------------------------
  //               Render final del componente AGTMaker
  // --------------------------------------------------------------------------

  if (!isOpen || !hasContent) return null;

  return (
    <WhiteboardContainer>
      <WhiteboardHeader>
        <WhiteboardTitle>AGT Maker</WhiteboardTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <X />
        </IconButton>
      </WhiteboardHeader>

      <ContentArea>
        <Box ref={searchContentRef}>{renderContent()}</Box>
      </ContentArea>

      {isContentReady && (
        <WhiteboardFooter>
          <Tooltip title="Copiar contenido">
            <IconButton onClick={handleCopy}>
              <Copy />
            </IconButton>
          </Tooltip>
          <Tooltip title="Buscar contenido">
            <span>
              <AGTMakerSearch
                content={displayedContent}
                contentRef={searchContentRef}
              />
            </span>
          </Tooltip>
          <Tooltip title="Descargar contenido">
            <IconButton onClick={handleDownloadClick}>
              <Download />
            </IconButton>
          </Tooltip>
        </WhiteboardFooter>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleDownloadClose}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleDownloadTxt}>Descargar como TXT</MenuItem>
        <MenuItem onClick={handleDownloadPdf}>Descargar como PDF</MenuItem>
        <MenuItem onClick={handleDownloadDocx}>Descargar como DOCX</MenuItem>
      </Popover>

      {/* Modal amplio y con scroll si es necesario */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="diagram-modal-title"
        aria-describedby="diagram-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 2,
        }}
      >
        <Box
          sx={{
            width: '95vw',
            height: '85vh',
            overflow: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
            <IconButton onClick={() => setIsModalOpen(false)}>
              <X />
            </IconButton>
          </Box>

          <Box
            ref={diagramRef}
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              '& svg': {
                minWidth: '80%',
                minHeight: '60vh',
                width: 'auto !important',
                height: 'auto !important',
                maxWidth: '100%',
                maxHeight: '70vh',
                objectFit: 'contain',
              }
            }}
          >
            {selectedDiagram && selectedDiagram.type === 'mermaid' && (
              <Box
                id="diagram-modal-description"
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& > *': {
                    minWidth: '80%',
                    minHeight: '60vh',
                    width: 'auto !important',
                    height: 'auto !important',
                  }
                }}
                dangerouslySetInnerHTML={{ __html: selectedDiagram.svg }}
              />
            )}

            {selectedDiagram && selectedDiagram.type === 'line' && (
              <>
                <Typography variant="h6" align="center" gutterBottom>
                  {selectedDiagram.title}
                </Typography>
                <Box sx={{ width: '80%', height: 600 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={selectedDiagram.data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label" />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Line type="monotone" dataKey="value" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </>
            )}

            {selectedDiagram && selectedDiagram.type === 'bar' && (
              <>
                <Typography variant="h6" align="center" gutterBottom>
                  {selectedDiagram.title}
                </Typography>
                <Box sx={{ width: '80%', height: 600 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={selectedDiagram.data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="label" />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </>
            )}
          </Box>

          {/* Botón para descargar la imagen tal cual se ve */}
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Button variant="contained" onClick={handleDownloadImage}>
              Download as Image
            </Button>
          </Box>
        </Box>
      </Modal>
    </WhiteboardContainer>
  );
};

export default React.memo(AGTMaker);
