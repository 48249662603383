import jsPDF from "jspdf";

/**
 * Lógica de parseo de contenido Markdown línea a línea.
 * - Detecta ```...``` como bloques de código (con o sin lenguaje).
 * - El resto se agrupa en "párrafos" separados por línea en blanco.
 */
function parseMarkdownBlocks(content) {
  const lines = content.split('\n');

  let blocks = [];
  let currentBlock = [];
  let inCodeBlock = false;
  let codeFenceLanguage = '';

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    // 1) Detectar inicio/cierre de bloque de código con triple backticks: ```
    const codeFenceMatch = line.match(/^```(\S*)/);

    if (codeFenceMatch) {
      // ¿Ya estábamos en un bloque de código?
      if (inCodeBlock) {
        // Esto es el cierre de bloque
        const codeContent = currentBlock.join('\n');
        blocks.push({
          type: 'code',
          language: codeFenceLanguage,
          content: codeContent
        });

        // Reset
        currentBlock = [];
        inCodeBlock = false;
        codeFenceLanguage = '';
      } else {
        // Apertura de bloque
        inCodeBlock = true;
        codeFenceLanguage = codeFenceMatch[1].trim(); // p.ej. "python", "mermaid", etc.
        // Se acumula el código en currentBlock, a partir de la siguiente línea
        currentBlock = [];
      }
    }
    else if (inCodeBlock) {
      // Estamos dentro de un bloque de código
      currentBlock.push(line);
    }
    else {
      // Fuera de bloque de código
      // Si la línea está vacía => marca fin de párrafo
      if (line.trim() === '') {
        if (currentBlock.length > 0) {
          blocks.push({
            type: 'paragraph',
            content: currentBlock.join('\n')
          });
          currentBlock = [];
        }
      } else {
        // Seguimos acumulando texto en el párrafo
        currentBlock.push(line);
      }
    }
  }

  // Al final, si queda algo pendiente, lo cargamos como párrafo
  if (currentBlock.length > 0) {
    blocks.push({
      type: 'paragraph',
      content: currentBlock.join('\n')
    });
  }

  return blocks;
}

/**
 * Clase principal PDFExporter para construir un PDF:
 * - Portada, TOC, encabezados, figuras, tablas, ecuaciones, etc.
 * - Maneja la lógica de maquetación básica con jsPDF.
 */
class PDFExporter {
  constructor(title = '', author = '', institution = '', date = '') {
    this.doc = new jsPDF({
      orientation: "portrait",
      unit: "in",  // pulgadas
      format: "letter"
    });

    // Metadata
    this.documentInfo = {
      title,
      author,
      institution,
      date: date || new Date().toLocaleDateString()
    };

    // Dimensiones y márgenes
    this.pageWidth = this.doc.internal.pageSize.width;   // 8.5 in
    this.pageHeight = this.doc.internal.pageSize.height; // 11 in
    this.margins = { top: 1, bottom: 1, left: 1, right: 1 };

    // Contadores
    this.currentY = this.margins.top;
    this.tableCount = 0;
    this.figureCount = 0;
    this.equationCount = 0;

    // Definición de fuentes
    this.fonts = {
      mainTitle: {
        family: 'Times-Roman',
        style: 'bold',
        size: 16,
        transform: 'uppercase'
      },
      title: {
        family: 'Times-Roman',
        style: 'bold',
        size: 14
      },
      heading: {
        family: 'Times-Roman',
        style: 'bold',
        size: 12,
        decoration: 'underline'
      },
      subheading: {
        family: 'Times-Roman',
        style: 'bold',
        size: 12
      },
      body: {
        family: 'Times-Roman',
        style: 'normal',
        size: 12,
        lineHeight: 1.5
      },
      caption: {
        family: 'Times-Roman',
        style: 'italic',
        size: 10
      },
      citation: {
        family: 'Times-Roman',
        style: 'normal',
        size: 10
      },
      header: {
        family: 'Times-Roman',
        style: 'normal',
        size: 9,
        color: 120 // gris
      },
      boldInline: {
        family: 'Times-Roman',
        style: 'bold',
        size: 12
      }
    };

    // TOC: guardaremos aquí las entradas
    this.tocEntries = [];

    // Anclas para figuras
    this.figureAnchors = {};

    // Añadir portada y TOC
    this.addCoverPage();
    this.doc.addPage();
    this.addTableOfContentsPage();
    this.doc.addPage();
    this.currentY = this.margins.top;

    // Configurar headers/footers inicial
    this.setupHeadersAndFooters();
  }

  /**
   * Configura headers y footers en todas las páginas.
   */
  setupHeadersAndFooters() {
    const totalPages = this.doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      this.doc.setPage(i);

      // Omitimos portada (pág 1) y TOC (pág 2)
      if (i === 1) continue;
      if (i === 2) continue;

      // Header
      this.doc.setFont(this.fonts.header.family, this.fonts.header.style);
      this.doc.setFontSize(this.fonts.header.size);
      this.doc.setTextColor(this.fonts.header.color);

      const headerLeftX = this.margins.left;
      let headerY = 0.5;
      this.doc.text("Created with AGT Maker by AGT.", headerLeftX, headerY, { align: 'left' });
      headerY += 0.12;
      this.doc.text("Free to use.", headerLeftX, headerY, { align: 'left' });
      headerY += 0.12;
      this.doc.text("Subscribe to Pro for more features.", headerLeftX, headerY, { align: 'left' });

      // Footer - número de página
      this.setFont('body');
      this.doc.setTextColor(0);
      this.doc.text(
        `Page ${i}`,
        this.pageWidth - this.margins.right,
        this.pageHeight - 0.5,
        { align: 'right' }
      );
    }
  }

  /**
   * Crea portada en la página 1
   */
  addCoverPage() {
    // Título
    this.setFont('mainTitle');
    this.doc.text(
      (this.documentInfo.title || '').toUpperCase(),
      this.pageWidth / 2,
      4,
      { align: 'center' }
    );

    // Autor
    this.setFont('title');
    this.doc.text(
      this.documentInfo.author,
      this.pageWidth / 2,
      5,
      { align: 'center' }
    );

    // Institución
    this.doc.text(
      this.documentInfo.institution,
      this.pageWidth / 2,
      5.5,
      { align: 'center' }
    );

    // Fecha
    this.doc.text(
      this.documentInfo.date,
      this.pageWidth / 2,
      6,
      { align: 'center' }
    );
  }

  /**
   * Crea página 2 con Título "Table of Contents" (placeholder).
   */
  addTableOfContentsPage() {
    this.currentY = this.margins.top;
    this.setFont('title');
    this.doc.text('Table of Contents', this.pageWidth / 2, this.currentY, { align: 'center' });
    this.currentY += (this.fonts.title.size / 72) * 2;
  }

  /**
   * Tras terminar el contenido, genera la TOC en la página 2.
   */
  generateTableOfContents() {
    // Aseguramos que estamos en la página 2 (donde creaste el TOC)
    this.doc.setPage(2);
  
    // Título "Table of Contents"
    this.currentY = this.margins.top;
    this.setFont('title');
    this.doc.text('Table of Contents', this.pageWidth / 2, this.currentY, { align: 'center' });
    this.currentY += (this.fonts.title.size / 72) * 2;
  
    // Ajustes de fuente para el contenido del TOC
    this.setFont('body');
    const lineHeight = this.fonts.body.lineHeight * (this.fonts.body.size / 72);
  
    // Márgenes para izquierda y derecha
    const leftX = this.margins.left;
    const rightX = this.pageWidth - this.margins.right;
  
    this.tocEntries.forEach(({ text, pageNumber, level, yAnchor }) => {
      // Indent opcional según el nivel
      const levelIndent = (level - 1) * 0.25;
      const xPos = leftX + levelIndent;
  
      // Texto del heading en el TOC
      const textStr = text;
      
      // Medimos cuánto ancho ocupa ese texto
      const textWidth = this.doc.getTextWidth(textStr);
  
      // Número de página como string
      const pageNumberStr = String(pageNumber);
      const pageNumberWidth = this.doc.getTextWidth(pageNumberStr);
  
      // Calculamos desde dónde podemos pintar los puntos
      const gapAfterText = 0.2; // Espacio pequeño entre el texto y los puntos
      const fromX = xPos + textWidth + gapAfterText;
      // Queremos que el número de página termine en rightX, pero ojo con su ancho
      const toX = rightX - pageNumberWidth;
  
      // Espacio para los puntos
      let space = toX - fromX;
      if (space < 0) space = 0; // Si el texto es muy largo, evita números negativos
  
      // Cuántos puntos caben
      const dotWidth = this.doc.getTextWidth('.');
      const dotCount = Math.floor(space / dotWidth);
      const dots = '.'.repeat(dotCount);
  
      // -- 1) Texto con link --
      // Usamos textWithLink en lugar de text
      // para que sea clicable y salte a (pageNumber, yAnchor).
      this.doc.textWithLink(textStr, xPos, this.currentY, {
        pageNumber,
        top: yAnchor
      });
  
      // -- 2) Pintamos los puntos como "..." sin link
      this.doc.text(dots, fromX, this.currentY);
  
      // -- 3) Número de página también con link
      this.doc.textWithLink(pageNumberStr, toX, this.currentY, {
        pageNumber,
        top: yAnchor
      });
  
      // Salto de línea para la siguiente entrada
      this.currentY += lineHeight;
    });
  }  

  /**
   * Ajusta fuente según this.fonts
   */
  setFont(type) {
    const font = this.fonts[type];
    this.doc.setFont(font.family, font.style);
    this.doc.setFontSize(font.size);
    if (font.color !== undefined) {
      this.doc.setTextColor(font.color);
    } else {
      this.doc.setTextColor(0);
    }
  }

  /**
   * Añade un párrafo con soporte (simplificado) para negritas inline `**Texto**`.
   */
  addParagraph(text, indent = true) {
    this.setFont('body');
    const lineHeight = this.fonts.body.lineHeight * (this.fonts.body.size / 72);

    const maxWidth = this.pageWidth - this.margins.left - this.margins.right
      - (indent ? 0.5 : 0);

    // Rompe en líneas usando splitTextToSize
    const lines = this.doc.splitTextToSize(text, maxWidth);

    // Verifica salto de página
    const totalHeight = lines.length * lineHeight;
    if (this.checkPageBreak(totalHeight)) {
      this.currentY = this.margins.top;
    }

    // Para cada línea, parse `**...**`
    lines.forEach((line, index) => {
      let xPos = this.margins.left + (index === 0 && indent ? 0.5 : 0);

      // tokens = ["Texto normal ", "TextoNegrita", " más texto..."]
      const tokens = line.split(/\*\*(.*?)\*\*/g);

      for (let i = 0; i < tokens.length; i++) {
        const isBold = (i % 2 === 1); // posiciones impares
        const token = tokens[i];

        if (isBold) {
          this.doc.setFont(this.fonts.boldInline.family, this.fonts.boldInline.style);
          this.doc.setFontSize(this.fonts.boldInline.size);
        } else {
          this.doc.setFont(this.fonts.body.family, this.fonts.body.style);
          this.doc.setFontSize(this.fonts.body.size);
        }

        this.doc.text(token, xPos, this.currentY);
        const tokenWidth = this.doc.getTextWidth(token);
        xPos += tokenWidth;
      }

      this.currentY += lineHeight;
    });

    this.addSpacing(12/72);
  }

  /**
   * Añade cita en fuente pequeña, indentada
   */
  addCitation(text) {
    this.setFont('citation');
    const lineHeight = 1.5 * (this.fonts.citation.size / 72);
    const maxWidth = this.pageWidth - this.margins.left - this.margins.right - 1;

    const lines = this.doc.splitTextToSize(text, maxWidth);
    lines.forEach(line => {
      this.doc.text(line, this.margins.left + 1, this.currentY);
      this.currentY += lineHeight;
    });
    this.addSpacing(12/72);
  }

  /**
   * Añade una tabla simple
   */
  addTable(headers, rows, title = '') {
    this.tableCount++;
    const lineHeight = (this.fonts.body.size / 72) * 1.2;
    const cellPadding = 0.1;
    const maxWidth = this.pageWidth - this.margins.left - this.margins.right;
    const columnWidth = maxWidth / headers.length;

    this.setFont('body');
    const tableTitle = `Table ${this.tableCount}: ${title}`;
    this.doc.text(tableTitle, this.pageWidth / 2, this.currentY, { align: 'center' });
    this.currentY += lineHeight * 1.5;

    const tableLeft = this.margins.left;
    // Encabezados
    headers.forEach((header, i) => {
      const x = tableLeft + columnWidth * i;
      this.doc.rect(x, this.currentY - lineHeight + cellPadding, columnWidth, lineHeight);
      this.doc.text(header, x + cellPadding, this.currentY);
    });
    this.currentY += lineHeight;

    // Filas
    this.setFont('citation');
    rows.forEach(row => {
      row.forEach((cell, i) => {
        const x = tableLeft + columnWidth * i;
        this.doc.rect(x, this.currentY - lineHeight + cellPadding, columnWidth, lineHeight);
        this.doc.text(cell.toString(), x + cellPadding, this.currentY);
      });
      this.currentY += lineHeight;
    });

    this.addSpacing(12/72);
  }

  /**
   * Añade imagen como figura + caption y ancla
   */
  addImage(dataUrl, caption = '', source = '') {
    this.figureCount++;

    const figurePage = this.doc.internal.getCurrentPageInfo().pageNumber;
    const figureY = this.currentY;

    const maxWidth = this.pageWidth - this.margins.left - this.margins.right;
    const imgProps = this.doc.getImageProperties(dataUrl);

    this.setFont('caption');
    const captionText = `Figure ${this.figureCount}: ${caption}`;
    this.doc.text(captionText, this.pageWidth / 2, this.currentY, { align: 'center' });
    this.currentY += 0.2;

    let imgWidth = maxWidth * 0.8;
    if (imgWidth > maxWidth) {
      imgWidth = maxWidth;
    }
    const scale = imgWidth / imgProps.width;
    const imgHeight = imgProps.height * scale;
    const xOffset = (this.pageWidth - imgWidth) / 2;

    if (this.checkPageBreak(imgHeight + 0.5)) {
      this.currentY = this.margins.top;
    }

    this.doc.addImage(
      dataUrl,
      'PNG',
      xOffset,
      this.currentY,
      imgWidth,
      imgHeight
    );
    this.currentY += imgHeight + 0.2;

    if (source) {
      this.doc.text(`Source: ${source}`, this.pageWidth / 2, this.currentY, { align: 'center' });
      this.currentY += 0.2;
    }

    this.addSpacing(12/72);

    this.figureAnchors[this.figureCount] = {
      page: figurePage,
      yPos: figureY
    };

    return this.figureCount;
  }

  /**
   * Añade ecuación (placeholder LaTeX)
   */
  addEquation(latex, numbered = true) {
    if (numbered) this.equationCount++;
    this.setFont('body');
    const eqStr = `[${latex}]${numbered ? ` (${this.equationCount})` : ''}`;
    this.doc.text(eqStr, this.pageWidth / 2, this.currentY, { align: 'center' });
    this.currentY += (this.fonts.body.size / 72) * 1.5;
    this.addSpacing(12/72);
  }

  addBulletList(items, level = 0) {
    this.setFont('body');
    const lineHeight = this.fonts.body.lineHeight * (this.fonts.body.size / 72);
    const indent = this.margins.left + (level * 0.25);
    const bullet = level === 0 ? '•' : '➔';

    items.forEach(item => {
      this.doc.text(`${bullet} ${item}`, indent, this.currentY);
      this.currentY += lineHeight;
    });
    this.addSpacing(12/72);
  }

  addNumberedList(items, level = 0) {
    this.setFont('body');
    const lineHeight = this.fonts.body.lineHeight * (this.fonts.body.size / 72);
    const indent = this.margins.left + (level * 0.25);

    items.forEach((item, index) => {
      this.doc.text(`${index + 1}. ${item}`, indent, this.currentY);
      this.currentY += lineHeight;
    });
    this.addSpacing(12/72);
  }

  /**
   * Verifica salto de página
   */
  checkPageBreak(height) {
    if (this.currentY + height > this.pageHeight - this.margins.bottom) {
      this.doc.addPage();
      this.currentY = this.margins.top;
      return true;
    }
    return false;
  }

  /**
   * Añade un espacio vertical en pulgadas
   */
  addSpacing(inches) {
    this.currentY += inches;
  }

  /**
   * Genera TOC, configura headers/footers y guarda el PDF
   */
  save(filename = 'document.pdf') {
    this.generateTableOfContents();
    this.setupHeadersAndFooters();
    this.doc.save(filename);
  }
}

/**
 * Función auxiliar para pintar bloques de código (no mermaid)
 */
function printCodeBlock(pdf, codeString) {
  pdf.doc.setFont('Courier', 'normal');
  pdf.doc.setFontSize(10);

  const lineHeight = (10 / 72) * 1.2;
  const lines = codeString.split('\n');
  
  // Aumentamos el padding superior e inferior
  const topPadding = 0.25; // Aumentado significativamente
  const bottomPadding = 0.15;
  const blockHeight = (lines.length * lineHeight) + topPadding + bottomPadding;

  pdf.checkPageBreak(blockHeight + 0.2);

  const rectX = pdf.margins.left + 0.25;
  const rectY = pdf.currentY;
  const rectWidth = pdf.pageWidth - pdf.margins.left - pdf.margins.right - 0.5;

  // Fondo gris claro
  pdf.doc.setFillColor(245, 245, 245);
  pdf.doc.rect(rectX, rectY, rectWidth, blockHeight, 'F');

  // Comenzamos el texto más abajo dentro del rectángulo
  let textY = rectY + topPadding; // Comenzamos después del padding superior
  lines.forEach(line => {
    pdf.doc.text(line, rectX + 0.125, textY);
    textY += lineHeight;
  });

  pdf.currentY = rectY + blockHeight + 0.1;
  pdf.addSpacing(12/72);
}

/**
 * Función principal para exportar a PDF:
 * - Parsea el contenido Markdown (línea a línea) para no perder bloques de código.
 * - Añade diagramas mermaid, crea "VÉASE FIGURA" si aplica.
 * - Gestiona encabezados (hasta nivel 5), citas, párrafos, negritas, etc.
 */
const exportToPdf = async (content, metadata = {}, diagramImages = {}) => {
  // 1) Instanciar PDFExporter
  const pdf = new PDFExporter(
    metadata.title || 'Document',
    metadata.author || '',
    metadata.institution || '',
    metadata.date || new Date().toLocaleDateString()
  );

  // 2) Insertar diagramas mermaid como figuras (si existen)
  const mermaidToFigureMap = {};
  Object.entries(diagramImages).forEach(([_, image]) => {
    const figIndex = pdf.addImage(image.dataUrl, image.title, 'Own elaboration');
    if (image.originalCode) {
      const normalizedCode = image.originalCode.trim();
      mermaidToFigureMap[normalizedCode] = figIndex;
    }
  });

  // 3) Parsear contenido en bloques
  const blocks = parseMarkdownBlocks(content);

  // 4) Recorrer cada bloque y procesar
  blocks.forEach(block => {
    if (block.type === 'code') {
      const code = block.content;
      const language = (block.language || '').toLowerCase();

      // Si es mermaid => "VÉASE FIGURA" si existe la ancla
      if (language === 'mermaid') {
        const normalizedCode = code.trim();
        const figureIndex = mermaidToFigureMap[normalizedCode];
        // Pintamos el bloque de código en gris, como referencia, si quieres:
        printCodeBlock(pdf, code);

        if (figureIndex) {
          const anchor = pdf.figureAnchors[figureIndex];
          if (anchor) {
            const txt = `VÉASE FIGURA ${figureIndex}`;
            const linkX = pdf.pageWidth / 2;
            const linkY = pdf.currentY;

            pdf.setFont('caption');
            pdf.doc.textWithLink(txt, linkX, linkY, {
              align: 'center',
              pageNumber: anchor.page,
              top: anchor.yPos
            });
            pdf.currentY += (pdf.fonts.caption.size / 72) * 2;
          }
        }
      } else {
        // Bloque de código normal
        printCodeBlock(pdf, code);
      }

    } else if (block.type === 'paragraph') {
      const trimmed = block.content.trim();

      // Detectar si es quote
      if (trimmed.startsWith('>')) {
        // Cita
        const quote = trimmed.replace(/^>\s+/, '');
        pdf.addCitation(quote);
      }
      else {
        // Checar si es heading (hasta nivel 5)
        const headingMatch = trimmed.match(/^#{1,5}\s+(.+)$/);
        if (headingMatch) {
          const prefix = headingMatch[0].match(/^#{1,5}/)[0];
          const level = prefix.length;
          const titleText = headingMatch[0].replace(/^#{1,5}\s+/, '').trim();

          // Guardar ancla para TOC
          const currentPage = pdf.doc.internal.getCurrentPageInfo().pageNumber;
          const headingY = pdf.currentY;
          pdf.tocEntries.push({
            text: titleText,
            pageNumber: currentPage,
            level,
            yAnchor: headingY
          });

          // Pintar heading
          switch (level) {
            case 1:
              pdf.setFont('mainTitle');
              pdf.doc.text(titleText.toUpperCase(), pdf.pageWidth / 2, pdf.currentY, { align: 'center' });
              pdf.currentY += (pdf.fonts.mainTitle.size / 72) * 2;
              break;
            case 2:
              pdf.setFont('title');
              pdf.doc.text(titleText, pdf.pageWidth / 2, pdf.currentY, { align: 'center' });
              pdf.currentY += (pdf.fonts.title.size / 72) * 1.8;
              break;
            case 3:
              pdf.setFont('heading');
              pdf.doc.text(titleText, pdf.margins.left, pdf.currentY);
              pdf.currentY += (pdf.fonts.heading.size / 72) * 1.5;
              break;
            case 4:
              pdf.setFont('subheading');
              pdf.doc.text(titleText, pdf.margins.left + 0.25, pdf.currentY);
              pdf.currentY += (pdf.fonts.subheading.size / 72) * 1.5;
              break;
            case 5:
              // Usamos la misma fuente subheading o puedes definir otra
              pdf.setFont('subheading');
              pdf.doc.text(titleText, pdf.margins.left + 0.5, pdf.currentY);
              pdf.currentY += (pdf.fonts.subheading.size / 72) * 1.5;
              break;
          }
          pdf.addSpacing(12/72);
        } 
        else {
          // Párrafo normal (con negritas inline **)
          pdf.addParagraph(trimmed);
        }
      }
    }
  });

  // 5) Guardar
  pdf.save(metadata.filename || 'document.pdf');
};

export default {
  PDFExporter,
  exportToPdf
};
