import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  TextField, 
  Chip, 
  LinearProgress,
  Tab,
  Tabs,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  BookOpen, 
  FileText, 
  Search,
  Download,
  Link as LinkIcon,
  Clock,
  AlertCircle,
  CheckCircle,
  Book,
  Bookmark,
  Filter,
  SortAsc,
  Edit3,
  Share2,
  Users,
  PlusCircle,
  UserPlus 
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const SearchBar = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(2),
  },
}));

const ProjectCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
}));

const ReferenceCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
}));

const AGTAcademic = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [citationStyle, setCitationStyle] = useState('APA');
  const [searchQuery, setSearchQuery] = useState('');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Research Project Data
  const projects = [
    {
      title: "Machine Learning Applications",
      progress: 75,
      deadline: "2 days",
      status: "In Progress",
      citations: 12,
      collaborators: 3
    },
    {
      title: "Quantum Computing Research",
      progress: 45,
      deadline: "1 week",
      status: "Planning",
      citations: 8,
      collaborators: 2
    }
  ];

  // Citation Styles
  const citationStyles = ['APA', 'MLA', 'Chicago', 'Harvard', 'IEEE'];

  const renderResearchTools = () => (
    <Grid container spacing={3}>
      {/* Search and Filters */}
      <Grid item xs={12}>
        <SearchBar
          fullWidth
          variant="outlined"
          placeholder="Search academic databases..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <Search size={20} className="mr-2 text-gray-400" />,
            endAdornment: (
              <Box className="flex gap-2">
                <IconButton>
                  <Filter size={20} />
                </IconButton>
                <IconButton>
                  <SortAsc size={20} />
                </IconButton>
              </Box>
            ),
          }}
        />
      </Grid>

      {/* Recent Research */}
      <Grid item xs={12} md={8}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Recent Research</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReferenceCard>
                <Box className="flex justify-between items-start">
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Advances in Quantum Computing
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Journal of Quantum Physics, 2024
                    </Typography>
                  </Box>
                  <Box className="flex gap-1">
                    <IconButton size="small">
                      <Bookmark />
                    </IconButton>
                    <IconButton size="small">
                      <Download />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="flex gap-2 mt-2">
                  <Chip size="small" label="Quantum" />
                  <Chip size="small" label="Computing" />
                  <Chip size="small" label="Research" />
                </Box>
              </ReferenceCard>
              <ReferenceCard>
                <Box className="flex justify-between items-start">
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Machine Learning in Healthcare
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Medical AI Review, 2024
                    </Typography>
                  </Box>
                  <Box className="flex gap-1">
                    <IconButton size="small">
                      <Bookmark />
                    </IconButton>
                    <IconButton size="small">
                      <Download />
                    </IconButton>
                  </Box>
                </Box>
                <Box className="flex gap-2 mt-2">
                  <Chip size="small" label="AI" />
                  <Chip size="small" label="Healthcare" />
                  <Chip size="small" label="ML" />
                </Box>
              </ReferenceCard>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>

      {/* Quick Tools */}
      <Grid item xs={12} md={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>Quick Tools</Typography>
              <Box className="space-y-2">
                <ActionButton className="w-full">
                  <Search size={16} />
                  Search Databases
                </ActionButton>
                <ActionButton className="w-full">
                  <FileText size={16} />
                  Generate Citation
                </ActionButton>
                <ActionButton className="w-full">
                  <Book size={16} />
                  Find References
                </ActionButton>
              </Box>
            </StyledPaper>
          </Grid>
          
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>Citation Style</Typography>
              <Box className="flex flex-wrap gap-2">
                {citationStyles.map((style) => (
                  <Chip
                    key={style}
                    label={style}
                    onClick={() => setCitationStyle(style)}
                    color={citationStyle === style ? 'primary' : 'default'}
                  />
                ))}
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderProjectManagement = () => (
    <Grid container spacing={3}>
      {/* Active Projects */}
      <Grid item xs={12} md={8}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>Active Projects</Typography>
          {projects.map((project, index) => (
            <ProjectCard key={index}>
              <Box className="flex justify-between items-start mb-3">
                <Box>
                  <Typography variant="subtitle1">{project.title}</Typography>
                  <Box className="flex items-center gap-2 mt-1">
                    <Clock size={14} className="text-gray-400" />
                    <Typography variant="caption" color="text.secondary">
                      Deadline: {project.deadline}
                    </Typography>
                  </Box>
                </Box>
                <Box className="flex gap-1">
                  <IconButton size="small">
                    <Edit3 />
                  </IconButton>
                  <IconButton size="small">
                    <Share2 />
                  </IconButton>
                </Box>
              </Box>
              
              <Box className="space-y-2">
                <Box className="flex justify-between items-center">
                  <Typography variant="body2" color="text.secondary">
                    Progress
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {project.progress}%
                  </Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={project.progress} 
                  sx={{ height: 6, borderRadius: 3 }}
                />
                
                <Box className="flex gap-2 mt-2">
                  <Chip 
                    size="small" 
                    label={`${project.citations} Citations`}
                    icon={<FileText size={14} />}
                  />
                  <Chip 
                    size="small" 
                    label={`${project.collaborators} Collaborators`}
                    icon={<Users size={14} />}
                  />
                  <Chip 
                    size="small" 
                    label={project.status}
                    color={project.status === "In Progress" ? "primary" : "default"}
                  />
                </Box>
              </Box>
            </ProjectCard>
          ))}
        </StyledPaper>
      </Grid>

      {/* Project Stats and Actions */}
      <Grid item xs={12} md={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>Project Stats</Typography>
              <Box className="space-y-4">
                <Box className="p-3 bg-blue-50 rounded-lg">
                  <Typography variant="subtitle2">Total Projects</Typography>
                  <Typography variant="h4">8</Typography>
                </Box>
                <Box className="p-3 bg-green-50 rounded-lg">
                  <Typography variant="subtitle2">Completed</Typography>
                  <Typography variant="h4">5</Typography>
                </Box>
                <Box className="p-3 bg-yellow-50 rounded-lg">
                  <Typography variant="subtitle2">In Progress</Typography>
                  <Typography variant="h4">3</Typography>
                </Box>
              </Box>
            </StyledPaper>
          </Grid>

          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>Quick Actions</Typography>
              <Box className="space-y-2">
                <ActionButton className="w-full">
                  <PlusCircle size={16} />
                  New Project
                </ActionButton>
                <ActionButton className="w-full">
                  <UserPlus size={16} />
                  Add Collaborator
                </ActionButton>
                <ActionButton className="w-full">
                  <FileText size={16} />
                  Generate Report
                </ActionButton>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box className="space-y-6">
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        className="mb-6"
      >
        <Tab label="Research Tools" />
        <Tab label="Project Management" />
      </Tabs>

      {activeTab === 0 && renderResearchTools()}
      {activeTab === 1 && renderProjectManagement()}
    </Box>
  );
};

export default AGTAcademic;