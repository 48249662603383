// ThinkingAudioPlayer.js
import React, { useEffect, useRef, useState, useCallback } from 'react';

const THINKING_AUDIO_MAP = {
  alloy: '/audio/alloy_thinking.mp3',
  echo: '/audio/echo_thinking.mp3',
  fable: '/audio/fable_thinking.mp3',
  onyx: '/audio/onyx_thinking.mp3',
  nova: '/audio/nova_thinking.mp3',
  shimmer: '/audio/shimmer_thinking.mp3'
};

const ThinkingAudioPlayer = ({ 
  isThinking, 
  selectedVoice, 
  onPlayingChange,
  onError 
}) => {
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);
  const bufferRef = useRef(null);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const isPlayingRef = useRef(false);
  const fadeTimeoutRef = useRef(null);

  const getAudioContext = useCallback(() => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 44100,
        latencyHint: 'interactive'
      });
      gainNodeRef.current = audioContextRef.current.createGain();
      gainNodeRef.current.connect(audioContextRef.current.destination);
      gainNodeRef.current.gain.value = 0;
    }
    return audioContextRef.current;
  }, []);

  const loadAudio = useCallback(async () => {
    try {
      const ctx = getAudioContext();
      const audioUrl = THINKING_AUDIO_MAP[selectedVoice];

      const response = await fetch(audioUrl);
      const arrayBuffer = await response.arrayBuffer();
      bufferRef.current = await ctx.decodeAudioData(arrayBuffer);
      setAudioLoaded(true);
    } catch (error) {
      console.error('Error loading audio:', error);
      onError?.('Error loading thinking audio');
      setAudioLoaded(false);
    }
  }, [selectedVoice, getAudioContext, onError]);

  const cleanupPlayback = useCallback(() => {
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
      fadeTimeoutRef.current = null;
    }

    if (sourceNodeRef.current) {
      try {
        sourceNodeRef.current.stop();
        sourceNodeRef.current.disconnect();
        sourceNodeRef.current = null;
      } catch (e) {
        console.warn('Cleanup error:', e);
      }
    }
    
    isPlayingRef.current = false;
    onPlayingChange?.(false);
  }, [onPlayingChange]);

  const startPlayback = useCallback(() => {
    if (!audioLoaded || !bufferRef.current || isPlayingRef.current) return;

    try {
      const ctx = getAudioContext();
      
      if (ctx.state === 'suspended') {
        ctx.resume();
      }

      cleanupPlayback();

      sourceNodeRef.current = ctx.createBufferSource();
      sourceNodeRef.current.buffer = bufferRef.current;
      sourceNodeRef.current.loop = true;
      sourceNodeRef.current.connect(gainNodeRef.current);

      const now = ctx.currentTime;
      gainNodeRef.current.gain.cancelScheduledValues(now);
      gainNodeRef.current.gain.setValueAtTime(0, now);
      gainNodeRef.current.gain.linearRampToValueAtTime(0.7, now + 0.5);

      sourceNodeRef.current.start(0);
      isPlayingRef.current = true;
      onPlayingChange?.(true);
    } catch (error) {
      console.error('Error starting playback:', error);
      cleanupPlayback();
    }
  }, [audioLoaded, getAudioContext, onPlayingChange, cleanupPlayback]);

  const stopPlayback = useCallback(() => {
    if (!sourceNodeRef.current || !isPlayingRef.current) return;

    try {
      const ctx = getAudioContext();
      const now = ctx.currentTime;
      
      gainNodeRef.current.gain.cancelScheduledValues(now);
      gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, now);
      gainNodeRef.current.gain.linearRampToValueAtTime(0, now + 0.3);

      fadeTimeoutRef.current = setTimeout(() => {
        cleanupPlayback();
      }, 300);
    } catch (error) {
      console.error('Error stopping playback:', error);
      cleanupPlayback();
    }
  }, [getAudioContext, cleanupPlayback]);

  useEffect(() => {
    loadAudio();
  }, [selectedVoice, loadAudio]);

  useEffect(() => {
    if (isThinking && audioLoaded) {
      startPlayback();
    } else {
      stopPlayback();
    }
  }, [isThinking, audioLoaded, startPlayback, stopPlayback]);

  useEffect(() => {
    return () => {
      cleanupPlayback();
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [cleanupPlayback]);

  return null;
};

export default ThinkingAudioPlayer;
