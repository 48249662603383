import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  IconButton, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress,
  Chip,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Upload,
  Camera,
  File,
  Image,
  FileText,
  CheckCircle,
  AlertCircle,
  X,
  Edit2,
  Plus,
  Calendar,
  DollarSign,
  Tag,
  Clock,
  Search,
  Download,
  Smartphone,
  MessageSquare,
  Send
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const UploadZone = styled(Box)(({ theme, isDragging }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(2),
  border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isDragging ? theme.palette.action.hover : 'transparent',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: theme.palette.primary.main,
  },
}));

const PreviewCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  gap: theme.spacing(1),
}));

const QuickUpload = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [openMobileDialog, setOpenMobileDialog] = useState(false);
  const [showProcessingStatus, setShowProcessingStatus] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);

  const recentUploads = [
    {
      id: 1,
      fileName: 'office_supplies.jpg',
      fileType: 'image',
      uploadDate: '2024-02-20',
      status: 'processed',
      amount: 249.99,
      category: 'Office Supplies',
      vendor: 'Office Depot',
      processingDetails: {
        confidence: 95,
        extractedData: {
          date: '2024-02-19',
          items: [
            { name: 'Printer Paper', amount: 89.99 },
            { name: 'Ink Cartridges', amount: 159.99 }
          ]
        }
      }
    },
    {
      id: 2,
      fileName: 'software_license.pdf',
      fileType: 'pdf',
      uploadDate: '2024-02-19',
      status: 'processing',
      amount: null,
      category: 'Software',
      vendor: 'Adobe',
      processingDetails: {
        progress: 60
      }
    }
  ];

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const newFiles = files.map(file => ({
      id: Date.now() + Math.random(),
      file,
      preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
      status: 'uploading',
      progress: 0
    }));

    setSelectedFiles(prev => [...prev, ...newFiles]);
    simulateUpload(newFiles);
  };

  const simulateUpload = (files) => {
    files.forEach(file => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(prev => ({
          ...prev,
          [file.id]: progress
        }));

        if (progress >= 100) {
          clearInterval(interval);
          setUploadProgress(prev => ({
            ...prev,
            [file.id]: 'processing'
          }));

          setTimeout(() => {
            setUploadProgress(prev => ({
              ...prev,
              [file.id]: 'complete'
            }));
            setShowProcessingStatus(true);
          }, 2000);
        }
      }, 500);
    });
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      setIsCameraActive(true);
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const stopCamera = () => {
    if (videoRef.current?.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    setIsCameraActive(false);
  };

  const captureImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
    
    canvas.toBlob(blob => {
      const file = new File([blob], `receipt_${Date.now()}.jpg`, { type: 'image/jpeg' });
      handleFiles([file]);
    }, 'image/jpeg');
    
    stopCamera();
  };

  return (
    <Box className="space-y-6">
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Quick Upload</Typography>
            <UploadZone
              isDragging={isDragging}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current?.click()}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                accept="image/*,.pdf"
                onChange={(e) => handleFiles(Array.from(e.target.files))}
              />
              <Upload size={48} className="mx-auto mb-4 text-gray-400" />
              <Typography variant="h6" gutterBottom>
                Drop files here or click to upload
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Support for images and PDF files
              </Typography>
            </UploadZone>

            <Box className="flex justify-center gap-4 mt-4">
              <ActionButton
                variant="outlined"
                onClick={startCamera}
                startIcon={<Camera />}
              >
                Scan Receipt
              </ActionButton>
              <ActionButton
                variant="outlined"
                onClick={() => setOpenMobileDialog(true)}
                startIcon={<Smartphone />}
              >
                Mobile Upload
              </ActionButton>
            </Box>
          </StyledPaper>

          {selectedFiles.length > 0 && (
            <StyledPaper className="mt-4">
              <Typography variant="h6" gutterBottom>
                Selected Files
              </Typography>
              <Grid container spacing={2}>
                {selectedFiles.map(file => (
                  <Grid item xs={12} key={file.id}>
                    <PreviewCard>
                      <Box className="flex items-start gap-3">
                        {file.preview ? (
                          <img 
                            src={file.preview}
                            alt="preview"
                            className="w-20 h-20 object-cover rounded"
                          />
                        ) : (
                          <FileText size={48} className="text-gray-400" />
                        )}
                        <Box className="flex-1">
                          <Typography variant="subtitle2" noWrap>
                            {file.file.name}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            color="text.secondary"
                            display="block"
                          >
                            {(file.file.size / 1024 / 1024).toFixed(2)} MB
                          </Typography>
                          {uploadProgress[file.id] === 'complete' ? (
                            <Chip
                              size="small"
                              icon={<CheckCircle size={14} />}
                              label="Processed"
                              color="success"
                            />
                          ) : uploadProgress[file.id] === 'processing' ? (
                            <Box className="flex items-center gap-2">
                              <CircularProgress size={14} />
                              <Typography variant="caption" color="text.secondary">
                                Processing...
                              </Typography>
                            </Box>
                          ) : (
                            <Box className="mt-2">
                              <LinearProgress 
                                variant="determinate" 
                                value={uploadProgress[file.id] || 0} 
                              />
                            </Box>
                          )}
                        </Box>
                        <IconButton 
                          size="small"
                          onClick={() => {
                            setSelectedFiles(prev => 
                              prev.filter(f => f.id !== file.id)
                            );
                            if (file.preview) {
                              URL.revokeObjectURL(file.preview);
                            }
                          }}
                        >
                          <X size={16} />
                        </IconButton>
                      </Box>
                    </PreviewCard>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Recent Uploads</Typography>
            {recentUploads.map(upload => (
              <PreviewCard key={upload.id}>
                <Box className="flex justify-between items-start">
                  <Box className="flex items-start gap-3">
                    {upload.fileType === 'image' ? (
                      <Image size={24} className="text-blue-500" />
                    ) : (
                      <File size={24} className="text-purple-500" />
                    )}
                    <Box>
                      <Typography variant="subtitle2">{upload.fileName}</Typography>
                      <Typography variant="caption" color="text.secondary" display="block">
                        {upload.uploadDate}
                      </Typography>
                      {upload.status === 'processed' && (
                        <Box className="flex items-center gap-2 mt-1">
                          <DollarSign size={14} />
                          <Typography variant="subtitle2" color="success.main">
                            ${upload.amount}
                          </Typography>
                          <Chip
                            size="small"
                            label={upload.category}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box className="flex gap-2">
                    <IconButton 
                      size="small"
                      onClick={() => {
                        setSelectedUpload(upload);
                        setOpenPreview(true);
                      }}
                    >
                      <Search size={16} />
                    </IconButton>
                    <IconButton size="small">
                      <Download size={16} />
                    </IconButton>
                  </Box>
                </Box>
              </PreviewCard>
            ))}
          </StyledPaper>
        </Grid>
      </Grid>

      <Dialog
        open={isCameraActive}
        onClose={stopCamera}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Scan Receipt</DialogTitle>
        <DialogContent>
          <Box className="relative">
            <video
              ref={videoRef}
              autoPlay
              className="w-full rounded-lg"
            />
            <Box className="absolute inset-0 border-2 border-dashed border-primary-500 rounded-lg" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={stopCamera}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={captureImage}
            startIcon={<Camera />}
          >
            Capture
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedUpload && (
          <>
            <DialogTitle>
              <Box className="flex justify-between items-center">
                <Typography variant="h6">{selectedUpload.fileName}</Typography>
                <IconButton onClick={() => setOpenPreview(false)}>
                  <X size={20} />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box className="space-y-6">
                <Box className="aspect-[4/3] bg-gray-100 rounded-lg flex items-center justify-center">
                  {selectedUpload.fileType === 'image' ? (
                    <Image size={48} className="text-gray-400" />
                  ) : (
                    <FileText size={48} className="text-gray-400" />
                  )}
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Date"
                      value={selectedUpload.processingDetails?.extractedData?.date || ''}
                      InputProps={{
                        startAdornment: <Calendar size={16} className="mr-2" />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Amount"
                      value={selectedUpload.amount || ''}
                      InputProps={{
                        startAdornment: <DollarSign size={16} className="mr-2" />
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Vendor"
                      value={selectedUpload.vendor || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Category"
                      value={selectedUpload.category || ''}
                      InputProps={{
                        startAdornment: <Tag size={16} className="mr-2" />
                      }}
                    />
                  </Grid>
                </Grid>

                {selectedUpload.processingDetails?.extractedData?.items && (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      Extracted Items
                    </Typography>
                    {selectedUpload.processingDetails.extractedData.items.map((item, index) => (
                      <Box 
                        key={index}
                        className="flex justify-between items-center p-2 bg-gray-50 rounded mb-2"
                      >
                        <Typography variant="body2">{item.name}</Typography>
                        <Typography variant="body2">${item.amount}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}

                {selectedUpload.processingDetails?.confidence && (
                  <Box className="p-4 bg-blue-50 rounded-lg">
                    <Box className="flex justify-between items-center mb-2">
                      <Typography variant="subtitle2">Processing Accuracy</Typography>
                      <Typography variant="subtitle2" color="primary">
                        {selectedUpload.processingDetails.confidence}%
                      </Typography>
                    </Box>
                    <LinearProgress 
                      variant="determinate"
                      value={selectedUpload.processingDetails.confidence}
                      color="primary"
                    />
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" startIcon={<Edit2 />}>
                Edit Details
              </Button>
              <Button variant="contained" startIcon={<Send />}>
                Process Receipt
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={openMobileDialog}
        onClose={() => setOpenMobileDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Mobile Upload</DialogTitle>
        <DialogContent>
          <Box className="space-y-4 text-center">
            <Smartphone size={48} className="mx-auto text-gray-400" />
            <Typography variant="subtitle1">
              Scan QR Code to Upload
            </Typography>
            <Box className="p-4 bg-gray-100 rounded-lg">
              <Box className="w-48 h-48 mx-auto bg-white" />
            </Box>
            <Typography variant="body2" color="text.secondary">
              Open your mobile camera and scan the QR code to upload receipts directly from your phone
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMobileDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Tips</DialogTitle>
        <DialogContent>
          <Box className="space-y-4">
            <Box className="p-4 bg-blue-50 rounded-lg">
              <Typography variant="subtitle2" gutterBottom>
                For Best Results:
              </Typography>
              <Box className="space-y-2">
                <Typography variant="body2">
                  • Ensure good lighting when scanning
                </Typography>
                <Typography variant="body2">
                  • Keep the receipt flat and centered
                </Typography>
                <Typography variant="body2">
                  • Include all receipt edges in the frame
                </Typography>
                <Typography variant="body2">
                  • Avoid shadows and glare
                </Typography>
              </Box>
            </Box>

            <Box className="p-4 bg-green-50 rounded-lg">
              <Typography variant="subtitle2" gutterBottom>
                Supported Formats:
              </Typography>
              <Typography variant="body2">
                • Images: JPG, PNG, HEIC
              </Typography>
              <Typography variant="body2">
                • Documents: PDF
              </Typography>
            </Box>

            <Box className="p-4 bg-purple-50 rounded-lg">
              <Typography variant="subtitle2" gutterBottom>
                Auto-Detection Features:
              </Typography>
              <Typography variant="body2">
                • Vendor name and details
              </Typography>
              <Typography variant="body2">
                • Transaction date and amount
              </Typography>
              <Typography variant="body2">
                • Item-level details and categories
              </Typography>
              <Typography variant="body2">
                • Tax and discount information
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHelpDialog(false)}>
            Got It
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showProcessingStatus}
        autoHideDuration={6000}
        onClose={() => setShowProcessingStatus(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowProcessingStatus(false)} 
          severity="success"
          elevation={6}
          variant="filled"
        >
          Receipt processed successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuickUpload;