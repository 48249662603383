import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  IconButton, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  LinearProgress,
  FormControlLabel,
  Switch,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  PieChart, 
  BarChart2,
  TrendingUp,
  TrendingDown,
  Plus,
  Edit2,
  Trash2,
  DollarSign,
  Download,
  Settings,
  Filter,
  AlertCircle,
  Tag,
  Briefcase,
  Coffee,
  ShoppingBag,
  Home,
  Car,
  Smartphone,
  Zap,
  Globe,
  Book
} from 'lucide-react';
import { 
  PieChart as RechartsPieChart, 
  Pie, 
  Cell, 
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend
} from 'recharts';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const CategoryCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  gap: theme.spacing(1),
}));

const categories = [
    { 
      id: 'office',
      name: 'Office Supplies',
      budget: 5000,
      spent: 4200,
      trend: 'up',
      trendValue: 15,
      icon: Briefcase,
      color: '#4f46e5',
      transactions: 45
    },
    { 
      id: 'software',
      name: 'Software & Tools',
      budget: 3000,
      spent: 2800,
      trend: 'down',
      trendValue: 5,
      icon: Smartphone,
      color: '#0891b2',
      transactions: 12
    },
    {
      id: 'utilities',
      name: 'Utilities',
      budget: 2000,
      spent: 1800,
      trend: 'up',
      trendValue: 8,
      icon: Zap,
      color: '#059669',
      transactions: 6
    },
    {
      id: 'travel',
      name: 'Travel & Transport',
      budget: 4000,
      spent: 3500,
      trend: 'down',
      trendValue: 12,
      icon: Globe,
      color: '#7c3aed',
      transactions: 15
    }
  ];
  
  const monthlyData = [
    { month: 'Jan', Office: 3200, Software: 2400, Utilities: 1800, Travel: 3000 },
    { month: 'Feb', Office: 3800, Software: 2600, Utilities: 1600, Travel: 3200 },
    { month: 'Mar', Office: 4200, Software: 2800, Utilities: 1800, Travel: 3500 },
    { month: 'Apr', Office: 3900, Software: 2700, Utilities: 1700, Travel: 3300 },
    { month: 'May', Office: 4100, Software: 2900, Utilities: 1900, Travel: 3600 },
    { month: 'Jun', Office: 4200, Software: 2800, Utilities: 1800, Travel: 3500 }
  ];
  
  const ExpenseCategories = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [timeRange, setTimeRange] = useState('6m');
    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
    const [openAlertsDialog, setOpenAlertsDialog] = useState(false);
  
    const handleOpenDialog = (category = null) => {
      setSelectedCategory(category);
      setOpenDialog(true);
    };
  
    const getTotalBudget = () => {
      return categories.reduce((total, cat) => total + cat.budget, 0);
    };
  
    const getTotalSpent = () => {
      return categories.reduce((total, cat) => total + cat.spent, 0);
    };
  
    return (
      <Box className="space-y-6">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <StyledPaper>
              <Box className="flex items-center gap-2 mb-2">
                <DollarSign size={20} className="text-blue-500" />
                <Typography variant="h6">Total Budget</Typography>
              </Box>
              <Typography variant="h4">
                ${getTotalBudget().toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Across {categories.length} categories
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledPaper>
              <Box className="flex items-center gap-2 mb-2">
                <BarChart2 size={20} className="text-green-500" />
                <Typography variant="h6">Total Spent</Typography>
              </Box>
              <Typography variant="h4">
                ${getTotalSpent().toLocaleString()}
              </Typography>
              <Box className="flex items-center gap-1">
                <TrendingUp size={16} className="text-green-500" />
                <Typography variant="body2" color="success.main">
                  12% under budget
                </Typography>
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledPaper>
              <Box className="flex items-center gap-2 mb-2">
                <AlertCircle size={20} className="text-yellow-500" />
                <Typography variant="h6">Alerts</Typography>
              </Box>
              <Typography variant="h4">2</Typography>
              <Typography variant="body2" color="warning.main">
                Categories near budget limit
              </Typography>
            </StyledPaper>
          </Grid>
        </Grid>
  
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <StyledPaper>
              <Box className="flex justify-between items-center mb-4">
                <Typography variant="h6">Expense Categories</Typography>
                <Box className="flex gap-2">
                  <ActionButton
                    variant="outlined"
                    onClick={() => setOpenSettingsDialog(true)}
                    startIcon={<Settings size={20} />}
                  >
                    Settings
                  </ActionButton>
                  <ActionButton
                    variant="contained"
                    onClick={() => handleOpenDialog()}
                    startIcon={<Plus size={20} />}
                  >
                    Add Category
                  </ActionButton>
                </Box>
              </Box>
  
              {categories.map(category => (
                <CategoryCard key={category.id}>
                  <Box className="flex justify-between items-start">
                    <Box className="flex items-start gap-3">
                      <Box
                        sx={{
                          backgroundColor: category.color,
                          p: 1,
                          borderRadius: 1,
                          color: 'white',
                        }}
                      >
                        <category.icon size={24} />
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">{category.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {category.transactions} transactions this month
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="text-right">
                      <Typography variant="h6">
                        ${category.spent.toLocaleString()}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        of ${category.budget.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
  
                  <Box className="mt-3">
                    <Box className="flex justify-between mb-1">
                      <Typography variant="body2" color="text.secondary">
                        Budget Usage
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {Math.round((category.spent / category.budget) * 100)}%
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={(category.spent / category.budget) * 100}
                      color={
                        (category.spent / category.budget) > 0.9 
                          ? 'error' 
                          : (category.spent / category.budget) > 0.7 
                            ? 'warning' 
                            : 'primary'
                      }
                    />
                  </Box>
  
                  <Box className="flex justify-between items-center mt-3">
                    <Box className="flex items-center gap-2">
                      {category.trend === 'up' ? (
                        <TrendingUp size={16} className="text-red-500" />
                      ) : (
                        <TrendingDown size={16} className="text-green-500" />
                      )}
                      <Typography 
                        variant="body2"
                        color={category.trend === 'up' ? 'error' : 'success'}
                      >
                        {category.trendValue}% vs last month
                      </Typography>
                    </Box>
                    <Box className="flex gap-2">
                      <IconButton 
                        size="small"
                        onClick={() => handleOpenDialog(category)}
                      >
                        <Edit2 size={16} />
                      </IconButton>
                      <IconButton 
                        size="small"
                        color="error"
                        onClick={() => {
                          setSelectedCategory(category);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <Trash2 size={16} />
                      </IconButton>
                    </Box>
                  </Box>
                </CategoryCard>
              ))}
            </StyledPaper>
          </Grid>
  
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>Budget Distribution</Typography>
                  <Box className="h-64">
                    <ResponsiveContainer width="100%" height="100%">
                      <RechartsPieChart>
                        <Pie
                          data={categories}
                          dataKey="budget"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          label
                        >
                          {categories.map((category, index) => (
                            <Cell key={`cell-${index}`} fill={category.color} />
                          ))}
                        </Pie>
                        <RechartsTooltip />
                      </RechartsPieChart>
                    </ResponsiveContainer>
                  </Box>
                </StyledPaper>
              </Grid>
  
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>Monthly Trends</Typography>
                  <Box className="h-64">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={monthlyData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        {categories.map(category => (
                          <Bar
                            key={category.id}
                            dataKey={category.name.split(' ')[0]}
                            fill={category.color}
                          />
                        ))}
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
  
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            {selectedCategory ? 'Edit Category' : 'Add New Category'}
          </DialogTitle>
          <DialogContent>
            <Box className="space-y-4 mt-4">
              <TextField
                fullWidth
                label="Category Name"
                defaultValue={selectedCategory?.name}
              />
              <TextField
                fullWidth
                label="Budget Amount"
                type="number"
                defaultValue={selectedCategory?.budget}
                InputProps={{
                  startAdornment: <DollarSign size={16} className="mr-2" />
                }}
              />
              <FormControl fullWidth>
                <InputLabel>Icon</InputLabel>
                <Select
                  defaultValue={selectedCategory?.icon || 'briefcase'}
                  label="Icon"
                >
                  <MenuItem value="briefcase">
                    <Box className="flex items-center gap-2">
                      <Briefcase size={16} />
                      Briefcase
                    </Box>
                  </MenuItem>
                  <MenuItem value="smartphone">
                    <Box className="flex items-center gap-2">
                      <Smartphone size={16} />
                      Smartphone
                    </Box>
                  </MenuItem>
                  <MenuItem value="zap">
                    <Box className="flex items-center gap-2">
                      <Zap size={16} />
                      Zap
                    </Box>
                  </MenuItem>
                  <MenuItem value="globe">
                    <Box className="flex items-center gap-2">
                      <Globe size={16} />
                      Globe
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Color"
                type="color"
                defaultValue={selectedCategory?.color || '#4f46e5'}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button 
              variant="contained"
              onClick={() => setOpenDialog(false)}
            >
              {selectedCategory ? 'Save Changes' : 'Add Category'}
            </Button>
          </DialogActions>
        </Dialog>
  
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete the category "{selectedCategory?.name}"? 
              This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
            <Button 
              color="error"
              variant="contained"
              onClick={() => setOpenDeleteDialog(false)}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
  
        <Dialog
          open={openSettingsDialog}
          onClose={() => setOpenSettingsDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Category Settings</DialogTitle>
          <DialogContent>
            <Box className="space-y-4 mt-4">
              <FormControl fullWidth>
                <InputLabel>Budget Rollover</InputLabel>
                <Select
                  defaultValue="none"
                  label="Budget Rollover"
                >
                  <MenuItem value="none">No rollover</MenuItem>
                  <MenuItem value="monthly">Monthly rollover</MenuItem>
                  <MenuItem value="quarterly">Quarterly rollover</MenuItem>
                </Select>
              </FormControl>
  
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Enable budget alerts"
              />
  
              <FormControl fullWidth>
                <InputLabel>Alert Threshold</InputLabel>
                <Select
                  defaultValue="80"
                  label="Alert Threshold"
                >
                  <MenuItem value="70">70% of budget</MenuItem>
                  <MenuItem value="80">80% of budget</MenuItem>
                  <MenuItem value="90">90% of budget</MenuItem>
                </Select>
              </FormControl>
  
              <FormControl fullWidth>
                <InputLabel>Default View</InputLabel>
                <Select
                  defaultValue="monthly"
                  label="Default View"
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="quarterly">Quarterly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSettingsDialog(false)}>Cancel</Button>
            <Button 
              variant="contained"
              onClick={() => setOpenSettingsDialog(false)}
            >
              Save Settings
            </Button>
          </DialogActions>
        </Dialog>
  
        <Dialog
          open={openAlertsDialog}
          onClose={() => setOpenAlertsDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Category Alerts</DialogTitle>
          <DialogContent>
          <Box className="space-y-4 mt-4">
            {categories.map(category => (
              <Box key={category.id} className="p-4 border rounded-lg">
                <Box className="flex justify-between items-center mb-2">
                  <Box className="flex items-center gap-2">
                    <Box
                      sx={{
                        backgroundColor: category.color,
                        p: 1,
                        borderRadius: 1,
                        color: 'white',
                      }}
                    >
                      <category.icon size={20} />
                    </Box>
                    <Typography variant="subtitle1">{category.name}</Typography>
                  </Box>
                  <Switch defaultChecked />
                </Box>
                <FormControl fullWidth size="small">
                  <Select
                    defaultValue="80"
                    variant="outlined"
                  >
                    <MenuItem value="70">Alert at 70%</MenuItem>
                    <MenuItem value="80">Alert at 80%</MenuItem>
                    <MenuItem value="90">Alert at 90%</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertsDialog(false)}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={() => setOpenAlertsDialog(false)}
          >
            Save Alert Settings
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExpenseCategories;