import axios from 'axios';

const AGTFoundService = {
  async search(query) {
    try {
      const response = await axios.post('/api/agtups/found/search', { query });
      
      if (response.data.success === false) {
        throw new Error(response.data.error || 'Error en la búsqueda');
      }

      return response.data;

    } catch (error) {
      console.error('Error en AGTFoundService:', error);
      throw error;
    }
  }
};

export default AGTFoundService;