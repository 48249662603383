import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button,
  Snackbar,
  Tabs,
  Tab,
  Alert,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  AlertCircle,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

import MarketAnalysis from './AGTProduct-products/MarketAnalysis';
import PricingStrategy from './AGTProduct-products/PricingStrategy';
import SalesStrategy from './AGTProduct-products/SalesStrategy';
import ProductsManager from './AGTProduct-products/ProductsManager';

// 1. IMPORTAR EL SERVICIO
import ProductService from '../services/AGTUps-product-services/productService';

const ActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 500,
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(40, 40, 42, 0.94)'
    : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(118, 118, 128, 0.24)'
    : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow: theme.palette.mode === 'dark'
    ? 'none'
    : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)'
    : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  }
}));

const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  }
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background: theme.palette.mode === 'dark' 
      ? '#1C1C1E'
      : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden',
  }
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
  backgroundColor: 'transparent'
}));

const AGTProduct = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeTab, setActiveTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  // 2. ESTADO PARA LOADING
  const [loading, setLoading] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);

  const navigationTabs = [
    { id: 0, label: 'Products' },
    { id: 1, label: 'Market' },
    { id: 2, label: 'Pricing' },
    { id: 3, label: 'Sales' }
  ];

  useLayoutEffect(() => {
    // Mantener el scroll al top al cambiar de tab
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  const showNotification = useCallback((message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setShowSnackbar(true);
  }, []);

  // 3. EFECTO PARA CARGAR PRODUCTOS AL MONTAR
  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await ProductService.getAllProducts();
        if (response?.data?.products) {
          setProducts(response.data.products);
        }
      } catch (error) {
        setError('Error loading products: ' + error.message);
        showNotification('Error loading products', 'error');
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, [showNotification]);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView !== isMobile) {
        setVisibleRange({ start: 0, end: 3 });
      }
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleRange({ start: 0, end: navigationTabs.length });
      return;
    }

    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return;

    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
    } else if (activeIdx >= navigationTabs.length - 3) {
      setVisibleRange({ 
        start: navigationTabs.length - 3, 
        end: navigationTabs.length 
      });
    } else {
      setVisibleRange({
        start: activeIdx - 1,
        end: activeIdx + 2
      });
    }
  }, [activeTab, navigationTabs.length, isMobile]);

  const getIndicatorStyle = useCallback(() => {
    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return {};
    
    const visibleIdx = activeIdx - visibleRange.start;
    const offset = visibleIdx * (74 + 4); // ancho tab + gap
    return {
      transform: `translateX(${offset}px)`,
    };
  }, [activeTab, visibleRange.start, navigationTabs]);

  const handleTabChange = useCallback((event, newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      lastNavigationTime.current = now;
      setActiveTab(newValue);
      setError(null);
    }
  }, []);

  // 4. CREAR, ACTUALIZAR Y BORRAR PRODUCTOS USANDO EL SERVICIO

  const handleProductCreate = useCallback(async (newProduct) => {
    try {
      // Usamos el servicio
      const response = await ProductService.createProduct(newProduct);
      if (response?.data) {
        setProducts(prevProducts => [...prevProducts, response.data]);
        showNotification(`Product "${response.data.name}" created successfully`);
      }
    } catch (error) {
      setError(error.message);
      showNotification(error.message, 'error');
    }
  }, [showNotification]);

  const handleProductUpdate = useCallback(async (updatedProduct) => {
    try {
      const response = await ProductService.updateProduct(updatedProduct.id, updatedProduct);
      if (response?.data) {
        setProducts(prevProducts => 
          prevProducts.map(p => p.id === response.data.id ? response.data : p)
        );
        showNotification(`Product "${response.data.name}" updated successfully`);
      }
    } catch (error) {
      setError(error.message);
      showNotification(error.message, 'error');
    }
  }, [showNotification]);

  const handleProductDelete = useCallback(async (productId) => {
    try {
      const productToDelete = products.find(p => p.id === productId);
      if (!productToDelete) return;

      await ProductService.deleteProduct(productId);
      setProducts(prevProducts => prevProducts.filter(p => p.id !== productId));
      showNotification(`Product "${productToDelete.name}" deleted successfully`);
    } catch (error) {
      setError(error.message);
      showNotification(error.message, 'error');
    }
  }, [products, showNotification]);

  const renderContent = useCallback(() => {
    if (error) {
      return (
        <Alert 
          severity="warning"
          onClose={() => setError(null)}
          icon={
            <AlertCircle 
              size={20} 
              color={
                theme.palette.mode === 'light'
                  ? 'rgba(0,0,0,0.87)'
                  : '#FFFFFF'
              } 
            />
          }
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={() => setError(null)}
              sx={{ 
                color: theme.palette.mode === 'light'
                  ? 'rgba(0,0,0,0.87)'
                  : '#FFFFFF'
              }}
            >
              DISMISS
            </Button>
          }
          sx={{ 
            marginBottom: 2,
            backgroundColor: 'transparent',
            border: 'none',
            color: theme.palette.mode === 'light'
              ? 'rgba(0,0,0,0.87)'
              : '#FFFFFF',
            '& .MuiAlert-icon': {
              color: theme.palette.mode === 'light'
                ? 'rgba(0,0,0,0.87)'
                : '#FFFFFF',
            },
            '& .MuiAlert-message': {
              color: theme.palette.mode === 'light'
                ? 'rgba(0,0,0,0.87)'
                : '#FFFFFF',
            }
          }}
        >
          {error}
        </Alert>
      );
    }

    switch (activeTab) {
      case 0:
        return (
          <ProductsManager 
            products={products}
            onProductCreate={handleProductCreate}
            onProductUpdate={handleProductUpdate}
            onProductDelete={handleProductDelete}
            loading={loading}
          />
        );
      case 1:
        return <MarketAnalysis products={products} />;
      case 2:
        return <PricingStrategy products={products} />;
      case 3:
        return <SalesStrategy products={products} />;
      default:
        return null;
    }
  }, [
    activeTab,
    products,
    error,
    handleProductCreate,
    handleProductUpdate,
    handleProductDelete,
    loading,
    theme.palette.mode
  ]);

  return (
    <Box className="space-y-4 relative" sx={{ minHeight: '100vh' }}>
      <NavigationContainer>
        <Box>
          {isMobile && activeTab !== 0 && (
            <NavigationButton
              onClick={() => handleTabChange(null, 0)}
              aria-label="Go back"
            >
              <ChevronLeft size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}

          <StyledTabs
            ref={tabsRef}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="product navigation tabs"
          >
            <TabIndicator style={getIndicatorStyle()} />
            {navigationTabs
              .slice(visibleRange.start, visibleRange.end)
              .map((tab) => (
                <StyledTab 
                  key={tab.id}
                  label={tab.label}
                  disableRipple
                  value={tab.id}
                />
              ))}
          </StyledTabs>

          {isMobile && visibleRange.end < navigationTabs.length && (
            <NavigationButton
              onClick={() => {
                const nextTab = navigationTabs[visibleRange.end];
                handleTabChange(null, nextTab.id);
              }}
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}
        </Box>
      </NavigationContainer>

      <Box sx={{ pb: '100px' }}>
        {renderContent()}
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowSnackbar(false)} 
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AGTProduct;
