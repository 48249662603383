import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisMonth,
  isThisYear,
  parseISO,
  compareDesc,
} from 'date-fns';
import {
  List,
  ListItemButton,
  ListSubheader,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { getThemeStyles } from '../../themes/themeConfig';
import ChatListFooter from './ChatListFooter';

const STORAGE_KEY = 'chatList_state';

// ===== Estilos
const StyledBox = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  '&::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.divider,
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  ...getThemeStyles('ChatList', theme.palette.mode),
}));

const StyledList = styled(List)({
  padding: 0,
  width: '100%',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...getThemeStyles('AllChatsText', theme.palette.mode),
  flexGrow: 1,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

const LoadingIndicator = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  width: '100%',
});

// ===== Función para categorizar los chats según la fecha
const getChatCategory = (date) => {
  if (!date) {
    return 'No Date';
  }
  try {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) return 'Today';
    if (isYesterday(parsedDate)) return 'Yesterday';
    if (isThisWeek(parsedDate)) return 'This Week';
    if (isThisMonth(parsedDate)) return 'This Month';
    if (isThisYear(parsedDate)) return format(parsedDate, 'MMMM');
    return format(parsedDate, 'yyyy');
  } catch (error) {
    return 'Invalid Date';
  }
};

const CategorizedChatList = ({
  chats,
  currentChatId,
  onSelectChat,
  onMenuOpen,
  hasNextPage,
  isNextPageLoading,
  onLoadMore,
  onSidebarClose,
  isMobile,
  selectedCategory,
  persistentState,
  onPersistentStateChange,
}) => {
  // ===== Estado local para scroll, etc.
  const [state, setState] = useState(() => {
    try {
      const saved = localStorage.getItem(STORAGE_KEY);
      return saved
        ? JSON.parse(saved)
        : {
            scrollPosition: 0,
            loadedChats: [],
            lastVisibleCategory: null,
          };
    } catch (error) {
      return {
        scrollPosition: 0,
        loadedChats: [],
        lastVisibleCategory: null,
      };
    }
  });

  const containerRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const resizeObserverRef = useRef(null);
  const categoriesRef = useRef(new Map());

  // === Filtramos los chats que tengan projectId para no mostrarlos aquí
  const relevantChats = useMemo(() => {
    return chats.filter((chat) => !chat.projectId);
  }, [chats]);

  // ===== Guardar en localStorage
  useEffect(() => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    } catch (error) {
      console.error('Error saving state:', error);
    }
  }, [state]);

  // ===== Restaurar posición de scroll
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = state.scrollPosition;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ===== Actualizar posición de scroll en el estado
  const updateScrollPosition = useCallback((scrollTop) => {
    setState((prev) => ({
      ...prev,
      scrollPosition: scrollTop,
    }));
  }, []);

  // ===== Manejar scroll + cargar más chats
  const handleScroll = useCallback(() => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = setTimeout(() => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const { scrollTop, scrollHeight, clientHeight } = container;
      updateScrollPosition(scrollTop);

      // Cargar más si estamos cerca del final
      const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
      if (scrollPercentage > 0.8 && hasNextPage && !isNextPageLoading) {
        onLoadMore();
      }
    }, 150);
  }, [onLoadMore, hasNextPage, isNextPageLoading, updateScrollPosition]);

  // ===== Manejar cambio de tamaño
  useEffect(() => {
    if (containerRef.current) {
      const handleResize = () => {
        updateScrollPosition(containerRef.current.scrollTop);
      };
      resizeObserverRef.current = new ResizeObserver(handleResize);
      resizeObserverRef.current.observe(containerRef.current);

      return () => {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
      };
    }
  }, [updateScrollPosition]);

  // ===== Seleccionar chat
  const handleChatSelect = useCallback(
    (chatId) => {
      onSelectChat(chatId);
      if (isMobile) {
        onSidebarClose();
      }
    },
    [onSelectChat, isMobile, onSidebarClose]
  );

  // ===== Agrupar los chats por categoría
  const categorizedChats = useMemo(() => {
    const categories = {};
    relevantChats.forEach((chat) => {
      const cat = getChatCategory(chat.updatedAt || chat.createdAt);
      if (!categories[cat]) categories[cat] = [];
      categories[cat].push(chat);
    });

    // Orden descendente dentro de cada categoría
    Object.values(categories).forEach((group) => {
      group.sort((a, b) => {
        const dateA = parseISO(a.updatedAt || a.createdAt);
        const dateB = parseISO(b.updatedAt || b.createdAt);
        return compareDesc(dateA, dateB);
      });
    });

    return categories;
  }, [relevantChats]);

  // Orden específico de categorías
  const categoryOrder = [
    'Today',
    'Yesterday',
    'This Week',
    'This Month',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <StyledBox ref={containerRef} onScroll={handleScroll}>
      <StyledList>
        {Object.entries(categorizedChats)
          .sort(([catA], [catB]) => {
            const indexA = categoryOrder.indexOf(catA);
            const indexB = categoryOrder.indexOf(catB);
            // Si ninguno está en categoryOrder, orden alfabético
            if (indexA === -1 && indexB === -1) return catA.localeCompare(catB);
            // Si solo uno está, el que sí está se pone primero
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
          })
          .map(([category, categoryChats]) => (
            <Box
              key={category}
              ref={(element) => {
                if (element) categoriesRef.current.set(category, element);
              }}
              data-category={category}
            >
              <ListSubheader
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#0f0f0f' : '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: (theme) =>
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.7)'
                      : 'rgba(0, 0, 0, 0.7)',
                  py: 1,
                  px: 2,
                }}
              >
                {category}
              </ListSubheader>

              {categoryChats.map((chat) => (
                <ListItemButton
                  key={chat.id}
                  onClick={() => handleChatSelect(chat.id)}
                  selected={currentChatId === chat.id}
                  sx={{
                    height: 36,
                    py: 0,
                    pl: 3,
                    pr: 1,
                    borderRadius: '12px',
                    mx: 1,
                    '&.Mui-selected': {
                      backgroundColor: 'action.selected',
                      '& .MuiTypography-root': {
                        color: '#0385FF',
                      },
                    },
                    '&:hover': {
                      backgroundColor: 'action.hover',
                      borderRadius: '12px',
                    },
                  }}
                >
                  <StyledTypography noWrap>
                    {chat.title || 'New chat'}
                  </StyledTypography>
                  <StyledIconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      onMenuOpen(event, chat.id);
                    }}
                  >
                    <MoreVertIcon fontSize="small" />
                  </StyledIconButton>
                </ListItemButton>
              ))}
            </Box>
          ))}

        {isNextPageLoading && (
          <LoadingIndicator>
            <CircularProgress size={24} />
          </LoadingIndicator>
        )}
      </StyledList>

      <ChatListFooter
        category={selectedCategory}
        chatsCount={relevantChats.length}
        totalChatsCount={persistentState.totalChatsLoaded}  // <-- Ahora pasamos el total real
        hasNextPage={hasNextPage}
        isNextPageLoading={isNextPageLoading}
        onLoadMore={onLoadMore}
      />
    </StyledBox>
  );
};

export default CategorizedChatList;
