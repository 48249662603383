import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup, 
  OAuthProvider, 
  setPersistence, 
  browserLocalPersistence,
  onAuthStateChanged,
  onIdTokenChanged
} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { isAuthorized } from './AuthorizedUsers';
import { saveUserEmailToPreferences } from '../services/AGTService';

const firebaseConfig = {
  apiKey: "AIzaSyCl_QY3Y0RYRNuMc541mwhBlMW2VbLiqhQ",
  authDomain: "nest-cd957.firebaseapp.com",
  projectId: "nest-cd957",
  storageBucket: "nest-cd957.appspot.com",
  messagingSenderId: "703548963271",
  appId: "1:703548963271:web:236d5d9dd29a0d8e9141e2",
  measurementId: "G-S7XKCXJFLT"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

setPersistence(auth, browserLocalPersistence);

const AuthContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
  backgroundColor: 'var(--bg-primary-elevated)',
  color: 'var(--label-primary)',
});

const RoundedTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    backgroundColor: 'var(--ui-base)',
    border: '1px solid var(--ui-base)',
    color: 'var(--label-primary)',
    '& fieldset': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: 'var(--grouped-bg-primary-base)',
      border: '1px solid var(--grouped-bg-primary-base)',
    },
    '&.Mui-focused': {
      border: '1px solid var(--ui-accent)',
      backgroundColor: 'var(--grouped-bg-primary-base)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'var(--label-secondary)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'var(--ui-accent)',
  },
  '& .MuiInputBase-input': {
    color: 'var(--label-primary)',
    '&::placeholder': {
      color: 'var(--label-secondary)',
    },
  },
  marginBottom: '16px',
  width: '100%',
});

const AuthButton = styled(Button)({
  borderRadius: '30px',
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: 'var(--ui-accent)',
  color: 'var(--system-white)',
  height: '48px',
  width: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'var(--fill-tertiary)',
  },
});

const SocialButton = styled(Button)({
  borderRadius: '30px',
  marginTop: '8px',
  backgroundColor: 'var(--bg-primary-elevated)',
  color: 'var(--label-primary)',
  height: '48px',
  width: '100%',
  textTransform: 'none',
  border: '1px solid var(--system-grey3)',
  '&:hover': {
    backgroundColor: 'var(--grouped-bg-primary-base)',
    border: '1px solid var(--system-grey)',
  },
});

const ErrorMessage = styled(Typography)({
  color: 'var(--system-grey)',
  backgroundColor: 'var(--grouped-bg-primary-base)',
  padding: '8px 16px',
  borderRadius: '30px',
  marginTop: '16px',
  width: '100%',
  textAlign: 'center',
  border: '1px solid var(--system-grey3)',
});

const StyledDivider = styled(Divider)({
  margin: '16px 0',
  width: '100%',
  '&.MuiDivider-root': {
    '&::before, &::after': {
      borderTop: '1px solid var(--system-grey3)',
    },
  },
  '& .MuiDivider-wrapper': {
    color: 'var(--label-secondary)',
    padding: '0 10px',
  },
});

const StyledTypography = styled(Typography)({
  color: 'var(--label-primary)',
  '&.login-text': {
    fontSize: '14px',
    '& span': {
      color: 'var(--ui-accent)',
      cursor: 'pointer',
      fontSize: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});

const Auth = ({ onLogin, mode, onToggleMode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const unsubscribeAuthState = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleSuccessfulLogin(user);
      }
    });

    const unsubscribeIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        sessionStorage.setItem('authToken', token);
      } else {
        sessionStorage.removeItem('authToken');
      }
    });

    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, []);

  const handleSuccessfulLogin = async (user) => {
    try {
      if (user && typeof user.getIdToken === 'function') {
        const token = await user.getIdToken();
        sessionStorage.setItem('authToken', token);
  
        // Asegúrate de que user.email y user.uid existen
        const userEmail = user.email;
        const userId = user.uid; // O donde guardes el userId, si no usas el uid de Firebase
  
        // 1) Guardamos/actualizamos en la tabla userPreferences
        await saveUserEmailToPreferences({
          userId,       // clave principal que usas
          email: userEmail || '',
        });
  
        // 2) Llamamos a onLogin para la lógica actual
        const isUserAuthorized = isAuthorized(user.email);
        onLogin(user, isUserAuthorized);
      } else {
        throw new Error('Invalid user or getIdToken method not available');
      }
    } catch (error) {
      console.error('Error getting token', error);
      setMessage('Login error. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      let result;
      if (mode === 'login') {
        result = await signInWithEmailAndPassword(auth, email, password);
        setMessage('Login successful');
      } else {
        result = await createUserWithEmailAndPassword(auth, email, password);
        setMessage('User registered successfully');
      }
      await handleSuccessfulLogin(result.user);
    } catch (error) {
      console.error('Authentication error', error);
      setMessage(getErrorMessage(error.code));
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      let authProvider;
      switch (provider) {
        case 'google':
          authProvider = new GoogleAuthProvider();
          authProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
          authProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
          authProvider.setCustomParameters({
            prompt: 'select_account'
          });
          break;
        case 'microsoft':
          authProvider = new OAuthProvider('microsoft.com');
          authProvider.setCustomParameters({
            prompt: 'select_account'
          });
          break;
        case 'apple':
          authProvider = new OAuthProvider('apple.com');
          break;
        default:
          throw new Error('Unsupported provider');
      }

      const result = await signInWithPopup(auth, authProvider);
      
      if (window.opener && !window.opener.closed) {
        window.close();
      }

      await handleSuccessfulLogin(result.user);
      setMessage(`Successfully signed in with ${provider}`);
    } catch (error) {
      console.error('Social login error', error);
      setMessage(getErrorMessage(error.code));
    }
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'No account found with this email address.';
      case 'auth/wrong-password':
        return 'Incorrect password.';
      case 'auth/email-already-in-use':
        return 'An account already exists with this email address.';
      case 'auth/weak-password':
        return 'The password is too weak.';
      case 'auth/invalid-email':
        return 'Invalid email format.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with this email using a different sign-in method.';
      case 'auth/popup-closed-by-user':
        return 'The sign-in window was closed before completing the operation.';
      default:
        return 'An error occurred during authentication. Please try again.';
    }
  };

  return (
    <AuthContainer>
      <StyledTypography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        {mode === 'login' ? 'Welcome back' : 'Create an account'}
      </StyledTypography>
      
      <SocialButton
        startIcon={<GoogleIcon />}
        onClick={() => handleSocialLogin('google')}>
        Continue with Google
      </SocialButton>
      <SocialButton
        startIcon={<MicrosoftIcon />}
        onClick={() => handleSocialLogin('microsoft')}>
        Continue with Microsoft
      </SocialButton>
      <SocialButton
        startIcon={<AppleIcon />}
        onClick={() => handleSocialLogin('apple')}>
        Continue with Apple
      </SocialButton>

      <StyledDivider>
        <Typography variant="body2" sx={{ color: 'var(--label-secondary)' }}>
          Or
        </Typography>
      </StyledDivider>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
        <RoundedTextField
          variant="outlined"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <RoundedTextField
          variant="outlined"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <AuthButton type="submit" fullWidth variant="contained">
          {mode === 'login' ? 'Continue' : 'Register'}
        </AuthButton>
        <Box sx={{ textAlign: 'center' }}>
          <StyledTypography variant="body2" className="login-text">
            {mode === 'login' ? "Don't have an account?" : 'Already have an account?'}{' '}
            <span onClick={onToggleMode}>
              {mode === 'login' ? 'Sign Up' : 'Log In'}
            </span>
          </StyledTypography>
        </Box>
      </Box>

      {message && <ErrorMessage>{message}</ErrorMessage>}
    </AuthContainer>
  );
};

export default Auth;