// D:\directiveai-react\frontend\src\components\chat\input\vision\DynamicStormWave.js

import React, { useMemo } from 'react';

const DynamicStormWave = ({ 
  primaryColor = "#2196f3",
  secondaryColor = "#00ff9d",
  cloudColor = "rgba(255, 255, 255, 0.65)",
  voiceType = "alloy",
  layers = 3
}) => {
  const voiceConfigs = {
    alloy: {
      duration: 4,
      stormIntensity: 1.2,
      waveFlow: 8,
      waveFloat: 6,
      waveGradient: 4
    },
    echo: {
      duration: 6,
      stormIntensity: 1.8,
      waveFlow: 12,
      waveFloat: 8,
      waveGradient: 6
    },
    fable: {
      duration: 3,
      stormIntensity: 0.8,
      waveFlow: 6,
      waveFloat: 4,
      waveGradient: 3
    },
    onyx: {
      duration: 5,
      stormIntensity: 2,
      waveFlow: 10,
      waveFloat: 7,
      waveGradient: 5
    },
    nova: {
      duration: 3.5,
      stormIntensity: 1.5,
      waveFlow: 7,
      waveFloat: 5,
      waveGradient: 3.5
    },
    shimmer: {
      duration: 4,
      stormIntensity: 1.3,
      waveFlow: 8,
      waveFloat: 6,
      waveGradient: 4
    }
  };

  const config = voiceConfigs[voiceType] || voiceConfigs.alloy;
  const uniqueId = useMemo(() => `storm-${Math.random().toString(36).substr(2, 9)}`, []);
  const waveGradientId = useMemo(() => `wave-gradient-${uniqueId}`, [uniqueId]);

  const styles = `
    @keyframes ${uniqueId}-wave-float {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(${-15 * config.stormIntensity}px, ${-10 * config.stormIntensity}px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    @keyframes ${uniqueId}-wave-flow {
      0% {
        d: path("M-100 0 C 0 0, 200 30, 400 0 L 400 100 C 200 70, 0 100, -100 100 Z");
      }
      50% {
        d: path("M-100 0 C 0 ${20 * config.stormIntensity}, 200 ${-10 * config.stormIntensity}, 400 0 L 400 100 C 200 ${80 * config.stormIntensity}, 0 ${110 * config.stormIntensity}, -100 100 Z");
      }
      100% {
        d: path("M-100 0 C 0 0, 200 30, 400 0 L 400 100 C 200 70, 0 100, -100 100 Z");
      }
    }

    @keyframes ${uniqueId}-wave-gradient {
      0% {
        stop-opacity: 0.5;
      }
      50% {
        stop-opacity: ${0.35 / config.stormIntensity};
      }
      100% {
        stop-opacity: 0.5;
      }
    }

    .wave-path {
      animation: ${uniqueId}-wave-flow ${config.waveFlow}s ease-in-out infinite;
    }

    .wave-group {
      animation: ${uniqueId}-wave-float ${config.waveFloat}s ease-in-out infinite;
    }

    .wave-stop {
      animation: ${uniqueId}-wave-gradient ${config.waveGradient}s ease-in-out infinite;
    }
  `;

  const WaveCloud = ({ y, opacity = 0.4, animationDelay = 0 }) => (
    <path
      className="wave-path"
      d="M-100 0 C 0 0, 200 30, 400 0 L 400 100 C 200 70, 0 100, -100 100 Z"
      fill={`url(#${waveGradientId}-${y})`}
      style={{
        transform: `translateY(${y}px)`,
        animationDelay: `${animationDelay * config.duration}s`,
        opacity: opacity / (config.stormIntensity * 0.8)
      }}
    />
  );

  return (
    <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
      <svg
        className="w-full h-full scale-150"
        viewBox="0 0 300 300"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          {[50, 100, 150].map((y) => (
            <linearGradient
              key={y}
              id={`${waveGradientId}-${y}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop 
                offset="0%" 
                stopColor={cloudColor} 
                className="wave-stop"
                style={{ 
                  animationDelay: `${y * 0.1 * config.duration}s`
                }}
              />
              <stop 
                offset="100%" 
                stopColor="rgba(255,255,255,0)" 
              />
            </linearGradient>
          ))}
        </defs>
        <style>{styles}</style>

        <g 
          className="wave-group" 
          transform="translate(0, 50)"
          style={{
            transform: `scale(${1 + (config.stormIntensity - 1) * 0.1})`
          }}
        >
          <WaveCloud y={50} opacity={0.3} animationDelay={0} />
          <WaveCloud y={100} opacity={0.4} animationDelay={0.5} />
          <WaveCloud y={150} opacity={0.5} animationDelay={1} />
        </g>
      </svg>
    </div>
  );
};

export default DynamicStormWave;
