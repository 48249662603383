import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TrendingUp } from 'lucide-react';
import { getThemeStyles } from '../themes/themeConfig';

const normalizeText = (text) => {
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9\s]/g, "");
};

const rawSuggestions = [
  "Explícame el cambio climático",
  "¿Cuáles son los beneficios de la meditación?",
  "¿Cómo puedo aprender a programar?",
  "¿Qué es el blockchain?",
  "¿Cuáles son las últimas tendencias en tecnología?",
  "¿Cómo puedo mejorar mi productividad?",
  "Explícame la teoría de la relatividad",
  "¿Cuáles son los mejores libros de ciencia ficción?",
  "¿Cómo funciona la energía solar?",
  "¿Qué es el aprendizaje automático?",
  "¿Cómo puedo empezar a invertir en bolsa?",
  "Explícame la fotosíntesis",
  "¿Cuáles son los beneficios del yoga?",
  "¿Cómo puedo aprender un nuevo idioma rápidamente?",
  "¿Qué es el marketing digital?",
  "¿Cómo crear un plan de negocios?",
  "¿Cuáles son las habilidades más demandadas en el mercado laboral?",
  "¿Cómo puedo mejorar mi currículum vitae?",
  "¿Qué es el pensamiento crítico?",
  "¿Cómo puedo desarrollar mi inteligencia emocional?",
  "¿Qué carreras tienen mayor salida laboral?",
  "¿Cómo negociar un aumento de sueldo?",
  "¿Qué es el emprendimiento social?",
  "¿Cómo puedo gestionar mi tiempo de manera eficiente?",
  "¿Cuáles son las técnicas de estudio más efectivas?",
  "¿Cómo funciona el mercado de criptomonedas?",
  "¿Qué es la economía circular?",
  "¿Cómo puedo reducir mi huella de carbono?",
  "¿Qué es el teletrabajo?",
  "¿Cómo puedo mejorar mis habilidades de comunicación?",
  "¿Qué es el liderazgo transformacional?",
  "¿Cómo puedo iniciar mi propio negocio?",
  "¿Qué es el coworking?",
  "¿Cómo funciona el comercio electrónico?",
  "¿Qué es el design thinking?",
  "¿Cómo puedo fomentar la innovación en mi empresa?",
  "¿Qué son las metodologías ágiles?",
  "¿Cómo crear una startup exitosa?",
  "¿Qué es el networking y cómo hacerlo efectivamente?",
  "¿Cómo puedo administrar mis finanzas personales?",
  "¿Qué es la inteligencia emocional?",
  "¿Cómo manejar el estrés laboral?",
  "¿Qué es la inteligencia de negocios?",
  "¿Cómo puedo mejorar mi marca personal?",
  "¿Qué es el capital de riesgo?",
  "¿Cómo funciona el crowdfunding?",
  "¿Qué es el big data?",
  "¿Cómo puedo mejorar mis habilidades de liderazgo?",
  "¿Qué es la automatización de procesos?",
  "¿Cómo puedo ser más creativo en mi trabajo?",
  "¿Qué es la realidad virtual?",
  "¿Cómo funciona la impresión 3D?",
  "¿Qué es la ciberseguridad?",
  "¿Cómo puedo proteger mi privacidad en línea?",
  "¿Qué es el Internet de las cosas?",
  "¿Cómo puedo implementar la inteligencia artificial en mi empresa?",
  "¿Qué es el marketing de contenidos?",
  "¿Cómo puedo optimizar mi sitio web para SEO?",
  "¿Qué es la transformación digital?",
  "¿Cómo puedo aprender sobre finanzas personales?",
  "¿Qué es la economía colaborativa?",
  "¿Cómo puedo mejorar mi servicio al cliente?",
  "¿Qué es el pensamiento lateral?",
  "¿Cómo puedo desarrollar un producto mínimo viable?",
  "¿Qué son las habilidades blandas y por qué son importantes?",
  "¿Cómo puedo superar el miedo a hablar en público?",
  "¿Qué es el coaching empresarial?",
  "¿Cómo puedo mantener un equilibrio entre trabajo y vida personal?",
  "¿Qué es el blockchain y cómo afecta a mi industria?",
  "¿Cómo puedo mejorar mi concentración?",
  "¿Qué es el aprendizaje continuo?",
  "¿Cómo puedo establecer metas efectivas?",
  "¿Qué es el marketing de afiliación?",
  "¿Cómo puedo aumentar las ventas de mi negocio?",
  "¿Qué es el análisis DAFO?",
  "¿Cómo puedo ser más resiliente en el trabajo?",
  "¿Qué es la gestión del cambio?",
  "¿Cómo puedo fomentar el trabajo en equipo?",
  "¿Qué es el dropshipping?",
  "¿Cómo puedo crear un canal de YouTube exitoso?",
  "¿Qué es el marketing en redes sociales?",
  "¿Cómo puedo escribir un libro?",
  "¿Qué es el desarrollo sostenible?",
  "¿Cómo puedo mejorar mi postura corporal?",
  "¿Qué es la neurociencia y cómo puede ayudarme?",
  "¿Cómo puedo hacer networking en eventos profesionales?",
  "¿Qué es el design sprint?",
  "¿Cómo puedo implementar políticas de inclusión en mi empresa?",
  "¿Qué es el salario emocional?",
  "¿Cómo puedo mejorar mi inteligencia financiera?",
  "¿Qué es el metaverso?",
  "¿Cómo puedo desarrollar aplicaciones móviles?",
  "¿Qué es la economía digital?",
  "¿Cómo puedo proteger mi propiedad intelectual?",
  "¿Qué es el marketing viral?",
  "¿Cómo puedo hacer un análisis de mercado?",
  "¿Qué es el capital humano?",
  "¿Cómo puedo implementar prácticas de responsabilidad social corporativa?",
  "¿Qué es el marketing verde?",
  "¿Cómo puedo mejorar mi inglés de negocios?",
  "¿Qué es la analítica web?",
  "¿Cómo puedo realizar una campaña de email marketing efectiva?",
  "¿Qué es la inteligencia artificial explicable?",
  "¿Cómo puedo preparar una presentación impactante?",
  "¿Qué es el e-learning?",
  "¿Cómo puedo gestionar proyectos de manera eficiente?",
  "¿Qué es el neuromarketing?",
  "¿Cómo puedo desarrollar mi pensamiento estratégico?",
  "¿Qué es la economía compartida?",
  "¿Cómo puedo mejorar mi memoria?",
  "¿Qué es el fracaso empresarial y cómo aprender de él?",
  "¿Cómo puedo crear un podcast?",
  "¿Qué es la gestión de riesgos?",
  "¿Cómo puedo realizar un plan de marketing?",
  "¿Qué es el machine learning?",
  "¿Cómo puedo aplicar metodologías lean en mi empresa?",
  "¿Qué es la cultura organizacional?",
  "¿Cómo puedo fomentar la creatividad en mi equipo?",
  "¿Qué es el análisis de datos?",
  "¿Cómo puedo mejorar mi oratoria?",
  "¿Qué es la gestión de talento?",
  "¿Cómo puedo realizar una investigación de mercado?",
  "¿Qué es el customer journey?",
  "¿Cómo puedo aumentar el engagement en redes sociales?",
  "¿Qué es el benchmarking?",
  "¿Cómo puedo optimizar mi cadena de suministro?",
  "¿Qué es la gestión del conocimiento?",
  "¿Cómo puedo implementar la gestión por objetivos?",
  "¿Qué es el valor agregado?",
  "¿Cómo puedo mejorar la experiencia del cliente?",
  "¿Qué es el mercado bursátil?",
  "¿Cómo puedo aprender sobre análisis técnico?",
  "¿Qué es el venture capital?",
  "¿Cómo puedo preparar un pitch exitoso?",
  "¿Qué es el growth hacking?",
  "¿Cómo puedo realizar una auditoría interna?",
  "¿Qué es el modelo canvas?",
  "¿Cómo puedo mejorar la cultura de mi empresa?",
  "¿Qué es el storytelling en los negocios?",
  "¿Cómo puedo aplicar la inteligencia emocional en el trabajo?",
  "¿Qué es la gestión del tiempo?",
  "¿Cómo puedo manejar conflictos en el equipo?",
  "¿Qué es la gestión de la calidad?",
  "¿Cómo puedo desarrollar un plan estratégico?",
  "¿Qué es el capital intelectual?",
  "¿Cómo puedo implementar el teletrabajo en mi empresa?",
  "¿Qué es la innovación abierta?",
  "¿Cómo puedo mejorar mi nivel de satisfacción laboral?",
  "Redáctame un ensayo sobre la innovación tecnológica",
  "Mejora mi plan de negocios",
  "Analiza este informe financiero",
  "Escribe una carta de presentación para mi solicitud de empleo",
  "Crea un esquema para mi proyecto de tesis",
  "Desarrolla una estrategia de marketing para mi empresa",
  "Optimiza mi horario de estudio",
  "Genera ideas para mi próxima reunión de equipo",
  "Elabora un presupuesto para mi startup",
  "Resume los puntos clave de este artículo",
  "Tradúceme este documento al inglés",
  "Organiza mis tareas pendientes",
  "Proporciona sugerencias para mejorar mi productividad",
  "Escribe un discurso motivacional",
  "Prepara una presentación sobre liderazgo",
  "Crea un plan de acción para alcanzar mis metas",
  "Diseña un logo para mi empresa",
  "Calcula el retorno de inversión de este proyecto",
  "Escribe un informe sobre las tendencias del mercado",
  "Haz un análisis FODA de mi negocio",
  "Planifica una campaña publicitaria",
  "Elabora un plan de estudios para aprender programación",
  "Revisa mi ensayo y corrige los errores",
  "Sugiere formas de mejorar la comunicación en mi equipo",
  "Desarrolla un protocolo de seguridad para la oficina",
  "Redacta una política de privacidad para mi sitio web",
  "Proporciona consejos para negociar contratos",
  "Crea una agenda para la próxima reunión",
  "Escribe un blog sobre emprendimiento",
  "Genera un informe sobre el análisis de datos",
  "Diseña un cuestionario para evaluar la satisfacción del cliente",
  "Escribe un manual de usuario para mi producto",
  "Crea un plan de capacitación para nuevos empleados",
  "Desarrolla un sistema de seguimiento de ventas",
  "Propón iniciativas para mejorar la cultura empresarial",
  "Analiza la competencia en mi sector",
  "Elabora un plan de contingencia",
  "Sugiere herramientas para gestión de proyectos",
  "Escribe una propuesta de colaboración",
  "Optimiza mi perfil profesional en LinkedIn",
  "Crea un calendario editorial para mi blog",
  "Escribe un artículo sobre sostenibilidad empresarial",
  "Desarrolla un prototipo para mi aplicación",
  "Proporciona estrategias para aumentar las ventas",
  "Elabora un plan financiero a cinco años",
  "Diseña una encuesta de mercado",
  "Escribe un caso de estudio sobre éxito empresarial",
  "Crea un video promocional para mi producto",
  "Planifica un evento de networking",
  "Redacta una política de recursos humanos",
  "Sugiere mejoras para mi tienda en línea",
  "Elabora un plan de sucesión para la empresa",
  "Desarrolla un sistema de recompensas para empleados",
  "Escribe un guión para un anuncio de radio",
  "Crea un diagrama de flujo para el proceso de producción",
  "Analiza el impacto de las redes sociales en mi negocio",
  "Proporciona recomendaciones para mejorar la retención de clientes",
  "Escribe un whitepaper sobre tecnología emergente",
  "Diseña un folleto informativo",
  "Elabora un plan de expansión internacional",
  "Redacta una política de uso de dispositivos móviles",
  "Desarrolla una aplicación web para gestionar tareas",
  "Crea una estrategia de branding",
  "Escribe un informe sobre responsabilidad social corporativa",
  "Optimiza mi sitio web para motores de búsqueda",
  "Planifica un programa de mentoría",
  "Sugiere actividades para mejorar el trabajo en equipo",
  "Elabora un protocolo de atención al cliente",
  "Diseña un sistema de incentivos",
  "Escribe una propuesta de proyecto para obtener financiamiento",
  "Crea una campaña de email marketing",
  "Desarrolla una estrategia para reducir costos",
  "Proporciona consejos para balancear trabajo y vida personal",
  "Analiza tendencias en el comportamiento del consumidor",
  "Redacta un contrato de prestación de servicios",
  "Escribe un manual de políticas internas",
  "Crea un plan de lanzamiento de producto",
  "Elabora una estrategia de gestión del cambio",
  "Diseña un plan de crisis empresarial",
  "Desarrolla un programa de bienestar para empleados",
  "Proporciona técnicas para mejorar habilidades de negociación",
  "Escribe un informe sobre innovación abierta",
  "Planifica una estrategia de salida del mercado",
  "Sugiere métodos para medir la productividad",
  "Elabora un plan de marketing digital",
  "Crea una propuesta para colaboración interdepartamental",
  "Desarrolla una política de trabajo remoto",
  "Escribe un artículo sobre tendencias en tecnología educativa",
  "Diseña un sistema de evaluación del desempeño",
  "Proporciona estrategias para mejorar la satisfacción del cliente",
  "Analiza el retorno de inversión en marketing",
  "Redacta un protocolo para manejo de quejas",
  "Crea un plan para reducir el impacto ambiental de la empresa",
  "Escribe un discurso para una conferencia sobre liderazgo",
  "Desarrolla un plan para implementar inteligencia artificial",
  "Sugiere formas de mejorar la diversidad e inclusión",
  "Elabora un presupuesto anual",
  "Planifica una estrategia para entrar en nuevos mercados",
  "Diseña un programa de fidelización de clientes",
  "Escribe un informe sobre ciberseguridad",
  "Crea un plan para automatizar procesos",
  "Proporciona técnicas para mejorar la creatividad en el equipo",
  "Desarrolla un modelo de negocio para una startup",
  "Redacta una propuesta para reducir la rotación de personal",
  "Analiza el impacto de la globalización en mi industria",
  "Escribe una guía para el uso de herramientas de colaboración",
  "Crea una política de protección de datos",
  "Elabora un plan para implementar energías renovables",
  "Sugiere estrategias para mejorar el liderazgo",
  "Diseña un sistema de comunicación interna",
  "Proporciona consejos para mejorar la gestión del tiempo",
  "Desarrolla un plan para capacitar a empleados en nuevas tecnologías",
  "Escribe un artículo sobre ética empresarial",
  "Redacta un informe sobre el teletrabajo",
  "Crea un plan para mejorar la cadena de suministro",
  "Planifica una estrategia de responsabilidad social",
  "Elabora un programa de formación continua",
  "Analiza los riesgos de invertir en criptomonedas",
  "Proporciona estrategias para reducir el estrés laboral",
  "Diseña un sistema de feedback para empleados",
  "Escribe una propuesta para mejorar la innovación",
  "Desarrolla un plan de mejora continua",
  "Sugiere formas de aprovechar el big data en mi empresa",
  "Redacta una política de uso aceptable de internet",
  "Crea una estrategia para mejorar la cultura organizacional",
  "Elabora un plan para implementar la transformación digital",
  "Proporciona técnicas para mejorar la toma de decisiones",
  "Diseña un sistema para gestionar la información",
  "Escribe un informe sobre tendencias económicas globales",
  "Desarrolla una estrategia de engagement en redes sociales",
  "Analiza las ventajas y desventajas del trabajo freelance",
  "Redacta una propuesta para optimizar recursos",
  "Crea un plan para mejorar la satisfacción laboral",
  "Sugiere actividades para fomentar la colaboración",
  "Elabora un protocolo de seguridad informática",
  "Proporciona consejos para mejorar habilidades de presentación",
  "Diseña un programa de reconocimiento para empleados",
  "Desarrolla un plan de transición para nuevos líderes",
  "Escribe un artículo sobre la importancia de la innovación",
  "Planifica una estrategia de relaciones públicas",
  "Redacta un manual de procedimientos",
  "Crea un plan para implementar metodologías ágiles",
  "Elabora una estrategia para mejorar la eficiencia operativa",
];

export const getSuggestions = (inputValue) => {
  const normalizedInput = normalizeText(inputValue);
  return rawSuggestions.filter(suggestion => 
    normalizeText(suggestion).includes(normalizedInput)
  );
};

const TrendingContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0),
  padding: theme.spacing(2),
  borderRadius: '10px',
  ...getThemeStyles('TrendingContainer', theme.palette.mode),
}));

const TrendingItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    '& .trend-line': {
      stroke: '#0056b3',
    }
  },
  ...getThemeStyles('TrendingItem', theme.palette.mode),
}));

const TrendGraph = styled('svg')({
  width: '50px',
  height: '20px',
  marginLeft: '8px',
});

const trendingData = [
  { text: "Inteligencia Artificial en 2024", value: 95 },
  { text: "Criptomonedas y blockchain", value: 80 },
  { text: "Viajes espaciales comerciales", value: 75 },
  { text: "Energías renovables", value: 88 },
  { text: "Realidad virtual y aumentada", value: 70 },
];

export const TrendingSuggestions = ({ onSuggestionClick }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  return (
    <TrendingContainer>
      <Typography variant="h6" gutterBottom>
        Tendencias
      </Typography>
      {trendingData.map((item, index) => (
        <TrendingItem key={index} onClick={() => onSuggestionClick(item.text)}>
          <TrendingUp 
            size={16} 
            style={{ 
              marginRight: '8px',
              color: '#007BFE',
            }} 
          />
          <Typography 
            variant="body2" 
            sx={getThemeStyles('TrendingText', mode)}
          >
            {item.text}
          </Typography>
          <Box flexGrow={1} />
          <Typography 
            variant="caption" 
            sx={{
              color: '#007BFE',
            }}
          >
            {item.value}%
          </Typography>
          <TrendGraph viewBox="0 0 50 20">
            <polyline
              points="0,20 25,10 50,0"
              fill="none"
              strokeWidth="2"
              className="trend-line"
              style={{
                stroke: '#007BFE',
              }}
            />
          </TrendGraph>
        </TrendingItem>
      ))}
    </TrendingContainer>
  );
};