import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  IconButton, 
  Tooltip,
  TextField,
  InputAdornment,
  Avatar,
  Menu,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Search,
  Filter,
  Download,
  MoreVertical,
  Edit2,
  Trash2,
  Flag,
  ArrowUpRight,
  ArrowDownRight,
  Receipt,
  ShoppingBag,
  Coffee,
  Home,
  Car,
  DollarSign,
  Plus,
  FileText,
  Camera,
  Tag
} from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const TransactionCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  gap: theme.spacing(1),
}));

// Datos de ejemplo
const transactions = [
  {
    id: 1,
    type: 'expense',
    title: 'Office Supplies',
    amount: 249.99,
    date: '2024-02-20',
    category: 'office',
    status: 'completed',
    receipt: true,
    description: 'Monthly office supplies and stationery',
    icon: ShoppingBag,
    color: '#4f46e5'
  },
  {
    id: 2,
    type: 'income',
    title: 'Client Payment',
    amount: 1500.00,
    date: '2024-02-19',
    category: 'sales',
    status: 'completed',
    receipt: false,
    description: 'Project completion payment',
    icon: DollarSign,
    color: '#059669'
  },
  {
    id: 3,
    type: 'expense',
    title: 'Software Subscription',
    amount: 79.99,
    date: '2024-02-18',
    category: 'software',
    status: 'pending',
    receipt: true,
    description: 'Monthly software subscription',
    icon: Receipt,
    color: '#0891b2'
  }
];

const categories = [
  { id: 'office', name: 'Office Supplies', icon: ShoppingBag, color: '#4f46e5' },
  { id: 'software', name: 'Software', icon: FileText, color: '#0891b2' },
  { id: 'sales', name: 'Sales', icon: DollarSign, color: '#059669' },
  { id: 'utilities', name: 'Utilities', icon: Home, color: '#7c3aed' },
  { id: 'travel', name: 'Travel', icon: Car, color: '#db2777' }
];

const RecentTransactions = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [filterCategory, setFilterCategory] = useState('all');
  const [openFilters, setOpenFilters] = useState(false);

  const handleMenuOpen = (event, transaction) => {
    setAnchorEl(event.currentTarget);
    setSelectedTransaction(transaction);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTransaction(null);
  };

  const handleOpenDialog = (transaction = null) => {
    setSelectedTransaction(transaction);
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransaction(null);
  };

  const filteredTransactions = transactions.filter(transaction => {
    const matchesSearch = transaction.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         transaction.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesType = filterType === 'all' || transaction.type === filterType;
    const matchesCategory = filterCategory === 'all' || transaction.category === filterCategory;
    
    return matchesSearch && matchesType && matchesCategory;
  });

  return (
    <Box className="space-y-6">
      {/* Control Panel */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            placeholder="Search transactions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} className="flex justify-end gap-2">
          <ActionButton 
            variant="outlined"
            onClick={() => setOpenFilters(true)}
            startIcon={<Filter />}
          >
            Filters
          </ActionButton>
          <ActionButton 
            variant="outlined"
            startIcon={<Download />}
          >
            Export
          </ActionButton>
          <ActionButton 
            variant="contained"
            onClick={() => handleOpenDialog()}
            startIcon={<Plus />}
          >
            Add Transaction
          </ActionButton>
        </Grid>
      </Grid>

      {/* Filter Panel */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Transaction Type</InputLabel>
            <Select
              value={filterType}
              label="Transaction Type"
              onChange={(e) => setFilterType(e.target.value)}
            >
              <MenuItem value="all">All Types</MenuItem>
              <MenuItem value="income">Income</MenuItem>
              <MenuItem value="expense">Expense</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={filterCategory}
              label="Category"
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <MenuItem value="all">All Categories</MenuItem>
              {categories.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  <Box className="flex items-center gap-2">
                    <category.icon size={16} />
                    {category.name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Transactions List */}
      <StyledPaper>
        <Box className="mb-4">
          <Typography variant="h6" gutterBottom>Recent Transactions</Typography>
          <Typography variant="body2" color="text.secondary">
            Showing {filteredTransactions.length} transactions
          </Typography>
        </Box>

        {filteredTransactions.map(transaction => (
          <TransactionCard key={transaction.id}>
            <Box className="flex justify-between items-start">
              <Box className="flex items-center gap-3">
                <Avatar 
                  sx={{ 
                    bgcolor: transaction.color,
                    width: 40,
                    height: 40
                  }}
                >
                  <transaction.icon size={20} />
                </Avatar>
                <Box>
                  <Typography variant="subtitle1">{transaction.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {transaction.description}
                  </Typography>
                  <Box className="flex items-center gap-2 mt-1">
                    <Typography variant="caption" color="text.secondary">
                      {new Date(transaction.date).toLocaleDateString()}
                    </Typography>
                    {transaction.receipt && (
                      <Tooltip title="Receipt Available">
                        <Receipt size={14} className="text-gray-400" />
                      </Tooltip>
                    )}
                    <Chip 
                      size="small"
                      label={transaction.status}
                      color={transaction.status === 'completed' ? 'success' : 'warning'}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="flex items-center gap-2">
                <Typography 
                  variant="subtitle1"
                  color={transaction.type === 'income' ? 'success.main' : 'error.main'}
                  className="flex items-center"
                >
                  {transaction.type === 'income' ? (
                    <ArrowUpRight size={16} className="mr-1" />
                  ) : (
                    <ArrowDownRight size={16} className="mr-1" />
                  )}
                  ${transaction.amount.toLocaleString()}
                </Typography>
                <IconButton 
                  size="small"
                  onClick={(e) => handleMenuOpen(e, transaction)}
                >
                  <MoreVertical size={16} />
                </IconButton>
              </Box>
            </Box>
          </TransactionCard>
        ))}
      </StyledPaper>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleOpenDialog(selectedTransaction)}>
          <Edit2 size={16} className="mr-2" />
          Edit Transaction
        </MenuItem>
        <MenuItem className="text-red-500">
          <Trash2 size={16} className="mr-2" />
          Delete Transaction
        </MenuItem>
        <MenuItem>
          <Flag size={16} className="mr-2" />
          Mark as Pending
        </MenuItem>
      </Menu>

      {/* Transaction Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {selectedTransaction ? 'Edit Transaction' : 'Add New Transaction'}
        </DialogTitle>
        <DialogContent>
          <Box className="space-y-4 mt-4">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  defaultValue={selectedTransaction?.title}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  defaultValue={selectedTransaction?.amount}
                  InputProps={{
                    startAdornment: <DollarSign size={16} className="mr-2" />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  defaultValue={selectedTransaction?.date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    defaultValue={selectedTransaction?.type || 'expense'}
                    label="Type"
                  >
                    <MenuItem value="income">Income</MenuItem>
                    <MenuItem value="expense">Expense</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    defaultValue={selectedTransaction?.category || 'office'}
                    label="Category"
                  >
                    {categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        <Box className="flex items-center gap-2">
                          <category.icon size={16} />
                          {category.name}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={3}
                  defaultValue={selectedTransaction?.description}
                />
              </Grid>
            </Grid>
            <Box className="flex gap-2">
              <ActionButton
                variant="outlined"
                startIcon={<Camera />}
              >
                Scan Receipt
              </ActionButton>
              <ActionButton
                variant="outlined"
                startIcon={<Tag />}
              >
                Add Tags
              </ActionButton>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={handleCloseDialog}
          >
            {selectedTransaction ? 'Save Changes' : 'Add Transaction'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RecentTransactions;