/**
 * AGTMakerExportTxt.js
 *
 * Exporta el contenido a texto plano (.txt), eliminando elementos
 * de Markdown y caracteres especiales. Puedes ajustar la lógica
 * de limpieza según tus necesidades.
 */

import { saveAs } from 'file-saver';

/**
 * Limpia el Markdown y deja solo texto plano.
 * - Opcionalmente detecta bloques de código y los incluye como texto normal.
 * - Quita encabezados, backticks, etc.
 * - Quita HTML tags si existieran, etc.
 */
function convertMarkdownToPlainText(markdown) {
  let plain = markdown;

  // 1) Eliminar bloque de triple backticks y dejar el contenido interno sin ```.
  //    Ojo: si queremos de plano ignorar el contenido de los bloques de código,
  //    podríamos dejarlos en blanco o extraerlos. Aquí lo mantenemos como texto.
  plain = plain.replace(/```([\s\S]*?)```/g, (match, p1) => {
    // p1 = contenido dentro de triple backticks
    // Lo dejamos tal cual, pero sin las ```
    return '\n' + p1 + '\n';
  });

  // 2) Eliminar encabezados: #, ##, ###, etc.
  //    Reemplazamos "# " con "" (vacío), pero dejamos el texto. 
  plain = plain.replace(/^#{1,6}\s+/gm, '');

  // 3) Eliminar backticks individuales de inline code, `texto`.
  plain = plain.replace(/`([^`]+)`/g, '$1');

  // 4) Eliminar asteriscos de negritas o itálicas: *texto* o **texto** o ***texto***
  //    Aquí lo más sencillo es quitar * y _, sabiendo que quita énfasis.
  plain = plain.replace(/[*_]{1,3}([^*_]+)[*_]{1,3}/g, '$1');

  // 5) Eliminar (o convertir) listas con -, +, * al inicio de línea
  plain = plain.replace(/^[\-\+\*]\s+/gm, '- ');

  // 6) Eliminar imágenes y links en formato markdown, dejándolos en texto
  //    Ej: ![alt](url) => "alt (url)", [Texto](URL) => "Texto (URL)"
  plain = plain.replace(/!\[([^\]]*?)\]\((.*?)\)/g, '$1 ($2)');
  plain = plain.replace(/\[([^\]]*?)\]\((.*?)\)/g, '$1 ($2)');

  // 7) Opcional: eliminar tags HTML <tag>...</tag>
  //    Si tu contenido no trae HTML, puedes omitirlo. 
  //    Este ejemplo deja el texto interno:
  plain = plain.replace(/<\/?[^>]+(>|$)/g, '');

  // 8) Eliminar entidades HTML (si existieran) de forma básica
  //    Ej. &nbsp; => espacio, &lt; => <, etc.
  //    Aquí podemos reemplazar unas cuantas o usar una librería para decode
  plain = plain
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');

  // 9) Quitar doble espacios múltiples, line breaks extra
  plain = plain.replace(/\r\n/g, '\n');   // uniformiza saltos de línea
  plain = plain.replace(/\n{3,}/g, '\n\n'); // no más de dos saltos
  plain = plain.replace(/[ \t]+$/gm, '');   // quita espacios al final de líneas
  plain = plain.trim();

  return plain;
}

/**
 * exportToTxt
 * - Convierte contenido a texto plano
 * - Lo guarda usando file-saver
 */
async function exportToTxt(markdownContent, filename = 'document.txt') {
  const plainText = convertMarkdownToPlainText(markdownContent);
  const blob = new Blob([plainText], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, filename);
}

export default {
  exportToTxt,
};
