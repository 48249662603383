import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Tabs, Tab, Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus, ChevronLeft, ChevronRight } from 'lucide-react';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(40, 40, 42, 0.94)'
    : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(118, 118, 128, 0.24)'
    : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow: theme.palette.mode === 'dark'
    ? 'none'
    : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)'
    : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  }
}));

const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  }
}));

const NavigationBar = ({ 
  onNavigate, 
  activeSection, 
  showUpsInNav, 
  showEduInNav 
}) => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);

  const baseMenuItems = ['Home', 'Chat'];
  const menuItems = [
    ...baseMenuItems,
    ...(showUpsInNav ? ['Ups'] : []),
    ...(showEduInNav ? ['Edu'] : [])
  ];

  const visibleMenuItems = isMobile 
    ? menuItems.slice(visibleRange.start, visibleRange.end)
    : menuItems;

  const showHomeButton = isMobile && activeSection !== 'home' && !visibleMenuItems.includes('Home');
  const showRightArrow = isMobile && visibleRange.end < menuItems.length;

  // Calcula la posición del indicador
  const getIndicatorStyle = () => {
    const activeIdx = getRelativeActiveIndex();
    if (activeIdx === false) return {};
    
    const offset = activeIdx * (74 + 4); // width + gap
    return {
      transform: `translateX(${offset}px)`,
    };
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!isMobile) return;

    const activeIdx = menuItems.findIndex(item => item.toLowerCase() === activeSection);
    if (activeIdx === -1) return;

    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
      return;
    }

    if (activeIdx >= menuItems.length - 3) {
      setVisibleRange({ 
        start: menuItems.length - 3, 
        end: menuItems.length 
      });
      return;
    }

    setVisibleRange({
      start: activeIdx - 1,
      end: activeIdx + 2
    });
  }, [activeSection, menuItems.length, isMobile]);

  const handleNavigationWithDebounce = useCallback((section) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      lastNavigationTime.current = now;
      onNavigate(section);
    }
  }, [onNavigate]);

  const handleHomeNavigation = () => {
    handleNavigationWithDebounce('home');
  };

  const handleChange = (event, newValue) => {
    const realIndex = newValue + visibleRange.start;
    const section = menuItems[realIndex].toLowerCase();
    handleNavigationWithDebounce(section);
  };

  const getRelativeActiveIndex = () => {
    const absoluteIndex = menuItems.findIndex(
      item => item.toLowerCase() === activeSection
    );
    if (absoluteIndex === -1) return false;
    
    return absoluteIndex - visibleRange.start;
  };

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '1872px',
      height: '52px',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '52px',
        background: theme.palette.mode === 'dark' 
          ? '#1C1C1E'
          : 'rgba(118, 118, 128, 0.20)',
        borderRadius: '30px',
        overflow: 'hidden',
      }}>
        {showHomeButton && (
          <NavigationButton
            onClick={handleHomeNavigation}
            aria-label="Go to Home"
          >
            <ChevronLeft size={16} strokeWidth={2.5} />
          </NavigationButton>
        )}

        <StyledTabs
          ref={tabsRef}
          value={getRelativeActiveIndex()}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="navigation tabs"
        >
          <TabIndicator style={getIndicatorStyle()} />
          {visibleMenuItems.map((item, index) => (
            <StyledTab 
              key={item}
              label={item}
              disableRipple
              value={index}
            />
          ))}
        </StyledTabs>

        {isMobile ? (
          showRightArrow ? (
            <NavigationButton
              onClick={() => handleNavigationWithDebounce(menuItems[visibleRange.end].toLowerCase())}
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          ) : (
            menuItems.length <= 3 && (
              <NavigationButton
                onClick={() => handleNavigationWithDebounce('promo')}
                aria-label="Add new"
              >
                <Plus size={16} strokeWidth={2.5} />
              </NavigationButton>
            )
          )
        ) : (
          <NavigationButton
            onClick={() => handleNavigationWithDebounce('promo')}
            aria-label="Add new"
          >
            <Plus size={16} strokeWidth={2.5} />
          </NavigationButton>
        )}
      </Box>
    </Box>
  );
};

export default NavigationBar;