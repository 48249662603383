// VoicePreviewPlayer.js
import React, { useEffect, useRef, useState, useCallback } from 'react';

// Mapeo de voces a sus archivos de audio de previsualización
const PREVIEW_AUDIO_MAP = {
  alloy: '/audio/alloy_preview.mp3',
  echo: '/audio/echo_preview.mp3',
  fable: '/audio/fable_preview.mp3',
  onyx: '/audio/onyx_preview.mp3',
  nova: '/audio/nova_preview.mp3',
  shimmer: '/audio/shimmer_preview.mp3'
};

const VoicePreviewPlayer = ({ 
  voiceId,
  onPlayingChange,
  onError 
}) => {
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);
  const bufferRef = useRef(null);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const isPlayingRef = useRef(false);
  const currentVoiceRef = useRef(voiceId);
  const abortControllerRef = useRef(null);

  const getAudioContext = useCallback(() => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 44100,
        latencyHint: 'interactive'
      });
      gainNodeRef.current = audioContextRef.current.createGain();
      gainNodeRef.current.connect(audioContextRef.current.destination);
      gainNodeRef.current.gain.value = 0;
    }
    return audioContextRef.current;
  }, []);

  const stopCurrentPlayback = useCallback((immediate = false) => {
    if (sourceNodeRef.current) {
      try {
        const ctx = audioContextRef.current;
        if (ctx && !immediate) {
          const now = ctx.currentTime;
          gainNodeRef.current.gain.cancelScheduledValues(now);
          gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, now);
          gainNodeRef.current.gain.linearRampToValueAtTime(0, now + 0.1);
          
          setTimeout(() => {
            if (sourceNodeRef.current) {
              sourceNodeRef.current.stop();
              sourceNodeRef.current.disconnect();
              sourceNodeRef.current = null;
            }
          }, 100);
        } else {
          sourceNodeRef.current.stop();
          sourceNodeRef.current.disconnect();
          sourceNodeRef.current = null;
        }
        
        isPlayingRef.current = false;
        onPlayingChange?.(false);
      } catch (e) {
        // console.warn('Error stopping playback:', e);
      }
    }
  }, [onPlayingChange]);

  const loadAudio = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const ctx = getAudioContext();
      const audioUrl = PREVIEW_AUDIO_MAP[voiceId];

      const response = await fetch(audioUrl, {
        signal: abortControllerRef.current.signal
      });
      
      if (currentVoiceRef.current !== voiceId) {
        return;
      }

      const arrayBuffer = await response.arrayBuffer();
      bufferRef.current = await ctx.decodeAudioData(arrayBuffer);
      setAudioLoaded(true);
      return true;
    } catch (error) {
      if (error.name === 'AbortError') {
        return false;
      }
      // console.error('Error loading preview audio:', error);
      onError?.('Error loading voice preview');
      setAudioLoaded(false);
      return false;
    }
  }, [voiceId, getAudioContext, onError]);

  const playPreview = useCallback(async () => {
    if (!audioLoaded || !bufferRef.current) return;

    try {
      stopCurrentPlayback(true);

      const ctx = getAudioContext();
      if (ctx.state === 'suspended') {
        await ctx.resume();
      }

      sourceNodeRef.current = ctx.createBufferSource();
      sourceNodeRef.current.buffer = bufferRef.current;
      sourceNodeRef.current.connect(gainNodeRef.current);

      const now = ctx.currentTime;
      const duration = bufferRef.current.duration;
      
      gainNodeRef.current.gain.cancelScheduledValues(now);
      gainNodeRef.current.gain.setValueAtTime(0, now);
      gainNodeRef.current.gain.linearRampToValueAtTime(0.7, now + 0.2);
      gainNodeRef.current.gain.setValueAtTime(0.7, now + duration - 0.2);
      gainNodeRef.current.gain.linearRampToValueAtTime(0, now + duration);

      sourceNodeRef.current.onended = () => {
        if (currentVoiceRef.current === voiceId) {
          isPlayingRef.current = false;
          onPlayingChange?.(false);
          sourceNodeRef.current = null;
        }
      };

      sourceNodeRef.current.start();
      isPlayingRef.current = true;
      onPlayingChange?.(true);
    } catch (error) {
      // console.error('Error playing preview:', error);
      isPlayingRef.current = false;
      onPlayingChange?.(false);
    }
  }, [audioLoaded, getAudioContext, onPlayingChange, stopCurrentPlayback, voiceId]);

  useEffect(() => {
    currentVoiceRef.current = voiceId;
    const initPreview = async () => {
      stopCurrentPlayback(true);
      const success = await loadAudio();
      if (success && currentVoiceRef.current === voiceId) {
        await playPreview();
      }
    };
    
    initPreview();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      stopCurrentPlayback(true);
    };
  }, [voiceId, loadAudio, playPreview, stopCurrentPlayback]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      stopCurrentPlayback(true);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [stopCurrentPlayback]);

  return null;
};

export default VoicePreviewPlayer;
