import React, { useState, useRef } from 'react';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';
import { styled } from '@mui/material/styles';

const PlayerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '8px 16px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.05)' 
    : 'rgba(0, 0, 0, 0.05)',
  width: 'fit-content',
  minWidth: '200px',
}));

const TimeDisplay = styled(Typography)({
  fontSize: '0.75rem',
  minWidth: '40px',
  textAlign: 'center',
});

const AudioPlayer = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const handleVolumeChange = (event, newValue) => {
    const volumeValue = newValue / 100;
    setVolume(volumeValue);
    audioRef.current.volume = volumeValue;
    setIsMuted(volumeValue === 0);
  };

  const handleSliderChange = (event, newValue) => {
    const time = (newValue / 100) * duration;
    setCurrentTime(time);
    audioRef.current.currentTime = time;
  };

  const toggleMute = () => {
    if (isMuted) {
      audioRef.current.volume = volume;
      setIsMuted(false);
    } else {
      audioRef.current.volume = 0;
      setIsMuted(true);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <PlayerContainer>
      <audio
        ref={audioRef}
        src={audioUrl}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={handleEnded}
      />
      
      <IconButton onClick={handlePlayPause} size="small">
        {isPlaying ? <Pause size={20} /> : <Play size={20} />}
      </IconButton>

      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
        <TimeDisplay>{formatTime(currentTime)}</TimeDisplay>
        <Slider
          size="small"
          value={(currentTime / duration) * 100 || 0}
          onChange={handleSliderChange}
          sx={{ mx: 1 }}
        />
        <TimeDisplay>{formatTime(duration)}</TimeDisplay>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: '100px' }}>
        <IconButton onClick={toggleMute} size="small">
          {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
        </IconButton>
        <Slider
          size="small"
          value={isMuted ? 0 : volume * 100}
          onChange={handleVolumeChange}
          sx={{ width: '60px' }}
        />
      </Box>
    </PlayerContainer>
  );
};

export default AudioPlayer;