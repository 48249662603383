import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { X, FileText, Download } from 'lucide-react';
import { styled } from '@mui/material/styles';

/** Obtiene la extensión del filename (minusculizada) */
function getFileExtension(filename = '') {
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop().toLowerCase() : '';
}
/** Retorna true si la extensión es .doc / .docx / .xls / .xlsx */
function isOfficeExtension(ext) {
  return ['doc', 'docx', 'xls', 'xlsx'].includes(ext);
}
/** Retorna true si la extensión es de imagen */
function isImageExtension(ext) {
  return ['png','jpg','jpeg','gif','webp'].includes(ext);
}

// === Estilos ===
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: theme.spacing(2),
    borderRadius: '12px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      maxHeight: '100%',
      borderRadius: 0,
      height: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
      maxWidth: '800px',
      maxHeight: '80vh'
    }
  }
}));

const DialogHeader = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingRight: '48px',
  fontSize: '1.1rem',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
    fontSize: '1rem'
  }
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '8px',
  top: '8px',
  color: 'inherit',
  [theme.breakpoints.down('sm')]: {
    right: '4px',
    top: '4px'
  }
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    gap: '12px'
  }
}));

/** Caja azul para PDF/Office */
const BluePreviewBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '8px',
  backgroundColor: '#0385FF',
  color: '#ffffff',
  padding: '32px',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    height: '400px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '300px',
    padding: '24px',
    gap: '12px'
  }
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  margin: theme.spacing(0.5)
}));

const ActionButtons = styled(Box)({
  display: 'flex',
  gap: '8px',
  marginTop: 'auto'
});

const ImagePreview = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '& img': {
    maxWidth: '100%',
    maxHeight: '60vh',
    objectFit: 'contain',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '70vh'
    }
  }
}));

// === COMPONENTE PRINCIPAL ===
const FilePreviewModal = ({ open, onClose, file }) => {
  const [previewError, setPreviewError] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Detectamos extensión
  const extension = getFileExtension(file?.name || '');
  // Vemos si es PDF, Office o imagen
  const isPDF = (extension === 'pdf');
  const isOffice = isOfficeExtension(extension);
  const isImg = isImageExtension(extension);

  const handlePreviewError = () => {
    setPreviewError(true);
  };

  const handleDownload = () => {
    if (!file?.data) return;
    const link = document.createElement('a');
    link.href = `data:${file.type};base64,${file.data}`;
    link.download = file.name;
    link.click();
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
    >
      <DialogHeader>
        {file?.name}
        <CloseButton onClick={onClose}>
          <X size={isMobile ? 24 : 20} />
        </CloseButton>
      </DialogHeader>

      <DialogContent>
        <PreviewContainer>
          { (isPDF || isOffice) ? (
            /* PDF u Office -> Mismo recuadro azul */
            <BluePreviewBox>
              <FileText size={isMobile ? 48 : 64} />
              <Typography
                variant={isMobile ? 'subtitle1' : 'h6'}
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  wordBreak: 'break-word',
                }}
              >
                {file?.name}
              </Typography>

              {isOffice && (
                <Typography variant="body2" sx={{ color: '#fff', textAlign: 'center' }}>
                  No preview available for Word/Excel, please download the file.
                </Typography>
              )}

              <ActionButtons>
                <ActionButton onClick={handleDownload} title="Download">
                  <Download size={20} />
                </ActionButton>
              </ActionButtons>
            </BluePreviewBox>
          ) : isImg ? (
            /* Imagen */
            <ImagePreview>
              <Box
                component="img"
                src={`data:${file.type};base64,${file.data}`}
                alt={file.name}
                onError={handlePreviewError}
                sx={{
                  display: previewError ? 'none' : 'block'
                }}
              />
              <ActionButtons
                sx={{
                  position: 'absolute',
                  bottom: '16px',
                  right: '16px',
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  borderRadius: '8px',
                  padding: '4px'
                }}
              >
                <ActionButton onClick={handleDownload} title="Download">
                  <Download size={20} />
                </ActionButton>
              </ActionButtons>
            </ImagePreview>
          ) : (
            /* Otros archivos (txt, csv, etc.) -> fallback con recuadro azul */
            <BluePreviewBox>
              <FileText size={isMobile ? 48 : 64} />
              <Typography
                variant={isMobile ? 'subtitle1' : 'h6'}
                sx={{
                  color: 'white',
                  textAlign: 'center',
                  wordBreak: 'break-word',
                }}
              >
                {file?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: '#fff', textAlign: 'center' }}>
                No preview available. Please download the file.
              </Typography>
              <ActionButtons>
                <ActionButton onClick={handleDownload} title="Download">
                  <Download size={20} />
                </ActionButton>
              </ActionButtons>
            </BluePreviewBox>
          )}

          {previewError && (
            <Typography color="error">
              Error loading preview
            </Typography>
          )}
        </PreviewContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default FilePreviewModal;
