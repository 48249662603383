import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { 
  Box, 
  Typography, 
  IconButton, 
  Tooltip, 
  useMediaQuery, 
  Button,
  Tabs,
  Tab
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { 
  ChevronLeft,
  ChevronRight,
  RefreshCcw,
  Download,
  Plus
} from 'lucide-react';

import BalanceOverview from './AGTCashFlow-products/BalanceOverview';
import RecentTransactions from './AGTCashFlow-products/RecentTransactions';
import UpcomingPayments from './AGTCashFlow-products/UpcomingPayments';
import ExpenseCategories from './AGTCashFlow-products/ExpenseCategories';
import QuickUpload from './AGTCashFlow-products/QuickUpload';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(40, 40, 42, 0.94)'
    : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(118, 118, 128, 0.24)'
    : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow: theme.palette.mode === 'dark'
    ? 'none'
    : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.7)'
    : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  }
}));

const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  }
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background: theme.palette.mode === 'dark' 
      ? '#1C1C1E'
      : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden',
  }
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: '100%',
  backgroundColor: 'transparent'
}));

const AGTCashFlow = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState(0);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);

  const navigationTabs = [
    { id: 0, label: 'Balance', disabled: false },
    { id: 1, label: 'Recent', disabled: false },
    { id: 2, label: 'Upcoming', disabled: false },
    { id: 3, label: 'Expenses', disabled: false },
    { id: 4, label: 'Upload', disabled: false }
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView !== isMobile) {
        setVisibleRange({ start: 0, end: 3 });
      }
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleRange({ start: 0, end: navigationTabs.length });
      return;
    }

    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return;

    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
    } else if (activeIdx >= navigationTabs.length - 3) {
      setVisibleRange({ 
        start: navigationTabs.length - 3, 
        end: navigationTabs.length 
      });
    } else {
      setVisibleRange({
        start: activeIdx - 1,
        end: activeIdx + 2
      });
    }
  }, [activeTab, navigationTabs.length, isMobile]);

  const getIndicatorStyle = useCallback(() => {
    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return {};
    
    const visibleIdx = activeIdx - visibleRange.start;
    const offset = visibleIdx * (74 + 4); // width + gap
    return {
      transform: `translateX(${offset}px)`,
    };
  }, [activeTab, visibleRange.start, navigationTabs]);

  const handleTabChange = useCallback((event, newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      const tab = navigationTabs.find(tab => tab.id === newValue);
      
      if (tab?.disabled) {
        return;
      }

      lastNavigationTime.current = now;
      setActiveTab(newValue);
    }
  }, [navigationTabs]);

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <BalanceOverview />;
      case 1:
        return <RecentTransactions />;
      case 2:
        return <UpcomingPayments />;
      case 3:
        return <ExpenseCategories />;
      case 4:
        return <QuickUpload />;
      default:
        return null;
    }
  };

  return (
    <Box className="space-y-4">
      <Box className="flex justify-between items-center">
        <NavigationContainer>
          <Box>
            {isMobile && activeTab !== 0 && (
              <NavigationButton
                onClick={() => handleTabChange(null, 0)}
                aria-label="Go back"
              >
                <ChevronLeft size={16} strokeWidth={2.5} />
              </NavigationButton>
            )}

            <StyledTabs
              ref={tabsRef}
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              aria-label="cash flow navigation tabs"
            >
              <TabIndicator style={getIndicatorStyle()} />
              {navigationTabs
                .slice(visibleRange.start, visibleRange.end)
                .map((tab) => (
                  <StyledTab 
                    key={tab.id}
                    label={tab.label}
                    disabled={tab.disabled}
                    disableRipple
                    value={tab.id}
                  />
                ))}
            </StyledTabs>

            {isMobile && visibleRange.end < navigationTabs.length && (
              <NavigationButton
                onClick={() => {
                  const nextTab = navigationTabs[visibleRange.end];
                  handleTabChange(null, nextTab.id);
                }}
                aria-label="Next section"
              >
                <ChevronRight size={16} strokeWidth={2.5} />
              </NavigationButton>
            )}
          </Box>
        </NavigationContainer>

        <Box className="flex gap-2">
          <Tooltip title="Refresh Data">
            <IconButton>
              <RefreshCcw size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Report">
            <IconButton>
              <Download size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Transaction">
            <IconButton color="primary">
              <Plus size={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {renderContent()}
    </Box>
  );
};

export default AGTCashFlow;