import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { 
  useMediaQuery, 
  useTheme, 
  Menu, 
  MenuItem, 
  Box,
  Typography,
  ClickAwayListener 
} from '@mui/material';
import { 
  ArrowUp, 
  MapPin, 
  Sun, 
  Search, 
  Video, 
  GraduationCap, 
  DollarSign, 
  X, 
  Clock, 
  Coffee, 
  Book, 
  Music, 
  ShoppingCart, 
  Briefcase, 
  Utensils, 
  Camera, 
  Plane, 
  Car, 
  Smartphone, 
  Heart, 
  Zap, 
  Umbrella, 
  Calendar, 
  Users, 
  Scissors, 
  Palette, 
  Gift, 
  HelpCircle 
} from 'lucide-react';
import debounce from 'lodash/debounce';
import { getSuggestions, TrendingSuggestions } from '../../data/suggestions';
import {
  CenteredContainer,
  ContentWrapper,
  TypedText,
  StyledForm,
  StyledTextField,
  SendButton,
  ClearButton,
  SectionsContainer,
  Section,
  CardsRow,
  CustomCard,
  CustomCardContent,
  StyledFooter,
  LinksContainer,
  FooterLink,
  HelpButton,
  WaitingMessage,
  LoadingIndicator,
  InputContainer
} from './HomeInputStyles';

const BRAND_COLOR = '#007BFE';

const AGT_CARDS = [
  { Icon: GraduationCap, text: "AGT Edu", color: "#4B5563" },
  { Icon: DollarSign, text: "AGT Ups", color: "#10B981" }
];

const PROMPT_CARDS = [
  // These work without additional context:
  { Icon: Video, text: 'Top 10 viral videos today', color: BRAND_COLOR },
  { Icon: Music, text: 'Global music hit charts', color: BRAND_COLOR },
  { Icon: Search, text: 'Top headlines right now', color: BRAND_COLOR },
  { Icon: Calendar, text: 'Featured events this week', color: BRAND_COLOR },
  { Icon: Zap, text: 'Work productivity rankings', color: BRAND_COLOR },
  { Icon: Book, text: 'Best-selling books of the month', color: BRAND_COLOR },
  { Icon: ShoppingCart, text: 'E-commerce trends', color: BRAND_COLOR },
  { Icon: Users, text: 'Most active online communities', color: BRAND_COLOR },
  { Icon: Briefcase, text: 'Sectors with highest job demand', color: BRAND_COLOR },
  { Icon: Palette, text: 'Current design trends', color: BRAND_COLOR },
  { Icon: Heart, text: 'Popular conversation topics', color: BRAND_COLOR },
  { Icon: Gift, text: 'Most searched products online', color: BRAND_COLOR },
  { Icon: Smartphone, text: 'Trending apps right now', color: BRAND_COLOR }
];

const WAITING_PHASES = [
  ["Analyzing input data", "Processing information", "Gathering relevant parameters"],
  ["Identifying key variables", "Establishing causal relationships", "Mapping data structures"],
  ["Formulating hypotheses", "Predicting outcomes", "Simulating scenarios"],
  ["Checking logical consistency", "Evaluating hypotheses", "Validating models"],
  ["Integrating new information", "Finalizing conclusions", "Preparing comprehensive response"]
];

const FooterLinks = React.memo(() => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (isMobile) return null;

  return (
    <StyledFooter>
      <LinksContainer>
        <FooterLink href="https://directiveai.org/agt-pro" target="_blank" rel="noopener">
          Pro
        </FooterLink>
        <FooterLink href="https://directiveai.org/edu" target="_blank" rel="noopener">
          Edu
        </FooterLink>
        <FooterLink href="https://directiveai.org/ups-overview" target="_blank" rel="noopener">
          Ups
        </FooterLink>
        <FooterLink href="https://directiveai.org/about" target="_blank" rel="noopener">
          DirectiveAI
        </FooterLink>
        <FooterLink href="https://directiveai.org/integration-guide" target="_blank" rel="noopener">
          Simplicity
        </FooterLink>
        <FooterLink href="https://omverse.ai/" target="_blank" rel="noopener">
          Omverse
        </FooterLink>
      </LinksContainer>
      <HelpMenu />
    </StyledFooter>
  );
});

const HelpMenu = React.memo(() => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (isMobile) return null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const helpLinks = [
    { text: 'Help & FAQ', url: 'https://directiveai.org/knowledge-base' },
    { text: 'Integration Guide', url: 'https://directiveai.org/docs' },
    { text: 'Support', url: 'https://directiveai.org/support' },
  ];

  return (
    <>
      <HelpButton
        aria-label="help"
        onClick={handleClick}
      >
        <HelpCircle />
      </HelpButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {helpLinks.map((link) => (
          <MenuItem
            key={link.text}
            onClick={() => {
              window.open(link.url, '_blank');
              handleClose();
            }}
          >
            {link.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

const InputField = React.memo(({ 
  state, 
  isWaiting,
  inputRef,
  onSubmit,
  onStateUpdate,
  onSuggestionsUpdate 
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleInputChange = useCallback((event, newValue) => {
    onStateUpdate({ input: newValue || '' });
    onSuggestionsUpdate(newValue);
  }, [onStateUpdate, onSuggestionsUpdate]);

  const handleClear = useCallback(() => {
    onStateUpdate({
      input: '',
      showTrending: true,
      showSuggestions: false,
      filteredSuggestions: []
    });
  }, [onStateUpdate]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      if (isMobile) {
        if (event.shiftKey) {
          event.preventDefault();
          onSubmit(event);
        }
      } else {
        if (!event.shiftKey) {
          event.preventDefault();
          onSubmit(event);
        }
      }
    }
  }, [isMobile, onSubmit]);

  return (
    <InputContainer>
      <StyledTextField
        inputRef={inputRef}
        placeholder="Ask AGT"
        variant="outlined"
        fullWidth
        multiline
        maxRows={10}
        disabled={isWaiting}
        value={state.input}
        onChange={(e) => handleInputChange(e, e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <>
              {state.input && !isWaiting && (
                <ClearButton
                  onClick={handleClear}
                  size="small"
                  aria-label="clear"
                >
                  <X size={20} />
                </ClearButton>
              )}
              <SendButton
                onClick={onSubmit}
                disabled={isWaiting || !state.input.trim()}
                size="small"
                aria-label="send"
              >
                {isWaiting ? (
                  <LoadingIndicator size={24} />
                ) : (
                  <ArrowUp size={24} />
                )}
              </SendButton>
            </>
          ),
        }}
      />
    </InputContainer>
  );
});

const PromptCard = React.memo(({ Icon, text, color, onClick, isMobile }) => (
  <CustomCard onClick={onClick}>
    <CustomCardContent>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 36,
          height: 36,
          borderRadius: '50%',
          background: `linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent), ${color}`,
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 4px 8px ${color}40`,
          }
        }}
      >
        <Icon size={24} color="white" />
      </Box>
      <Typography variant="body2">{text}</Typography>
    </CustomCardContent>
  </CustomCard>
));

const CardRow = React.memo(({ cardData, onCardClick, isMobile }) => (
  <CardsRow>
    {cardData.map((card, index) => (
      <PromptCard
        key={`${card.text}-${index}`}
        {...card}
        onClick={() => onCardClick(card.text)}
        isMobile={isMobile}
      />
    ))}
  </CardsRow>
));

const SectionComponent = React.memo(({ title, cards, onCardClick, isMobile }) => {
  const theme = useTheme();

  return (
    <Section>
      <Typography variant="h5" sx={{
        marginBottom: theme.spacing(1),
        fontWeight: 600,
        width: '100%',
        textAlign: 'left',
        fontSize: '21px',
        lineHeight: 1.19048,
        letterSpacing: '0.011em',
        [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(0.5),
          fontSize: '21px',
        }
      }}>
        {title}
      </Typography>
      <CardRow 
        cardData={cards}
        onCardClick={onCardClick}
        isMobile={isMobile}
      />
    </Section>
  );
});

const useWaitingState = () => {
  const [waitingMessage, setWaitingMessage] = useState('');
  const intervalRef = useRef(null);

  const startWaiting = useCallback(() => {
    let phaseIndex = 0;
    let messageIndex = 0;

    const updateMessage = () => {
      if (phaseIndex < WAITING_PHASES.length) {
        setWaitingMessage(WAITING_PHASES[phaseIndex][messageIndex]);
        messageIndex = (messageIndex + 1) % WAITING_PHASES[phaseIndex].length;
        if (messageIndex === 0) phaseIndex++;
      } else {
        stopWaiting();
      }
    };

    updateMessage();
    intervalRef.current = setInterval(updateMessage, 3000);
  }, []);

  const stopWaiting = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setWaitingMessage('');
  }, []);

  return {
    waitingMessage,
    startWaiting,
    stopWaiting,
    intervalRef
  };
};

const useInputState = (onSendMessage, isWaiting) => {
  const [state, setState] = useState({
    input: '',
    showTrending: false,
    showSuggestions: false,
    filteredSuggestions: [],
    isInitialFocus: true,
  });

  const updateState = useCallback((updates) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  const debouncedUpdateSuggestions = useMemo(
    () => debounce((value) => {
      if (value) {
        const suggestions = getSuggestions(value);
        updateState({
          filteredSuggestions: suggestions,
          showSuggestions: true,
          showTrending: false,
        });
      } else {
        updateState({
          filteredSuggestions: [],
          showSuggestions: false,
          showTrending: true,
        });
      }
    }, 200),
    [updateState]
  );

  const handleSubmit = useCallback(async (e) => {
    e?.preventDefault();
    if (state.input.trim() && !isWaiting) {
      await onSendMessage(state.input);
      updateState({
        input: '',
        showTrending: false,
        showSuggestions: false,
      });
    }
  }, [state.input, isWaiting, onSendMessage, updateState]);

  useEffect(() => {
    return () => {
      debouncedUpdateSuggestions.cancel();
    };
  }, [debouncedUpdateSuggestions]);

  return {
    state,
    updateState,
    debouncedUpdateSuggestions,
    handleSubmit,
  };
};

const HomeInput = ({ onSendMessage, onOpenAGTPromo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isWaiting, setIsWaiting] = useState(false);
  const inputRef = useRef(null);

  const { waitingMessage, startWaiting, stopWaiting, intervalRef } = useWaitingState();
  const { state, updateState, debouncedUpdateSuggestions, handleSubmit } = useInputState(onSendMessageWrapped, isWaiting);

  /**
   * Este wrapper se encarga de inyectar el `source: 'AGTSearch'` al mensaje
   * que se envía a `onSendMessage`. Así se simula el comportamiento de AGTSearch.
   */
  async function onSendMessageWrapped(userText) {
    // El usuario escribió `userText`, lo enviamos marcando 'source: AGTSearch'
    await onSendMessage({ text: userText, source: 'AGTSearch' });
  }

  const randomPromptCards = useMemo(() => 
    [...PROMPT_CARDS].sort(() => 0.5 - Math.random()).slice(0, 2),
    []
  );

  const handleCardClick = useCallback((text) => {
    if (text === "AGT Ups" || text === "AGT Edu") {
      onOpenAGTPromo();
    } else {
      updateState({ 
        input: text, 
        showTrending: false, 
        showSuggestions: false 
      });
    }
  }, [onOpenAGTPromo, updateState]);

  const handleMessageSubmit = useCallback(async (e) => {
    e?.preventDefault();
    if (state.input.trim() && !isWaiting) {
      setIsWaiting(true);
      startWaiting();
      try {
        await handleSubmit(e);
      } finally {
        setIsWaiting(false);
        stopWaiting();
      }
    }
  }, [state.input, isWaiting, handleSubmit, startWaiting, stopWaiting]);

  const handleClickAway = useCallback(() => {
    updateState({ 
      showTrending: false, 
      showSuggestions: false 
    });
  }, [updateState]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [intervalRef]);

  const MemoizedTrendingSuggestions = useMemo(() => React.memo(TrendingSuggestions), []);

  return (
    <CenteredContainer>
      <ContentWrapper>
        <TypedText 
          variant="h2" 
          gutterBottom 
          align="center" 
        >
          Simplicity meets power.
        </TypedText>

        <ClickAwayListener onClickAway={handleClickAway}>
          <Box width="100%" position="relative">
            <StyledForm onSubmit={handleMessageSubmit}>
              <InputField
                state={state}
                isWaiting={isWaiting}
                inputRef={inputRef}
                onSubmit={handleMessageSubmit}
                onStateUpdate={updateState}
                onSuggestionsUpdate={debouncedUpdateSuggestions}
              />
            </StyledForm>

            {state.showTrending && !state.input && !isWaiting && (
              <Box position="absolute" width="100%" zIndex={1}>
                <MemoizedTrendingSuggestions onSuggestionClick={handleCardClick} />
              </Box>
            )}

            {isWaiting && (
              <WaitingMessage>
                {waitingMessage}...
              </WaitingMessage>
            )}
          </Box>
        </ClickAwayListener>

        {!isWaiting && (
          <SectionsContainer>
            <SectionComponent
              title="Explore"
              cards={AGT_CARDS}
              onCardClick={handleCardClick}
              isMobile={isMobile}
            />
            <SectionComponent
              title="Ask"
              cards={randomPromptCards}
              onCardClick={handleCardClick}
              isMobile={isMobile}
            />
          </SectionsContainer>
        )}
      </ContentWrapper>
      <FooterLinks />
    </CenteredContainer>
  );
};

HomeInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  onOpenAGTPromo: PropTypes.func.isRequired,
};

InputField.propTypes = {
  state: PropTypes.shape({
    input: PropTypes.string.isRequired,
    showTrending: PropTypes.bool.isRequired,
    showSuggestions: PropTypes.bool.isRequired,
    filteredSuggestions: PropTypes.array.isRequired,
    isInitialFocus: PropTypes.bool.isRequired,
  }).isRequired,
  isWaiting: PropTypes.bool.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStateUpdate: PropTypes.func.isRequired,
  onSuggestionsUpdate: PropTypes.func.isRequired,
};

PromptCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool
};

CardRow.propTypes = {
  cardData: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.elementType.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ).isRequired,
  onCardClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};

SectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  onCardClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

FooterLinks.propTypes = {};
HelpMenu.propTypes = {};

export default React.memo(HomeInput);
