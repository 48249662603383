// ProductSelector.jsx
import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Package,
  RefreshCw,
  Search
} from 'lucide-react';

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  cursor: 'pointer',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? isSelected ? 'rgba(255, 255, 255, 0.1)' : 'rgba(40, 40, 42, 0.94)'
    : isSelected ? 'rgba(3, 133, 255, 0.1)' : 'rgba(242, 242, 247, 1)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.15)'
      : 'rgba(3, 133, 255, 0.15)',
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(40, 40, 42, 0.94)'
    : 'rgba(242, 242, 247, 1)',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

const ProductSelector = ({
  products = [],
  selectedProduct,
  onProductSelect,
  loading = false,
  onRefresh,
  compact = false, // Modo compacto para usar en headers
  className
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleProductSelect = useCallback((product) => {
    if (onProductSelect) {
      onProductSelect(product);
    }
  }, [onProductSelect]);

  if (compact) {
    return (
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          maxWidth: '300px'
        }}
        className={className}
      >
        <FormControl fullWidth size="small">
          <InputLabel>Selected Product</InputLabel>
          <StyledSelect
            value={selectedProduct?.id || ''}
            onChange={(e) => {
              const product = products.find(p => p.id === e.target.value);
              handleProductSelect(product);
            }}
            label="Selected Product"
          >
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {onRefresh && (
          <Tooltip title="Refresh products list">
            <IconButton 
              onClick={onRefresh}
              disabled={loading}
              size="small"
            >
              <RefreshCw 
                size={20}
                className={loading ? 'animate-spin' : ''}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }

  return (
    <Box className={className}>
      {/* Header con búsqueda y refresh */}
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Select a Product
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '8px',
              padding: '4px 12px'
            }}
          >
            <Search size={20} />
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                border: 'none',
                background: 'none',
                outline: 'none',
                color: 'inherit'
              }}
            />
          </Box>
          {onRefresh && (
            <Tooltip title="Refresh products list">
              <IconButton onClick={onRefresh} disabled={loading}>
                <RefreshCw 
                  size={20}
                  className={loading ? 'animate-spin' : ''}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {/* Grid de productos */}
      {loading ? (
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px'
          }}
        >
          <CircularProgress />
        </Box>
      ) : filteredProducts.length === 0 ? (
        <Box 
          sx={{ 
            textAlign: 'center',
            py: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '12px'
          }}
        >
          <Package size={40} style={{ opacity: 0.5, marginBottom: '16px' }} />
          <Typography>
            {searchTerm
              ? 'No products match your search'
              : 'No products available'}
          </Typography>
        </Box>
      ) : (
        <Box 
          sx={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: 2
          }}
        >
          {filteredProducts.map((product) => (
            <StyledCard
              key={product.id}
              isSelected={selectedProduct?.id === product.id}
              onClick={() => handleProductSelect(product)}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(3, 133, 255, 0.1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Package size={20} color="#0385FF" />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {product.name}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        opacity: 0.7,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {product.description || 'No description'}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </StyledCard>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ProductSelector;