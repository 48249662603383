import React, { useState, useCallback, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  IconButton, 
  Breadcrumbs, 
  Link, 
  Fade,
  TextField,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Tooltip,
  CircularProgress,
  Alert,
  Snackbar,
  AvatarGroup,
  Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  ChevronLeft,
  Users,
  GraduationCap,
  TrendingUp,
  Settings,
  Bell,
  Search,
  Star,
  LayoutDashboard,
  AlertCircle,
  FileText,
  Filter,
  BookOpen,
  MessageSquare,
  Calendar,
  Clock,
  BookMarked,
  Target,
  PenTool,
  Coffee,
  UserPlus
} from 'lucide-react';

import { Chip } from '@mui/material';

// Importar componentes de productos
import DirectiveAI from './products/DirectiveAI';
import AGTAcademic from './products/AGTAcademic';
import AGTGrowth from './products/AGTGrowth';

// Styled Components
const AppContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  backgroundColor: theme.palette.background.default,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: theme.spacing(8),
  transition: 'all 0.3s ease',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    padding: theme.spacing(2),
  },
}));

const SideNav = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  width: theme.spacing(8),
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  zIndex: 1200,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const ModuleContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
  maxWidth: '1400px',
  margin: '0 auto',
}));

const ModuleCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const SearchBar = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(2),
  },
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
}));

const IconContainer = styled(Box)(({ theme, color }) => ({
  backgroundColor: color,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  color: 'white',
  display: 'inline-flex',
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(1),
  border: 'none',
  background: theme.palette.primary.main,
  color: 'white',
  fontWeight: 500,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const NotificationBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}));

const QuickStatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const ActiveSessionCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.primary.light}`,
  backgroundColor: theme.palette.primary.lighter,
  marginBottom: theme.spacing(2),
}));

// Datos de productos
const products = [
  {
    id: 'directive-ai',
    title: 'DirectiveAI Mode',
    description: 'Real-time group chats with seamless collaboration and AI assistance',
    color: '#4f46e5',
    icon: MessageSquare,
    component: DirectiveAI,
    notifications: 3,
  },
  {
    id: 'academic',
    title: 'AGT Academic',
    description: 'Advanced research tools, citation generation, and project planning',
    color: '#0891b2',
    icon: GraduationCap,
    component: AGTAcademic,
    notifications: 2,
  },
  {
    id: 'growth',
    title: 'AGT Growth',
    description: 'Tailored learning strategies for every educational level',
    color: '#059669',
    icon: TrendingUp,
    component: AGTGrowth,
    notifications: 1,
  },
];

// Dashboard Component
const Dashboard = ({ stats, activeSessions }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>Learning Dashboard</Typography>
      </Grid>
      
      {/* Quick Stats */}
      {stats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <QuickStatsCard>
            <Box className="flex items-center gap-3">
              <IconContainer color={stat.color}>
                <stat.icon size={24} />
              </IconContainer>
              <Box>
                <Typography variant="h6">{stat.value}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {stat.label}
                </Typography>
              </Box>
            </Box>
          </QuickStatsCard>
        </Grid>
      ))}

      {/* Active Sessions */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Active Learning Sessions</Typography>
        {activeSessions.map((session, index) => (
          <ActiveSessionCard key={index}>
            <Box className="flex justify-between items-center mb-2">
              <Typography variant="subtitle1">{session.title}</Typography>
              <Chip 
                label={session.participants.length + " participants"} 
                size="small" 
                color="primary" 
              />
            </Box>
            <Box className="flex justify-between items-center">
              <Box className="flex items-center gap-2">
                <Clock size={16} />
                <Typography variant="body2" color="text.secondary">
                  {session.duration}
                </Typography>
              </Box>
              <AvatarGroup max={4}>
                {session.participants.map((participant, idx) => (
                  <Avatar key={idx} alt={participant.name}>{participant.initials}</Avatar>
                ))}
              </AvatarGroup>
            </Box>
            <Box className="mt-2">
              <ActionButton>
                Join Session
              </ActionButton>
            </Box>
          </ActiveSessionCard>
        ))}
      </Grid>
    </Grid>
  );
};

// Main Component
const AGTEdu = () => {
  // Estados
  const [activeProduct, setActiveProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState(['AGT Edu']);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [favorites, setFavorites] = useState([]);
  const [showDashboard, setShowDashboard] = useState(true);

  // Datos de ejemplo
  const stats = [
    { 
      label: 'Active Courses', 
      value: '8', 
      color: '#4f46e5',
      icon: BookOpen 
    },
    { 
      label: 'Study Hours', 
      value: '32.5h', 
      color: '#059669',
      icon: Clock 
    },
    { 
      label: 'Assignments', 
      value: '12', 
      color: '#0891b2',
      icon: FileText 
    },
    { 
      label: 'Progress', 
      value: '85%', 
      color: '#db2777',
      icon: Target 
    },
  ];

  const activeSessions = [
    {
      title: "Advanced Mathematics Study Group",
      duration: "1h 30m remaining",
      participants: [
        { name: "John Doe", initials: "JD" },
        { name: "Maria Smith", initials: "MS" },
        { name: "Robert Johnson", initials: "RJ" },
        { name: "Alice Brown", initials: "AB" },
      ]
    },
    {
      title: "Physics Research Discussion",
      duration: "45m remaining",
      participants: [
        { name: "Thomas Wilson", initials: "TW" },
        { name: "Sarah Davis", initials: "SD" },
        { name: "Michael Lee", initials: "ML" },
      ]
    }
  ];

  // Efectos
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Handlers
  const handleProductClick = useCallback((productId) => {
    setActiveProduct(productId);
    setShowDashboard(false);
    setBreadcrumbs(['AGT Edu', products.find(p => p.id === productId).title]);
  }, []);

  const handleBack = useCallback(() => {
    setActiveProduct(null);
    setShowDashboard(true);
    setBreadcrumbs(['AGT Edu']);
  }, []);

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const toggleFavorite = useCallback((productId) => {
    setFavorites(prev => {
      const newFavorites = prev.includes(productId)
        ? prev.filter(id => id !== productId)
        : [...prev, productId];
      
      setSnackbarMessage(
        prev.includes(productId)
          ? 'Removed from favorites'
          : 'Added to favorites'
      );
      setShowSnackbar(true);
      
      return newFavorites;
    });
  }, []);

  const filteredProducts = products.filter(product =>
    product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Render loading state
  if (isLoading) {
    return (
      <Box className="h-screen flex items-center justify-center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppContainer>
      {/* Side Navigation */}
      <SideNav>
        <Tooltip title="Dashboard" placement="right">
          <IconButton onClick={handleBack}>
            <LayoutDashboard />
          </IconButton>
        </Tooltip>
        <Box className="flex-grow" />
        <Tooltip title="Study Break" placement="right">
          <IconButton>
            <Coffee />
          </IconButton>
        </Tooltip>
        <Tooltip title="Find Study Partners" placement="right">
          <IconButton>
            <UserPlus />
          </IconButton>
        </Tooltip>
        <Tooltip title="Notifications" placement="right">
          <IconButton onClick={() => setNotificationsOpen(true)}>
            <NotificationBadge badgeContent={6}>
              <Bell />
            </NotificationBadge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Settings" placement="right">
          <IconButton onClick={() => setSettingsOpen(true)}>
            <Settings />
          </IconButton>
        </Tooltip>
      </SideNav>

      {/* Main Content */}
      <ContentContainer>
        <TopBar>
          <Box>
            <Breadcrumbs>
              {breadcrumbs.map((crumb, index) => (
                <Link
                  key={index}
                  color="inherit"
                  onClick={index === 0 ? handleBack : undefined}
                  style={{ cursor: index === 0 ? 'pointer' : 'default' }}
                >
                  {crumb}
                </Link>
              ))}
            </Breadcrumbs>
            <Typography variant="h4" className="mt-2">
              {activeProduct ? products.find(p => p.id === activeProduct).title : 'AGT Edu Suite'}
            </Typography>
          </Box>
          {activeProduct && (
            <Box className="flex gap-2">
              <IconButton onClick={() => toggleFavorite(activeProduct)}>
                <Star 
                  fill={favorites.includes(activeProduct) ? 'currentColor' : 'none'}
                />
              </IconButton>
              <IconButton onClick={handleBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
          )}
        </TopBar>

        <Fade in={true} timeout={300}>
          <Box>
            {!activeProduct ? (
              <Box>
                <SearchBar
                  fullWidth
                  variant="outlined"
                  placeholder="Search educational tools..."
                  value={searchQuery}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: <Search size={20} className="mr-2 text-gray-400" />,
                  }}
                />

                {showDashboard && (
                  <Dashboard 
                    stats={stats}
                    activeSessions={activeSessions}
                  />
                )}

                <ModuleContainer>
                  {filteredProducts.map((product) => (
                    <ModuleCard
                      key={product.id}
                      onClick={() => handleProductClick(product.id)}
                    >
                      <Box className="flex justify-between items-start">
                        <IconContainer color={product.color}>
                          <product.icon size={24} />
                        </IconContainer>
                        {product.notifications > 0 && (
                          <Badge badgeContent={product.notifications} color="error" />
                        )}
                      </Box>
                      <Box className="flex justify-between items-center mb-2">
                        <Typography variant="h6">{product.title}</Typography>
                        <IconButton 
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFavorite(product.id);
                          }}
                        >
                          <Star 
                            size={18}
                            fill={favorites.includes(product.id) ? 'currentColor' : 'none'}
                          />
                        </IconButton>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {product.description}
                      </Typography>
                    </ModuleCard>
                  ))}
                </ModuleContainer>
              </Box>
            ) : (
              <Box>
                {(() => {
                  const ProductComponent = products.find(p => p.id === activeProduct).component;
                  return <ProductComponent />;
                })()}
              </Box>
            )}
          </Box>
        </Fade>
      </ContentContainer>

      {/* Notifications Drawer */}
      <Drawer
        anchor="right"
        open={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
      >
        <Box sx={{ width: 300, p: 2 }}>
          <Typography variant="h6" gutterBottom>Notifications</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <BookOpen size={20} className="text-blue-500" />
              </ListItemIcon>
              <ListItemText 
                primary="New study session invitation"
                secondary="Advanced Mathematics - 5 minutes ago"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <PenTool size={20} className="text-green-500" />
              </ListItemIcon>
              <ListItemText 
                primary="Assignment due soon"
                secondary="Physics Report - Due in 2 hours"
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <Target size={20} className="text-purple-500" />
              </ListItemIcon>
              <ListItemText 
                primary="Learning milestone achieved"
                secondary="Completed Advanced SQL Course"
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Settings Drawer */}
      <Drawer
        anchor="right"
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      >
        <Box sx={{ width: 300, p: 2 }}>
          <Typography variant="h6" gutterBottom>Learning Settings</Typography>
          <List>
            <ListItemButton>
              <ListItemIcon>
                <Calendar size={20} />
              </ListItemIcon>
              <ListItemText 
                primary="Study Schedule"
                secondary="Manage your study times"
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <BookMarked size={20} />
              </ListItemIcon>
              <ListItemText 
                primary="Learning Preferences"
                secondary="Customize your learning style"
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <Bell size={20} />
              </ListItemIcon>
              <ListItemText 
                primary="Notifications"
                secondary="Set reminder preferences"
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <Users size={20} />
              </ListItemIcon>
              <ListItemText 
                primary="Study Groups"
                secondary="Manage group settings"
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <FileText size={20} />
              </ListItemIcon>
              <ListItemText 
                primary="Progress Reports"
                secondary="Configure report settings"
              />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowSnackbar(false)} 
          severity="success" 
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </AppContainer>
  );
};

export default AGTEdu;