import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  CircularProgress,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowLeft, MoreHorizontal, Plus, Shapes } from 'lucide-react';
import { getProjectChats, deleteChat, createNewChat } from '../../../services/AGTService';
import DeleteIcon from '@mui/icons-material/Delete';
import { useProjectContext } from '../ProjectContextProvider';

const CategoryChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '20px',
  padding: '0 8px',
  borderRadius: '10px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(3, 133, 255, 0.1)'
      : 'rgba(3, 133, 255, 0.08)',
  color: '#0385FF',
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  marginLeft: theme.spacing(1),
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: '#0385FF',
  color: '#FFFFFF',
  borderRadius: '20px',
  textTransform: 'none',
  padding: '6px 16px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#0371db',
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: 6,
  },
}));

const ChatsView = ({ project, onBack, user, onChatSelect }) => {
  const theme = useTheme();
  const { 
    projectChats,
    updateProjectChats,
    addProjectChat,
    removeProjectChat
  } = useProjectContext();

  const [selectedChat, setSelectedChat] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuChatId, setMenuChatId] = useState(null);

  // Cargar chats al montar
  useEffect(() => {
    if (!project?.id || !user?.uid) return;

    setIsLoading(true);
    getProjectChats(user.uid, project.id)
      .then((res) => {
        updateProjectChats(project.id, res.chats || []);
      })
      .catch((err) => {
        console.error('Error fetching project chats:', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [project?.id, user?.uid, updateProjectChats]);

  const handleCreateNewChat = async () => {
    if (!project?.id || !user?.uid) return;

    setIsLoading(true);
    try {
      const newChat = await createNewChat(
        user.uid,
        project.category,
        project.id
      );

      if (!newChat || !newChat.id) {
        throw new Error('Failed to create project chat');
      }

      // Añadir el nuevo chat al estado global
      addProjectChat(project.id, newChat);
      setSelectedChat(newChat);
      
      if (onChatSelect) {
        onChatSelect({
          id: newChat.id,
          title: newChat.title || 'New chat'
        });
      }

    } catch (error) {
      console.error('Error creating chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    if (onChatSelect) {
      onChatSelect({
        id: chat.id,
        title: chat.title
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleMenuOpen = (event, chat) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setMenuChatId(chat.id);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuChatId(null);
  };

  const handleDeleteChat = async () => {
    if (!menuChatId || !user?.uid) return;
    setIsLoading(true);
  
    try {
      await deleteChat(user.uid, menuChatId);
      
      // Eliminar chat del estado global
      removeProjectChat(project.id, menuChatId);
      handleMenuClose();

      if (selectedChat?.id === menuChatId) {
        setSelectedChat(null);
        if (onBack) onBack();
      }

    } catch (error) {
      console.error('Error deleting project chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const currentProjectChats = projectChats[project?.id] || [];

  return (
    <Box 
      sx={{ 
        width: '100%', 
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '800px',
            lg: '900px'
          },
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          py: 2,
          borderBottom: 1,
          borderColor: 'divider',
          mb: 3
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton 
              onClick={onBack} 
              size="small" 
              sx={{ 
                mr: 1,
                color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700'
              }}
            >
              <ArrowLeft />
            </IconButton>
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: '1.1rem',
                fontWeight: 500
              }}
            >
              {project?.name}
            </Typography>
          </Box>

          <StyledButton
            startIcon={<Plus size={18} />}
            onClick={handleCreateNewChat}
            disabled={isLoading}
          >
            New Chat
          </StyledButton>
        </Box>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress size={24} />
          </Box>
        ) : currentProjectChats.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Shapes size={48} color={theme.palette.text.secondary} opacity={0.5} />
            <Typography color="text.secondary">No chats found in this project</Typography>
          </Box>
        ) : (
          <List sx={{ px: 0 }}>
            {[...currentProjectChats]
              .sort((a, b) => {
                const dateA = new Date(a.updatedAt || a.createdAt);
                const dateB = new Date(b.updatedAt || b.createdAt);
                return dateB - dateA;
              })
              .map((chat) => (
              <ListItem
                key={chat.id}
                button
                sx={{
                  borderRadius: '12px',
                  mb: 1.5,
                  p: 1.5,
                  backgroundColor: chat.id === selectedChat?.id 
                    ? theme.palette.mode === 'dark' 
                      ? 'rgba(255, 255, 255, 0.05)'
                      : 'rgba(0, 0, 0, 0.03)'
                    : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, 0.08)'
                      : 'rgba(0, 0, 0, 0.05)',
                  },
                  transition: 'background-color 0.2s ease',
                }}
                onClick={() => handleChatClick(chat)}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Shapes
                    size={20}
                    color={theme.palette.mode === 'dark' ? '#fff' : '#000'}
                    opacity={chat.id === selectedChat?.id ? 1 : 0.7}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '0.95rem',
                          fontWeight: chat.id === selectedChat?.id ? 500 : 400
                        }}
                      >
                        {chat.title || 'New chat'}
                      </Typography>

                      {chat.type === 'project' && chat.category && (
                        <CategoryChip>
                          {chat.category} project
                        </CategoryChip>
                      )}
                    </Box>
                  }
                  secondary={formatDate(chat.updatedAt || chat.createdAt)}
                  secondaryTypographyProps={{
                    sx: {
                      fontSize: '0.8rem',
                      color: theme.palette.mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.5)'
                        : 'rgba(0, 0, 0, 0.5)',
                      mt: 0.5
                    }
                  }}
                />

                <IconButton
                  sx={{ 
                    ml: 'auto',
                    color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700',
                  }}
                  onClick={(e) => handleMenuOpen(e, chat)}
                  size="small"
                >
                  <MoreHorizontal size={18} />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              borderRadius: '12px',
              boxShadow: theme.palette.mode === 'dark'
                ? '0 2px 8px rgba(0, 0, 0, 0.4)'
                : '0 2px 8px rgba(0, 0, 0, 0.1)',
            }
          }}
        >
          <MenuItem
            onClick={handleDeleteChat}
            sx={{
              color: 'error.main',
              fontSize: '0.9rem',
              py: 1,
              px: 2,
              gap: 1,
            }}
          >
            <DeleteIcon fontSize="small" />
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default ChatsView;