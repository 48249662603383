import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Copy, Check } from 'lucide-react';
import Prism from 'prismjs';

// Import theme and required languages
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-tsx';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-markdown';

const CodeContainer = styled('div')({
  position: 'relative',
  width: '100%',
  margin: '16px 0',
  borderRadius: '12px',
  overflow: 'hidden',
  backgroundColor: '#1E1E1E',
  border: '1px solid #323232',
  fontFamily: "'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
});

const HeaderContainer = styled('div')({
  position: 'sticky',
  top: 0,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px',
  backgroundColor: '#2A2A2C',
  borderBottom: '1px solid #424242',
  fontFamily: "'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
});

const LanguageLabel = styled('span')({
  color: '#E4E4E4',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
});

const CopyButton = styled('button')(({ isCopied }) => ({
  padding: '6px',
  borderRadius: '6px',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease',
  backgroundColor: isCopied ? 'rgba(40, 167, 68, 0.2)' : '#424242',
  '&:hover': {
    backgroundColor: isCopied ? 'rgba(40, 167, 68, 0.25)' : '#525252',
  },
  fontFamily: "'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
}));

const CodeContent = styled('div')({
  padding: '16px',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  '& pre': {
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    fontFamily: "'SF Mono', ui-monospace, monospace",
    fontSize: '14px',
    lineHeight: '21px',
  },
  '& code': {
    display: 'inline',
    fontFamily: "'SF Mono', ui-monospace, monospace",
    fontSize: '14px',
    lineHeight: '21px',
    padding: '1.4px',
    textAlign: 'left',
    backgroundColor: 'transparent',
    color: '#E4E4E4',
    fontSmoothing: 'antialiased',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    tabSize: 2,
  },
  // Syntax highlighting styles
  '& .token': {
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: "'SF Mono', ui-monospace, monospace",
  },
  '& .token.comment': {
    color: '#7F8C98',
  },
  '& .token.string': {
    color: '#FF8170',
  },
  '& .token.keyword': {
    color: '#FF7AB2',
  },
  '& .token.function': {
    color: '#6BDFFF',
  },
  '& .token.number': {
    color: '#D9C97C',
  },
  '& .token.operator': {
    color: '#E4E4E4',
  },
  '& .token.class-name': {
    color: '#ACF2E4',
  },
  '& .token.variable': {
    color: '#78C2B3',
  },
  '& .token.property': {
    color: '#78C2B3',
  },
  '& .token.punctuation': {
    color: '#E4E4E4',
  },
  '& .token.parameter': {
    color: '#78C2B3',
  },
  '& .token.tag': {
    color: '#FF7AB2',
  },
  '& .token.attr-name': {
    color: '#78C2B3',
  },
  '& .token.attr-value': {
    color: '#FF8170',
  },
  '& .token.regex': {
    color: '#FF8170',
  },
  '& .token.boolean': {
    color: '#FF7AB2',
  },
});

const EnhancedCodeBlock = ({ code, language }) => {
  const [isCopied, setIsCopied] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const lang = language?.toLowerCase() || 'plaintext';
      const grammar = Prism.languages[lang] || Prism.languages.plaintext;
      const highlightedCode = Prism.highlight(code, grammar, lang);
      const codeElement = contentRef.current.querySelector('code');
      if (codeElement) {
        codeElement.innerHTML = highlightedCode;
      }
    }
  }, [code, language]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Error copying text:', error);
    }
  };

  const getLanguageDisplay = (lang) => {
    const languageMap = {
      js: 'JavaScript',
      jsx: 'JavaScript',
      ts: 'TypeScript',
      tsx: 'TypeScript',
      py: 'Python',
      java: 'Java',
      cpp: 'C++',
      html: 'HTML',
      css: 'CSS',
      json: 'JSON',
      yaml: 'YAML',
      sql: 'SQL',
      bash: 'Bash',
      shell: 'Shell',
      markdown: 'Markdown',
      xml: 'XML',
      plaintext: 'Plain Text',
    };
    return languageMap[lang?.toLowerCase()] || lang || 'Plain Text';
  };

  return (
    <CodeContainer ref={containerRef}>
      <HeaderContainer>
        <LanguageLabel>{getLanguageDisplay(language)}</LanguageLabel>
        <CopyButton onClick={handleCopyClick} isCopied={isCopied}>
          {isCopied ? (
            <Check size={16} style={{ color: '#28A744' }} />
          ) : (
            <Copy size={16} style={{ color: '#E4E4E4' }} />
          )}
        </CopyButton>
      </HeaderContainer>

      <CodeContent ref={contentRef}>
        <pre>
          <code className={`language-${language || 'plaintext'}`}>{code}</code>
        </pre>
      </CodeContent>
    </CodeContainer>
  );
};

export default React.memo(EnhancedCodeBlock);