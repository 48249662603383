import React from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Camera, Monitor } from 'lucide-react';
import { styled } from '@mui/system';

const SelectorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(6),
  maxWidth: '480px',
  margin: '0 auto',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2),
  }
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  justifyContent: 'center',
  marginBottom: '32px',
  [theme.breakpoints.down('sm')]: {
    gap: '12px',
    marginBottom: '24px',
  }
}));

const OptionButton = styled(Button)(({ theme }) => ({
  padding: '20px',
  width: '120px',
  height: '120px',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.1)' 
    : 'rgba(0, 0, 0, 0.05)',
  border: 'none',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.2)' 
      : 'rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)',
  },
  '& svg': {
    width: '32px',
    height: '32px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100px',
    height: '100px',
    padding: '16px',
    '& svg': {
      width: '28px',
      height: '28px',
    }
  }
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  padding: '12px 24px',
  fontSize: '0.95rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.05)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 20px',
    fontSize: '0.9rem',
  }
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: '32px',
  textAlign: 'center',
  lineHeight: 1.2,
  padding: '0 16px',
  width: '100%',
  maxWidth: '100%',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    marginBottom: '24px',
    lineHeight: 1.3,
  }
}));

const SourceSelector = ({ onSelect, onCancel }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SelectorContainer>
      <MainTitle>
        How can AGT Vision help you?
      </MainTitle>

      <ButtonsContainer>
        <OptionButton onClick={() => onSelect('camera')}>
          <Camera />
        </OptionButton>
        
        <OptionButton onClick={() => onSelect('screen')}>
          <Monitor />
        </OptionButton>
      </ButtonsContainer>

      <CancelButton onClick={onCancel}>
        Cancel
      </CancelButton>
    </SelectorContainer>
  );
};

export default SourceSelector;