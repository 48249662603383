import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Chip,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  // Reemplazamos Compare por GitCompare
  History,
  GitCompare,
  Info,
  TrendingUp,
  TrendingDown,
  AlertCircle,
  Calendar,
  Download
} from 'lucide-react';
import { format } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import MarketAnalysisService from '../../services/AGTUps-product-services/marketAnalysisService';

/* -------------------------
   1) Se copian los colores
--------------------------- */
const colors = {
  light: {
    label: { primary: '#000000' },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      white: '#FFFFFF',
      black: '#000000',
      green: '#31C859'
    },
    bg: { primaryElevated: '#FFFFFF' },
    fill: { tertiary: '#767680' },
    ui: { accent: '#007BFE', backgroundBlur: '#FFFFFF', base: '#F2F2F7' },
    grouped: { bgPrimaryBase: '#FFFFFF' }
  },
  dark: {
    label: { primary: '#FFFFFF' },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      white: '#FFFFFF',
      black: '#000000',
      green: '#2DD257'
    },
    bg: { primaryElevated: '#000000' },
    fill: { tertiary: '#767680' },
    ui: { accent: '#0385FF', backgroundBlur: '#282828', base: '#272729' },
    grouped: { bgPrimaryBase: '#000000' }
  }
};

const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const currentColors = mode === 'light' ? colors.light : colors.dark;
  return {
    background: currentColors.bg.primaryElevated,
    textDefault: currentColors.label.primary,
    textSecondary: currentColors.system.grey,
    cardBackground: currentColors.ui.base,
    borderColor: currentColors.system.grey3,
    accent: currentColors.ui.accent,
    success: currentColors.system.green,
    error: '#FF3B30',
    warning: '#FF9500',
    secondary: '#AF52DE'
  };
};

/* -------------------------
   2) Styled components
--------------------------- */
const StyledPaper = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    border: `1px solid ${navColors.borderColor}`,
    transition: 'all 0.3s ease',
    boxShadow: 'none',
    backdropFilter: 'blur(20px)',
    '&:hover': {
      borderColor: navColors.accent
    }
  };
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    '& .MuiTableCell-root': {
      borderColor: alpha(navColors.borderColor, 0.2),
      color: navColors.textDefault
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: alpha(navColors.accent, 0.05)
    }
  };
});

const ComparisonChip = styled(Chip)(({ theme, trend }) => {
  const navColors = navigationColors(theme);
  const isPositive = trend === 'up';
  
  return {
    backgroundColor: alpha(isPositive ? navColors.success : navColors.error, 0.1),
    color: isPositive ? navColors.success : navColors.error,
    border: `1px solid ${alpha(isPositive ? navColors.success : navColors.error, 0.2)}`,
    '& .MuiChip-icon': {
      color: 'inherit'
    }
  };
});

const MarketAnalysisHistory = ({ productId }) => {
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAnalyses, setSelectedAnalyses] = useState([]);
  const [comparison, setComparison] = useState(null);
  const [showComparison, setShowComparison] = useState(false);

  useEffect(() => {
    if (productId) {
      loadAnalysesHistory();
    }
  }, [productId]);

  const loadAnalysesHistory = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await MarketAnalysisService.getAnalysesHistory(productId);
      setAnalyses(response.data.analyses);
    } catch (err) {
      setError(`Error loading analyses history: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAnalysisSelect = (analysisId) => {
    setSelectedAnalyses((prev) => {
      const isSelected = prev.includes(analysisId);
      if (isSelected) {
        return prev.filter((id) => id !== analysisId);
      }
      if (prev.length >= 2) {
        return [prev[1], analysisId];
      }
      return [...prev, analysisId];
    });
  };

  const handleCompare = async () => {
    if (selectedAnalyses.length !== 2) return;
    
    try {
      setLoading(true);
      const response = await MarketAnalysisService.compareAnalyses(productId, selectedAnalyses);
      setComparison(response.data);
      setShowComparison(true);
    } catch (err) {
      setError(`Error comparing analyses: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleExportComparison = () => {
    if (!comparison) return;
    const data = {
      comparison,
      exportDate: new Date().toISOString(),
      metadata: {
        productId,
        analysisIds: selectedAnalyses
      }
    };
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json'
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `market-analysis-comparison-${selectedAnalyses.join('-')}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleExportAnalysis = async (analysis) => {
    try {
      await MarketAnalysisService.exportAnalysis(analysis);
    } catch (err) {
      setError(`Error exporting analysis: ${err.message}`);
    }
  };

  const ComparisonDialog = () => (
    <Dialog
      open={showComparison}
      onClose={() => setShowComparison(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Market Analysis Comparison
        <IconButton onClick={() => handleExportComparison()}>
          <Download size={20} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {comparison && (
          <Box sx={{ mt: 2 }}>
            {/* ... Aquí va todo el bloque de comparación (alertas, charts, grids) ... */}
            {/* Lo omitimos para el ejemplo; mantén tu lógica original */}
            {/* Solo asegúrate de no usar el import Compare, sino GitCompare */}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowComparison(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <StyledPaper>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
          <History size={24} />
          Analysis History
        </Typography>
        <Alert severity="info" icon={<Info />}>
          Select two analyses to compare their results and see how your market position has evolved.
        </Alert>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>Date</TableCell>
                  <TableCell>Market Share</TableCell>
                  <TableCell>Competitors</TableCell>
                  <TableCell>Key Insights</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analyses.map((analysis) => (
                  <TableRow key={analysis.analysisId}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAnalyses.includes(analysis.analysisId)}
                        onChange={() => handleAnalysisSelect(analysis.analysisId)}
                        disabled={selectedAnalyses.length >= 2 && !selectedAnalyses.includes(analysis.analysisId)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Calendar size={16} />
                        {format(new Date(analysis.createdAt), 'MMM d, yyyy HH:mm')}
                      </Box>
                    </TableCell>
                    <TableCell>{analysis.marketShare.toFixed(2)}%</TableCell>
                    <TableCell>{analysis.competitors.length}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                        {analysis.insights.opportunities.length > 0 && (
                          <Chip 
                            size="small" 
                            label={`${analysis.insights.opportunities.length} opportunities`}
                            color="success"
                          />
                        )}
                        {analysis.insights.threats.length > 0 && (
                          <Chip 
                            size="small" 
                            label={`${analysis.insights.threats.length} threats`}
                            color="error"
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Download Analysis">
                        <IconButton onClick={() => handleExportAnalysis(analysis)}>
                          <Download size={16} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              // Reemplazamos Compare por GitCompare
              startIcon={<GitCompare />}
              onClick={handleCompare}
              disabled={selectedAnalyses.length !== 2}
            >
              Compare Selected
            </Button>
          </Box>

          <ComparisonDialog />
        </>
      )}
    </StyledPaper>
  );
};

export default MarketAnalysisHistory;
