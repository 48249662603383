export const colors = {
  light: {
    label: {
      primary: '#000000'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      white: '#FFFFFF',
      black: '#000000',
      green: '#31C859'
    },
    bg: {
      primaryElevated: '#FFFFFF'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#007BFE',
      backgroundBlur: '#FFFFFF',
      base: '#F2F2F7'
    },
    grouped: {
      bgPrimaryBase: '#FFFFFF'
    }
  },
  dark: {
    label: {
      primary: '#FFFFFF'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      white: '#FFFFFF',
      black: '#000000',
      green: '#2DD257'
    },
    bg: {
      primaryElevated: '#000000'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#0385FF',
      backgroundBlur: '#282828',
      base: '#272729'
    },
    grouped: {
      bgPrimaryBase: '#000000'
    }
  }
};

const getTheme = (mode) => ({
palette: {
  mode,
  colors: mode === 'light' ? colors.light : colors.dark
}
});

export const cssVariables = `
:root {
  --label-primary: ${colors.light.label.primary};
  --system-grey: ${colors.light.system.grey};
  --system-grey3: ${colors.light.system.grey3};
  --system-white: ${colors.light.system.white};
  --system-black: ${colors.light.system.black};
  --system-green: ${colors.light.system.green};
  --bg-primary-elevated: ${colors.light.bg.primaryElevated};
  --fill-tertiary: ${colors.light.fill.tertiary};
  --ui-accent: ${colors.light.ui.accent};
  --ui-background-blur: ${colors.light.ui.backgroundBlur};
  --ui-base: ${colors.light.ui.base};
  --grouped-bg-primary-base: ${colors.light.grouped.bgPrimaryBase};
}

[data-theme='dark'] {
  --label-primary: ${colors.dark.label.primary};
  --system-grey: ${colors.dark.system.grey};
  --system-grey3: ${colors.dark.system.grey3};
  --system-white: ${colors.dark.system.white};
  --system-black: ${colors.dark.system.black};
  --system-green: ${colors.dark.system.green};
  --bg-primary-elevated: ${colors.dark.bg.primaryElevated};
  --fill-tertiary: ${colors.dark.fill.tertiary};
  --ui-accent: ${colors.dark.ui.accent};
  --ui-background-blur: ${colors.dark.ui.backgroundBlur};
  --ui-base: ${colors.dark.ui.base};
  --grouped-bg-primary-base: ${colors.dark.grouped.bgPrimaryBase};
}
`;

export { getTheme };